import React, { useEffect,useState,useRef } from 'react'
import Footer from '../../components/footer'
import {useParams } from 'react-router-dom';
import NavbarNew from '../../components/navbar-new';  
import TinySlider from 'tiny-slider-react';
import axios from 'axios';
import { REST_API_END_POINT } from '../../constants/DefaultValue';
import req from '../../images/how to/image.jpg'
import apply from '../../images/how to/image (1).jpg'
import globe from '../../images/how to/image (3).jpg'
import sts from '../../images/how to/image (2).jpg'
import { empty } from '../../common/Helper';
import { DNA } from 'react-loader-spinner';

const newImg = [
  { image_url:globe },
  { image_url: req },
  { image_url: apply },
  { image_url: sts },
];

const settings1 = {
  items: 1,
  controls: true,
  mouseDrag: true,
  loop: true,
  rewind: true,
  autoplay: true,
  autoplayButtonOutput: false,
  autoplayTimeout: 3000,
  navPosition: "bottom",
  nav: false,
  speed: 400,
  gutter: 0,
  controlsText: ['<i class="mdi mdi-chevron-left "></i>', '<i class="mdi mdi-chevron-right"></i>']
};


const getData = (source, ids) => {
  if (!ids) return [];
  return ids.split(',').map(id => source.find(item => item.id == id)).filter(item => item !== undefined);
};

export default function CheckMyVisaDetails() {
  const { country_id, tab } = useParams();
  const [currentDate, setCurrentDate] = useState('');
  const [details,setDetails] = useState({})
  const [documents,setDocuments] = useState([])
  const [docRequirement, setDocRequirement] = useState([])
    const [docReqData, setDocReqData] = useState([])
    const [docName, setDocName] = useState([])
    const [activeIndex, setActiveIndex] = useState(0);
    const [activeIndexx, setActiveIndexx] = useState(0);
    const [faqs, setFaq] = useState([])
    const [loading, setLoading] = useState(true);
  const getVisaDetailsByCountryId=()=>{
      axios.get(`${REST_API_END_POINT}get-check-visa/${country_id}`)
      .then((res)=>{
        if(res.data.status===1){
          setDetails(res.data.data)
          setDocuments(res.data.documents)
          setDocRequirement(res.data.documentsRequired)
          setFaq(res.data.faq);
          setLoading(false)
        }
      })
      .catch((error)=>{
        console.log(error)
      })
     
  }


  useEffect(() => {
    const getCurrentDate = () => {
      const options = { day: 'numeric', month: 'long', year: 'numeric' };
      const today = new Date();
      return today.toLocaleDateString('en-US', options);
    };
    setCurrentDate(getCurrentDate());
}, []);

  useEffect(() => {
    getVisaDetailsByCountryId()
    const getCurrentDate = () => {
      const options = { day: 'numeric', month: 'long', year: 'numeric' };
      const today = new Date();
      return today.toLocaleDateString('en-US', options);
    };
    setCurrentDate(getCurrentDate());
}, [country_id]);

  useEffect(() => {
    document.documentElement.classList.add('dark');
  }, []);



  const [activeDocument, setActiveDocument] = useState(null);
  const handleClick = (data) => {
    setActiveIndex(0);
    setDocReqData(getData(documents, data.documents_ids));
    setDocName(data.document_type);
    setActiveDocument(data.document_type);
  };
  
  useEffect(() => {
    if (docRequirement?.length > 0) {
      handleClick(docRequirement[0])
    }
  }, [docRequirement])


  const initialFAQ = getData(faqs,details?.faq_ids)
  
  const toggleAccordion = (index) => {
    if (activeIndex === index) {
      setActiveIndex(0);
    } else {
      setActiveIndex(index);
    }
  }
  const toggleAccordions = (index) => {
    if (activeIndexx === index) {
      setActiveIndexx(0);
    } else {
      setActiveIndexx(index);
    }
  }

  // const formatDescription = (description) => {
  //   return description.replace(/<p>/g, '<p><span style="font-weight: bold; font-size: larger;">•</span> ');
  // };

  // const formatDescription = (description) => {
  //   description = description?.replace(/<p><br><\/p>/g, '<br>');
  //   description = description?.replace(/<p>/g, '<p>• ');
  //   description = description?.replace(/<ul><li>/g, '');
  //   description = description?.replace(/<li>/g, '<p>• ');
  //   description = description?.replace(/<\/li>/g, '</p>');
  //   description = description?.replace(/<\/ul>/g, '');
  
  //   if (description && !description.startsWith('<p>• ')) {
  //       description = '<p>• ' + description;
  //   }
  
  //   return description;
  // };
  // const formatDescription = (description) => {
  //   if (!description) return '';
  
  //   // Handle ordered lists
  //   let listIndex = 1;
  //   if (description.includes('<ol>')) {
  //     description = description.replace(/<ol>/g, '');
  //     description = description.replace(/<\/ol>/g, '');
  //     // description = description.replace(/<li>/g, () => {
  //     //   const item = `${listIndex++}. `;
  //     //   return `<p>${item}$1</p>`;
  //     // });
  //     description = description.replace(/<li>/g, () => {
  //       const item = `${listIndex++}. `;
  //       return `<p>&nbsp;&nbsp;&nbsp;${item}`;
  //     });
  //   }
  
  //   // Handle unordered lists
  //   if (description.includes('<ul>')) {
  //     description = description.replace(/<ul>/g, '');
  //     description = description.replace(/<\/ul>/g, '');
  //     // description = description.replace(/<li>/g, '<p>&nbsp;&nbsp;&nbsp;• $1</p>');
  //     description = description.replace(/<li>/g, '<p>&nbsp;&nbsp;&nbsp;• ');
  //   }
  //   description = description.replace(/<\/li>/g, '');
  //   // Clean up any extra <p> tags that might be left
  //   description = description.replace(/<p><\/p>/g, '');
  
  //   return description;
  // }
  const formatDescription = (description) => {
    if (!description) return '';
  
    // Handle ordered lists
    let listIndex = 1;
    if (description.includes('<ol>')) {
      description = description.replace(/<ol>/g, '');
      description = description.replace(/<\/ol>/g, '');
      description = description.replace(/<li>/g, () => {
        const item = `${listIndex++}. `;
        return `<div class="list-item">${item}`;
      });
    }
  
    // Handle unordered lists
    if (description.includes('<ul>')) {
      description = description.replace(/<ul>/g, '');
      description = description.replace(/<\/ul>/g, '');
      description = description.replace(/<li>/g, '<div class="list-item" style="margin-left:35px;">● ');
    }
  
    description = description.replace(/<\/li>/g, '</div>');
  
    // Clean up empty paragraphs if any
    description = description.replace(/<p><\/p>/g, '');
  
    return description;
  };

  return (
   <>
       {loading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
          }}
        >
        <DNA
        visible={true}
        height="140"
        width="140"     
        ariaLabel="dna-loading"
        wrapperStyle={{}}
         wrapperClass="dna-wrapper"
           />
        </div>
      ) : (<>
       <NavbarNew noHomeTab={true} tab={tab} />
  
                  <section className="relative " style={{ marginTop: '110px' }}>
                    <div className="container">
                    <div className="md:flex">
                      <div className="lg:w-5/5 md:w-full md:mb-8 md:order-2">
                        <div style={{ position: 'relative', width: '100%', maxWidth: '100%', overflow: 'hidden' }}>
                          <img
                            style={{
                              width: '100%',
                              left: '0',
                              minWidth: '300px',
                              height: '365px',
                              borderRadius: '10px',
                              objectFit: 'cover',
                            }}
                            src={details?.image}
                            alt="VIsa Search Image"
                          />
                          <div className='visa-image'>
                            {details?.name}
                          </div>
                        </div>                                                                                     
                      </div>                                                                   
                      </div>
                  </div>
  
                  <div className="group rounded-xl bg-white dark:bg-slate-900 w-full mx-auto lg:max-w-2xl "
                   style={{ zIndex: 1, marginTop: '50px',display:'flex',justifyContent:'center',alignContent:'center',alignItems:'center',paddingLeft:'10px',paddingRight:'9px'}}>
  
                  <button 
                  onClick={()=>window.open(details?.link, '_blank')}                                 
                    className='font-semibold transition-all duration-500 ease-in-out text-white bg-violet-6001 '                                                                     
                    style={{ color: 'black',width:'550px',height:'70px',display:'flex',justifyContent:'center',textAlign:'center',padding:'22px',borderRadius:'14px'}}
                    >
                    Check My Visa
                  </button>
     
                </div>
  
                <hr style={{ marginTop: '-36px', borderColor: 'black', borderWidth: '1px' }} />
  
                <div className="container" style={{ marginTop:'50px',}}>
                      <div className="md:flex" style={{marginTop:'-20px'}}>                   
                          <div className="lg:w-5/5 md:w-full md:mb-8 md:order-2" >
                              <div className="grid xl:grid-cols-1 lg:grid-cols-1 md:grid-cols-1 grid-cols-1 gap-[30px]">
                                  <div className="group relative rounded-xl bg-white dark:bg-slate-900 shadow1 p-6 text-center mt-10 " style={{display:'flex',justifyContent:'left',alignContent:'left'}}>
                                      <div className="relative -mt-16"style={{display:'flex',justifyContent:'left',alignContent:'left',textAlign:'left'}}>                            
                                          <span style={{marginTop:'60px',textAlign:'left'}}>
                                              <li className="block mt-4">                                                
                                                  <span style={{color: '#000000',fontWeight:'500',fontSize: '16px', cursor: 'pointer',textAlign:'left',}}>
                                                    {details?.description}
                                                    </span>
                                              </li>
                                          </span>
                                      </div> 
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div className="container mt-24">
             <div className="md:flex">
             <div className="lg:w-2/5 md:w-full md:pe-4 md:order-1 md:mt-0 mt-10 lg:mt-0">
             <div className="sticky top-20 -mt-10">
      {newImg?.map((ele, index) => (
        <div
          key={index}
          className="group relative rounded-xl shadow1 p-5 newImgWidth text-center mt-4 xs:hidden"
          style={{
            display: "flex",
            justifyContent: "center",
            backgroundColor:'black',
            alignContent: "center",
          //   display:'flex',
          // alignItems:'end'
          }}
        >
          <div
            className="relative"
            style={{
              display: "flex",
              // backgroundColor:'black',
              justifyContent: "center",
              alignContent: "center",
            }}
          >
            <img
              style={{
                width: "7rem",
                
                height: "auto",
                maxWidth: "100%",
                verticalAlign: "middle",
                objectFit: "contain",
              }}
              src={ele.image_url}
              alt={`Partner ${index}`}
            />
          </div>
        </div>
      ))}
      <div className="grid relative grid-cols-1 sm:hidden">
                <div className="tiny-four-icon-item">
                    <TinySlider settings={settings1}>
                        {newImg.map((item, index) => (
                            <div className="tiny-slide" key={index}>
                                <div className="group relative overflow-hidden rounded-lg shadow p-4 dark:shadow-gray-800 ease-in-out duration-500 m-2" style={{backgroundColor:'#000'}}>
                                    <img src={item.image_url}  alt={`Partner ${index}`} style={{verticalAlign: "middle",objectFit:'contain',height:'150px',justifyContent:'center',width:'100%'}} className="rounded-lg" />
                                </div>
                            </div>
                        ))}
                    </TinySlider>
                </div>
            </div>
       </div>
  </div>
  
   
               <div className="lg:w-3/5 md:w-full md:mb-8 md:order-2  "
                 style={{ paddingInline: "1rem" }}
               >
                 <ol class="c-stepper">
                 <li className="block items-center OnlyDesktopSpan1 Mt-new"
                     style={{ marginBottom: "35px",}}
                   >
                     <span
                       style={{
                         color: "#000000",
                         fontWeight: "bold",
                         fontSize: "26px",
                         cursor: "pointer",
                         
                         
                       }}
                     >
                       {/* How to Apply */}
                       How it works
                     </span>
                   </li>
                   <li class="c-stepper__item">
                     <div class="c-stepper__content">
                       <a
                         class="stepper-link c-stepper__title"
                         style={{ display: "flex", alignItems: "center",height:'70px'}}
                       >
                         <span
                           style={{
                             color: "black",
                             marginLeft: "-48px",
                             fontWeight: "bold",
                             marginTop: "-45px",
                           }}>
                            Step <br/> 01
                           </span>
                         <span
                           style={{
                             marginLeft: "10px",
                             marginTop: "-45px",
                             fontWeight: "bold",
                             fontSize:"20px",
                             flex: "1",
                           }}
                         >
                           {/* Before you apply */}
                           Click Check my visa
                         </span>
                       </a>
                       <a class="stepper-link" style={{ textAlign: "justify",marginTop:'-16px',marginLeft:'14px' }}>
                          {/* <span>{details?.beforeapply}</span> */}
                        </a>
                     </div>
                   </li>
   
                   <li class="c-stepper__item">
                     <div class="c-stepper__content">
                       <a
                         class="stepper-link c-stepper__title"
                         style={{ display: "flex", alignItems: "center" }}
                       >
                         <span
                           style={{
                             color: "black",
                             marginLeft: "-48px",
                             fontWeight: "bold",
                             marginTop: "-7px",
                           }}>
                            Step <br/> 02
                           </span>
                         <span
                           style={{
                             marginLeft: "10px",
                             marginTop: "-15px",
                             flex: "1",
                             fontWeight: "bold",
                             fontSize:"20px",
                           }}
                         >
                         {/* Collect your required documents */}
                         Fill your passport and visa details as requested
                         </span>
                       </a>
                       <a class="stepper-link" style={{ textAlign: "justify",marginTop:'-16px',marginLeft:'14px',height:'70px' }}>
                          {/* <span>{details?.collectrequireddoc}</span> */}
                        </a>
                     </div>
                   </li>
   
                   <li class="c-stepper__item">
                     <div class="c-stepper__content">
                       <a
                         class="stepper-link c-stepper__title"
                         style={{ display: "flex", alignItems: "center" }}
                       >
                         <span
                           style={{
                             color: "black",
                             marginLeft: "-48px",
                             fontWeight: "bold",
                             marginTop: "-7px",
                           }}>
                            Step <br/> 03
                           </span>
                         <span
                           style={{
                             marginLeft: "10px",
                             marginTop: "-15px",
                             flex: "1",
                             fontWeight: "bold",
                             fontSize:"20px",
                           }}
                         >
                           {/* Apply for visa <span
                            style={{
                              color: "#000000",
                              fontWeight: "bold",
                              marginTop: "7px", 
                              cursor: "pointer",
                              marginLeft: "1px",
                            }}
                          >
                            {currentDate} (Today)
                          </span> */}
                          Cross check the details of visa granted
                         </span>
                       </a>
                       <a class="stepper-link" style={{ textAlign: "justify",marginTop:'-16px',marginLeft:'14px' }}>
                          {/* <span>{details?.applyforvisa}</span> */}
                        </a>
                     </div> 
                   </li>
                   {/* <li class="c-stepper__item">
                     <div class="c-stepper__content">
                       <a
                         class="stepper-link c-stepper__title"
                         style={{ display: "flex", alignItems: "center" }}
                       >
                        <span
                           style={{
                             color: "black",
                             marginLeft: "-48px",
                             fontWeight: "bold",
                             marginTop: "-7px",
                           }}>
                            Step <br/> 04
                           </span>
                         <span
                           style={{
                             marginLeft: "10px",
                             marginTop: "-15px",
                             flex: "1",
                             fontWeight: "bold",
                             fontSize:"20px",
                           }}
                         >
                           After you apply
                         </span>
                       </a>
                       <a class="stepper-link" style={{ textAlign: "justify",marginTop:'-16px',marginLeft:'14px' }}>
                          <span>{details?.afterapply}</span>
                        </a>
                      
                     </div>
                   </li>
                   <li class="c-stepper__item">
                     <div class="c-stepper__content">
                       <a class="stepper-link c-stepper__title">
                       <span
                           style={{
                             color: "black",
                             marginLeft: "-48px",
                             fontWeight: "bold",
                             marginTop: "-7px",
                           }}>
                            Step <br/> 05
                           </span>
                         <span style={{ marginLeft: "10px", marginTop: "5px", fontWeight: "bold",fontSize:"20px", }}>
                           Visa outcome in <span style={{fontWeight: "bold",fontSize:'20px'}}>
                        
                              days as per standard visa processing time</span>
                         </span>
                       </a>
                       <a class="stepper-link" style={{ textAlign: "justify",marginTop:'-16px',marginLeft:'14px' }}>
                          <span>{details?.visaoutcome}</span>
                        </a>
                      
                     </div>
                   </li> */}
                 </ol>
               </div>
             </div>
           </div>
           <div className="container mt-24">
           {!empty(docReqData)?(
                <div className="md:flex">
                  <div className="lg:w-2/5 md:w-full md:pe-4 md:order-1 md:mt-0 mt-10 md:mt-0 lg:mt-0">
                    <div className="sticky top-20">
                      <li className="block items-center OnlyDesktopSpan1">
                        <span
                          style={{
                            color: "#000000",
                            fontWeight: "bold",
                            fontSize: "22px",
                            cursor: "pointer",
                          }}
                        >
                          Documents Required
                        </span>
                      </li>
  
                      <ol className="c-stepper mt-8 OnlyDesktop">
                        {Array.isArray(docRequirement) && (
  
                          docRequirement.map((step, index) => (
                            <li className="c-stepper__item" key={index} style={{ marginBottom: '-15px' }}>
                              <div className="c-stepper__content">
                                <a className="c-stepper__title">
                                  <span
                                    style={{
                                      color: "black",
                                      marginLeft: "-53px",
                                      fontWeight: "bold",
                                      marginTop: "7px",
                                    }}>
                                    Doc
                                  </span>
                                  <br />
                                  <span
                                    style={{
                                      color: "black",
                                      marginLeft: "-44px",
                                      fontWeight: "bold",
                                      marginTop: "7px",
                                    }}>
                                    0{index + 1}
                                  </span>
                                  <span style={{ marginLeft: "10px", marginTop: "5px" }}>
                                    <div
                                      className="color-option"
                                      style={{ marginTop: "-38px", marginLeft: "15px" }}
                                    >
                                  
                                      <div className="popover-container1">
                                        <span
                                          className="color"
                                          style={{
                                            fontSize: "20px",
                                            color: "#000",
                                            fontWeight: "bold",
                                            cursor: "pointer",
                                          }}
                                          onClick={() => handleClick(step)}
                                        >
                                          <span
                                          className='underlineeee'
                                          >
                                         
                                            {step?.document_type}
                                        </span>
                                        </span>
                                      </div>
                                    </div>
                                  </span>
                                </a>
                              </div>
                            </li>
                          ))
                        )}
                      </ol>
                      {/* <li className="block items-center" style={{marginTop:'-30px',marginBottom:'30px'}}>
                                <span style={{color: '#000000', fontWeight: 'bold', fontSize: '18px', cursor: 'pointer',fontStyle:'italic'}}>...All documents should be verified by authorized country staff's</span>
                              </li> */}
                     
                      <div
                        className="color-price"
                        style={{
                          whiteSpace: "nowrap",
                          marginTop: "5px",
                          //  marginBottom: "26px",
                        }}
                      >
                       
                      </div>
                    </div>
                  </div>
  
                  <div className="lg:w-3/5 md:w-full md:mb-8 md:order-2 OnlyDesktop"
                    style={{ padding: "2rem", backgroundColor: 'black', borderRadius: '20px' ,height:'fit-content'}}
                  >
                    <p
                      style={{
                        color: "white",
                        fontWeight: "bold",
                        textAlign: 'center',
                        marginTop: "7px",
                      }}>
                      {docName}  Documents
                    </p>
                    <div id="accordion-collapseone" data-accordion="collapse" className="mt-12" >
                      {/* {initialFAQ.map((item, index) => ( */}
                      {/* {reqDocuments && reqDocuments.length > 0 && reqDocuments.map((step, index) => ( */}
  
                      {docReqData?.map((item, index) => (
                        <div key={index} className="relative shadow1 dark:shadow1 rounded-md overflow-hidden mt-4" style={{ backgroundColor: 'white' }}>
                          <h2 className="text-base font-semibold" id="accordion-collapse-heading-1" >
                            <button onClick={() => toggleAccordion(index)} type="button" style={{ color: 'black' }}
                              className={`flex justify-between items-center p-5 w-full font-semibold text-start ${activeIndex === index ? 'bg-gray-50 dark:bg-slate-8002 text-violet-6001' : ''}`}
                              data-accordion-target="#accordion-collapse-body-1" aria-expanded="true" aria-controls="accordion-collapse-body-1">
                              <span>{item.title}</span>
                              <svg data-accordion-icon className={`w-4 h-4  shrink-0 ${activeIndex === index ? "rotate-180" : "rotate-360"}`} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path>
                              </svg>
                            </button>
                          </h2>
                          {activeIndex === index && (
                            <div id="accordion-collapse-body-1" aria-labelledby="accordion-collapse-heading-1">
                              <div className="p-5" style={{ backgroundColor: '#6e6e6e' }}>
                                <p className='travel-advice' style={{ color: 'white' }}
                                  dangerouslySetInnerHTML={{ __html: formatDescription(item?.description_html) }}></p>
                              </div>
                            </div>
                          )}
                        </div>
                      ))}
                      {/* ))} */}
                    </div>
  
                </div>
                  <div className="lg:w-3/5 md:w-full md:mb-8 md:order-2 OnlyPhone"
                    style={{ padding: "2rem", backgroundColor: 'black', borderRadius: '20px' ,height:'fit-content'}}
                  >
                  {Array.isArray(docRequirement) && (
    docRequirement.map((step, index) => (
      <div key={index}>
        <p
          style={{
            color: "white",
            fontWeight: "bold",
            textAlign: 'center',
            marginTop: "7px",
            textDecoration: activeDocument === step.document_type ? 'underline' : 'none',
          }}
          onClick={() => handleClick(step)} // Handle the click to load related documents
        >
          {step?.document_type} Documents
        </p>
  
        {docName === step.document_type && ( // Conditional rendering based on selected document type
          <div id="accordion-collapseone" data-accordion="collapse" className="mt-4">
            {docReqData?.map((item, index) => (
              <div
                key={index}
                className="relative shadow1 dark:shadow1 rounded-md overflow-hidden mt-4"
                style={{ backgroundColor: 'white' }}
              >
                <h2 className="text-base font-semibold" id={`accordion-collapse-heading-${index}`}>
                  <button
                    onClick={() => toggleAccordion(index)}
                    type="button"
                    style={{ color: 'black' }}
                    className={`flex justify-between items-center p-5 w-full font-semibold text-start ${activeIndex === index ? 'bg-gray-50 dark:bg-slate-8002 text-violet-6001' : ''}`}
                    data-accordion-target={`#accordion-collapse-body-${index}`}
                    aria-expanded={activeIndex === index}
                    aria-controls={`accordion-collapse-body-${index}`}
                  >
                    <span>{item.title}</span>
                    <svg
                      data-accordion-icon
                      className={`w-4 h-4 shrink-0 ${activeIndex === index ? "rotate-180" : "rotate-360"}`}
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </button>
                </h2>
  
                {activeIndex === index && (
                  <div id={`accordion-collapse-body-${index}`} aria-labelledby={`accordion-collapse-heading-${index}`}>
                    <div className="p-5" style={{ backgroundColor: '#6e6e6e' }}>
                      <p
                        className='travel-advice'
                        style={{ color: 'white' }}
                        dangerouslySetInnerHTML={{ __html: formatDescription(item?.description_html) }}
                      ></p>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    ))
  )}
                </div>
                
                  </div>):("")}
                  {!empty(initialFAQ)?(<div className="md:flex" style={{ marginTop: '60px',marginBottom:'-30px' }}>
                          <div className="lg:w-5/5 md:w-full md:mb-8 md:order-2" >
                              <li className="block">
                                  <span style={{ color: '#000000', fontWeight: 'bold', fontSize: '30px', cursor: 'pointer', textAlign: 'left' }}>FAQs</span>
                              </li>
  
                              <div id="accordion-collapseone" data-accordion="collapse" className="mt-12">
                                  {initialFAQ.map((item, index) => (
                                    
                                      <div key={index} className="relative shadow1 dark:shadow1 rounded-md overflow-hidden mt-4">
                                          <h2 className="text-base font-semibold" id="accordion-collapse-heading-1">
                                              <button onClick={() => toggleAccordions(index)} type="button" style={{ color: 'black' }}
                                                  className={`flex justify-between items-center p-5 w-full font-semibold text-start ${activeIndexx === index ? 'bg-gray-50 dark:bg-slate-8002 text-violet-6001' : ''}`}
                                                  data-accordion-target="#accordion-collapse-body-1" aria-expanded="true" aria-controls="accordion-collapse-body-1">
                                                  <span>{item.title}</span>
                                                  <svg data-accordion-icon className={`w-4 h-4  shrink-0 ${activeIndexx === index ? "rotate-180" : "rotate-360"}`} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                      <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path>
                                                  </svg>
                                              </button>
                                          </h2>
                                          {activeIndexx === index && (
                                              <div id="accordion-collapse-body-1" aria-labelledby="accordion-collapse-heading-1">
                                                  <div className="p-5" style={{ backgroundColor: 'rgb(110 110 110 / 15%)' }}>
                                                      <p className='travel-advice' style={{ color: 'black' }}
                                                          dangerouslySetInnerHTML={{ __html: formatDescription(item?.description) }}></p>
                                                  </div>
                                              </div>
                                          )}
                                      </div>
                                  ))}
                              </div>
  
                          </div>
                      </div>):("")}
              </div>
  
              
                  {/* <div className="container mt-24" style={{marginBottom:'-80px'}}>
                    <div className="md:flex">     
                        <div className="lg:w-2/5 md:w-full md:pe-4 md:order-1 md:mt-0 mt-10 md:mt-0 lg:mt-0">
                        <div className="sticky top-20">
  
                          <li className="block items-center">                                                
                              <span style={{color: '#000000', fontWeight: 'bold', fontSize: '22px', cursor: 'pointer',}}>Documents Required</span>
                          </li>
                            
                          <ol className="c-stepper mt-8">
                          {documents.map((step, index) => (
                       <li className="c-stepper__item" key={index}>
                         <div className="c-stepper__content">
                           <a className="c-stepper__title">
                             <span
                               className={"mdi--passport"}
                               style={{
                                 color: "black",
                                 fontSize: "1.5rem",
                                 marginLeft: "-48px",
                                 marginTop: "8px",
                               }}
                             />
                             <span
                               style={{ marginLeft: "10px", marginTop: "5px" }}
                             >
                               <div
                                 className="color-option"
                                 style={{ marginTop: "-30px" }}
                               >
                                 <div className="popover-container1">
                                   <span
                                     className="color"
                                     style={{
                                       fontSize: "20px",
                                       color: "#000",
                                       fontWeight: "bold",
                                     }}
                                   >
                                     {step?.title}
                                   </span>
                                   <div className="popover-content111">
                                     <div className="popover1">                                 
                                         <p                                       
                                           className="popover-paragraph1"
                                           style={{
                                             marginTop: index === 0 ? "-6px" : "0",
                                           }}
                                         >
                                       
                                          {parseDescription(step?.description)?.map((sentence,index)=>(                                     
                                             <span   className="font-medium inline-flex">
                                             <span
                                               style={{ color: "black" }}
                                               className="me-2"
                                             >
                                               ●
                                             </span> 
                                             <span
                                               style={{
                                                 color: "#000000",
                                                 fontSize: "16px",
                                               }}
                                             >                       
                                               {sentence}
                                             </span>
                                           </span>                                    
                                          ))}
                                          
                                         </p>                                
                                     </div>
                                   </div>
                                 </div>
                               </div>
                             </span>
                           </a>
                         </div>
                       </li>
                     ))}
                          </ol>
                          <li className="block items-center" style={{marginTop:'-50px',marginBottom:'30px'}}>
                           <span style={{color: '#000000', fontWeight: 'bold', fontSize: '18px', cursor: 'pointer',fontStyle:'italic'}}>...All documents should be verified by authorized country staff's</span>
                          </li>   
                        </div>
                      </div>
                      
                      <div className="lg:w-3/5 md:w-full md:mb-8 md:order-2" style={{paddingInline:'1rem'}}>
                          <ol class="c-stepper">
                              <li className="block items-center" style={{marginBottom:'25px'}}>                                                
                                  <span style={{color: '#000000', fontWeight: 'bold', fontSize: '24px', cursor: 'pointer',}}>How It Works</span>
                                </li>  
                              <li class="c-stepper__item">
                                  <div class="c-stepper__content">
                                      <a class="stepper-link c-stepper__title" style={{display: 'flex',alignItems: 'center'}}>                                     
                                          <Check style={{ color: 'black', fontSize: '23px',marginLeft:'-50px',marginTop:'5px' }} />
                                          <span style={{ marginLeft:'10px',marginTop:'5px', flex: '1'  }}>Click check my visa</span>
                                      </a>
                                      <a class="stepper-link" style={{textAlign:"justify"}}>
                                       <span className="font-medium inline-flex"><span style={{color: 'black'}} className="me-2">●</span><span style={{color: '#000000', fontSize: '16px', cursor: 'pointer'}}>Official {details?.name} government visa check page will open in the next tab</span></span>	                            		                 
  	                            	</a>
                                  </div>
                              </li>
  
                              <li class="c-stepper__item">
                                  <div class="c-stepper__content">
                                      <a class="stepper-link c-stepper__title" style={{display: 'flex',alignItems: 'center'}}>                                     
                                          <Check style={{ color: 'black', fontSize: '23px',marginLeft:'-50px',marginTop:'5px' }} />
                                          <span style={{ marginLeft:'10px',marginTop:'5px', flex: '1'  }}>Fill your passport and e-visa details are requested</span>
                                      </a>
                                  </div>
                              </li>
  
                              <li class="c-stepper__item">
                                  <div class="c-stepper__content">
                                      <a class="stepper-link c-stepper__title">                                     
                                          <Check style={{ color: 'black', fontSize: '23px',marginLeft:'-50px',marginTop:'5px' }} />
                                          <span style={{ marginLeft:'10px',marginTop:'5px', flex: '1'  }}>Cross check your e-visa application</span>
                                      </a>
                                  </div>
                              </li>
                          </ol>                                                                        
                      </div>                                        
                    </div>
                  </div> */}
              </section>
              <Footer />
             
          </>)}
   </>
    )
}
