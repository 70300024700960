import React, { useEffect, useState } from 'react'
import Footer from '../../components/footer'
import star from '../../assets/images/rating-star.png';
import check from '../../assets/images/tick.png';
import { Icon } from '@iconify/react';
import airplaneTakeoffDuotone from '@iconify/icons-ph/airplane-takeoff-duotone';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { LuSearch, GrVisa, CiMedicalClipboard, GiAges, GiCash, GiFamilyHouse,CgClose,} from "../../assets/icons/vander"
import {  Calendar, Clock} from 'feather-icons-react/build/IconComponents';
import NavbarNew from '../../components/navbar-new';
import NavbarPR from '../../components/navbar-pr';
import axios from 'axios';
import { REST_API_END_POINT } from '../../constants/DefaultValue';
import { empty, parseDescription } from '../../common/Helper';
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import req from '../../images/how to/image.jpg'
import apply from '../../images/how to/image (1).jpg'
import globe from '../../images/how to/image (3).jpg'
import sts from '../../images/how to/image (2).jpg'
import NotificationNav from '../../components/notificationNav';
import TinySlider from 'tiny-slider-react';
import { DNA } from 'react-loader-spinner';
const newImg = [
  { image_url: globe },
  { image_url: req },
  { image_url: apply },
  { image_url: sts },
];


const customStyles = {
  control: (styles, { isFocused, isHovered }) => ({
    ...styles,
    backgroundColor: 'transparent',
    marginTop: "-7px",
    color: 'white !important',
    placeholder: {
      color: 'white',
    },
    borderColor: isHovered ? 'white' : 'transparent',
    boxShadow: isHovered ? '0 0 0 2px white' : 'none',
    borderWidth: 0,
    outline: 'none',
    height: '30px',
  }),

  menu: provided => ({
    ...provided,
    marginLeft: '-35px',
    zIndex: 999,
  }),

  option: (styles, { isFocused }) => ({
    ...styles,

    backgroundColor: 'white',
    color: 'black',
    zIndex: isFocused ? 999 : 999,
  }),
  singleValue: (styles) => ({
    ...styles,
    color: 'white',
  }),
  menuPortal: (base) => ({
    ...base,
    zIndex: 9999,
  }),
  dropdownIndicator: (styles) => ({
    ...styles,
    color: 'white',
    '.css-tj5bde-Svg': {
      color: 'white',
    },
  }),
  input: (styles) => ({
    ...styles,
    color: 'white',
  }),
};


const VisaType = [
  { label: 'Skills', type: 1 },
  { label: 'Family', type: 2 },
  { label: ' Business/Investor', type: 3 },
  { label: 'Other etc', type: 4 },
]

const getData = (source, ids) => {
  if (!ids) return [];
  return ids.split(',').map(id => source?.find(item => item?.id == id)).filter(item => item !== undefined);
};
export default function PRPathwayDSkilledWork() {
  const { PRPathwayId, tab } = useParams();
  const navigate = useNavigate()
  const [data,setData] = useState({})
  const [country,setCountry] = useState({})
  const [userData,setUserData] = useState({})
  const [prFormData,setprFormData] = useState({})
  const [dataVar,setDataVar] = useState([])
  const [dataVarSpon,setDataVarSpon] = useState([])
  const [visaVar,setVisaVar] =  useState([])
  const [prDocs,setPrDocs] = useState([])
  const [prDetails,setPrDetails] = useState([])
  const [documents,setDocuments] = useState([])
  const [occupation,setOccupations] = useState([])
  const [selectOcc,setSelectOcc] = useState({})
  const [docRequirement, setDocRequirment] = useState({})
  const [filteredOccupation,setFilteredOccupations] = useState([])
  const [selectedLink, setSelectedLink] = useState(prDetails[0]);
  const [inputValue, setInputValue] = useState('')
  const [occupationData, setOccupationData] = useState('')
  const [isForApplicants, setIsForApplicants] = useState(false) 
  const [isSponcer, setIsSponcer] = useState(prFormData?.sponser === 1) 
  const [notification, setNotification] = useState({})
  const [currentDate, setCurrentDate] = useState("");
  const [docName, setDocName] = useState([])
  const [activeIndex, setActiveIndex] = useState(0);
  const [activeIndexx, setActiveIndexx] = useState(0);
  const [docReqData, setDocReqData] = useState([])
  const [faqs, setFaq] = useState([])
  const [visaForms,setVisaForms] = useState([])
  const [pdfDocuments,setPdfDocuments] = useState({})
  const [review,setReview] = useState(0)
  const [reviewCount,setReviewCount] = useState(0)
  const [loading, setLoading] = useState(true);
  const handleSearchOccupation = (event) => {
    const value = event.target.value;
    setInputValue(value);
    if (value) {
      // axios.post(`${REST_API_END_POINT}pr-pathway/get-search-occupation`, { search: value, typeOf_visa: data.visa_type })
      axios.post(`${REST_API_END_POINT}pr_pathway/get-all-data`, {  id:PRPathwayId,customer:1,search: value })
        .then((res) => {
          if (res.data.status === 1) {
            // setFilteredOccupations(res.data.data)
            setFilteredOccupations(res?.data?.data)
          } else {
            setFilteredOccupations([]);
          }
        })
      // const filtered = occupation.filter(occupat =>
      //   occupat.name.toLowerCase().includes(value.toLowerCase())
      // );
      // setFilteredOccupations(filtered);
    } else {
      setFilteredOccupations([]);
    }
  }
  const getOccupationForms = (data) => {
    axios.post(`${REST_API_END_POINT}pr-detail/occup-id`, { id: data.id, prID: PRPathwayId })
      .then((res) => {
        if (res.data.status === 1) {
          if (isForApplicants) {
            let result = res.data.prDetails?.filter(d => d.sponser === 1)
            setPrDetails(result);
            setSelectedLink(result[0])
            fetchPrDetailsbyId(result[0].id)
          } else {
            setPrDetails(res.data.prDetails);
            fetchPrDetailsbyId(res.data.prDetails[0].id)
            setSelectedLink(res.data.prDetails[0])
          }

          setFilteredOccupations([]);
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
  const handleSearchOption = (data) => {
    // setInputValue(data.occupation_name)
    setOccupationData(data.occupation_name)
    setInputValue('')
    setSelectOcc(data)
    getOccupationForms(data)
    setFilteredOccupations([])
  };

  const handleCloseSearch = () => {
    setInputValue('')
    setSelectOcc({})
    setFilteredOccupations([])
    // fetchDataByprID();
  }

  const handleLinkClick = (link) => {
    setSelectedLink(link);
    fetchPrDetailsbyId(link.id)
  };
  const [activeDocument, setActiveDocument] = useState(null);
  const handleClick = (data) => {
    setActiveIndex(0);
    setDocReqData(getData(documents, data.documents_ids));
    setDocName(data.document_type);
    setActiveDocument(data.document_type);
  };

  useEffect(() => {
    if (docRequirement?.length > 0) {
      handleClick(docRequirement[0])
    }
  }, [docRequirement])



  useEffect(() => {
    if (isForApplicants) {

      let filterData = prDetails?.filter(d => d.sponser === 1)
      if (filterData.length > 0 && filterData) {
        setPrDetails(filterData)
        fetchPrDetailsbyId(filterData[0]?.id)
        setSelectedLink(filterData[0])
      } else {
        setPrDetails([])
        setSelectedLink({})
        // setIsForApplicants(!isForApplicants);
        if (!empty(selectOcc)) {
          console.log(selectOcc)
          getOccupationForms(selectOcc)
        }
        //  else {
        //   fetchDataByprID()
        // }
      }
    } else {
      // fetchDataByprID()
      if (!empty(selectOcc)) {
        console.log(selectOcc)
        getOccupationForms(selectOcc)
      } else {
        fetchDataByprID()
      }
    }
  }, [isForApplicants])

  useEffect(() => {
    setIsSponcer(prFormData?.sponser === 1);
  }, [prFormData?.sponser]);

  // const handleToggle = () => {
  //   // const updatedIsSponcer = !isSponcer;
  //   setIsSponcer(!isSponcer);
  //   // localStorage.setItem('isSponcer', JSON.stringify(updatedIsSponcer));
  // };

  const handleToggle = () => {
    setIsSponcer(prev => {
      const newState = !prev;
      // You can optionally save the new state to localStorage here
      // localStorage.setItem('isSponcer', JSON.stringify(newState));
      return newState;
    });
  };


  // useEffect(() => {
  //   const savedIsSponcer = localStorage.getItem('isSponcer');
  //   if (savedIsSponcer !== null) {
  //     setIsSponcer(JSON.parse(savedIsSponcer));
  //   }
  // }, []);


    


  // if(prFormData?.sponser==0){
  //   setIsSponcer(true)
  // }
  // console.log("Personalize this page::",isForApplicants)
  // if(isForApplicants){
  //   setIsSponcer(true)
  // }else{
  //   setIsSponcer(false)
  // }

  const fetchPrDetailsbyId = (id) => {
    axios.get(`${REST_API_END_POINT}pr-pathway/get-pr-form-details/${id}`)
      .then((res) => {
        if (res.data.status === 1) {
          setReview(res.data.review)
          setReviewCount(res.data.reviewers_count)
          setprFormData(res.data.prForm_Data)
          setNotification(res.data.prForm_Data.notification_btn)
          setDataVar(res.data.data_variable)
          setDataVarSpon(res.data.data_variable_sponsor)
          setPrDocs(res.data.pr_docs)
          setVisaVar(res.data.visa_variable)
          setDocuments(res.data.doc)
          setOccupations(res.data.occupation)
          setDocRequirment(res.data.documentsRequired)
          setPdfDocuments(res.data.pdfDocument)
          setVisaForms(res.data.visa_forms)
          setFaq(res.data.faq)
          setLoading(false)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }


  const fetchDataByprID = () => {
    axios.get(`${REST_API_END_POINT}pr-pathway/get-pr-data/${PRPathwayId}`)
      .then((res) => {
        if (res.data.status === 1) {
          setCountry(res.data.countryData)
          setData(res.data.data)
          // setNotification(res.data.data.notification_btn)
          fetchPrDetailsbyId(res.data.prDetails[0]?.id)
          setSelectedLink(res.data.prDetails[0])
          setPrDetails(res.data.prDetails)
        }
      })
  }

  const toggleAccordion = (index) => {
    if (activeIndex === index) {
      setActiveIndex(0);
    } else {
      setActiveIndex(index);
    }
  }
  const toggleAccordions = (index) => {
    if (activeIndexx === index) {
      setActiveIndexx(0);
    } else {
      setActiveIndexx(index);
    }
  }

  // const formatDescription = (description) => {
  //   return description.replace(/<p>/g, '<p><span style="font-weight: bold; font-size: larger;">•</span> ');
  // };
  
  // const formatDescription = (description) => {
  //   description = description?.replace(/<p><br><\/p>/g, '<br>');
  //   description = description?.replace(/<p>/g, '<p>• ');
  //   description = description?.replace(/<ul><li>/g, '');
  //   description = description?.replace(/<li>/g, '<p>• ');
  //   description = description?.replace(/<\/li>/g, '</p>');
  //   description = description?.replace(/<\/ul>/g, '');
  
  //   if (description && !description.startsWith('<p>• ')) {
  //       description = '<p>• ' + description;
  //   }
  
  //   return description;
  // };
  // const formatDescription = (description) => {
  //   if (!description) return '';
  
  //   // Handle ordered lists
  //   let listIndex = 1;
  //   if (description.includes('<ol>')) {
  //     description = description.replace(/<ol>/g, '');
  //     description = description.replace(/<\/ol>/g, '');
  //     // description = description.replace(/<li>/g, () => {
  //     //   const item = `${listIndex++}. `;
  //     //   return `<p>${item}$1</p>`;
  //     // });
  //     description = description.replace(/<li>/g, () => {
  //       const item = `${listIndex++}. `;
  //       return `<p>&nbsp;&nbsp;&nbsp;${item}`;
  //     });
  //   }
  
  //   // Handle unordered lists
  //   if (description.includes('<ul>')) {
  //     description = description.replace(/<ul>/g, '');
  //     description = description.replace(/<\/ul>/g, '');
  //     // description = description.replace(/<li>/g, '<p>&nbsp;&nbsp;&nbsp;• $1</p>');
  //     description = description.replace(/<li>/g, '<p>&nbsp;&nbsp;&nbsp;• ');
  //   }
  //   description = description.replace(/<\/li>/g, '');
  //   // Clean up any extra <p> tags that might be left
  //   description = description.replace(/<p><\/p>/g, '');
  
  //   return description;
  // }
  const formatDescription = (description) => {
    if (!description) return '';
  
    // Handle ordered lists
    let listIndex = 1;
    if (description.includes('<ol>')) {
      description = description.replace(/<ol>/g, '');
      description = description.replace(/<\/ol>/g, '');
      description = description.replace(/<li>/g, () => {
        const item = `${listIndex++}. `;
        return `<div class="list-item">${item}`;
      });
    }
  
    // Handle unordered lists
    if (description.includes('<ul>')) {
      description = description.replace(/<ul>/g, '');
      description = description.replace(/<\/ul>/g, '');
      description = description.replace(/<li>/g, '<div class="list-item" style="margin-left:35px;">● ');
    }
  
    description = description.replace(/<\/li>/g, '</div>');
  
    // Clean up empty paragraphs if any
    description = description.replace(/<p><\/p>/g, '');
  
    return description;
  };

  const initialFAQ = getData(faqs,prFormData?.faq_ids)

  const expireDate = moment.unix(data?.expiredate);
  const formattedExpireDate = expireDate.format('MMMM D, YYYY');


useEffect(()=>{
  fetchDataByprID()
},[PRPathwayId])

  let headingData = country.name + ' PR' + ' ' + data.name

const handleClickApply=()=>{
  if(empty(userData)){
    navigate('/signup-visa/visa')
  }else{
    axios.post(`${REST_API_END_POINT}user-apply-for-visa`,{
      user_id:userData.user_id,formId:prFormData.id,is_pr:true
    })
    .then((res)=>{
      if(res.data.status==1){
        toast.success(res.data.message)
      }else{
        toast.error(res.data.message)
      }
    })
    .catch((err)=>{
      console.log(err)
    })
  }
}
const handleDownload=(data)=>{
  try {
   
    const link = document.createElement('a');
    link.href = data.url;
    link.setAttribute('download', data.name);
    link.setAttribute('target', '_blank');
    link.setAttribute('rel', 'noopener noreferrer'); 
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

  } catch (error) {
    console.error('Error downloading file:', error);
  }
}
const settings1 = {
  items: 1,
  controls: true,
  mouseDrag: true,
  loop: true,
  rewind: true,
  autoplay: true,
  autoplayButtonOutput: false,
  autoplayTimeout: 3000,
  navPosition: "bottom",
  nav: false,
  speed: 400,
  gutter: 0,
  controlsText: ['<i class="mdi mdi-chevron-left "></i>', '<i class="mdi mdi-chevron-right"></i>']
};
  return (
  <>
       {loading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
          }}
        >
        <DNA
        visible={true}
        height="140"
        width="140"     
        ariaLabel="dna-loading"
        wrapperStyle={{}}
         wrapperClass="dna-wrapper"
           />
        </div>
      ) : (<>
      {notification===1&&<NotificationNav notificationData={prFormData?.notifications}/>}
       <NavbarNew noHomeTab={true} tab={'pr-pathway'} notification={notification===1?true:false} />
  
        <section className="relative" style={{ marginTop:notification===1?'160px':'110px' }}>
          <div className="container">
            {/* {(notification === 1) && (
              <div style={{ display: "flex", justifyContent: "center" }}>
                
                <div className="flex items-center p-3 mt-3 rounded-lg" style={{ width: '100%', backgroundColor: 'grey', marginBottom: '10px', justifyContent: 'space-between', color: 'white' }}>
  
  
                  <div className="ms-2 items-center" style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'row', textAlign: 'left' }}>
                    <BsExclamationOctagon style={{ color: 'white', marginRight: '10px' }} className="text-3xl" />
                    <div>
                      <span className="block" >{data?.notifications}</span>
                    </div>
                  </div>
                  <div className="ms-2" style={{ display: 'flex', justifyContent: 'flex-end', flexDirection: 'column', textAlign: 'right' }}>
                  </div>
  
                </div>
  
              </div>
            )} */}
  
            <div className="md:flex">
              {!empty(data) && (
                <div className="lg:w-5/5 md:w-full md:mb-8 md:order-2" style={{ paddingInline: '1rem' }}>
                  <div className="color-price" style={{ marginTop: '-20px', marginBottom: '30px' }}>
                    {/* <div className="flex items-center">
                              <Link  onClick={()=>navigate(`/pr-pathway/${country.name}`,{state:{back_to:true}})}     
                              to={`/pr-pathway/${country.name}`}                                           
                                  style={{
                                      color: '#000000',
                                      fontWeight: 'bold',
                                      fontSize: '16px',
                                      cursor: 'pointer',
                                      marginLeft: '15px',
                                      paddingBottom: '3px', 
                                      whiteSpace: 'nowrap', 
                                      display: 'flex',
                                      alignItems: 'center',
                                  }}
                                  className='under'>
  
                                  <MdOutlineArrowBack color='#000' style={{ marginRight: '5px' }} /> 
                                  Go back to PR's
                              </Link>
                          </div> */}
                  </div>
                  <div style={{ position: 'relative', width: '100%', maxWidth: '100%', }}>
                  {  prFormData?.sponser==1&&
                    <div style={{ position: 'absolute', bottom: 75, right: 0, width: '285px', height: '70px', backgroundColor: 'black', backdropFilter: 'blur(100px)', borderTopLeftRadius: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <div className="side-heading" style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: 'space-between', color: 'white', fontWeight: 'bold', }}>
                        {/* <h2 style={{ marginBottom: "5px", fontSize: '25px', textAlign: 'left' }}>Personalize this page..</h2> */}
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <span style={{ marginRight: "5px", fontSize: '16px' }}>Applicants</span>
                          <label className="switch" style={{ marginBottom: "0", marginLeft: "5px", marginRight: "8px",fontSize:'15px' }}>
                            <input type="checkbox" id="togBtn" value={isSponcer} onChange={handleToggle} />
                            <div className="slider round"></div>
                          </label>
                          <span style={{ fontSize: '16px' }}>Sponsors</span>
                        </div>
                      </div>
                    </div>
  }
                    <img
                      style={{
                        width: '100%',
                        left: '0',
                        minWidth: '300px',
                        height: '310px',
                        borderRadius: '10px',
                        objectFit: 'cover',
                      }}
                      src={country.image}
                      alt={country.name}
                    />
                    <div className='visa-image' style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <span>{headingData}</span> <span
                          className="visa-image1"
                          style={{ paddingRight: "15px" }}
                        >
                          {review} <img
                           src={star}
                           height={27}
                           width={27}
                           alt="Image Description"
                           className="badge-image"
                           style={{ marginLeft: "0px", }}
                         />
                         <br />
                          <span
                            className="visa-image1"
                            style={{ display: "flex", justifyContent: "flex-end" ,fontSize:'14px'}}
                          >
                            Rating({reviewCount})
                          </span>
                        </span>
  
                      <div className="bagde-flag-wrap4">
                        <img
                          style={{
                            position: 'absolute',
                            top: '0',
                            right: '0',
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                            zIndex: '2',
                          }}
                          src={country.flag}
                          alt={country.name}
                        />
                      </div>
                    </div>
                  </div>
  
                </div>
              )}
            </div>
            <div style={{marginTop:'20px'}}>
              <Link
               to={'/consultants'}
               style={{
                color: '#000000', 
                fontWeight: 'bold',
                fontSize: '20px',
                cursor: 'pointer',
                textAlign: "center",
                display:'flex',
                justifyContent:'flex-end',
                paddingRight:'20px',
                }}>
               <span className='under1 '>Apply Now</span> 
              </Link>
            </div>
          </div>
  
          {!empty(prDetails) && (
            <>
              <div className="group rounded-xl bg-white dark:bg-slate-900 w-full mx-auto lg:max-w-2xl " style={{ zIndex: 1, marginTop: '20px', display: 'flex', justifyContent: 'center', alignContent: 'center', alignItems: 'center' ,paddingLeft:'10px',paddingRight:'9px'}}>
  
                {/* <Link
                    className='font-semibold transition-all duration-500 ease-in-out text-white bg-violet-6001 '                                  
                    type="button"
                    style={{ color: 'black',width:'550px',height:'70px',display:'flex',justifyContent:'center',textAlign:'center',padding:'22px',borderRadius:'40px'}}
                    >
                       <div className="container z-1" style={{color:'white',marginTop:'-11px'}}>
                        <div className="search-container" style={{color:'white',}}>
                          <div className="search_box" style={{color:'white',}}>          
                            <div className="search" style={{color:'white',}}>
                            <Select styles={customStyles} 
                            placeholder='Search Occupation'
                              className="input" 
                            options={Country}
                             />
                            </div>
                          </div>                     
                        </div>
                      </div>             
                                     
                  </Link> */}
                <Link
                  className='font-semibold transition-all duration-500 ease-in-out text-white bg-violet-6001 '
                  type="button"
                  style={{ color: 'black', width: '550px', height: '70px', display: 'flex', justifyContent: 'center', textAlign: 'center', padding: '22px', borderRadius: '14px' ,}}
                >
                  <div className="container z-1" style={{ color: 'white', marginTop: '-15px' }}>
                    <div className="autocomplete-container wrapper-prPath" style={{ marginTop: '0px', backgroundColor: 'black' }}>
                      <div className="search flex items-center">
                        <div onClick={handleCloseSearch}
                          className="icon-remove"
                        >
                          <CgClose style={{ color: '#fff' }} />
                        </div>
                        <input
                          type="text"
                          className="pr-path-skill-search"
                          style={{
                            color: 'white', width: '70%', backgroundColor: 'black',
                            whiteSpace: "nowrap", overflow: "hidden",
                            textOverflow: 'ellipsis'
                          }}
                          placeholder={`Search ${VisaType?.find(d => d.type === data?.visa_type)?.label}....`}
                          value={inputValue}
                          onChange={handleSearchOccupation}
                        />
                        <div className="icon" style={{ color: 'white' }}>
                          <LuSearch style={{ color: '#fff' }} />
                        </div>
                      </div>
                      {filteredOccupation?.length > 0 && (
                        <ul className="autocomplete-options1">
                          {filteredOccupation?.length > 0 ? (
                            filteredOccupation?.map((option, index) => (
                              <li key={index}
                                onClick={() => handleSearchOption(option)}
                              >
                                {/* {option.name} */}
                                {option.occupation_name}
                              </li>
                            ))
                          ) : (
                            <li>No matching Occupation found</li>
                          )}
                        </ul>
                      )}
                    </div>
                  </div>
                </Link>
              </div>
  
              <hr style={{ marginTop: '-36px', borderColor: 'black', borderWidth: '1px' }} />
  
              <div className="container" style={{ marginLeft: '0px', marginTop: '60px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div className="flex justify-between">
                  {/* <div style={{display: 'flex', justifyContent: 'center',}}>
                      <div className="flex items-center">
                        <Link style={{color: '#000000', fontWeight: 'bold', fontSize: '20px', cursor: 'pointer',marginTop:'15px',marginLeft:'10px'}}>
                          Registered Nurse is in skilled occupation list (SOL) 
                        </Link>
                      </div>                        
                    </div>                            */}
                </div>
              </div>
                  <div className="container mb-6">
                    <div className="md:flex">
                      <div className="lg:w-5/5 md:w-full md:mb-8 md:order-2" style={{ paddingInline: '1rem' }}>
                        <div style={{ position: 'relative', width: '100%', maxWidth: '100%', }}>
                          <Link style={{ color: '#000000', fontWeight: 'bold', fontSize: '30px', cursor: 'pointer',textDecoration:'underline' }}>
                            {occupationData}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="container">
                    <div className="md:flex">
                      <div className="lg:w-5/5 md:w-full md:mb-8 md:order-2" style={{ paddingInline: '1rem' }}>
                        <div style={{ position: 'relative', width: '100%', maxWidth: '100%', }}>
                          <Link style={{ color: '#000000', fontWeight: 'bold', fontSize: '24px', cursor: 'pointer', }}>
                            Visas you can apply
                          </Link>
                          <div className="flex justify-between mt-4">
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                              <div className="flex items-center" style={{ flexWrap: 'wrap' }}>
                                {prDetails?.map((item) => (
                                  <div key={item.id} style={{ marginBottom: '20px',}}>
                                    <div className="flex items-center" style={{ marginTop: '10px' }}>
                                      <button
                                        className={`px-6 py-2 color font-semibold rounded-md w-full flex items-center justify-between transition-all duration-500 ease-in-out
                                           ${selectedLink.id === item.id ? 'text-white bg-violet-6001' : 'text-black bg-white'
                                          }`}
                                        type="button"
                                        style={{ textAlign: 'left' }}
                                        onClick={() => handleLinkClick(item)}
                                      >
                                        <div className={` ${selectedLink.id === item.id ? 'text-white bg-violet-6001' : 'text-black bg-white'}`}
                                          style={{ fontSize: '26px', marginRight: '18px' }}>
                                          <span className='cib--skillshare'
                                            style={{ color: selectedLink.id !== item.id && 'black' }} />
                                        </div>
                                        <div>
                                          <span>{item.name}</span>
                                          <br />
                                          {item.sub_class}
                                        </div>
                                      </button>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {(isSponcer||!prFormData?.sponser==1)?(
                    <div className="container mt-6">
                    <div className="md:flex">
                      <div className="lg:w-5/5 md:w-full md:mb-8 md:order-2" style={{ paddingInline: '1rem' }}>
                        <div style={{ position: 'relative', width: '100%', maxWidth: '100%',}}>
                          <Link style={{ color: '#000000', fontWeight: 'bold', fontSize: '24px', cursor: 'pointer', }}>
                            With {selectedLink?.sub_class}, you can
                          </Link>
                          <div className="lg:flex" >
                            <div className="w-full" >
                              <div
                                className="grid-container"
                                style={{
                                  display: 'grid',
                                  width: '100%',
                                }}
                              >
                                <div className="color-price mt-new" >
                                  <div className="flex items-center">
                                    <div style={{ fontSize: '26px', color: '#000000', marginRight: '18px' }}>
                                      <Calendar style={{ color: 'black' }} />
                                    </div>
                                    <div className="color-option" style={{ display: 'flex', flexDirection: 'column', marginTop: '25px' }}>
                                      <Link className="color" style={{ fontSize: '18px', color: '#000', marginBottom: '4px', textAlign: 'left' }}>Stay</Link>
                                      <div className="popover-container1" style={{maxWidth:'140px'}}>
                                        <Link className="color" style={{ fontSize: '16px',color: '#000000', }}>{prFormData?.stay}</Link>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="color-price pl-minus mt-new">
                                  <div className="flex items-center">
                                    <div style={{ fontSize: '26px', color: '#000000', marginRight: '18px' }}>
                                      <Icon style={{ color: 'black' }} icon={airplaneTakeoffDuotone} />
                                    </div>
                                    <div className="color-option" style={{ display: 'flex', flexDirection: 'column', marginTop: '25px' }}>
                                      <Link className="color" style={{ fontSize: '18px', color: '#000000', marginBottom: '4px', textAlign: 'left' }}>
                                        Travel to and from
                                      </Link>
                                      <div className="popover-container1">
                                        <Link className="color" style={{ fontSize: '16px', color: '#000000', }}>
                                          {prFormData?.to_and_from}
                                        </Link>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="color-price mt-new" >
                                  <div className="flex items-center">
                                    <div style={{ fontSize: '26px', color: '#000000', marginRight: '18px' }}>
                                      <GiFamilyHouse style={{ color: 'black' }} />
                                    </div>
                                    <div className="color-option" style={{ display: 'flex', flexDirection: 'column' }}>
                                      <Link className="color" style={{ fontSize: '18px', color: '#000000', marginBottom: '4px', marginTop: '15px', textAlign: 'left' }}>Settle in {country.name}</Link>
                                      <Link className="color" style={{ fontSize: '16px', color: '#000000', }}>{prFormData?.settle_in}</Link>
                                    </div>
                                  </div>
                                </div>
                                {
                                  dataVar?.map((ele) => (
                                    <div key={ele.id} className="color-price mt-new" >
                                      <div className="flex items-center">
                                        <div style={{ fontSize: '26px', color: '#000000', marginRight: '18px' }}>
                                          <span className='icomoon-free--books' style={{ color: 'black' }} />
                                        </div>
                                        <div className="color-option" style={{ display: 'flex', flexDirection: 'column', marginTop: '50px' }}>
                                          <div className="popover-container1">
                                            <Link className="color" style={{ fontSize: '18px', color: '#000000', marginBottom: '4px', textAlign: 'left', textDecoration: 'underline' }}>
                                              {ele?.title}
                                            </Link><br></br>
                                            <img src={check} height={60} width={60} alt="Image Description" className="badge-image" style={{ marginLeft: '-13px', }} />
                                  
                                            <div className="popover-content11" style={{ marginLeft: '15px' }}>
                                              <div className="popover">
                                                {/* <h3 className="popover-heading1 color-price">{ele?.title}:</h3> */}
                                                <p dangerouslySetInnerHTML={{ __html: formatDescription(ele?.description) }}
                                                  className="popover-paragraph1 color-price travel-advice" />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ))
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>):<div className="container mt-6">
                    <div className="md:flex">
                      <div className="lg:w-5/5 md:w-full md:mb-8 md:order-2" style={{ paddingInline: '1rem' }}>
                        <div style={{ position: 'relative', width: '100%', maxWidth: '100%',}}>
                          <Link style={{ color: '#000000', fontWeight: 'bold', fontSize: '24px', cursor: 'pointer', }}>
                            With this sponsorship,you can
                          </Link>
                          <div className="lg:flex" >
                            <div className="w-full" >
                              <div
                                className="grid-container"
                                style={{
                                  display: 'grid',
                                  width: '100%',
                                }}
                              >
                               <div style={{ marginBottom: '15px',marginTop:'-5px' }}>
                                  <div className="flex items-center">
                                    <div className="color-option" style={{ display: 'flex', flexDirection: 'column', marginTop: '25px' }}>
                                      <Link className="color" style={{ fontSize: '18px', color: '#000', marginBottom: '4px', textAlign: 'left' }}>
                                      Have spouse
                                       stay</Link>
                                      <Link className="color" style={{ fontSize: '18px', color: '#000', marginBottom: '4px', textAlign: 'left' }}>
                                      temporarily in {prFormData?.to_and_from}</Link>
                                      <div className="popover-container1">
                                      </div>
                                    </div>
                                  </div>
                                </div>
                       
                                <div style={{ marginBottom: '15px',marginTop:'-5px' }}>
                                  <div className="flex items-center">
                                    <div className="color-option" style={{ display: 'flex', flexDirection: 'column', marginTop: '25px' }}>
                                      <Link className="color" style={{ fontSize: '18px', color: '#000000', marginBottom: '4px', textAlign: 'left' }}>
                                      Have spouse travel 
                                      </Link>
                                      <Link className="color" style={{ fontSize: '18px', color: '#000000', marginBottom: '4px', textAlign: 'left' }}>
                                      to and from {prFormData?.to_and_from}
                                      </Link>
                                    </div>
                                  </div>
                                </div>
                                <div style={{ marginBottom: '15px',marginTop:'4px' }}>
                                  <div className="flex items-center">
                                    <div className="color-option" style={{ display: 'flex', flexDirection: 'column' }}>
                                      <Link className="color" style={{ fontSize: '18px', color: '#000000', marginBottom: '4px', marginTop: '15px', textAlign: 'left' }}>Have spouse study</Link>
                                      <Link className="color" style={{ fontSize: '16px', color: '#000000', }}>temporarily</Link>
                                    </div>
                                  </div>
                                </div>
                                {
                                  dataVarSpon?.map((ele) => (
                                    <div key={ele.id}  style={{ marginBottom: '15px',marginTop:'17px' }}
                                    >
                                      <div className="flex items-center">
                                        <div className="color-option" style={{ display: 'flex', flexDirection: 'column', }}>
                                          <div className="popover-container1">
                                            <Link className="color" style={{ fontSize: '18px', color: '#000000', textAlign: 'left', textDecoration: 'underline' }}>
                                              {ele?.title}
                                            </Link><br></br>
                                            <div className="popover-content11" style={{ marginLeft: '15px' }}>
                                              <div className="popover">
                                                <p dangerouslySetInnerHTML={{ __html: formatDescription(ele?.description) }}
                                                  className="popover-paragraph1 color-price travel-advice" />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ))
                                }
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>}
                          <div className="container mt-6">
                    <div className="md:flex">
                      <div className="lg:w-5/5 md:w-full md:mb-8 md:order-2" style={{ paddingInline: '1rem' }}>
                        <div style={{ position: 'relative', width: '100%', maxWidth: '100%',}}>
                          <Link style={{ color: '#000000', fontWeight: 'bold', fontSize: '24px', cursor: 'pointer', }}>
                            About {selectedLink?.sub_class} Visa
                          </Link>
                          <div className="lg:flex" >
                            <div className="w-full" >
                              <div
                                className="grid-container"
                                style={{
                                  display: 'grid',
                                  width: '100%',
                                }}
                              >
                               <div className="color-price" >
                                <div className="flex items-center">
                                  <div style={{ fontSize: '26px', color: '#000000', marginRight: '18px' }}>
                                    <GiCash style={{ color: 'black' }} />
                                  </div>
                                  <div className="color-option" style={{ display: 'flex', flexDirection: 'column', marginTop: '25px' }}>
                                    <Link className="color" style={{ fontSize: '18px', color: '#000', marginBottom: '4px', textAlign: 'left' }}>
                                      Cost</Link>
                                    <div className="popover-container1">
                                      <Link className="color"
                                        style={{ fontSize: '16px', color: '#000000', }}>
                                        {prFormData?.cost} {prFormData?.cost_symbol}</Link>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="color-price ">
                                <div className="flex items-center">
                                  <div style={{ fontSize: '26px', color: '#000000', marginRight: '18px' }}>
                                    <Clock style={{ color: 'black' }} />
                                  </div>
                                  <div className="color-option" style={{ display: 'flex', flexDirection: 'column', marginTop: '25px' }}>
                                    <Link className="color" style={{ fontSize: '18px', color: '#000000', marginBottom: '4px', textAlign: 'left' }}>Processing Times</Link>
                                    <div className="popover-container1">
                                      <Link className="color"
                                        style={{ fontSize: '16px', color: '#000000', }}
                                      >{prFormData?.process_time} {prFormData?.process_time_unit}</Link>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="color-price " >
                                <div className="flex items-center">
                                  <div style={{ fontSize: '26px', color: '#000000', marginRight: '18px' }}>
                                    <span className='clarity--form-line' style={{ color: 'black' }} />
                                  </div>
                                  <div className="color-option" style={{ display: 'flex', flexDirection: 'column', marginTop: '25px' }}>
                                    <Link className="color" style={{ fontSize: '18px', color: '#000000', marginBottom: '4px', textAlign: 'left' }}>
                                      Apply from</Link>
                                    <div className="popover-container1">
                                      <Link className="color" style={{ fontSize: '16px', color: '#000000', }}>{prFormData?.applying_from}</Link>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="color-price ">
                                <div className="flex items-center">
                                  <div style={{ fontSize: '26px', color: '#000000', marginRight: '18px' }}>
                                    <GiAges style={{ color: 'black' }} />
                                  </div>
                                  <div className="color-option" style={{ display: 'flex', flexDirection: 'column', marginTop: '25px' }}>
                                    <Link className="color" style={{ fontSize: '18px', color: '#000000', marginBottom: '4px', textAlign: 'left' }}>
                                      Be the right age</Link>
                                    <div className="popover-container1">
                                      <Link className="color" style={{ fontSize: '16px', color: '#000000', }}>
                                        {prFormData?.age}
                                      </Link>
                                    </div>
                                  </div>
                                </div>
                              </div>
  
                              <div className="color-price " >
                                <div className="flex items-center">
                                  <div style={{ fontSize: '26px', color: '#000000', marginRight: '18px' }}>
                                    <GrVisa style={{ color: 'black' }} />
                                  </div>
                                  <div className="color-option" style={{ display: 'flex', flexDirection: 'column', marginTop: '25px' }}>
                                    <Link className="color" style={{ fontSize: '18px', color: '#000000', marginBottom: '4px', textAlign: 'left' }}>Visa label</Link>
                                    <div className="popover-container1">
                                      <Link className="color"
                                        style={{ fontSize: '16px', color: '#000000', }} >{prFormData?.visa_label}</Link>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="color-price" >
                                <div className="flex items-center">
                                  <div style={{ fontSize: '26px', color: '#000000', marginRight: '18px' }}>
                                    <CiMedicalClipboard style={{ color: 'black' }} />
                                  </div>
                                  <div className="color-option" style={{ display: 'flex', flexDirection: 'column' }}>
                                    <Link className="color" style={{ fontSize: '18px', color: '#000000', marginBottom: '4px', marginTop: '15px', textAlign: 'left' }}>Medical</Link>
                                    <Link className="color" style={{ fontSize: '16px', color: '#000000', }}>{prFormData?.medical}</Link>
                                  </div>
                                </div>
                              </div>
                              <div className="color-price " >
                                <div className="flex items-center">
                                  <div style={{ fontSize: '26px', color: '#000000', marginRight: '18px' }}>
                                    <span className='fluent-mdl2--commitments' style={{ color: 'black' }} />
                                  </div>
                                  <div className="color-option" style={{ display: 'flex', flexDirection: 'column' }}>
                                    <Link className="color" style={{ fontSize: '18px', color: '#000000', marginBottom: '4px', marginTop: '15px', textAlign: 'left' }}>
                                      Marriage</Link>
                                    <Link className="color" style={{ fontSize: '16px', color: '#000000', }}>{prFormData?.marriage}</Link>
                                  </div>
                                </div>
                              </div>
                              <div className="color-price " >
                                <div className="flex items-center">
                                  <div style={{ fontSize: '26px', color: '#000000', marginRight: '18px' }}>
                                    <span className='streamline--insurance-hand' style={{ color: 'black' }} />
                                  </div>
                                  <div className="color-option" style={{ display: 'flex', flexDirection: 'column' }}>
                                    <Link className="color" style={{ fontSize: '18px', color: '#000000', marginBottom: '4px', marginTop: '15px', textAlign: 'left' }}>Insurance</Link>
                                    <Link className="color" style={{ fontSize: '16px', color: '#000000', }}>{prFormData?.insurance}</Link>
                                  </div>
                                </div>
                              </div>
                              <div className="color-price " >
                                <div className="flex items-center">
                                  <div style={{ fontSize: '26px', color: '#000000', marginRight: '18px' }}>
                                    <span className='game-icons--relationship-bounds' style={{ color: 'black' }} />
                                  </div>
                                  <div className="color-option" style={{ display: 'flex', flexDirection: 'column' }}>
                                    <Link className="color" style={{ fontSize: '18px', color: '#000000', marginBottom: '4px', marginTop: '15px', textAlign: 'left' }}>
                                      Meet relationship</Link>
                                    <Link className="color" style={{ fontSize: '16px', color: '#000000', }}>
                                      {prFormData?.meetRelationship}
                                    </Link>
                                  </div>
                                </div>
                              </div>
                              <div className="color-price " >
                                <div className="flex items-center">
                                  <div style={{ fontSize: '26px', color: '#000000', marginRight: '18px' }}>
                                    <span className='icon-park-outline--english' style={{ color: 'black' }} />
                                  </div>
                                  <div className="color-option" style={{ display: 'flex', flexDirection: 'column' }}>
                                    <Link className="color" style={{ fontSize: '18px', color: '#000000', marginBottom: '4px', marginTop: '15px', textAlign: 'left' }}>
                                      English</Link>
                                    <Link className="color" style={{ fontSize: '16px', color: '#000000', }}>
                                      {prFormData?.english}
                                    </Link>
                                  </div>
                                </div>
                              </div>
                              <div className="color-price mt-new-one">
                                <div className="flex items-center">
                                  <div style={{ fontSize: '26px', color: '#000000', marginRight: '18px' }}>
                                    <span className='foundation--social-skillshare' style={{ color: 'black' }} />
                                  </div>
                                  <div className="color-option" style={{ display: 'flex', flexDirection: 'column' }}>
                                    <Link className="color" style={{ fontSize: '18px', color: '#000000', marginBottom: '4px', marginTop: '15px', textAlign: 'left' }}>
                                      Skill Assessment</Link>
                                    <Link className="color" style={{ fontSize: '16px', color: '#000000', }}>
                                      {prFormData?.skill_assessment}
                                    </Link>
                                  </div>
                                </div>
                              </div>
                              {
                                visaVar?.map((ele) => (
                                  <div key={ele.id} className="color-price"  style={{marginTop:'-12px'}}>
                                    <div className="flex items-center">
                                      <div style={{ fontSize: '26px', color: '#000000', marginRight: '18px' }}>
                                        <span className='uil--award' style={{ color: 'black' }} />
                                      </div>
                                      <div className="color-option" style={{ display: 'flex', flexDirection: 'column', marginTop: '40px' }}>
                                        <div className="popover-container1">
                                          <Link className="color" style={{ fontSize: '18px', color: '#000000', marginBottom: '4px', textAlign: 'left', textDecoration: 'underline' }}>
                                            {ele.title}
                                          </Link><br></br>
                                          <img src={check} height={60} width={60} alt="Image Description" className="badge-image" style={{ marginLeft: '-13px', }} />
                                          <div className="popover-content11" style={{ marginLeft: '15px' }}>
                                            <div className="popover">
                                              {/* <h3 className="popover-heading1 color-price"> {ele.title}:</h3> */}
                                              <p className="popover-paragraph1 color-price travel-advice"
                                                dangerouslySetInnerHTML={{ __html: formatDescription(ele?.description) }} />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))
                              }
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
  
              {/* <div className="container mt-24">
                    <div className="md:flex">
                        <div className="lg:w-2/5 md:w-full md:pe-4 md:order-1 md:mt-0 mt-10 md:mt-0 lg:mt-0">
                          <div className="sticky top-20">
                              <ol className="c-stepper">
                                <li className="block items-center">                                                
                                  <span style={{ color: '#000000', fontWeight: 'bold', fontSize: '22px', cursor: 'pointer' }}>Documents Required</span>
                                </li>
                                {document?.map((item, index) => (
                                  <li className="c-stepper__item" key={index} style={{ marginTop: index > 0 ? '30px' : '30px' }}>
                                    <div className="popover-container1">
                                      <div className="c-stepper__content">
                                        <a className="stepper-link c-stepper__title">
                                          <span 
                                          className={"ion--documents"}
                                           style={{ color: 'black', fontSize: '27px', marginLeft: '-50px', marginTop: '5px' }} />
                                          <span  className="color" 
                                          style={{ fontSize: '20px', color: '#000', fontWeight: 'bold',marginLeft: '10px', marginTop: '5px', }}>
                                            {item.title}</span>
                                        </a>
                                      </div>                              
                                       <div className="popover-content111">
                                     <div className="popover1">                              
                                         <p className="popover-paragraph1"
                                           style={{
                                             marginTop: index === 0 ? "-6px" : "0",
                                           }}
                                         >
                                       
                                          {parseDescription(item?.description)?.map((sentence,index)=>(                                     
                                             <span key={index}  className="font-medium inline-flex">
                                             <span
                                               style={{ color: "black" }}
                                               className="me-2"
                                             >
                                               ●
                                             </span> 
                                             <span
                                               style={{
                                                 color: "#000000",
                                                 fontSize: "16px",
                                               }}
                                             >                       
                                               {sentence}
                                             </span>
                                           </span>                                    
                                          ))}  
                                         </p>                                   
                                     </div>
                                   </div>
                                    </div>
                                  </li>
                                ))}
                              </ol>
                              <li className="block items-center" style={{marginTop:'-30px',marginBottom:'30px'}}>
                                <span style={{color: '#000000', fontWeight: 'bold', fontSize: '18px', cursor: 'pointer',fontStyle:'italic'}}>...All documents should be verified by authorized country staff's</span>
                              </li>
                            </div>
                        </div>
                      
                      <div className="lg:w-3/5 md:w-full md:mb-8 md:order-2" style={{paddingInline:'1rem'}}>
                          <ol class="c-stepper">
                              <li className="block items-center" style={{marginBottom:'30px'}}>                                                
                                <span style={{color: '#000000', fontWeight: 'bold', fontSize: '24px', cursor: 'pointer',}}>PR Pathway</span>
                              </li>  
                              {prDocs?.map((item, index) => (
                                  <li className="c-stepper__item" key={index} style={{ marginTop: index > 0 ? '30px' : '30px' }}>
                                    <div className="popover-container1">
                                      <div className="c-stepper__content">
                                        <a className="stepper-link c-stepper__title">
                                          <span 
                                          className={"arcticons--amazon-visa"}
                                           style={{ color: 'black', fontSize: '27px', marginLeft: '-50px', marginTop: '5px' }} />
                                          <span  className="color" 
                                          style={{ fontSize: '20px', color: '#000', fontWeight: 'bold',marginLeft: '10px', marginTop: '5px', }}>
                                            {item.title}</span>
                                        </a>
                                      </div>                              
                                       <div className="popover-content111">
                                     <div className="popover1">                              
                                         <p className="popover-paragraph1"
                                           style={{
                                             marginTop: index === 0 ? "-6px" : "0",
                                           }}
                                         >
                                       
                                          {parseDescription(item?.description)?.map((sentence,index)=>(                                     
                                             <span   className="font-medium inline-flex">
                                             <span
                                               style={{ color: "black" }}
                                               className="me-2"
                                             >
                                               ●
                                             </span> 
                                             <span
                                               style={{
                                                 color: "#000000",
                                                 fontSize: "16px",
                                               }}
                                             >                       
                                               {sentence}
                                             </span>
                                           </span>                                    
                                          ))}  
                                         </p>                                   
                                     </div>
                                   </div>
                                    </div>
                                  </li>
                                ))}
                          </ol>  
                      </div>                                        
                    </div>
                  </div> */}
              <div className="container mt-6">
                <div className="md:flex">
                  <div className="lg:w-2/5 md:w-full md:pe-4 newImgWidth md:order-1 md:mt-0 mt-10 lg:mt-0">
                  <div className="sticky top-20">
                    {newImg?.map((ele, index) => (
                      <div
                        key={index}
                        className="group relative rounded-xl shadow1 p-5 text-center mt-4 xs:hidden"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          backgroundColor: 'black',
                          alignContent: "center",
                          //   display:'flex',
                          // alignItems:'end'
                        }}
                      >
                        <div
                          className="relative"
                          style={{
                            display: "flex",
                            // backgroundColor:'black',
                            justifyContent: "center",
                            alignContent: "center",
                          }}
                        >
                          <img
                            style={{
                              width: "7rem",
  
                              height: "auto",
                              maxWidth: "100%",
                              verticalAlign: "middle",
                              objectFit: "contain",
                            }}
                            src={ele.image_url}
                            alt={`Partner ${index}`}
                          />
                        </div>
                      </div>
                    ))}
                    <div className="grid relative grid-cols-1 sm:hidden">
                        <div className="tiny-four-icon-item">
                            <TinySlider settings={settings1}>
                                {newImg.map((item, index) => (
                                    <div className="tiny-slide" key={index}>
                                        <div className="group relative overflow-hidden rounded-lg shadow p-4 dark:shadow-gray-800 ease-in-out duration-500 m-2" style={{backgroundColor:'#000'}}>
                                            <img src={item.image_url}  alt={`Partner ${index}`} style={{verticalAlign: "middle",objectFit:'contain',height:'150px',justifyContent:'center',width:'100%'}} className="rounded-lg" />
                                        </div>
                                    </div>
                                ))}
                            </TinySlider>
                        </div>
                    </div>
                    </div>
                  </div>
  
  
                  <div className="lg:w-3/5 md:w-full md:mb-8 md:order-2"
                    style={{ paddingInline: "1rem" }}
                  >
                    <ol class="c-stepper">
                      <li
                        className="block items-center"
                        style={{ marginBottom: "30px", marginTop: '17px' }}
                      >
                        <span
                          style={{
                            color: "#000000",
                            fontWeight: "bold",
                            fontSize: "24px",
                            cursor: "pointer",
  
                          }}
                        >
                          How to Apply
                        </span>
                      </li>
                      <li class="c-stepper__item">
                        <div class="c-stepper__content">
                          <a
                            class="stepper-link c-stepper__title"
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <span
                              style={{
                                color: "black",
                                marginLeft: "-48px",
                                fontWeight: "bold",
                                marginTop: "-7px",
                              }}>
                              Step <br /> 01
                            </span>
                            <span
                              style={{
                                marginLeft: "10px",
                                marginTop: "-15px",
                                fontWeight: "bold",
                                fontSize: "20px",
                                flex: "1",
                              }}
                            >
                              Before you apply
                            </span>
                          </a>
                          <a class="stepper-link travel-advice" style={{ textAlign: "justify", marginTop: '-16px', marginLeft: '14px' }}>
                            <span dangerouslySetInnerHTML={{__html:formatDescription(prFormData?.beforeapply)}} />
                          </a>
                        </div>
                      </li>
  
                      <li class="c-stepper__item">
                        <div class="c-stepper__content">
                          <a
                            class="stepper-link c-stepper__title"
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <span
                              style={{
                                color: "black",
                                marginLeft: "-48px",
                                fontWeight: "bold",
                                marginTop: "-7px",
                              }}>
                              Step <br /> 02
                            </span>
                            <span
                              style={{
                                marginLeft: "10px",
                                marginTop: "-15px",
                                flex: "1",
                                fontWeight: "bold",
                                fontSize: "20px",
                              }}
                            >
                              Collect your required documents
                            </span>
                          </a>
                          <a class="stepper-link travel-advice" style={{ textAlign: "justify", marginTop: '-16px', marginLeft: '14px' }}>
                            <span  dangerouslySetInnerHTML={{__html:formatDescription(prFormData?.collectrequireddoc)}} />
                          </a>
                        </div>
                      </li>
  
                      <li class="c-stepper__item">
                        <div class="c-stepper__content">
                          <a
                            class="stepper-link c-stepper__title"
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <span
                              style={{
                                color: "black",
                                marginLeft: "-48px",
                                fontWeight: "bold",
                                marginTop: "-7px",
                              }}>
                              Step <br /> 03
                            </span>
                            <span
                              style={{
                                marginLeft: "10px",
                                marginTop: "-15px",
                                flex: "1",
                                fontWeight: "bold",
                                fontSize: "20px",
                              }}
                            >
                              Apply for visa <span
                                style={{
                                  color: "#000000",
                                  fontWeight: "bold",
                                  marginTop: "7px",
                                  cursor: "pointer",
                                  marginLeft: "1px",
                                }}
                              >
                                {currentDate} (Today)
                              </span>
                            </span>
                          </a>
                          <a class="stepper-link travel-advice" style={{ textAlign: "justify", marginTop: '-16px', marginLeft: '14px' }}>
                            <span dangerouslySetInnerHTML={{__html:formatDescription( prFormData?.applyforvisa)}} />
                          </a>
                        </div>
                      </li>
                      <li class="c-stepper__item">
                        <div class="c-stepper__content">
                          <a
                            class="stepper-link c-stepper__title"
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <span
                              style={{
                                color: "black",
                                marginLeft: "-48px",
                                fontWeight: "bold",
                                marginTop: "-7px",
                              }}>
                              Step <br /> 04
                            </span>
                            <span
                              style={{
                                marginLeft: "10px",
                                marginTop: "-15px",
                                flex: "1",
                                fontWeight: "bold",
                                fontSize: "20px",
                              }}
                            >
                              After you apply
                            </span>
                          </a>
                          <a class="stepper-link travel-advice" style={{ textAlign: "justify", marginTop: '-16px', marginLeft: '14px' }}>
                            <span dangerouslySetInnerHTML={{__html:formatDescription( prFormData?.afterapply)}} />
                          </a>
                          {/* <li class="stepper-item">
                         {/* <span class="stepper-counter"><span class="streamline--check-solid" style={{color:'#80bc04',fontSize:'30px'}}></span></span> */}
                          {/* <span class="stepper-counter"> */}
                          {/* <img src={check} style={{ marginLeft: '10px' ,fontSize:'30px'}} height={100} width={100} alt="Image Description" className="badge-image"  /> */}
  
                          {/* <span
                             class="mdi--tick"
                             style={{ color: "#80bc04", fontSize: "30px" }}
                           ></span>
                         </span>
                         <a class="stepper-link">
                           <span>
                             Application has been sent to the immigration
                             supervisor
                           </span>
                           <span style={{ paddingTop: 6 }} className="bagde-flag1">
                             ON TIME
                           </span>
                         </a>
                         <span class="stepper-line"></span>
                       </li> */}
  
                          {/* <li class="stepper-item">
                         <span class="stepper-counter">
                           <span
                             class="mdi--tick"
                             style={{ color: "#80bc04", fontSize: "30px" }}
                           ></span>
                         </span>
                         <a class="stepper-link">
                           <span>
                             Application has been sent to the internal intelligence
                           </span>
                           <span style={{ paddingTop: 6 }} className="bagde-flag1">
                             ON TIME
                           </span>
                         </a>
                         <span class="stepper-line"></span>
                       </li> */}
                          {/* <li class="stepper-item">
                         <span class="stepper-counter">
                           <span
                             class="mdi--tick"
                             style={{ color: "#80bc04", fontSize: "30px" }}
                           ></span>
                         </span>
                         <a class="stepper-link">
                           <span>Application sent to the clearance officer </span>
                         </a>
                         <span class="stepper-line"></span>
                       </li> */}
                        </div>
                      </li>
                      <li class="c-stepper__item">
                        <div class="c-stepper__content">
                          <a class="stepper-link c-stepper__title">
                            <span
                              style={{
                                color: "black",
                                marginLeft: "-48px",
                                fontWeight: "bold",
                                marginTop: "-7px",
                              }}>
                              Step <br /> 05
                            </span>
                            <span style={{ marginLeft: "10px", marginTop: "5px", fontWeight: "bold", fontSize: "20px", }}>
                              Visa outcome in <span style={{ fontWeight: "bold", fontSize: '20px' }}>{prFormData.process_time} {prFormData.process_time_unit} as per standard visa processing time</span>
                            </span>
                          </a>
                          <a class="stepper-link travel-advice" style={{ textAlign: "justify", marginTop: '-16px', marginLeft: '14px' }}>
                            <span dangerouslySetInnerHTML={{__html:formatDescription(prFormData?.visaoutcome)}} />
                          </a>
                          {/* <li class="stepper-item">
                         <span class="stepper-counter"></span>
                         <a class="stepper-link">
                           <span
                             style={{
                               color: "#000000",
                               fontWeight: "bold",
                               color: "black",
                               fontSize: "19px",
                               marginLeft: "-50px",
                               marginTop: "5px",
                             }}
                           >
                             {countryDetails?.no_of_dates} Days
                           </span>
                           <span
                             style={{
                               marginLeft: "10px",
                               paddingTop: 7,
                               background: "#000000",
                             }}
                             className="bagde-flag1"
                           >
                             Guaranteed
                           </span>
                         </a>
                         <span class="stepper-line"></span>
                       </li> */}
                        </div>
                      </li>
                    </ol>
                    
                  </div>
                </div>
              </div>        
              {!empty(docReqData)?(<div className="container mt-24">
                <div className="md:flex">
                  <div className="lg:w-2/5 md:w-full md:pe-4 md:order-1 md:mt-0 md:mt-0 lg:mt-0 OnlyPhoneDocument">
                    <div className="sticky top-20">
                      <li className="block items-center OnlyDesktopSpan1">
                        <span
                          style={{
                            color: "#000000",
                            fontWeight: "bold",
                            fontSize: "22px",
                            cursor: "pointer",
                          }}
                        >
                          Documents Required
                        </span>
                      </li>
  
                      <ol className="c-stepper mt-8 OnlyDesktop">
                        {Array.isArray(docRequirement) && (
  
                          docRequirement.map((step, index) => (
                            <li className="c-stepper__item" key={index} style={{ marginBottom: '-15px' }}>
                              <div className="c-stepper__content">
                                <a className="c-stepper__title">
                                  <span
                                    style={{
                                      color: "black",
                                      marginLeft: "-53px",
                                      fontWeight: "bold",
                                      marginTop: "7px",
                                    }}>
                                    Doc
                                  </span>
                                  <br />
                                  <span
                                    style={{
                                      color: "black",
                                      marginLeft: "-44px",
                                      fontWeight: "bold",
                                      marginTop: "7px",
                                    }}>
                                    0{index + 1}
                                  </span>
                                  <span style={{ marginLeft: "10px", marginTop: "5px" }}>
                                    <div
                                      className="color-option"
                                      style={{ marginTop: "-38px", marginLeft: "15px" }}
                                    >
                                  
                                      <div className="popover-container1">
                                        <span
                                          className="color"
                                          style={{
                                            fontSize: "20px",
                                            color: "#000",
                                            fontWeight: "bold",
                                            cursor: "pointer",
                                          }}
                                          onClick={() => handleClick(step)}
                                        >
                                          <span
                                          className='underlineeee'
                                          >
                                         
                                            {step?.document_type}
                                        </span>
                                        </span>
                                      </div>
                                    </div>
                                  </span>
                                </a>
                              </div>
                            </li>
                          ))
                        )}
                      </ol>
                     <div className='OnlyDesktop '>
                        { visaForms?.length>0 && (
                     <li style={{marginTop:'20px'}} className="block items-center">
                       <span
                         style={{
                          color: "#000000",
                          fontWeight: "bold",
                          fontSize: "22px",
                          cursor: "pointer",                  
                         }}
                       >
                         Visa Forms
                       </span>
                     </li>)}
                     <ol className="c-stepper mt-8">
                       {visaForms?.map((d, index) => (
                         <li className="c-stepper__item" key={index} style={{marginBottom:'-15px'}}>
                           <div className="c-stepper__content">
                             <a className="c-stepper__title">                     
                              <span
                              className="material-symbols--download"
                             style={{
                               color: "black",
                               marginLeft: "-50px",
                               fontSize: "23px",
                               marginTop: "7px",
                             }}>                     
                              </span>              
                             <span
                                 style={{ marginLeft: "10px", marginTop: "5px" }}
                               >
                                 <div
                                   className="color-option"
                                   style={{ marginTop: "-38px",marginLeft:"15px" }}
                                 >
                                    <Link
                             className="px-6 py-2 font-semibold rounded-md w-full transition-all 
                             duration-500 ease-in-out shadow1  truncated-link"
                             type="button"
                             onClick={()=>handleDownload(d)}
                             style={{
                              cursor: "pointer",
                              color: "black",
                              display: "flex",
                              alignItems: "center",
                              whiteSpace: "nowrap", 
                              overflow: "hidden", 
                              textOverflow: "ellipsis", 
                            }}
                           >                     
                             {d?.name.slice(0, 12)}
                           </Link>
                                 </div>
                               </span>
                             </a>
                           </div>
                         </li>
                       ))}
                        <li className="c-stepper__item" key={1} >
                        <div className="c-stepper__content">
                             <a className="c-stepper__title">
                             <span
                              className="material-symbols--download"
                             style={{
                               color: "black",
                               marginLeft: "-50px",
                               fontSize: "23px",
                               marginTop: "7px",
                             }}>                     
                              </span>
                              <br/>
                              <span style={{color: "black",
                               marginLeft: "-44px",
                               fontWeight: "bold",
                               marginTop: "7px",}}>
                                </span>
                                <span
                                 style={{ marginLeft: "10px", marginTop: "5px" }}
                               >
                                 <div
                                   className="color-option"
                                   style={{ marginTop: "-65px",marginLeft:"15px" }}
                                 >
                                   <Link
                             className="px-6 py-2 font-semibold rounded-md w-full transition-all 
                             duration-500 ease-in-out shadow1"
                             type="button"
                            onClick={()=>handleDownload(pdfDocuments)}
                             style={{
                               cursor: "pointer",
                               color: "black",
                               display: "flex",
                               alignItems: "center",
                             }}
                           >
                             <span
                              //  className="material-symbols--download"
                               
                               style={{
                                 cursor: "pointer",
                                 whiteSpace: "nowrap",
                                //  marginRight: "5px",
                                 fontSize: "23px",
                               }}
                             ></span>
                             Download Checklist
                           </Link>
                                 </div>
                               </span>                  
                             </a>
                           </div>
                             </li>
                     </ol>
                     </div>
                      {/* <li className="block items-center" style={{marginTop:'-30px',marginBottom:'30px'}}>
                                <span style={{color: '#000000', fontWeight: 'bold', fontSize: '18px', cursor: 'pointer',fontStyle:'italic'}}>...All documents should be verified by authorized country staff's</span>
                              </li> */}
                      {/* <li
                     className="block items-center"
                     style={{ marginTop: "-10px", marginBottom: "30px" }}
                   >
                     <span
                       style={{
                         color: "#000000",
                         fontWeight: "bold",
                         fontSize: "18px",
                         cursor: "pointer",
                         fontStyle: "italic",
                       }}
                     >
                       ...All documents should be verified by authorized country
                       staff's
                     </span>
                   </li> */}
                      <div
                        className="color-price"
                        style={{
                          whiteSpace: "nowrap",
                          marginTop: "5px",
                          //  marginBottom: "26px",
                        }}
                      >
                        {/* <div className="flex items-center">
                       <Link
                         className="px-6 py-2 font-semibold rounded-md w-full transition-all duration-500 ease-in-out shadow1"
                         type="button"
                         onClick={()=>handleDownload(currentFormData?.checkList)}
                         style={{
                           cursor: "pointer",
                           color: "black",
                           display: "flex",
                           alignItems: "center",
                         }}
                       >
                         <span
                           className="material-symbols--download"
                           
                           style={{
                             cursor: "pointer",
                             whiteSpace: "nowrap",
                            //  marginRight: "5px",
                             fontSize: "23px",
                           }}
                         ></span>
                         Download Checklist
                       </Link>
                     </div> */}
                      </div>
                    </div>
                  </div>
  
                  <div className="lg:w-3/5 md:w-full md:mb-8 md:order-2 OnlyDesktop"
                    style={{ padding: "2rem", backgroundColor: 'black', borderRadius: '20px',height:'fit-content' }}
                  >
                    <p
                      style={{
                        color: "white",
                        fontWeight: "bold",
                        textAlign: 'center',
                        marginTop: "7px",
                      }}>
                      {docName}  Documents
                    </p>
                    <div id="accordion-collapseone" data-accordion="collapse" className="mt-12" >
                      {/* {initialFAQ.map((item, index) => ( */}
                      {/* {reqDocuments && reqDocuments.length > 0 && reqDocuments.map((step, index) => ( */}
  
                      {docReqData?.map((item, index) => (
                        <div key={index} className="relative shadow1 dark:shadow1 rounded-md overflow-hidden mt-4" style={{ backgroundColor: 'white' }}>
                          <h2 className="text-base font-semibold" id="accordion-collapse-heading-1" >
                            <button onClick={() => toggleAccordion(index)} type="button" style={{ color: 'black' }}
                              className={`flex justify-between items-center p-5 w-full font-semibold text-start ${activeIndex === index ? 'bg-gray-50 dark:bg-slate-8002 text-violet-6001' : ''}`}
                              data-accordion-target="#accordion-collapse-body-1" aria-expanded="true" aria-controls="accordion-collapse-body-1">
                              <span>{item.title}</span>
                              <svg data-accordion-icon className={`w-4 h-4  shrink-0 ${activeIndex === index ? "rotate-180" : "rotate-360"}`} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path>
                              </svg>
                            </button>
                          </h2>
                          {activeIndex === index && (
                            <div id="accordion-collapse-body-1" aria-labelledby="accordion-collapse-heading-1">
                              <div className="p-5" style={{ backgroundColor: '#6e6e6e' }}>
                                <p className="travel-advice" style={{ color: 'white' }}
                                  dangerouslySetInnerHTML={{ __html: formatDescription(item?.description) }}></p>
                              </div>
                            </div>
                          )}
                        </div>
                      ))}
                      {/* ))} */}
                    </div>
  
                  </div>
                  <div className="lg:w-3/5 md:w-full md:mb-8 md:order-2 OnlyPhone "
                    style={{ padding: "2rem", backgroundColor: 'black', borderRadius: '20px',height:'fit-content' }}
                  >
                    {Array.isArray(docRequirement) && (
    docRequirement.map((step, stepIndex) => (
      <div key={stepIndex}>
        <p
          style={{
            color: "white",
            fontWeight: "bold",
            textAlign: 'center',
            marginTop: "7px",
            textDecoration: activeDocument === step.document_type ? 'underline' : 'none',
          }}
          onClick={() => handleClick(step)}
        >
          {step?.document_type} Documents
        </p>
  
        {/* Conditional rendering: only show documents for the selected document type */}
        {docName === step.document_type && (
          <div id={`accordion-collapse-${stepIndex}`} data-accordion="collapse" className="mt-4">
            {docReqData?.map((item, index) => (
              <div
                key={index}
                className="relative shadow1 dark:shadow1 rounded-md overflow-hidden mt-4"
                style={{ backgroundColor: 'white' }}
              >
                <h2 className="text-base font-semibold" id={`accordion-heading-${index}`}>
                  <button
                    onClick={() => toggleAccordion(index)}
                    type="button"
                    style={{ color: 'black' }}
                    className={`flex justify-between items-center p-5 w-full font-semibold text-start ${
                      activeIndex === index ? 'bg-gray-50 dark:bg-slate-8002 text-violet-6001' : ''
                    }`}
                    data-accordion-target={`#accordion-body-${index}`}
                    aria-expanded={activeIndex === index}
                    aria-controls={`accordion-body-${index}`}
                  >
                    <span>{item.title}</span>
                    <svg
                      data-accordion-icon
                      className={`w-4 h-4 shrink-0 ${activeIndex === index ? 'rotate-180' : 'rotate-360'}`}
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </button>
                </h2>
  
                {activeIndex === index && (
                  <div id={`accordion-body-${index}`} aria-labelledby={`accordion-heading-${index}`}>
                    <div className="p-5" style={{ backgroundColor: '#6e6e6e' }}>
                      <p
                        style={{ color: 'white' }}
                        dangerouslySetInnerHTML={{ __html: formatDescription(item?.description) }}
                      ></p>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    ))
  )}
  
  
                  </div>
                  <div className='OnlyPhone OnlyPhoneDocument1'>
                        { visaForms?.length>0 && (
                     <li style={{marginTop:'20px'}} className="block items-center">
                       <span
                         style={{
                          color: "#000000",
                          fontWeight: "bold",
                          fontSize: "22px",
                          cursor: "pointer",                  
                         }}
                       >
                         Visa Forms
                       </span>
                     </li>)}
                     <ol className="c-stepper mt-8">
                       {visaForms?.map((d, index) => (
                         <li className="c-stepper__item" key={index} style={{marginBottom:'-15px'}}>
                           <div className="c-stepper__content">
                             <a className="c-stepper__title">                     
                              <span
                              className="material-symbols--download"
                             style={{
                               color: "black",
                               marginLeft: "-50px",
                               fontSize: "23px",
                               marginTop: "7px",
                             }}>                     
                              </span>              
                             <span
                                 style={{ marginLeft: "10px", marginTop: "5px" }}
                               >
                                 <div
                                   className="color-option"
                                   style={{ marginTop: "-38px",marginLeft:"15px" }}
                                 >
                                    <Link
                             className="px-6 py-2 font-semibold rounded-md w-full transition-all 
                             duration-500 ease-in-out shadow1  truncated-link"
                             type="button"
                             onClick={()=>handleDownload(d)}
                             style={{
                              cursor: "pointer",
                              color: "black",
                              display: "flex",
                              alignItems: "center",
                              whiteSpace: "nowrap", 
                              overflow: "hidden", 
                              textOverflow: "ellipsis", 
                            }}
                           >                     
                             {d?.name.slice(0, 12)}
                           </Link>
                                 </div>
                               </span>
                             </a>
                           </div>
                         </li>
                       ))}
                        <li className="c-stepper__item" key={1} >
                        <div className="c-stepper__content">
                             <a className="c-stepper__title">
                             <span
                              className="material-symbols--download"
                             style={{
                               color: "black",
                               marginLeft: "-50px",
                               fontSize: "23px",
                               marginTop: "7px",
                             }}>                     
                              </span>
                              <br/>
                              <span style={{color: "black",
                               marginLeft: "-44px",
                               fontWeight: "bold",
                               marginTop: "7px",}}>
                                </span>
                                <span
                                 style={{ marginLeft: "10px", marginTop: "5px" }}
                               >
                                 <div
                                   className="color-option"
                                   style={{ marginTop: "-65px",marginLeft:"15px" }}
                                 >
                                   <Link
                             className="px-6 py-2 font-semibold rounded-md w-full transition-all 
                             duration-500 ease-in-out shadow1"
                             type="button"
                            onClick={()=>handleDownload(pdfDocuments)}
                             style={{
                               cursor: "pointer",
                               color: "black",
                               display: "flex",
                               alignItems: "center",
                             }}
                           >
                             <span
                              //  className="material-symbols--download"
                               
                               style={{
                                 cursor: "pointer",
                                 whiteSpace: "nowrap",
                                //  marginRight: "5px",
                                 fontSize: "23px",
                               }}
                             ></span>
                             Download Checklist
                           </Link>
                                 </div>
                               </span>                  
                             </a>
                           </div>
                             </li>
                     </ol>
                     </div>
                </div>
              </div>):("")}
              
              {/*<div className="container" style={{ marginLeft: '0px', marginTop: '30px' }}>
                <div className="flex justify-between">
                  <div style={{ display: 'flex', justifyContent: 'center', }}>
                    <div className="flex items-center">
                      <Link style={{ color: '#000000', fontWeight: 'bold', fontSize: '20px', cursor: 'pointer', marginTop: '15px', marginLeft: '10px' }}>
                        <span className='under1'>Other Visa Options</span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>*/}
  
             {!empty(initialFAQ)?( <div className="container">
                <div className="md:flex" style={{ marginTop: '0px' }}>
                          <div className="lg:w-5/5 md:w-full md:mb-8 md:order-2" >
                              <li className="block">
                                  <span style={{ color: '#000000', fontWeight: 'bold', fontSize: '30px', cursor: 'pointer', textAlign: 'left' }}>FAQs</span>
                              </li>
  
                              <div id="accordion-collapseone" data-accordion="collapse" className="mt-12">
                                  {initialFAQ.map((item, index) => (
                                    
                                      <div key={index} className="relative shadow1 dark:shadow1 rounded-md overflow-hidden mt-4">
                                          <h2 className="text-base font-semibold" id="accordion-collapse-heading-1">
                                              <button onClick={() => toggleAccordions(index)} type="button" style={{ color: 'black' }}
                                                  className={`flex justify-between items-center p-5 w-full font-semibold text-start ${activeIndexx === index ? 'bg-gray-50 dark:bg-slate-8002 text-violet-6001' : ''}`}
                                                  data-accordion-target="#accordion-collapse-body-1" aria-expanded="true" aria-controls="accordion-collapse-body-1">
                                                  <span>{item.title}</span>
                                                  <svg data-accordion-icon className={`w-4 h-4  shrink-0 ${activeIndexx === index ? "rotate-180" : "rotate-360"}`} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                      <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path>
                                                  </svg>
                                              </button>
                                          </h2>
                                          {activeIndexx === index && (
                                              <div id="accordion-collapse-body-1" aria-labelledby="accordion-collapse-heading-1">
                                                  <div className="p-5" style={{ backgroundColor: 'rgb(110 110 110 / 15%)' }}>
                                                      <p style={{ color: 'black' }}
                                                          dangerouslySetInnerHTML={{ __html: formatDescription(item?.description) }}></p>
                                                  </div>
                                              </div>
                                          )}
                                      </div>
                                  ))}
                              </div>
  
                          </div>
                      </div>
              </div>):("")}
            </>
          )}
  
        </section>
  
        <Footer />
  
      </>)}
  </>
  )
}
