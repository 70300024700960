import React, { useState, useEffect, useRef } from 'react';
import image from '../../assets/images/items/1.gif';
import image1 from '../../assets/images/items/1.jpg';
import image2 from '../../assets/images/items/2.jpg';
import image3 from '../../assets/images/items/3.jpg';
import image4 from '../../assets/images/items/2.gif';
import image5 from '../../assets/images/items/4.jpg';
import image6 from '../../assets/images/items/3.gif';
import Navbar from '../../components/navbar'
import Footer from '../../components/footer'
import Switcher from '../../components/switcher';
import tick from '../../assets/images/tick1.png';
import star from '../../assets/images/rating-star.png';
import check from '../../assets/images/tick.png';
import travel from '../../assets/images/travel-insurance.png';
import { Icon } from '@iconify/react';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt, faImage, faPassport,faHotel } from '@fortawesome/free-solid-svg-icons';
import airplaneTakeoffDuotone from '@iconify/icons-ph/airplane-takeoff-duotone';
import airplaneTakeoffFill from '@iconify/icons-ph/airplane-takeoff-fill';
import { Link, useParams } from 'react-router-dom';
import {LuSearch, BiCheckCircle,CgArrowDown,FiTarget, BsTag,BiPlusCircle,BiDoorOpen, RiArrowDropDownLine, FiHeart, PiBrowsersLight,FiUsers,VscListFlat, PiMusicNotesBold, LuCamera,FaRegPaperPlane, BiWallet, BiMinusCircle, BiShield, BiTargetLock, FaSearchLocation, FaSearchDollar, LuUsers2, LuFolderCheck, MdOutlineChatBubbleOutline, TiPhoneOutline, IoMdClose, GrVisa, AiTwotoneInsurance, RiExchangeBoxFill, RiExchangeDollarLine, MdWorkOff, GoLocation, GoLaw, MdOutlineArrowForward, BsExclamationOctagon} from "../../assets/icons/vander"
import { Users,Calendar,Clock,DollarSign, Folder } from 'feather-icons-react/build/IconComponents';
import NavbarNew from '../../components/navbar-new';
import File from 'feather-icons-react/build/IconComponents/File';
import { dataArray } from '../../data/data';
import HealthListing from '../index/health-listing';
import Comingsoon from '../special/comingsoon';
import { loanDetails } from '../../data/data';  
import { languageoffers } from '../../data/data';
import QA from '../../components/qa';
import { REST_API_END_POINT } from '../../constants/DefaultValue';
import axios from 'axios';
import EmiPrint from './EmiPrint';
import moment from 'moment';
import { empty } from '../../common/Helper';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';import globe from '../../images/how to/database.png'

import sts from '../../images/how to/image.jpg'
import NotificationNav from '../../components/notificationNav';
import TinySlider from 'tiny-slider-react';
const newImg = [  
  { image_url: globe },
  { image_url: sts },
];

const customStyles = {
  control: (styles, { isFocused, isHovered }) => ({
    ...styles,
    backgroundColor: 'transparent',
    color: 'rgb(165 124 1 / var(--tw-bg-opacity)) !important',
    placeholder: {
      color: 'black',
    },
    borderColor: isHovered ? 'white' : '#e6e6e6', 
    boxShadow: isHovered ? '0 0 0 2px white' : 'none', 
    borderWidth: '2px',
    outline: '#e6e6e6',
    height: '45px', 
    paddingLeft: '8px', 
  }),
  option: (styles, { isFocused }) => ({
    ...styles,
    backgroundColor: 'white',
    color: 'black',
    zIndex: isFocused ? 999 : 'auto',
  }),
  singleValue: (styles) => ({
    ...styles,
    color: 'black',
  }),
  menuPortal: (base) => ({
    ...base,
    zIndex: 9999,
  }),
};



const Score = [
  { value: 'Excellent', label: 'Excellent',value:3.5  },
  { value: 'Good', label: 'Good',value:5},
  { value: 'Bad', label: 'Bad',value:7  },
  
];




const getData = (source, ids) => {
  if (!ids) return [];
  return ids.split(',').map(id => source.find(item => item.id == id)).filter(item => item !== undefined);
};
export default function CreditsDetail() {
    const { loanId, tab } = useParams();
    const [countries,setCountries] = useState([])
    const [editedData,setEditedData] = useState({})
    const [establishFee,setEstablishFee] = useState([])
    const [bankData,setBankData] = useState({})
    const [locationConsults,setLocationConsult] = useState([])
    const [dataVar,setDataVar] = useState([])
    const [documents,setDocuments] = useState([])
    ////////////this state is for emi
    const [loanAmount, setLoanAmount] = useState('');
    const [loanTerm, setLoanTerm] = useState(null);
    const [creditScore, setCreditScore] = useState(0);
    const [emi, setEmi] = useState(0);
    const [totalInterest, setTotalInterest] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);
    ///////////////////
    const [placeBid, setPlaceBid] = useState(false);
    const [CreditsData, setCreditsData] = useState([]);
    const [notification,setNotification]=useState({})
    const [userData,setUserData] = useState({})   
     const [docRequirement, setDocRequirement] = useState([])
    const [docReqData, setDocReqData] = useState([])
    const [docName, setDocName] = useState([])
    const [activeIndex, setActiveIndex] = useState(0);
    const [faqs, setFaq] = useState([])
  const fetchDataById=()=>{
    axios.post(`${REST_API_END_POINT}bank-loan/get-by-id`,{id:loanId})
    .then((res)=>{
      if(res.data.status===1){
        setBankData(res.data.data)  
        setNotification(res.data.data.notification_btn)  
        setEditedData(res.data.EditedData)
        setEstablishFee(res.data.estd)
        setDocRequirement(res.data.documentsRequired)
      }
    })
    .catch((err)=>{
      console.log(err)
    })
  }
  const fetchAllCountries=()=>{
    axios.post(`${REST_API_END_POINT}country-listing`,{})
    .then((res)=>{
      if(res.data.status===1){
        setCountries(res.data.data?.map(country => {
          return {
              ...country, 
              label: country.name 
          };
      }))
      }
    })
  }
  const fetchAllBankDocuments=()=>{
    axios.post(`${REST_API_END_POINT}bank/get-all-bank-documents`,{
      loanId,
    })
    .then((res)=>{
      if(res.data.status===1){
        setDataVar(res.data.data.filter(d=>d.type===1))
        setDocuments(res.data.data.filter(d=>d.type===2))
        setFaq(res.data.data.filter(d=>d.type===4))
      }
    })
    .catch((err)=>{
      console.log(err)
    })
  }
  const getAllActiveLocationConsult=()=>{
    axios.post(`${REST_API_END_POINT}bank/location-consult/get-all-location-list`,{loanId})
    .then((res)=>{
      if(res.data.status===1){
        setLocationConsult(res.data.data.filter(d=>d.status===1&&d.verified===1))
      }
    })
    .catch((err)=>{
      console.log(err)
    })
   }
  useEffect(() => {
    fetchDataById()
    fetchAllBankDocuments()
    fetchAllCountries()
}, [loanId]);

const initialFAQ = getData(faqs,editedData?.faq_ids)
    // console.log("initialFAQ",initialFAQ);
    // console.log("faqs",faqs);
    // console.log("details?.faq_ids",editedData?.faq_ids);
   



// const formatDescription = (description) => {
//   return description.replace(/<p>/g, '<p><span style="font-weight: bold; font-size: larger;">•</span> ');
// };
// const formatDescription = (description) => {
//   description = description?.replace(/<p><br><\/p>/g, '<br>');
//   description = description?.replace(/<p>/g, '<p>• ');
//   description = description?.replace(/<ul><li>/g, '');
//   description = description?.replace(/<li>/g, '<p>• ');
//   description = description?.replace(/<\/li>/g, '</p>');
//   description = description?.replace(/<\/ul>/g, '');

//   if (description && !description.startsWith('<p>• ')) {
//       description = '<p>• ' + description;
//   }

//   return description;
// };
// const formatDescription = (description) => {
//   if (!description) return '';

//   // Handle ordered lists
//   let listIndex = 1;
//   if (description.includes('<ol>')) {
//     description = description.replace(/<ol>/g, '');
//     description = description.replace(/<\/ol>/g, '');
//     // description = description.replace(/<li>/g, () => {
//     //   const item = `${listIndex++}. `;
//     //   return `<p>${item}$1</p>`;
//     // });
//     description = description.replace(/<li>/g, () => {
//       const item = `${listIndex++}. `;
//       return `<p>&nbsp;&nbsp;&nbsp;${item}`;
//     });
//   }

//   // Handle unordered lists
//   if (description.includes('<ul>')) {
//     description = description.replace(/<ul>/g, '');
//     description = description.replace(/<\/ul>/g, '');
//     // description = description.replace(/<li>/g, '<p>&nbsp;&nbsp;&nbsp;• $1</p>');
//     description = description.replace(/<li>/g, '<p>&nbsp;&nbsp;&nbsp;• ');
//   }
//   description = description.replace(/<\/li>/g, '');
//   // Clean up any extra <p> tags that might be left
//   description = description.replace(/<p><\/p>/g, '');

//   return description;
// }
const formatDescription = (description) => {
  if (!description) return '';

  // Handle ordered lists
  let listIndex = 1;
  if (description.includes('<ol>')) {
    description = description.replace(/<ol>/g, '');
    description = description.replace(/<\/ol>/g, '');
    description = description.replace(/<li>/g, () => {
      const item = `${listIndex++}. `;
      return `<div class="list-item">${item}`;
    });
  }

  // Handle unordered lists
  if (description.includes('<ul>')) {
    description = description.replace(/<ul>/g, '');
    description = description.replace(/<\/ul>/g, '');
    description = description.replace(/<li>/g, '<div class="list-item" style="margin-left:35px;">● ');
  }

  description = description.replace(/<\/li>/g, '</div>');

  // Clean up empty paragraphs if any
  description = description.replace(/<p><\/p>/g, '');

  return description;
};
const parseDescription = (description) => {
  const sentences = description.split(/[.]/); 
  const trimmedSentences = sentences.filter(sentence => sentence.trim() !== '');

  const parsedSentences = trimmedSentences.map(sentence => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = sentence;
    
    if (tempDiv.querySelector('ul')) {
      const listItems = Array.from(tempDiv.querySelectorAll('li')).map(li => li.textContent);
      return [tempDiv.textContent || tempDiv.innerText || '', ...listItems];
    } else {
      return [tempDiv.textContent || tempDiv.innerText || ''];
    }
  });
  


 
  
  return parsedSentences.flat();
}

////emi functions
const handleCalculateEmi = () => {
  if (!loanAmount || !loanTerm || !creditScore) {
      alert('Please fill all the fields');
      return;
  }

  const P = parseFloat(loanAmount);
  const annualInterestRate = parseFloat(creditScore.value);
  const r = annualInterestRate / 12 / 100; 
  const n = loanTerm * 12;

  const emi = (P * r * Math.pow(1 + r, n)) / (Math.pow(1 + r, n) - 1);
  setEmi(emi.toFixed(2));
  const totalRepayment = emi * n;
  const totalInterest = totalRepayment - P;

  setTotalAmount(totalRepayment.toFixed(2));
  setTotalInterest(totalInterest.toFixed(2));
};
////



const handleClick = (data) => {
  setActiveIndex(0);
  setDocReqData(getData(documents, data.documents_ids));
  setDocName(data.document_type)
};

useEffect(() => {
  if (docRequirement?.length > 0) {
    handleClick(docRequirement[0])
  }
}, [docRequirement])

const toggleAccordion = (index) => {
  if (activeIndex === index) {
    setActiveIndex(0);
  } else {
    setActiveIndex(index);
  }
}




useEffect(() => {
  const CreditsDatas = loanDetails?.find((credits) => credits?.id === parseInt(1));
  const title = CreditsDatas ? CreditsDatas?.title : '';
  setCreditsData(CreditsDatas)


}, [CreditsData]);

  useEffect(() => {
    document.documentElement.classList.add('dark');
    setUserData(JSON.parse(localStorage.getItem('userVisa')))
  }, []);

  const initialDocuments = getData(documents,editedData?.document_ids)

  const renderLoanTermButtons = () => {
    const years = [];
    for (let year = editedData?.min_years; year <= editedData.max_years; year++) {
        years.push(year);
    }
    return years.map((year, index) => (
        <li role="presentation" className="md:inline-block block md:w-fit w-full" key={index}>
            <button
                className={`px-6 py-2 font-semibold rounded-md w-full transition-all duration-500 ease-in-out ${loanTerm === year ? 'text-white bg-violet-6001' : ''}`}
                type="button"
                onClick={() => setLoanTerm(year)}
                style={{ color: loanTerm === year ? 'white' : 'black' }}
            >
                {year} years
            </button>
        </li>
    ));
};

const handlePrint = () => {
  const printContent = document.getElementById('print-section').innerHTML;
  const originalContent = document.body.innerHTML;

  const finePrintElement = document.querySelector('.fine-print');
  const getMyRateElement = document.querySelector('.get-my-rate');
  if (finePrintElement) finePrintElement.style.display = 'none';
  if (getMyRateElement) getMyRateElement.style.display = 'none'; 

  document.body.innerHTML = printContent;
  window.print();
  document.body.innerHTML = originalContent;

  if (finePrintElement) finePrintElement.style.display = 'block'; 
  if (getMyRateElement) getMyRateElement.style.display = 'block';

  window.location.reload();
};

const expireDate = moment .unix(bankData?.expiredate);
const formattedExpireDate = expireDate.format('MMMM D, YYYY');

const handleApplyForLoan=async()=>{
  try {
  axios.post(`${REST_API_END_POINT}bank/loan-application-form`,{
    userId:userData?.user_id,bankId:bankData.bank_id,loanId
  })
  .then((res)=>{
    if(res.data.status==1){
      toast.success(res.data.message)
    }else{
      toast.error(res.data.message)
    }
  })
  } catch (error) {
    console.log(error)
  }
}

const settings1 = {
  items: 1,
  controls: true,
  mouseDrag: true,
  loop: true,
  rewind: true,
  autoplay: true,
  autoplayButtonOutput: false,
  autoplayTimeout: 3000,
  navPosition: "bottom",
  nav: false,
  speed: 400,
  gutter: 0,
  controlsText: ['<i class="mdi mdi-chevron-left "></i>', '<i class="mdi mdi-chevron-right"></i>']
};
  return (

    <>
      
      {notification===1&&<NotificationNav notificationData={bankData?.notifications}/>}
      <NavbarNew noHomeTab={true} tab={tab} notification={notification===1?true:false} />

          <section className="relative" style={{ marginTop:notification===1?"160px": '20px' }}>
                <div className="container">
                {/* {(notification === 1) && (
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <div className="flex items-center p-3 mt-3 rounded-lg" style={{ width: '100%', backgroundColor: 'grey', marginBottom: '10px', justifyContent: 'space-between', color: 'white' }}>


                                <div className="ms-2 items-center" style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'row', textAlign: 'left' }}>
                                    <BsExclamationOctagon style={{ color: 'white', marginRight: '10px' }} className="text-3xl" />
                                    <div>
                                        <span className="block" >{bankData?.notifications}</span>
                                    </div>
                                </div>
                                <div className="ms-2" style={{ display: 'flex', justifyContent: 'flex-end', flexDirection: 'column', textAlign: 'right' }}>
                                </div>

                            </div>

                        </div>
                    )} */}
                  <div className="md:flex">
                    <div className="lg:w-5/5 md:w-full md:mb-8 md:order-2" style={{paddingInline:'1rem'}}>
                      <div style={{ position: 'relative', width: '100%', maxWidth: '100%', overflow: 'hidden' }}>
                        <img
                          style={{
                            width: '100%',
                            left: '0',
                            minWidth: '300px',
                            height: '360px',
                            borderRadius: '10px',
                            objectFit: 'cover',
                          }}
                          src={bankData?.cover_image}
                          alt="VIsa Search Image"
                        />
                        <div className='visa-image' style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <span>{bankData?.bank_name} <span style={{marginLeft:'10px',fontSize:'20px',fontWeight:'200'}}>
                              {bankData?.name}</span></span>
                            
                            <div className="bagde-flag-wrap2">
                              <img
                                style={{
                                    position: 'absolute',
                                    top: '0',
                                    right: '0',
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                    zIndex: '2', 
                                }}
                                src={bankData?.logo} 
                                alt="College Logo"
                              />
                            </div>
                        </div>                       
                      </div>                                                                                                                                     
                    </div>                                                                   
                    </div>
                </div>

                <div className="lg:flex" style={{ marginLeft: '0px', marginTop: '30px'}}>
                    <div className="p-6 w-full" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginBottom: '20px'}}>
                        <div className="flex flex-wrap justify-between wrapp" style={{ marginBottom: '20px',gap:'20px' }}>
                            {/* First color-price */}
                            {/* <div style={{ marginBottom: '20px' ,marginRight:'30px'}}>
                                <div className="popover-container1">
                                    <div className="flex items-center">
                                        <Link className="color" style={{marginLeft: '10px', color: '#000000', fontWeight: 'bold', fontSize: '20px', cursor: 'pointer', textDecoration:'underline',marginRight:'200px'}}>
                                            Compare this Cover
                                        </Link>                         
                                    </div>
                                </div>
                            </div> */}
                                                
                            {/* Second color-price */}
                            {/* <div className="flex items-center justify-center" style={{marginTop:'-23px',textAlign:'center'}}>
                              <div className="popover-container1">
                                <Link className="color" style={{marginLeft: '10px', color: '#000000', fontWeight: 'bold', fontSize: '20px', cursor: 'pointer',textAlign:'center',textDecoration:'underline' }}>
                                    4.5
                                </Link>
                                <div className="popover-top-review" style={{textAlign:'left'}}>
                                  <div className="popover">
                                    <h3 className="popover-heading1 color-price">Rating:</h3>
                                    <p className="popover-paragraph1 color-price">
                                      Rating From Our Recent Clients 
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <img src={star} height={32} width={32} alt="Image Description" className="badge-image" style={{ marginLeft: '0px',marginTop:'3px' }} />
                            </div>  */}
                        </div>
                    </div>
                </div>

              <div style={{marginTop:'-35px'}}>
                <div className="group rounded-xl bg-white dark:bg-slate-900 w-full mx-auto lg:max-w-2xl " style={{ zIndex: 1, marginTop: '0px',display:'flex',justifyContent:'center',alignContent:'center',alignItems:'center'}}>
                {!empty(userData) ? (
                  <div
                  className='font-semibold transition-all duration-500 ease-in-out text-white bg-violet-6001 '                                  
                  type="button"
                   onClick={handleApplyForLoan}
                  style={{ color: 'black',width:'550px',height:'70px',display:'flex',cursor:'pointer',
                    justifyContent:'center',textAlign:'center',padding:'22px',borderRadius:'40px'}}
                  >
                Apply Now
                </div>
                ):(
                <Link
                  className='font-semibold transition-all duration-500 ease-in-out text-white bg-violet-6001 '                                  
                  type="button"
                  to={'/signup-visa/'+tab}
                  style={{ color: 'black',width:'550px',height:'70px',display:'flex',justifyContent:'center',textAlign:'center',padding:'22px',borderRadius:'40px'}}
                  >
                  Get Started Now
                </Link>
               )}
   
              </div>

              <hr style={{ marginTop: '-36px', borderColor: 'black', borderWidth: '1px' }} />
              </div>

              <div className="container" style={{marginLeft:'0px', marginTop:'80px'}}>
                  
                  
                </div>

                <div className="container">
                  <div className="md:flex">
                    <div className="lg:w-2/5 md:w-full md:mb-12 md:order-2" style={{paddingInline:'1rem',}}>
                      <div  className="group relative rounded-xl bg-white dark:bg-slate-900 shadow1 p-6 text-center" 
                      style={{display:'flex',justifyContent:'center',alignContent:'center'}}>
                        <div className="relative"style={{display:'flex',justifyContent:'center',alignContent:'center'}}>                         
                        <div className="color-price" style={{ marginTop: '0px', }}>
                           <div className="flex items-center">            
                             <div className="color-option" style={{ display: 'flex', flexDirection: 'column' }}>
                              <h3 className="md:text-4xl text-3xl md:leading-snug leading-snug font-semibold" style={{ color: '#000000', }}>Our Loan <br/> Consultant Near You !</h3>
                             </div>
                            <div style={{display:'flex',flexDirection:'column',marginBottom:'10px'}}>
                              <div style={{ fontSize: '29px', color: '#000000', marginRight: '18px' ,marginBottom:'25px'}}>
                              <span className='ion--location' style={{color:'black'}}/>
                              </div>
                              <div style={{ fontSize: '22px', color: '#000000', marginRight: '18px' }}>
                              <span className='formkit--email' style={{color:'black'}}/>
                              </div>
                            </div> 
                           </div>
                         </div>
                        </div>
                      </div>
                    </div>

                    <div className="lg:w-3/5 md:order-1 md:mt-0 mt-10 md:mt-0 lg:mt-0 mb-8 media">
                      <div className="relative">
                        <div className="flex justify-between">
                          <div style={{display: 'flex', justifyContent: 'center',marginTop:'35px'}}>
                                <div className="flex items-center">
                                  <Link style={{color: '#000000', fontWeight: 'bold', fontSize: '24px', cursor: 'pointer',
                                  marginTop:'15px',marginLeft:'0px',marginRight:'0px'}}>
                                    Choose your destination country 
                                  </Link>
                                  <div className='mt-2' style={{width:'80%'}}>
                                    <Select
                                      styles={customStyles}
                                      placeholder='Select Your Destination...'
                                      className="input"
                                      options={countries}
                                    />
                                  </div>
                                </div>                        
                              </div>                         
                            </div>
                          </div>
                        </div>
                      </div>                                                                    
                    </div>

                <div className="container" style={{marginLeft:'0px', marginTop:'-10px'}}>
                  <div className="flex justify-between">
                  <div style={{display: 'flex', justifyContent: 'center',}}>
                    <div className="flex items-center">
                      <Link style={{color: '#000000', fontWeight: 'bold', fontSize: '25px', cursor: 'pointer',marginTop:'15px',marginLeft:'0px'}}>
                        Benefits and Features for SBI student loan
                      </Link>
                    </div>                        
                  </div>                           
                  </div>
                </div>

                <div className="lg:flex" style={{ marginLeft: '0px', marginBottom: '30px' ,marginTop:'-25px'}}>
                <div className="p-6 w-full" style={{ display: 'flex', flexDirection: 'column',alignItems:'center',justifyContent:'center' }}>
                  <div className="flex flex-wrap wrapp" >
                    
                    <div className="color-price" style={{ marginTop: '30px', }}>
                        {dataVar?.map((data)=>(
                      <div key={data.id} className="flex items-center">
                        <div style={{ fontSize: '26px', color: '#000000', marginRight: '18px' }}>
                        <span className='game-icons--pay-money' style={{color:'black'}}/>
                        </div>
                        <div className="color-option" style={{ display: 'flex', flexDirection: 'column' }}>
                          <Link className="color" style={{ fontSize: '18px', color: '#000', marginBottom: '4px',}}>
                           {data.title}</Link>
                          <div className="popover-container1">
                            <Link className="color" style={{ fontSize: '16px', color: '#000000',textDecoration:'underline' }}>
                              {data.sub_title}</Link>
                            <div className="popover-content11">
                              <div className="popover">
                                <h3 className="popover-heading1 color-price">{data.title}:</h3>
                                <p className="popover-paragraph1 color-price" 
                                dangerouslySetInnerHTML={{__html:data.description}} />
                              </div>
                            </div>
                          </div>
                        </div>
                         </div>
                        ))}
                    </div>                                                                                                      

                    <div className="color-price" style={{ marginTop: '30px', }}>
                      <div className="flex items-center">
                      <div style={{ fontSize: '26px', color: '#000000', marginRight: '18px' }}>
                      <span className='ri--customer-service-2-fill' style={{color:'black'}}/>
                        </div>
                        <div className="color-option" style={{ display: 'flex', flexDirection: 'column' }}>
                          <Link className="color" style={{ fontSize: '18px', color: '#000000', marginBottom: '4px', }}>
                            Customer Service</Link>
                          <div  onClick={()=>window.open(editedData?.customer_services, '_blank')}  className="popover-container1">
                            <Link className="color" style={{ fontSize: '16px', color: '#000000', }}>Service</Link>                           
                          </div>                         
                        </div>
                      </div>
                    </div>                                                          
                  </div>
                  </div>
                </div>

                <div className="container" style={{marginLeft:'0px', marginTop:'80px',marginBottom:'80px'}}>   
                    <div className="md:flex" style={{marginTop:'-20px',gap:'10px'}}>           
                        <div className="lg:w-5/5 md:w-full md:mb-8 md:order-2 rounded-xl bg-white dark:bg-slate-900 shadow1 p-6 text-center" style={{borderRadius:'20px'}} >                      
                            <div className="md:flex">
                                <div className="lg:w-2/5 md:w-full md:mb-8 md:order-2" style={{textAlign:'center',}}>                                   
                                    <div className="group relative p-6 bg-white dark:bg-slate-900 creditsHeight flex flex-col">                                                                                                                                                                                             
                                        <span style={{color: '#000000',fontWeight:'600', fontSize: '40px', cursor: 'pointer',textAlign:'left'}}>Transparent </span>                                                                                                                                                                                                               
                                        <span className='mt-10 mb-3' style={{color: '#000000',fontWeight:'600', fontSize: '40px', cursor: 'pointer',textAlign:'left'}}>&</span>                                                                                                                                                                                                               
                                        <span className='mt-6' style={{color: '#000000',fontWeight:'600', fontSize: '40px', cursor: 'pointer',textAlign:'left'}}>Simple pricing</span>                                                                                                                                                                                                               
                                    </div>                                                                                                                                                                
                                </div>

                                <div className="lg:w-3/5 md:w-full md:mb-8 md:order-2">                                   
                                    <div className="group relative p-6 bg-white dark:bg-slate-900  ">                                                                                                          
                                        <span style={{textAlign:'center',}}>
                                            <div className="mt-10">
                                                <div className="flex justify-between">
                                                    <p className="font-medium text-slate-400 block " style={{textAlign:'left'}}>Interested rate<br/> (variable)</p>
                                                    <p className="font-medium text-slate-400 block " style={{display:'flex',justifyContent:'flex-start',alignContent:'flex-start'}}>
                                                      <span style={{fontWeight:'bold',fontSize:'40px'}}>{editedData?.interest_rate_from}</span>% pa. to 
                                                      <span style={{fontWeight:'bold',fontSize:'40px'}}>{editedData?.interest_rate_to}</span>% pa.</p>
                                                </div>
                                                <hr className='mb-5 mt-4' style={{border:'1px solid #858585'}}/>
                                                <div className="flex justify-between mt-1">
                                                    <p className="font-medium text-slate-400 block " style={{textAlign:'left'}}>Comparison rate*</p>
                                                    <p className="font-medium text-slate-400 block " style={{display:'flex',justifyContent:'flex-start',alignContent:'flex-start'}}>
                                                      <span style={{fontWeight:'bold',fontSize:'40px',}}>{editedData?.comparison_rate_from}</span>% pa. to 
                                                      <span style={{fontWeight:'bold',fontSize:'40px'}}>{editedData?.comparision_rate_to}</span>% pa.</p>
                                                </div>
                                                <hr className='mb-5 mt-4' style={{border:'1px solid #858585'}}/>
                                                <div className="flex justify-between mt-1">
                                                    <p className="font-medium text-slate-400 block " style={{textAlign:'left'}}>
                                                      Establishment fee <br/>(Direct Applications)</p>
                                                    <div className='flex flex-col'>
                                                      {establishFee?.map((data)=>(
                                                        <p key={data.id} className="font-medium text-slate-400 block ">                                                      
                                                        <span style={{fontWeight:'bold'}}>${data.fee_from}</span> for loan between 
                                                        <span style={{fontWeight:'bold'}}>${data.fee_to}</span> and 
                                                        <span style={{fontWeight:'bold'}}>${data.fee_loan}</span></p>
                                                      ))}  
                                                    </div>
                                                </div>
                                                <hr className='mb-5 mt-4' style={{border:'1px solid #858585'}}/>
                                                <div className="flex justify-between mt-1">
                                                    <p className="font-medium text-slate-400 block " style={{textAlign:'left'}}>Monthly fee</p>
                                                    <p className="font-medium text-slate-400 block " >
                                                      <span style={{fontWeight:'bold'}}>${editedData?.monthly_fee}</span></p>
                                                </div>  
                                                <hr className='mb-5 mt-4' style={{border:'1px solid #858585'}}/> 
                                                <div className="flex justify-between mt-1">
                                                    <p className="font-medium text-slate-400 block " style={{textAlign:'left'}}>Loan terms</p>
                                                    <div className='flex flex-col'>
                                                      <p className="font-medium text-slate-400 block ">
                                                        <span style={{fontWeight:'bold'}}>Minimum {editedData?.min_years} years</span></p>
                                                      <p className="font-medium text-slate-400 block ">
                                                        <span style={{fontWeight:'bold'}}>Maximum {editedData?.max_years} years</span></p>
                                                    </div>
                                                </div>
                                                <hr className='mb-5 mt-4' style={{border:'1px solid #858585'}}/> 
                                                <div className="flex justify-between mt-1">
                                                    <p className="font-medium text-slate-400 block " style={{textAlign:'left'}}>Early exit fees</p>
                                                    <p className="font-medium text-slate-400 block "style={{textAlign:'left'}}>
                                                      {editedData?.exit_fee}</p>
                                                </div>
                                            </div>                                               
                                        </span>                                                                                     
                                    </div>                                                                                                                                                                
                                </div>                                                                  
                            </div>        
                        </div>
                    </div>  
                </div>            
                <div className="container mt-24">
              <div className="md:flex">
                <div className="lg:w-2/5 md:w-full md:pe-4 md:order-1 md:mt-0 mt-10 lg:mt-0">
                  {newImg?.map((ele, index) => (
                    <div
                      key={index}
                      className="group relative rounded-xl shadow1 newImgWidth p-5 text-center mt-4 xs:hidden"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        backgroundColor: 'black',
                        alignContent: "center",
                        //   display:'flex',
                        // alignItems:'end'
                      }}
                    >
                      <div
                        className="relative"
                        style={{
                          display: "flex",
                          // backgroundColor:'black',
                          justifyContent: "center",
                          alignContent: "center",
                        }}
                      >
                        <img
                          style={{
                            width: "7rem",

                            height: "auto",
                            maxWidth: "100%",
                            verticalAlign: "middle",
                            objectFit: "contain",
                          }}
                          src={ele.image_url}
                          alt={`Partner ${index}`}
                        />
                      </div>
                    </div>
                  ))}
                   <div className="grid relative grid-cols-1 sm:hidden">
                      <div className="tiny-four-icon-item">
                          <TinySlider settings={settings1}>
                              {newImg.map((item, index) => (
                                  <div className="tiny-slide" key={index}>
                                      <div className="group relative overflow-hidden rounded-lg shadow p-4 dark:shadow-gray-800 ease-in-out duration-500 m-2" style={{backgroundColor:'#000'}}>
                                          <img src={item.image_url}  alt={`Partner ${index}`} style={{verticalAlign: "middle",objectFit:'contain',height:'150px',justifyContent:'center',width:'100%'}} className="rounded-lg" />
                                      </div>
                                  </div>
                              ))}
                          </TinySlider>
                      </div>
                  </div>
                </div>


                <div className="lg:w-3/5 md:w-full md:mb-8 md:order-2"
                  style={{ paddingInline: "1rem" }}
                >
                  <ol class="c-stepper">
                    <li
                      className="block items-center"
                      style={{ marginBottom: "30px", marginTop: '26px' }}
                    >
                      <span
                        style={{
                          color: "#000000",
                          fontWeight: "bold",
                          fontSize: "24px",
                          cursor: "pointer",

                        }}
                      >
                        How to apply
                      </span>
                    </li>
                    <li class="c-stepper__item">
                      <div class="c-stepper__content">
                        <a
                          class="stepper-link c-stepper__title"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <span
                            style={{
                              color: "black",
                              marginLeft: "-48px",
                              fontWeight: "bold",
                              marginTop: "-7px",
                            }}>
                            Step <br /> 01
                          </span>
                          <span
                            style={{
                              marginLeft: "10px",
                              marginTop: "-15px",
                              fontWeight: "bold",
                              fontSize: "20px",
                              flex: "1",
                            }}
                          >
                           Easy online application outcome within 2 working days
                          </span>
                        </a>
                        <a class="stepper-link" style={{ textAlign: "justify", marginTop: '-16px', marginLeft: '14px' }}>
                          <span>{editedData?.stepone}</span>
                        </a>
                      </div>
                    </li>

                    <li class="c-stepper__item">
                      <div class="c-stepper__content">
                        <a
                          class="stepper-link c-stepper__title"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <span
                            style={{
                              color: "black",
                              marginLeft: "-48px",
                              fontWeight: "bold",
                              marginTop: "-7px",
                            }}>
                            Step <br /> 02
                          </span>
                          <span
                            style={{
                              marginLeft: "10px",
                              marginTop: "-15px",
                              flex: "1",
                              fontWeight: "bold",
                              fontSize: "20px",
                            }}
                          >
                          Once approved, we will transfer the funds to the dedicated university accounts

                          </span>
                        </a>
                        <a class="stepper-link" style={{ textAlign: "justify", marginTop: '-16px', marginLeft: '14px' }}>
                          <span>{editedData?.steptwo}</span>
                        </a>
                      </div>
                    </li>

                    <li class="c-stepper__item">
                      <div class="c-stepper__content">
                        <a
                          class="stepper-link c-stepper__title"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <span
                            style={{
                              color: "black",
                              marginLeft: "-48px",
                              fontWeight: "bold",
                              marginTop: "-7px",
                            }}>
                            Step <br /> 03
                          </span>
                          <span
                            style={{
                              marginLeft: "10px",
                              marginTop: "-15px",
                              flex: "1",
                              fontWeight: "bold",
                              fontSize: "20px",
                            }}
                          >
                            Download the Commonwealth Bank of Australia (CBA) app to manage your account and repayments with ease
                          </span>
                        </a>
                        <a class="stepper-link" style={{ textAlign: "justify", marginTop: '-16px', marginLeft: '14px' }}>
                          <span>{editedData?.stepthree}</span>
                        </a>
                      </div>
                    </li>
                    {/* <li class="c-stepper__item">
                      <div class="c-stepper__content">
                        <a
                          class="stepper-link c-stepper__title"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <span
                            style={{
                              color: "black",
                              marginLeft: "-48px",
                              fontWeight: "bold",
                              marginTop: "-7px",
                            }}>
                            Info <br /> 04
                          </span>
                          <span
                            style={{
                              marginLeft: "10px",
                              marginTop: "-15px",
                              flex: "1",
                              fontWeight: "bold",
                              fontSize: "20px",
                            }}
                          >
                            Fees
                          </span>
                        </a>
                        <a class="stepper-link" style={{ textAlign: "justify", marginTop: '-16px', marginLeft: '14px' }}>
                          <span>{editedData?.fee_details}</span>
                        </a>
                       
                      </div>
                    </li>
                    <li class="c-stepper__item">
                      <div class="c-stepper__content">
                        <a class="stepper-link c-stepper__title">
                          <span
                            style={{
                              color: "black",
                              marginLeft: "-48px",
                              fontWeight: "bold",
                              marginTop: "-7px",
                            }}>
                            Info <br /> 05
                          </span>
                          <span style={{ marginLeft: "10px", marginTop: "5px", fontWeight: "bold", fontSize: "20px", }}>
                           Pathway to permanent residency
                          </span>
                        </a>
                        <a class="stepper-link" style={{ textAlign: "justify", marginTop: '-16px', marginLeft: '14px' }}>
                          <span>{editedData?.permenent_residency}</span>
                        </a>
                       
                      </div>
                    </li>
                    <li class="c-stepper__item">
                      <div class="c-stepper__content">
                        <a class="stepper-link c-stepper__title">
                          <span
                            style={{
                              color: "black",
                              marginLeft: "-48px",
                              fontWeight: "bold",
                              marginTop: "-7px",
                            }}>
                            Info <br /> 06
                          </span>
                          <span style={{ marginLeft: "10px", marginTop: "5px", fontWeight: "bold", fontSize: "20px", }}>
                           Opportunities this qualification provides
                          </span>
                        </a>
                        <a class="stepper-link" style={{ textAlign: "justify", marginTop: '-16px', marginLeft: '14px' }}>
                          <span>{editedData?.qualification}</span>
                        </a>
                       
                      </div>
                    </li> */}
                  </ol>
                  
                </div>
              </div>
            </div>
            <div className="container mt-24">
              {!empty(docReqData)?(<div className="md:flex">
                <div className="lg:w-2/5 md:w-full md:pe-4 md:order-1 md:mt-0 mt-10 md:mt-0 lg:mt-0">
                  <div className="sticky top-20">
                    <li className="block items-center">
                      <span
                        style={{
                          color: "#000000",
                          fontWeight: "bold",
                          fontSize: "22px",
                          cursor: "pointer",
                        }}
                      >
                        Documents Required
                      </span>
                    </li>

                    <ol className="c-stepper mt-8">
                      {Array.isArray(docRequirement) ? (

                        docRequirement.map((step, index) => (
                          <li className="c-stepper__item" key={index} style={{ marginBottom: '-15px' }}>
                            <div className="c-stepper__content">
                              <a className="c-stepper__title">
                                <span
                                  style={{
                                    color: "black",
                                    marginLeft: "-53px",
                                    fontWeight: "bold",
                                    marginTop: "7px",
                                  }}>
                                  Doc
                                </span>
                                <br />
                                <span
                                  style={{
                                    color: "black",
                                    marginLeft: "-44px",
                                    fontWeight: "bold",
                                    marginTop: "7px",
                                  }}>
                                  0{index + 1}
                                </span>
                                <span style={{ marginLeft: "10px", marginTop: "5px" }}>
                                  <div
                                    className="color-option"
                                    style={{ marginTop: "-38px", marginLeft: "15px" }}
                                  >
                                
                                    <div className="popover-container1">
                                      <span
                                        className="color"
                                        style={{
                                          fontSize: "20px",
                                          color: "#000",
                                          fontWeight: "bold",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => handleClick(step)}
                                      >
                                        <span
                                        className='underlineeee'
                                        >
                                       
                                          {step?.document_type}
                                      </span>
                                      </span>
                                    </div>
                                  </div>
                                </span>
                              </a>
                            </div>
                          </li>
                        ))
                      ) : (
                        ""
                      )}
                    </ol>
                    {/* <li className="block items-center" style={{marginTop:'-30px',marginBottom:'30px'}}>
                              <span style={{color: '#000000', fontWeight: 'bold', fontSize: '18px', cursor: 'pointer',fontStyle:'italic'}}>...All documents should be verified by authorized country staff's</span>
                            </li> */}
                    {/* <li
                   className="block items-center"
                   style={{ marginTop: "-10px", marginBottom: "30px" }}
                 >
                   <span
                     style={{
                       color: "#000000",
                       fontWeight: "bold",
                       fontSize: "18px",
                       cursor: "pointer",
                       fontStyle: "italic",
                     }}
                   >
                     ...All documents should be verified by authorized country
                     staff's
                   </span>
                 </li> */}
                    <div
                      className="color-price"
                      style={{
                        whiteSpace: "nowrap",
                        marginTop: "5px",
                        //  marginBottom: "26px",
                      }}
                    >
                      {/* <div className="flex items-center">
                     <Link
                       className="px-6 py-2 font-semibold rounded-md w-full transition-all duration-500 ease-in-out shadow1"
                       type="button"
                       onClick={()=>handleDownload(currentFormData?.checkList)}
                       style={{
                         cursor: "pointer",
                         color: "black",
                         display: "flex",
                         alignItems: "center",
                       }}
                     >
                       <span
                         className="material-symbols--download"
                         
                         style={{
                           cursor: "pointer",
                           whiteSpace: "nowrap",
                          //  marginRight: "5px",
                           fontSize: "23px",
                         }}
                       ></span>
                       Download Checklist
                     </Link>
                   </div> */}
                    </div>
                  </div>
                </div>

                <div className="lg:w-3/5 md:w-full md:mb-8 md:order-2"
                  style={{ padding: "2rem", backgroundColor: 'black', borderRadius: '20px',height:'fit-content' }}
                >
                  <p
                    style={{
                      color: "white",
                      fontWeight: "bold",
                      textAlign: 'center',
                      marginTop: "7px",
                    }}>
                    {docName}  Documents
                  </p>
                  <div id="accordion-collapseone" data-accordion="collapse" className="mt-12" >
                    {/* {initialFAQ.map((item, index) => ( */}
                    {/* {reqDocuments && reqDocuments.length > 0 && reqDocuments.map((step, index) => ( */}

                    {docReqData?.map((item, index) => (
                      <div key={index} className="relative shadow1 dark:shadow1 rounded-md overflow-hidden mt-4" style={{ backgroundColor: 'white' }}>
                        <h2 className="text-base font-semibold" id="accordion-collapse-heading-1" >
                          <button onClick={() => toggleAccordion(index)} type="button" style={{ color: 'black' }}
                            className={`flex justify-between items-center p-5 w-full font-semibold text-start ${activeIndex === index ? 'bg-gray-50 dark:bg-slate-8002 text-violet-6001' : ''}`}
                            data-accordion-target="#accordion-collapse-body-1" aria-expanded="true" aria-controls="accordion-collapse-body-1">
                            <span>{item.title}</span>
                            <svg data-accordion-icon className={`w-4 h-4  shrink-0 ${activeIndex === index ? "rotate-180" : "rotate-360"}`} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                              <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path>
                            </svg>
                          </button>
                        </h2>
                        {activeIndex === index && (
                          <div id="accordion-collapse-body-1" aria-labelledby="accordion-collapse-heading-1">
                            <div className="p-5" style={{ backgroundColor: '#6e6e6e' }}>
                              <p style={{ color: 'white' }}
                                dangerouslySetInnerHTML={{ __html: formatDescription(item?.description) }}></p>
                            </div>
                          </div>
                        )}
                      </div>
                    ))}
                    {/* ))} */}
                  </div>

                </div>
              </div>):("")}
            </div>
                {/* <div className="container mt-24">
                      <div className="md:flex">
                          <div className="lg:w-2/5 md:w-full md:pe-4 md:order-1 md:mt-0 mt-10 md:mt-0 lg:mt-0">
                            <div className="sticky top-20">                       
                              <li className="block items-center">
                   <span
                     style={{
                       color: "#000000",
                       fontWeight: "bold",
                       fontSize: "22px",
                       cursor: "pointer",
                     }}
                   >
                     Documents Required
                   </span>
                 </li>
                    
                 <ol className="c-stepper mt-8">
                   {initialDocuments.map((step, index) => (
                     <li className="c-stepper__item" key={index}>
                       <div className="c-stepper__content">
                         <a className="c-stepper__title">
                           <span
                             className={"mdi--passport"}
                             style={{
                               color: "black",
                               fontSize: "1.5rem",
                               marginLeft: "-48px",
                               marginTop: "8px",
                             }}
                           />
                           <span
                             style={{ marginLeft: "10px", marginTop: "5px" }}
                           >
                             <div
                               className="color-option"
                               style={{ marginTop: "-30px" }}
                             >
                               <div className="popover-container1">
                                 <span
                                   className="color"
                                   style={{
                                     fontSize: "20px",
                                     color: "#000",
                                     fontWeight: "bold",
                                   }}
                                 >
                                   {step?.title}
                                 </span>
                                 <div className="popover-content111">
                                   <div className="popover1">
                                       <p
                                         // key={index}
                                         className="popover-paragraph1"
                                         style={{
                                           marginTop: index === 0 ? "-6px" : "0",
                                         }}
                                       >
                                     
                                        {parseDescription(step?.description)?.map((sentence,index)=>(                                     
                                           <span   className="font-medium inline-flex">
                                           <span
                                             style={{ color: "black" }}
                                             className="me-2"
                                           >
                                             ●
                                           </span> 
                                           <span
                                             style={{
                                               color: "#000000",
                                               fontSize: "16px",
                                             }}
                                           >                       
                                             {sentence}
                                           </span>
                                         </span>                                    
                                        ))}
                                        
                                       </p>
                                   </div>
                                 </div>
                               </div>
                             </div>
                           </span>
                         </a>
                       </div>
                     </li>
                   ))}
                 </ol>
                                <li className="block items-center" style={{marginTop:'-30px',marginBottom:'30px'}}>
                                 <span style={{color: '#000000', fontWeight: 'bold', fontSize: '18px', cursor: 'pointer',fontStyle:'italic'}}>...All documents should be verified by authorized country staff's</span>
                                </li>
                              </div>
                          </div>
                                        
                        <div className="lg:w-3/5 md:w-full md:mb-8 md:order-2" style={{paddingInline:'1rem'}}>
                            <ol class="c-stepper">
                                <li className="block items-center" style={{marginBottom:'30px'}}>                                                
                                    <span style={{color: '#000000', fontWeight: 'bold', fontSize: '24px', cursor: 'pointer',}}>
                                      How it works ?</span>
                                  </li>  
                                  <li class="c-stepper__item">
                                    <div class="c-stepper__content">
                                        <a class="stepper-link c-stepper__title" style={{display: 'flex',alignItems: 'center'}}>
                                            <span class="clarity--certificate-line" style={{ color: 'black', fontSize:'28px',
                                            marginLeft:'-51px',marginTop:'5px' }}></span>
                                            <span style={{ marginLeft:'10px',marginTop:'5px', flex: '1',
                                            fontWeight: 'bold', fontSize: '20px',}}>
                                              Easy online application outcome within 2 working days</span>
                                        </a>               
                                        
                                    </div>
                                  </li>

                                  <li class="c-stepper__item">
                                    <div class="c-stepper__content">
                                        <a class="stepper-link c-stepper__title" style={{display: 'flex',alignItems: 'center'}}>
                                            <span class="pajamas--requirements" style={{ color: 'black', fontSize:'24px',marginLeft:'-51px',marginTop:'-5px' }}></span>
                                            <span style={{ marginLeft:'10px',marginTop:'5px', flex: '1' ,fontWeight: 'bold',
                                             fontSize: '20px',}}>Once approved, we will transfer the funds to the dedicated university accounts</span>
                                        </a>             
                                        
                                    </div>
                                  </li>
                                  <li class="c-stepper__item">
                                    <div class="c-stepper__content">
                                        <a class="stepper-link c-stepper__title" style={{display: 'flex',alignItems: 'center'}}>
                                            <span class="ic--twotone-get-app" style={{ color: 'black', fontSize:'32px',marginLeft:'-54px',marginTop:'5px' }}></span>
                                            <span style={{ marginLeft:'10px',marginTop:'5px', flex: '1' ,fontWeight: 'bold', fontSize: '20px',}}>Download the {bankData?.bank_name} app to manage your account and repayments with ease</span>
                                        </a>             
                                        
                                    </div>
                                  </li>
                            </ol>                                                                                                
                        </div>                                        
                      </div>
                    </div> */}

                    <div className="container" style={{marginLeft:'0px', marginTop:'80px',marginBottom:'50px'}}>   
                    <div className="md:flex" style={{marginTop:'-20px',gap:'10px'}}>          
                        <div className="lg:w-5/5 md:w-full md:mb-8 md:order-2 rounded-xl bg-white dark:bg-slate-900 shadow1 p-6 text-center" >                      
                            <div className='mt-2 ' style={{color: '#000000',textAlign:'center'}}>
                                <h3 className="mb-4 md:text-5xl text-3xl md:leading-snug leading-snug font-semibold">
                                  Estimate your repayments</h3>
                                {/* <span className="font-medium mb-8 estimate" style={{color: '#000000',fontSize: '50px', cursor: 'pointer',textAlign:'center',display: 'block !important',}} >Estimate your repayments</span> */}
                                <span style={{color: '#000000',fontWeight:'400', fontSize: '20px',
                                 cursor: 'pointer',textAlign:'center'}}>
                                  Find out how much your repayments and interest rate could be with our calculator</span>
                            </div> 
                            <div className="md:flex mb-8" >           
                                <div className="lg:w-2.5/5 md:w-full rounded-xl md:mb-8 md:order-2">                                   
                                    <div className="group relative p-6 bg-white dark:bg-slate-900 shadow1 mt-10 " style={{borderTopLeftRadius:'20px',borderBottomLeftRadius:'20px'}}>                                                                                                          
                                        <span style={{textAlign:'center',}}>
                                            <div style={{marginTop:'6px'}}>
                                                <span className="font-medium text-slate-400 block mb-8" style={{textAlign:'left',fontSize:'25px'}}>Enter your loan details</span>
                                            </div>
                                            <div className="mt-10">
                                                <div className="flex flex-col" style={{textAlign:'left'}}>
                                                    <p className="font-medium text-slate-400 block ">I want to borrow...</p>
                                                    <div style={{width:'70%'}}>
                                                      <input name="name" id="name" type="text"
                                                       className="w-full text-[15px] py-2 px-3 h-10 bg-transparent outline-none shadow1 mt-4"
                                                       placeholder="$ 4000" 
                                                       style={{color:'black',borderRadius:'4px'}}
                                                       value={loanAmount}
                                                        onChange={(e) => setLoanAmount(e.target.value)}
                                                       />
                                                    </div>
                                                    
                                                </div>
                                                <div className="flex flex-col mt-6" style={{textAlign:'left'}}>
                                                    <p className="font-medium text-slate-400 block">
                                                      I'd like to repay the loan in...</p>
                                                    <ul className="mt-4 text-center p-2 bg-white shadow1 mb-6" id="myTab"
                                                     data-tabs-toggle="#StarterContent" role="tablist" 
                                                     style={{ borderRadius: '4px',width:'70%' }}>
                                                      {/* <li role="presentation" className="md:inline-block block md:w-fit w-full">
                                                        <Link
                                                          className={`px-6 py-2 font-semibold rounded-md w-full transition-all duration-500 ease-in-out ${activeIndex === 0 ? 'text-white bg-violet-6001' : ''}`}
                                                          type="button"
                                                          onClick={() => setIndex(0)}
                                                          style={{color:'black'}}
                                                        >
                                                          3 years
                                                        </Link>
                                                      </li>
                                                      <li role="presentation" 
                                                      className="md:inline-block block md:w-fit w-full">
                                                        <Link
                                                          className={`px-6 py-2 font-semibold rounded-md w-full transition-all duration-500 ease-in-out ${activeIndex === 2 ? 'text-white bg-violet-6001' : ''}`}
                                                          type="button"
                                                          onClick={() => setIndex(2)}
                                                          style={{color:'black'}}
                                                        >
                                                         4 years
                                                        </Link>
                                                      </li>
                                                      <li role="presentation" 
                                                      className="md:inline-block block md:w-fit w-full">
                                                        <Link
                                                          className={`px-6 py-2 font-semibold rounded-md w-full transition-all duration-500 ease-in-out ${activeIndex === 4 ? 'text-white bg-violet-6001' : ''}`}
                                                          type="button"
                                                          onClick={() => setIndex(4)}
                                                          style={{color:'black'}}
                                                        >
                                                          5 years
                                                        </Link>
                                                      </li> */}
                                                       {renderLoanTermButtons()}
                                                    </ul>
                                                </div>

                                                <div className="flex flex-col mt-1" style={{textAlign:'left'}}>
                                                    <p className="font-medium text-slate-400 block ">My credit score is....</p>
                                                    <div className='mt-2' style={{width:'70%'}}>
                                                      <Select
                                                        styles={customStyles}
                                                        placeholder='Select credit score...'
                                                        className="input"
                                                        options={Score}
                                                        onChange={setCreditScore}
                                                      />
                                                    </div>
                                                </div> 
                                                <div className="mt-6">
                                                    <span className="font-medium text-slate-400  block mb-1"
                                                     style={{fontStyle:'normal'}}>Don't know your score? 
                                                     <span style={{fontStyle:'italic',fontWeight:'bold'}} 
                                                     className='underline'>Check for free</span></span>
                                                </div>
                                            </div>                                               
                                        </span>                                                                                     
                                    </div>                                                                                                                                                                
                                </div>
                                <div className="lg:w-2.5/5 md:w-full rounded-xl md:mb-8 md:order-2" >                                   
                                    <div  id="print-section" className="group relative p-6 shadow1 mt-10 " 
                                    style={{backgroundColor:'#000',borderTopRightRadius:'20px',borderBottomRightRadius:'20px'}}>                                                                                                          
                                        <span style={{textAlign:'center',}}>
                                            <div>
                                                <span className="font-medium  block mb-8" 
                                                style={{color: '#fff'}}>Estimated monthly repayments</span>
                                                <span style={{color: '#fff',fontWeight:'600', fontSize: '60px', 
                                                cursor: 'pointer',textAlign:'left'}}>${emi||0}</span>
                                            </div>
                                            <div className="mt-10">
                                                <div className="flex justify-between">
                                                    <p className="font-medium  block " style={{color: '#fff'}}> Interest rate</p>
                                                    <p className="font-medium block " style={{color: '#fff'}}>{creditScore?.value}% pa</p>
                                                </div>
                                                {/* <div className="flex justify-between mt-1">
                                                    <p className="font-medium block " style={{color: '#fff'}}>Comparison rate*</p>
                                                    <p className="font-medium  block " style={{color: '#fff'}}>10.04% pa</p>
                                                </div> */}
                                                <div className="flex justify-between mt-1">
                                                    <p className="font-medium  block " style={{color: '#fff'}}> Total charge</p>
                                                    <p className="font-medium  block " style={{color: '#fff'}}>${totalInterest}</p>
                                                </div>
                                                <div className="flex justify-between mt-1">
                                                    <p className="font-medium  block " style={{color: '#fff'}}> Total repayment</p>
                                                    <p className="font-medium  block " style={{color: '#fff'}}>${totalAmount}</p>
                                                </div>   
                                                <div onClick={handleCalculateEmi} className="mt-6 get-my-rate">                                                
                                                    <Link
                                                        className='px-8 py-4 font-semibold rounded-md w-full transition-all duration-500 ease-in-out'                                  
                                                        type="button"
                                                        style={{ color: 'black' ,textAlign:'center',background:'#fff'}}>
                                                            Get My Rate
                                                    </Link>                                                   
                                                </div> 
                                                <div className="mt-6">
                                                    <span className="font-medium  block mb-1" style={{fontStyle:'italic',color: '#fff'}}>It won't affect your credit score !</span>
                                                </div>
                                                <div className="mt-6 fine-print" onClick={handlePrint}>
                                                    <span className="font-medium underline block mb-1" style={{color: '#fff'}}>
                                                      View the fine print</span>
                                                </div>
                                            </div>                                               
                                        </span>                                                                                     
                                    </div>                                                                                                                                                                
                                </div>                                                                  
                            </div>        
                        </div>
                    </div> 
                    {!empty(initialFAQ)?(<div className="md:flex" style={{ marginTop: '60px' }}>
                        <div className="lg:w-5/5 md:w-full md:mb-8 md:order-2" >
                            <li className="block">
                                <span style={{ color: '#000000', fontWeight: 'bold', fontSize: '30px', cursor: 'pointer', textAlign: 'left' }}>FAQs</span>
                            </li>

                            <div id="accordion-collapseone" data-accordion="collapse" className="mt-12">
                                {initialFAQ.map((item, index) => (
                                  
                                    <div key={index} className="relative shadow1 dark:shadow1 rounded-md overflow-hidden mt-4">
                                        <h2 className="text-base font-semibold" id="accordion-collapse-heading-1">
                                            <button onClick={() => toggleAccordion(index)} type="button" style={{ color: 'black' }}
                                                className={`flex justify-between items-center p-5 w-full font-semibold text-start ${activeIndex === index ? 'bg-gray-50 dark:bg-slate-8002 text-violet-6001' : ''}`}
                                                data-accordion-target="#accordion-collapse-body-1" aria-expanded="true" aria-controls="accordion-collapse-body-1">
                                                <span>{item.title}</span>
                                                <svg data-accordion-icon className={`w-4 h-4  shrink-0 ${activeIndex === index ? "rotate-180" : "rotate-360"}`} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path>
                                                </svg>
                                            </button>
                                        </h2>
                                        {activeIndex === index && (
                                            <div id="accordion-collapse-body-1" aria-labelledby="accordion-collapse-heading-1">
                                                <div className="p-5" style={{ backgroundColor: 'rgb(110 110 110 / 15%)' }}>
                                                    <p style={{ color: 'black' }}
                                                        dangerouslySetInnerHTML={{ __html:formatDescription( item?.description) }}></p>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>

                        </div>
                    </div> ):("")}
                </div>

                {/* <div className="container" style={{marginLeft:'0px',marginBottom:'-100px'}}>
                  <div className="flex justify-between">
                  <div style={{display: 'flex', justifyContent: 'center',}}>
                  <div class="avatar-group" >
                    <div class="avatar">
                     <img src="https://pixinvent.com/materialize-material-design-admin-template/app-assets/images/user/12.jpg"/>
                    </div>
                    <div class="avatar">
                     <img src="https://htmlstream.com/preview/unify-v2.6.2/assets/img-temp/400x450/img5.jpg"/>
                    </div>
                    <div class="avatar">
                     <img src="https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"/>
                    </div>
                    <div class="avatar">
                     <img src="https://rimage.gnst.jp/livejapan.com/public/article/detail/a/00/04/a0004213/img/basic/a0004213_main.jpg?20200710184501&q=80&rw=750&rh=536"/>
                    </div>
                    <div class="avatar">
                     <img src="https://da4e1j5r7gw87.cloudfront.net/wp-content/uploads/sites/768/2018/08/glasses-american-man-20s-hipster.jpg"/>
                    </div>
                  </div>
                    <div className="flex items-center">
                      <Link onClick={()=> setPlaceBid(!placeBid)} 
                      style={{color: '#000000', fontWeight: 'bold', fontSize: '20px',
                       cursor: 'pointer',marginTop:'15px',marginLeft:'10px'}}>
                      Hear from {bankData?.bank_name} clients
                      </Link>
                      <div style={{marginTop:'17px',fontSize:'40px',color:'#000000',cursor:'pointer' }} >
                      <RiArrowDropDownLine onClick={()=> setPlaceBid(!placeBid)} style={{color:'#000000'}}/>
                      </div>
                    </div> 
                    <div className="flex items-center justify-center" style={{marginTop:'17px',textAlign:'center',textDecoration:'underline',color:'#000'}}>
                        <div className="popover-container1">
                          <Link className="color" style={{marginLeft: '10px', color: '#000000', fontWeight: 'bold', fontSize: '20px', cursor: 'pointer',textAlign:'center' }}>
                              4.5
                          </Link>
                          <div className="popover-bottom-review" style={{textAlign:'left'}}>
                            <div className="popover">
                              <h3 className="popover-heading1 color-price">Rating:</h3>
                              <p className="popover-paragraph1 color-price">
                                Rating From Our Recent Clients 
                              </p>
                            </div>
                          </div>
                        </div>
                        <img src={star} height={32} width={32} alt="Image Description" className="badge-image" style={{ marginLeft: '0px',marginTop:'3px' }} />
                    </div>                       
                  </div>                           
                  </div>
                </div>              */}
                {/* <div style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, zIndex: 50, overflow: 'hidden', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(0, 0, 0, 0.5)' }} className={`m-auto justify-center items-center flex bg-gray-900 bg-opacity-50 dark:bg-opacity-80 ${placeBid ? "" : "hidden" }`}>
                  <div style={{ position: 'relative', width: '100%', height: 'auto', maxWidth: '600px', padding: '4px' }} className="relative w-full h-auto max-w-2xl p-4">
                      <div style={{ position: 'relative', backgroundColor: 'white', borderRadius: '8px', boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)' }} className="relative bg-white dark:bg-slate-900 rounded-lg shadow dark:shadow-gray-800">
                         
                          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '6px 12px', borderBottom: '1px solid #e6e6e6' }} className="flex justify-between items-center px-6 py-4 border-b border-gray-100 dark:border-t-gray-800">
                              <h5 style={{ fontSize: '1.5rem', fontWeight: '800', color: 'black' }} className="text-xl font-bold">❤️ Hear from {CreditsData?.title} Students</h5>
                              <button style={{ color: '#718096', backgroundColor: 'transparent', ':hover': { backgroundColor: '#edf2f7', color: '#2d3748' }, borderRadius: '8px', fontSize: '1rem', padding: '0.375rem 0.75rem', marginLeft: 'auto' }} type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-lg p-1.5 ms-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white">
                                  <IoMdClose style={{fontSize:'1.8rem',color:'black'}} onClick={() => setPlaceBid(!placeBid)} />
                              </button>
                          </div> */}

                          {/* Scrollable Content Section */}
                          {/* <div id='center' style={{ padding: '1.5rem', overflowY: 'auto', maxHeight: '400px', color: 'black' }} className="p-6 overflow-y-auto max-h-96">
                              {dataArray.map((item, index) => (
                                <>
                                  <div key={index} className='product-card1 mb-4'> 
                                      <h5 style={{ fontSize: '1rem', fontWeight: '600', marginBottom: '20px' }} className="text-xl font-semibold">{item.heading}</h5>
                                      <p style={{ textAlign: 'start', color: 'black',fontWeight:'500' }}>
                                          {item.content}
                                      </p>
                                      <img src={item.image} style={{ borderRadius: '0.75rem', marginTop: '20px', width: '10rem', height: '10rem' ,objectFit:'cover'}} alt="Your Image" />

                                      <div style={{ paddingTop: '1rem', borderTop: '1px solid #e6e6e6',marginTop:'25px' }} className="pt-4 border-t dark:border-gray-700">
                                          <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '1rem' }}>
                                          <p style={{ textAlign: 'start', color: 'black', fontWeight: '500', display: 'flex', alignItems: 'center' }}>
                                              {item.name} 
                                              <span style={{ display: 'flex', alignItems: 'center', marginLeft: '10px',color:'rgb(111 118 133/var(--tw-text-opacity))' }}> — <span class="mingcute--location-fill" style={{ fontSize: '19px', marginRight: '5px',marginLeft:'7px' }}></span>{item.from}</span>
                                          </p>

                                          </div>
                                          <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '1rem' }}>
                                              <span style={{ background: '#000000', borderRadius: '15px', display: 'flex', alignItems: 'center' }} className="bagde-flag1">
                                                  <span class="mingcute--airplane-line" style={{ fontSize: '20px', marginRight: '5px',}}></span>
                                                 {item.location}
                                              </span>
                                          </div>
                                      </div>          
                                  </div>
                                  </>
                              ))}
                          </div>                           */}
                      {/* </div>
                    </div> */}
                  {/* </div> */}
            </section>
            <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        rtl={false}
         draggable
        theme="light"
         />

            <Footer />
           
        </>
    )
}

// https://www.npmjs.com/package/leaflet