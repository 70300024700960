import React, { useCallback } from 'react'
import image from '../../assets/images/avatar/1.jpg';
import Navbar from '../../components/navbar'
import Footer from '../../components/footer'
import Switcher from '../../components/switcher';
import { Link, useNavigate } from 'react-router-dom';
import { Facebook, Instagram, Linkedin, Youtube, Twitter } from 'react-feather';
import { useState } from 'react';
import { storage } from '../../assets/firebase/constant';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { useEffect } from 'react';
import { REST_API, REST_API_END_POINT } from '../../constants/DefaultValue';
import axios from 'axios';
import { MdOutlineArrowBack } from 'react-icons/md';
import NavbarNew from '../../components/navbar-new';
export default function CreatorProfileEdit() {
    const userStr = localStorage.getItem('userVisa');
    const user = JSON.parse(userStr) || {};
    // console.log("user", user);
    const initialFormData = {
        first_name: user?.first_name || '',
        last_name: user?.last_name || '',
        email: user?.email || '',
        profile_pic: user?.profile_pic || '',
        phoneNumber: user?.phoneNumber || '',

    };
    const [formData, setFormData] = useState(initialFormData);
    const [tempProfilePic, setTempProfilePic] = useState(initialFormData.profile_pic);
    const navigate = useNavigate()
    // const loadFile = (event) => {
    //     const image = document.getElementById(event.target.name);
    //     image.src = URL.createObjectURL(event.target.files[0]);
    // };

    const loadFile =
        useCallback(
            (acceptedFiles) => {
                const file = acceptedFiles[0]
                if (file) {
                    const Filename = new Date().getTime() + file.name;

                    const StorageRef = ref(storage, Filename);
                    const uploadTask = uploadBytesResumable(StorageRef, file);
                    uploadTask.on(
                        'state_changed',
                        (snapshot) => {
                            const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                        },
                        (error) => {
                            console.error(error.message);
                        },
                        () => {
                            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                                // setFormData((prevFormData) => {
                                //     const updatedFormData = { ...prevFormData, profile_pic: downloadURL };
                                //     localStorage.setItem('userVisa', JSON.stringify({ ...user, profile_pic: downloadURL }));
                                //     return updatedFormData;
                                // });
                                    setTempProfilePic(downloadURL);
                                    setFormData((prevFormData) => ({
                                    ...prevFormData,
                                    profile_pic: downloadURL,
                                    }));
                            });
                        }
                    );
                }
            },
            [user],
        )
    // console.log("formData", formData);

    const handleChange = (e) => {
        const { name, value } = e.target;
        // setFormData((prevFormData) => {
        //     const updatedFormData = { ...prevFormData, [name]: value };
        //     const updatedUser = { ...user, ...updatedFormData };
        //     localStorage.setItem('userVisa', JSON.stringify(updatedUser));
        //     return updatedFormData;
        // });
        setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    };

    // const handleChange = (e) => {
    //     const { name, value } = e.target;
    //     if (["first_name", "last_name", "email", "phoneNumber"].includes(name)) {
    //         setFormData((prevFormData) => {
    //             const updatedFormData = { ...prevFormData, [name]: value };
    //             const updatedUser = {
    //                 ...JSON.parse(localStorage.getItem('user')),
    //                 [name]: value
    //             };
    //             localStorage.setItem('user', JSON.stringify(updatedUser));
    //             return updatedFormData;
    //         });
    //     }
    // };


    const handleFormSubmit = async (e) => {
        e.preventDefault();
        try {

            const updatedUser = { ...user, ...formData };
            localStorage.setItem('userVisa', JSON.stringify(updatedUser));
            const response = await axios.post(`${REST_API_END_POINT}update-user-details`, { updatedUser });
            console.log('Response:', response.data);
            console.log('Form data saved to local storage:', formData);
            // setFormData(initialFormData);
            navigate('/visa')
        } catch (error) {
            console.error('Error:', error);
        }
    }
    return (
        <>
            <NavbarNew tab={"visa"} aboutus={true} />
            <section className="relative lg:pt-24 pt-[74px] overflow-hidden" style={{ backgroundColor: '#000000' }}>
        <div className="container text-center text-white py-10">
          <p className="text-lg font-bold mb-5">Visa Search Profile</p>
          <h1 className="font-bold text-4xl lg:text-6xl">Customize Your Personal Details 
            {/* Contact us!{" "} */}
            </h1>
        </div>
      </section>
            {/* <div className="color-price" style={{marginTop:'30px',marginLeft:'20px'}}>
          <div className="flex items-center">
              <Link   
                onClick={() => window.history.back()}
                // to={`/visa`}                                               
                  style={{
                      color: '#000000',
                      fontWeight: 'bold',
                      fontSize: '16px',
                      cursor: 'pointer',
                      marginLeft: '15px',
                      paddingBottom: '3px', 
                      whiteSpace: 'nowrap', 
                      display: 'flex', 
                      alignItems: 'center', 
                  }}
                  className='under'>
                    
                  <MdOutlineArrowBack color='#000' style={{ marginRight: '5px' }} /> 
                  Go back 
              </Link>
          </div>
        </div> */}
            <section className="relative py-16" style={{marginBottom:'-90px'}}>

                <div className="container">
                    <div className="grid md:grid-cols-12 gap-[30px]">
                        <div className="lg:col-span-3 md:col-span-4">
                            <div className="group profile-pic w-[112px]">
                                <input id="pro-img" name="profile_pic" type="file" className="hidden" onChange={(e) => loadFile(e.target.files)} />
                                <div>
                                    <div className="relative shadow-md overflow-hidden" style={{ width: '110px', height: '110px', borderRadius: '50%', padding: 0, overflow: 'hidden',border:'3px solid #868686' }}>
                                        <img src={tempProfilePic} className="w-full h-full object-cover"  id="profile-image" alt="Profile" />
                                        <div className="absolute inset-0 group-hover:bg-slate-900/40 transition duration-500"></div>
                                        <label className="absolute inset-0 cursor-pointer" htmlFor="pro-img"></label>
                                    </div>
                                </div>
                            </div>
                            <p className="text-slate-4001 mt-3 w-28 text-center" style={{ fontSize: '12px' }}>
                                We recommend an image of at least 400X400. GIFs work too.
                            </p>
                        </div>

                        <div className="lg:col-span-9 md:col-span-8">
                            <div className="p-6 rounded-md shadow dark:shadow-gray-800 bg-white dark:bg-slate-900">
                                <h5 className="text-lg font-semibold mb-4 text-slate-400">Personal Detail :</h5>
                                <form method='post' onSubmit={handleFormSubmit} style={{ width: '100%' }}>
                                    <div className="grid lg:grid-cols-2 grid-cols-1 gap-5" style={{ width: '100%' }}>
                                        <div className="text-start">
                                            <label className="form-label font-medium text-slate-400">
                                                First Name :
                                                <span className="text-red-600">
                                                    *</span></label>
                                            <input type="text"
                                                className="form-input w-full text-[15px] 
                                           py-2 px-3 h-10 bg-transparent dark:text-slate-600 
                                           rounded-full outline-none border border-gray-200 
                                           focus:border-gray-200 dark:border-gray-200 
                                           dark:focus:border-gray-200 focus:ring-0 mt-2" placeholder="First Name:" id="first_name" name="first_name" value={formData.first_name} onChange={handleChange}
                                            />
                                        </div>
                                        <div className="text-start">
                                            <label className="form-label font-medium text-slate-400">Last Name :
                                                <span className="text-red-600">*</span></label>
                                            <input type="text" className="form-input w-full text-[15px] 
                                           py-2 px-3 h-10 bg-transparent dark:text-slate-600 
                                           rounded-full outline-none border border-gray-200 
                                           focus:border-gray-200 dark:border-gray-200 
                                           dark:focus:border-gray-200 focus:ring-0 mt-2"
                                                placeholder="Last Name:" id="last_name" name="last_name" value={formData.last_name} onChange={handleChange} />
                                        </div>
                                        <div className="text-start">
                                            <label className="form-label font-medium text-slate-400" >
                                                Your Email :
                                                <span className="text-red-600">*</span>
                                            </label>
                                            <input type="email"
                                                className="form-input w-full text-[15px] 
                                           py-2 px-3 h-10 bg-transparent dark:text-slate-600 
                                           rounded-full outline-none border border-gray-200 
                                           focus:border-gray-200 dark:border-gray-200 
                                           dark:focus:border-gray-200 focus:ring-0 mt-2" placeholder="Email" name="email" value={formData.email} onChange={handleChange} />
                                        </div>
                                        <div className="text-start">
                                            <label className="form-label font-medium text-slate-400">
                                                Phone Number : </label>
                                            <input name="phoneNumber" id="phoneNumber" type="number"
                                                className="form-input w-full text-[15px] 
                                           py-2 px-3 h-10 bg-transparent dark:text-slate-600 
                                           rounded-full outline-none border border-gray-200 
                                           focus:border-gray-200 dark:border-gray-200 
                                           dark:focus:border-gray-200 focus:ring-0 mt-2" placeholder="Phone Number :" value={formData.phoneNumber} onChange={handleChange} />
                                        </div>

                                    </div>
                                    
                                    <div className='' style={{ width: "100%" }}>

                                        <input type="submit" id="submit" name="send"
                                            className="btn bg-violet-6001 hover:bg-red-600 border-violet-600 over:bg-gray-200 text-white rounded-full mt-5 justify-start"
                                            value="Save Changes" />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            
            <Footer />
        </>
    )
}
