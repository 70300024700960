import React, { useEffect,useState,useRef } from 'react'

import Footer from '../../components/footer'
import star from '../../assets/images/rating-star.png';
import check from '../../assets/images/tick.png';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { BiWallet, IoMdClose, GoLocation,LuSchool, BiCalendarExclamation} from "../../assets/icons/vander"
import { Users,Calendar } from 'feather-icons-react/build/IconComponents';
import NavbarNew from '../../components/navbar-new';
import { dataArray, insuranceDetails } from '../../data/data';
import next from '../../assets/images/next.png';
import prev from '../../assets/images/previous.png';
import { UniversityDetails } from '../../data/data';  
import TinySlider from 'tiny-slider-react';
import axios from 'axios';
import { REST_API_END_POINT } from '../../constants/DefaultValue';
import { empty, parseDescription } from '../../common/Helper';
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import globe from '../../images/how to/university.png'
import req from '../../images/how to/books.png'
import apply from '../../images/how to/image__4_-removebg-preview.png'
import sts from '../../images/how to/image.jpg'
import NotificationNav from '../../components/notificationNav';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormHelperText, TextField } from '@material-ui/core';
import { DNA } from 'react-loader-spinner';
const newImg = [  
  { image_url: globe },
  { image_url: req },
  { image_url: apply },
  { image_url: sts },
];

const settings = {
  items: 1,
  controls: false, // Disable default controls
  mouseDrag: true,
  loop: true,
  rewind: true,
  autoplay: false,
  autoplayButtonOutput: false,
  navPosition: 'bottom',
  nav: false,
  gutter: 0,
};



const getData = (source, ids) => {
  if (!ids) return [];
  return ids.split(',').map(id => source.find(item => item.id == id)).filter(item => item !== undefined);
};
export default function UniversityDetail() {
  const { courseId, tab } = useParams();
  const [placeBid, setPlaceBid] = useState(false);
  const [documents,setDocuments] = useState([])
  const [docRequirement, setDocRequirement] = useState([])
  const [universities,setUniversities] = useState([])
  const [aboutCourse,setAboutCourse] = useState([])
  const [coursePlans,setCoursePlans] = useState([])
  const [universityDetail,setUniversityDetail]= useState({})
  const [universitiesDetails,setUniversitiesDetails]= useState({})
  const [courseDetails,setCourseDetails] = useState({})
  const [url,setUrl] = useState('')
  const sliderRef = useRef(null);
  const [notification,setNotification]=useState({})
  const [userData,setUserData] = useState({})
  const navigate=useNavigate()
  const [docName, setDocName] = useState([])
  const [docReqData, setDocReqData] = useState([])
  const [faqs, setFaq] = useState([])
  const [activeIndex, setActiveIndex] = useState(0);
  const [activeIndexx, setActiveIndexx] = useState(0);
  const [review,setReview] = useState({})

  
 const [open,setOpen]=useState()
 const [scroll, setScroll] = useState('paper');
 const [inputValue, setInputValue] = useState('');
 const [error, setError] = useState('');
 const [loading, setLoading] = useState(true);
 const handleInputChange = (event) => {
   const value = event.target.value;
   setInputValue(value);

   if (value.trim() && value.length <= 500) {
     setError('');
   }
 };
 const handleSubmit = () => {
   if (!inputValue.trim()) {
     setError('Notes/Comments cannot be empty.');
     return; // Prevent submission if validation fails
   }

   if (inputValue.length > 500) { // Example limit
     setError('Notes/Comments cannot exceed 500 characters.');
     return;
   }
   handleApplyForUniversityCourse(inputValue); // Pass the input value to the enquiry function
   setInputValue(''); // Clear the input after submission if needed
 };
 const handleClose=()=>{
   setOpen(!open)

 }
 const hadleClick=()=>{
   setOpen(!open)
  
 }
 const descriptionElementRef = useRef(null);
 useEffect(() => {
   if (open) {
     const { current: descriptionElement } = descriptionElementRef;
     if (descriptionElement !== null) {
       descriptionElement.focus();
     }
   }
 }, [open]);
 
  const handleNext = () => {
    if (sliderRef.current && sliderRef.current.slider) {
      sliderRef.current.slider.goTo('next');
    }
  };

  const handlePrev = () => {
    if (sliderRef.current && sliderRef.current.slider) {
      sliderRef.current.slider.goTo('prev');
    }
  };

  const formatDuration = (duration, unit) => `${duration} ${unit}`;

  const getCombinedDuration = (ele, universityDetail) => {
    const eleDuration = ele?.courseDuration || 0;
    const eleUnit = ele?.courseDuration_unit || '';
    const uniDuration = universityDetail?.courseDuration || 0;
    const uniUnit = universityDetail?.courseDuration_unit || '';
  
    let totalMonths = 0;
  
    // Convert both durations to months
    if (eleUnit === "Years") {
      totalMonths += eleDuration * 12;
    } else if (eleUnit === "Months") {
      totalMonths += eleDuration;
    }
  
    if (uniUnit === "Years") {
      totalMonths += uniDuration * 12;
    } else if (uniUnit === "Months") {
      totalMonths += uniDuration;
    }
  
    // Convert total months back to years and months
    const years = Math.floor(totalMonths / 12);
    const months = totalMonths % 12;
  
    // Format the result
    if (years > 0 && months > 0) {
      return `${years} ${years > 1 ? 'Years' : 'Year'} and ${months} ${months > 1 ? 'Months' : 'Month'}`;
    } else if (years > 0) {
      return `${years} ${years > 1 ? 'Years' : 'Year'}`;
    } else if (months > 0) {
      return `${months} ${months > 1 ? 'Months' : 'Month'}`;
    } else {
      return 'No Duration';
    }
  };



const fetchCourseDetailsbyID=()=>{
  axios.post(`${REST_API_END_POINT}university/get-course-details`,{courseId})
  .then((res)=>{
    if(res.data.status==1){
      const combinedData = res.data.data ? 
      [res.data.data, ...res.data.coursePlans] : res.data.coursePlans;
      setCoursePlans(combinedData)
      setUniversityDetail(res.data.data)
      setUniversitiesDetails(res.data.universityDetail)
      setNotification(res.data.data.notification_btn)
      setDocRequirement(res.data.documentsRequired)
      setCourseDetails(res.data.initialDetails)
      setFaq(res.data.faq)
      setUrl(res.data.university_url)
      setReview(res.data.review)
      // setDocuments(getData(res.data.documents,res.data.initialDetails?.doc_ids))
      setDocuments(res.data.documents)
      setAboutCourse(getData(res.data.aboutCourse,res.data.initialDetails?.about_course_ids))
      setLoading(false)
    }
  })
}
const getAllUniversities=()=>{
  axios.post(`${REST_API_END_POINT}university/get-all-list`,{})
  .then((res)=>{
    if(res.data.status===1){
      setUniversities(res.data.data)
    }
  })
  .catch((err)=>{
    console.log(err)
  })
}

  useEffect(() => {
    getAllUniversities() 
    fetchCourseDetailsbyID()
}, [courseId]);

const toggleAccordion = (index) => {
  if (activeIndex === index) {
    setActiveIndex(0);
  } else {
    setActiveIndex(index);
  }
}
const toggleAccordions = (index) => {
  if (activeIndexx === index) {
    setActiveIndexx(0);
  } else {
    setActiveIndexx(index);
  }
}

const initialFAQ = getData(faqs, courseDetails?.faq_ids)
// console.log("initialFAQ",initialFAQ);
// const formatDescription = (description) => {
//   return description.replace(/<p>/g, '<p><span style="font-weight: bold; font-size: larger;">•</span> ');
// };
//  const formatDescription = (description) => {
//   if (!description) return '';

//   // Handle ordered lists
//   let listIndex = 1;
//   if (description.includes('<ol>')) {
//     description = description.replace(/<ol>/g, '');
//     description = description.replace(/<\/ol>/g, '');
//     description = description.replace(/<li>/g, () => {
//       const item = `${listIndex++}. `;
//       return `<div class="list-item">${item}`;
//     });
//   }

//   // Handle unordered lists
//   if (description.includes('<ul>')) {
//     description = description.replace(/<ul>/g, '');
//     description = description.replace(/<\/ul>/g, '');
//     description = description.replace(/<li>/g, '<div class="list-item">● ');
//   }

//   description = description.replace(/<\/li>/g, '</div>');

//   // Clean up empty paragraphs if any
//   description = description.replace(/<p><\/p>/g, '');

//   return description;
// };
const formatDescription = (description) => {
  if (!description) return '';

  // Handle ordered lists
  let listIndex = 1;
  if (description.includes('<ol>')) {
    description = description.replace(/<ol>/g, '');
    description = description.replace(/<\/ol>/g, '');
    description = description.replace(/<li>/g, () => {
      const item = `${listIndex++}. `;
      return `<div class="list-item">${item}`;
    });
  }

  // Handle unordered lists
  if (description.includes('<ul>')) {
    description = description.replace(/<ul>/g, '');
    description = description.replace(/<\/ul>/g, '');
    description = description.replace(/<li>/g, '<div class="list-item" style="margin-left:35px;">● ');
  }

  description = description.replace(/<\/li>/g, '</div>');

  // Clean up empty paragraphs if any
  description = description.replace(/<p><\/p>/g, '');

  return description;
};

// Usage
{/* <span dangerouslySetInnerHTML={{ __html: formatDescription(courseDetails?.course_details) }} /> */}


const expireDate = moment.unix(universityDetail?.expiredate);
const formattedExpireDate = expireDate.format('MMMM D, YYYY');



  const universityData = UniversityDetails.find((university) => university.id === parseInt(1));

  const title = universityData ? universityData.title : 'University Title';
  const years = universityData ? universityData.years : 'Course Time';
  const fee = universityData ? universityData.fee : 'Course Fee';


  const [activeDocument, setActiveDocument] = useState(null);
  const handleClick = (data) => {
    setActiveIndex(0);
    setDocReqData(getData(documents, data.documents_ids));
    setDocName(data.document_type);
    setActiveDocument(data.document_type);
  };

  useEffect(() => {
    if (docRequirement?.length > 0) {
      handleClick(docRequirement[0])
    }
  }, [docRequirement])


  useEffect(() => {
    document.documentElement.classList.add('dark');
    setUserData(JSON.parse(localStorage.getItem('userVisa')))
  }, []);

  const handleApplyForUniversityCourse=async(value)=>{
    setOpen(!open)
    if(!empty(userData)){try {
    axios.post(`${REST_API_END_POINT}university/save-application-form`,{
      userId:userData?.user_id,
      university_id:universityDetail?.university_id,
      courseId,value
    })
    .then((res)=>{
      if(res.data.status==1){
        toast.success(res.data.message)
      }else{
        toast.error(res.data.message)
      }
    })
    } catch (error) {
      console.log(error)
    }
  }else{
    navigate('/signup-visa/' + tab)
  }
  }
console.log("noHomeTab={true}noHomeTab={true}noHomeTab={true}",tab);

const settings1 = {
  items: 1,
  controls: true,
  mouseDrag: true,
  loop: true,
  rewind: true,
  autoplay: true,
  autoplayButtonOutput: false,
  autoplayTimeout: 3000,
  navPosition: "bottom",
  nav: false,
  speed: 400,
  gutter: 0,
  controlsText: ['<i class="mdi mdi-chevron-left "></i>', '<i class="mdi mdi-chevron-right"></i>']
};

  return (
   <>
       {loading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
          }}
        >
        <DNA
        visible={true}
        height="140"
        width="140"     
        ariaLabel="dna-loading"
        wrapperStyle={{}}
         wrapperClass="dna-wrapper"
           />
        </div>
      ) : (<>
    
        {notification === 1&&<NotificationNav notificationData={universityDetail?.notifications} />}
              <NavbarNew noHomeTab={true} tab={'universities'} notification={notification === 1?true:false} />
  
                  <section className="relative" style={{ marginTop:notification === 1?"170px": "110px" }}>
                    <div className="container">
  
                    {/* {(notification === 1) && (
                          <div style={{ display: "flex", justifyContent: "center" }}>
                              <div className="flex items-center p-3 mt-3 rounded-lg" style={{ width: '100%', backgroundColor: 'grey', marginBottom: '10px', justifyContent: 'space-between', color: 'white' }}>
  
  
                                  <div className="ms-2 items-center" style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'row', textAlign: 'left' }}>
                                      <BsExclamationOctagon style={{ color: 'white', marginRight: '10px' }} className="text-3xl" />
                                      <div>
                                          <span className="block" >{universityDetail?.notifications}</span>
                                      </div>
                                  </div>
                                  <div className="ms-2" style={{ display: 'flex', justifyContent: 'flex-end', flexDirection: 'column', textAlign: 'right' }}>
                                  </div>
  
                              </div>
  
                          </div>
                      )} */}
                    <div className="md:flex">
  
                      {!empty(universityDetail)&&(
                      <div className="lg:w-3/5 md:w-full  md:order-2">
                        <div style={{ position: 'relative', width: '100%', maxWidth: '100%', overflow: 'hidden' }}>
                          <img
                            style={{
                              width: '100%',
                              left: '0',
                              minWidth: '300px',
                              height: '330px',
                              borderRadius: '10px',
                              objectFit: 'cover',
                            }}
                            src={universityDetail.cover_image}
                            alt="VIsa Search Image"
                          />
                          <div className='visa-image' style={{ display: 'flex', justifyContent: 'space-between' }}>
                              <span style={{fontSize:'22px'}}>{universityDetail?.name}</span>
                             
                            
                                <div style={{display:'grid',justifyItems:'center',paddingRight:'15px'}}>
                                <span style={{fontSize:'17px'}}>
                                  {review.reviewRating}
                                <img
                           src={star}
                           height={20}
                           width={20}
                           alt="Image Description"
                           className="badge-image"
                           style={{ marginLeft: "0px", }}
                         />
                                </span>
                                <span style={{fontSize:'16px'}}>Rating(
                                  {review.userCount}
                                  )</span>
                                </div>
                              {/* <span style={{paddingRight:'15px'}}>{years}</span> */}
                              <div className="bagde-flag-wrap2">
                                <img
                                  style={{
                                      position: 'absolute',
                                      top: '0',
                                      right: '0',
                                      width: '100%',
                                      height: '100%',
                                      objectFit: 'cover',
                                      zIndex: '2', 
                                  }}
                                  src={universityDetail.logo} 
                                  alt="College Logo"
                                />
                              </div>
                              {universitiesDetails?.rank_button===1 &&( 
                              <div className="bagde-flag-wrap-rank">
                              {/* <span>
                               # {universitiesDetails?.global_rank_number}
                               </span> */}
                                </div>
                                )}
                             {/* {universityDetail?.rank_button===1 &&(  
                              <div className="bagde-flag-wrap4">
                                <img
                                  style={{
                                      position: 'absolute',
                                      top: '0',
                                      right: '0',
                                      width: '100%',
                                      height: '100%',
                                      objectFit: 'cover',
                                      zIndex: '2', 
                                  }}
                                  src={rank} 
                                  alt="Ranking"
                                />
                              </div>
                            )} */}
                          </div>
                        </div>                                                                                                                                    
                      </div>
                    )}
  
                      <div className="lg:w-2/5 md:order-1 md:mt-16  md:mt-0 lg:mt-0 mb-1" 
                       style={{
                                width: '100%',
                                '@media (max-width: 560px)': {
                                  width: '100%', 
                                },
                                cursor:'pointer'
                              }}>
                        <div>
                            <div className="sticky top-20"></div>
                              <div className="relative"
                               >
                                <div className="tiny-one-icon-item item-college">
                                    <TinySlider settings={settings} ref={sliderRef}>
                                            {coursePlans.map((ele, index) => (
                                                <div className="tiny-slide" key={index}>                                            
                                                    <div className="m-2 bg-white dark:bg-slate-900 rounded-lg dark:shadow-gray-800">
                                                    <div className="offer-badge" style={{marginTop:'-70px'}}>
                                                      <img src={check} height={80} width={80} alt="Image Description" className="badge-image" />
                                                      <div className="badge-text-container">
                                                       <span className="badge-text1">Your offer letter arrives in</span><br />
                                                        <span className="badge-text">{ele.offer_letter} {ele.getOfferLetterOn_unit}</span>
                                                      </div> 
                                                    </div>
                                                     
                                                    <div className="product-card"
                               
                                style={{minHeight:'210px',maxHeight:'433px'}}>
                                  <div className="  scroll-container" 
  style={{ marginTop:'-23px',
   textAlign: "left",
    paddingLeft: '15px',
     paddingTop: '10px',
      zIndex: 999,
      //  border: '1px solid #dbdada',
        borderRadius: '10px',
        // backgroundColor:'#dbdada',
        cursor: "all-scroll",
        height:'210px',
        overflowY: "scroll", // Ensure scrollbar appears only on the y-axis
        msOverflowStyle: "none", // Internet Explorer 10+
        scrollbarWidth: "thin", // Firefox
         }}>
                                                        {/* <hr style={{marginTop:'15px',border:'0.5px solid black',}}/> */}
                                                        { !empty(ele?.partner_u_id) && (<div className="color-price" style={{marginTop:'80px'}}>
                                                          <div className="color-option">
                                                            <span style={{ marginRight: '10px' }}><LuSchool style={{color:'black',fontSize:'1.5rem'}}/>
                                                            </span><span className="color">Partnership with</span>
                                                          </div>
                                                          <div className="price">
                                                          <div className="color-option">
                                                            <span className="color"><span style={{fontWeight:'bold'}}>
                                                              {universities?.find(d=>d.id===ele.partner_u_id)?.name}
                                                              </span></span>
                                                          </div>
                                                          </div>
                                                        </div>)}
                                                        {empty(ele?.partner_u_id) ? 
                                                         <div className="color-price" style={{marginTop:'80px'}}>
                                                          <div className="color-option">
                                                            <span style={{ marginRight: '10px' }}>
                                                              <Users style={{color:'black',fontSize:'1.5rem'}}/>
                                                              </span><span className="color">
                                                              {ele.name}</span>
                                                          </div>
                                                        </div>
                                                        :
                                                        <div className="color-price mt-4">
                                                          <div className="color-option">
                                                            <span style={{marginRight: '15px' ,marginBottom:"25px"}}>
                                                              <Users style={{color:'black'}}/></span>
                                                              <span className="color">
                                                                {ele.name} + <br/>
                                                                {universityDetail?.name}
                                                                </span>
                                                          </div>
                                                        </div> }
                                                        <div className="color-price mt-4">
                                                          <div className="color-option">
                                                          <span style={{marginRight:'10px',fontSize:'25px'}}><span class="icon-park-outline--time" style={{color:'black'}}/>
                                                          </span><span class="color">Course Intake</span>
                                                          </div>
                                                          <div className="price">
                                                          <div className="color-option">
                                                            <span className="color"><span style={{fontWeight:'bold'}}>
                                                              {universityDetail?.intake}
                                                              </span></span>
                                                          </div>
                                                          </div>
                                                        </div>
                                                        
                                                        <div className="color-price mt-4" style={{marginBottom:'20px'}}>
                                                          <div className="color-option">
                                                          <span style={{marginRight:'10px',fontSize:'25px'}}>
                                                            <BiWallet style={{color:'black'}}/>
                                                            </span><span class="color">Fee Yearly</span>
                                                          </div>
                                                          <div className="price">
                                                          <div className="color-option">
                                                          {empty(ele?.partner_u_id) ? 
                                                            <span className="color"><span style={{fontWeight:'bold'}}>{ele.fee_symbol} {ele.fee} </span></span>
                                                            :
                                                            <span className="color"><span style={{fontWeight:'bold'}}>
                                                              {ele.fee+universityDetail.fee}
                                                              </span></span> 
                                                            }
                                                          </div>
                                                          </div>
                                                        </div>  
                                                        <div className="color-price mt-4" style={{marginBottom:'20px'}}>
                                                          <div className="color-option">
                                                          <span style={{marginRight:'10px',fontSize:'25px'}}>
                                                            <BiCalendarExclamation style={{color:'black'}}/>
                                                            </span><span class="color">Course Duration</span>
                                                          </div>
                                                          <div className="price">
                                                          <div className="color-option">
                                                          {empty(ele?.partner_u_id) ? 
                                                            <span className="color"><span style={{fontWeight:'bold'}}>{ele.courseDuration} {ele.courseDuration_unit}</span></span>
                                                            :
                                                            <span className="color"><span style={{fontWeight:'bold'}}>
                                                              {/* {ele.fee+universityDetail.fee} */}
                                                              {getCombinedDuration(ele,universityDetail)}
                                                              {/* {universityDetail?.courseDuration_unit=="Years" && ele.?courseDuration_unit=="Years"?{{universityDetail.courseDuration}+{ele.courseDuration}{universityDetail?.courseDuration_unit}}:
                                                              universityDetail?.courseDuration_unit=="Years" && ele.?courseDuration_unit=="Months"?{{universityDetail?.courseDuration} {universityDetail?.courseDuration_unit} and {ele.courseDuration_unit}{ele.courseDuration}}:
                                                              universityDetail?.courseDuration_unit=="Months" && ele.?courseDuration_unit=="Years"?{{ele?.courseDuration}{ele?.courseDuration_unit} and{universityDetail?.courseDuration}  {universityDetail.courseDuration_unit}}
                                                              
                                                              } */}
                                                              </span></span> 
                                                            }
                                                          </div>
                                                          </div>
                                                        </div>  
                                                        
  </div>
                                                        {coursePlans.length>1 && (
                                                      <div className="color-price" style={{display:'flex',justifyContent:'center',flexDirection:'row',alignItems:'center',alignContent:'center'}}>
                                                          <button className="inline-block m-2" onClick={handlePrev}><img className="px-3 py-2 text-slate-400 bg-gray-50 dark:bg-slate-801 text-sm font-semibold rounded-lg shadow items-center" style={{cursor:'pointer'}} width={50} height={50} src={prev} /></button>
                                                          <button className="inline-block m-2" onClick={handleNext}><img className="px-3 py-2 text-slate-400 bg-gray-50 dark:bg-slate-801 text-sm font-semibold rounded-lg shadow items-center" style={{cursor:'pointer',marginRight:'15px'}}  width={50} height={50} src={next}/></button> 
                                                          <span className="color" >
                                                            <li className="inline-block m-2"><Link to="#" className="px-3 py-2 text-slate-400 bg-gray-50 dark:bg-slate-801 text-sm font-semibold rounded-lg shadow items-center">{index+1} / {coursePlans.length}</Link></li>
                                                          </span>
                                                      </div>
                                                      )}  
                                                      {/* <div className="color-price" style={{display:'flex',justifyContent:'center',alignContent:'center',alignItems:'center'}}>
                                                        <span className="color">
                                                          <li className="inline-block m-2"><Link to="#" className="px-3 py-2 text-slate-400 bg-gray-50 dark:bg-slate-801 text-sm font-semibold rounded-lg shadow items-center">{index+1} / {data.length}</Link></li>
                                                        </span>
                                                      </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </TinySlider>
                                </div>
                            </div>
                          </div>
                        </div>                                                                      
                    </div>
                    <div className='EnquiryName'>
                      <span
                       style={{
                        color: '#000000', 
                        fontWeight: 'bold',
                        fontSize: '24px',
                        cursor: 'pointer',
                        textAlign: "center",
                        }}
                       className='underlineeee'
                       onClick={hadleClick}>
                        Enquiry
                      </span>
                    </div>
                  </div>
                  
                  <div style={{marginTop:'-45px'}}>
  
                      <div className="group rounded-xl bg-white dark:bg-slate-900 w-full mx-auto lg:max-w-2xl " style={{ zIndex: 1, marginTop: '10px',display:'flex',justifyContent:'center',alignContent:'center',alignItems:'center',paddingLeft:'10px',paddingRight:'9px'}}>
                     
                      <Link
                        className='font-semibold transition-all duration-500 ease-in-out text-white bg-violet-6001 '                                  
                        type="button"
                        to={'/consultants/'}                               
                        style={{ color: 'black',width:'550px',height:'70px',display:'flex',justifyContent:'center',textAlign:'center',padding:'22px',borderRadius:'14px'}}
                        >
                          Find Education Consultant
                      </Link>
  
                    </div>
  
                </div>
  
                <hr style={{ marginTop: '-36px', borderColor: 'black', borderWidth: '1px' }} />
              
                <div className="container"  style={{ marginTop: '50px', }}>
   <div className="lg:flex" >
   <div className="w-full" >
      <div
        className="grid-container paddingPr"
        style={{
          display: 'grid',
          width: '100%',
          marginBottom:'20px'
        }}
      >
                      {/* First color-price */}
                      {universityDetail.location ?(<div className="color-price margnItem1" style={{ marginTop:'18px', textAlign:'left'}}>
                        <div className="flex">
                          <div style={{ fontSize: '22px', color: '#000000', marginRight: '15px' ,marginBottom:"10px"}}>
                          <GoLocation style={{color:'black'}}/>
                          </div>
                          
                          <div className="color-option" style={{ display: 'flex', flexDirection: 'column' }}>
                            <Link className="color" 
                            style={{ fontSize: '18px', color: '#000', marginBottom: '4px',textAlign:'left',display:"flex"}}>Location</Link>
                            <div className="popover-container">
                              <Link className="color" style={{ fontSize: '16px', color: '#000000',}}>
                             { universityDetail.location?.split(',').slice(0, 2).join(', ')}
                                </Link>
                             
                            </div>
                            
                          </div>
                        </div>
                      </div>) : ''}
                                              
                      {/* Second color-price */}
                      {courseDetails?.qualification ?(<div className="color-price margnItem1" style={{ marginTop: '22px' , textAlign:'left'}}>
                        <div className="flex">
                          <div style={{ fontSize: '26px', color: '#000000', marginRight: '15px' ,marginBottom:"25px" }}>
                            {/* Clock Component */}
                            <span className='material-symbols--school-outline' style={{color:'black'}}/>
                          </div>
                          <div className="color-option" style={{ display: 'flex', flexDirection: 'column' }}>
                            <Link className="color" style={{ fontSize: '18px', color: '#000', marginBottom: '4px',textAlign:'left'}}>
                              Qualification</Link>
                            <div className="popover-container">
                              <Link className="color" style={{ fontSize: '16px', color: '#000000',}}>
                                {courseDetails?.qualification}</Link>
                             
                            </div>
                          </div>
                        </div>
                      </div>): ''}
                                              
                      {/* Third color-price */}
                      
                      {courseDetails?.course ? (
    <div className="color-price margnItem1" style={{ marginTop: '22px', textAlign: 'left' }}>
      <div className="flex">
        <div style={{ fontSize: '26px', color: '#000000', marginRight: '18px' }}>
          <span className='bxs--school' style={{ color: 'black' }} />
        </div>
        <div className="color-option" style={{ display: 'flex', flexDirection: 'column' }}>
          <Link className="color" style={{ fontSize: '18px', color: '#000', marginBottom: '4px', textAlign: 'left' }}>
            Course
          </Link>
          <div style={{ position: 'relative' }}>
            <div
              style={{
                // fontWeight: 'bold',
                fontSize: '16px', // Increased font size
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: 'inline-block',
                maxWidth: '110px',
                cursor: 'pointer',
                color:'black'
              }}
              onMouseEnter={() => {
                document.getElementById('tooltip-course').style.display = 'block';
              }}
              onMouseLeave={() => {
                document.getElementById('tooltip-course').style.display = 'none';
              }}
            >
              {(() => {
                const courseList = courseDetails?.course?.split('.');
                if (courseList.length === 1) {
                  return courseList[0].trim();
                } else {
                  return `${courseList[0].trim()} & more`;
                }
              })()}
            </div>
            <div
              id="tooltip-course"
              style={{
                display: 'none',
                position: 'absolute',
                backgroundColor: '#fff', // Changed background to white
                border: '1px solid #e6e6e6', // Added border
                color: '#000', // Changed text color to black for better readability
                padding: '10px', // Increased padding for better spacing
                borderRadius: '5px',
                whiteSpace: 'nowrap',
                fontSize: '16px', // Increased font size
                zIndex: 1000,
                top: '-19%',
                left: '50%',
                transform: 'translateX(-50%)',
                boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)' // Added subtle shadow for depth
              }}
            >
              {courseDetails?.course.split('.').map((item, index) => (
                <div key={index}>{item.trim()}</div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : ''}
  
                                              
                      {/* Fourth color-price */}
                      {courseDetails?.intake ? (<div className="color-price margnItem1" style={{ marginTop: '15px', textAlign:'left' }}>
                        <div className="flex">
                          <div style={{ fontSize: '26px', color: '#000000',marginRight: '15px' ,marginBottom:"25px" }}>
                          <Calendar style={{color:'black'}}/>
                          </div>
                          <div className="color-option" style={{ display: 'flex', flexDirection: 'column' }}>
                            <Link className="color" style={{ fontSize: '18px', color: '#000', marginBottom: '4px',textAlign:'left'}}
                            >Next Intake</Link>
                            <div className="popover-container">
                              <Link className="color" style={{ fontSize: '16px', color: '#000000',}}>
                                {courseDetails?.intake}</Link>
                             
                            </div>
                          </div>
                        </div>
                      </div>) : ''}
                      {/* Fifth color-price */}
                      {courseDetails?.score ? (<div className="color-price margnItem1" style={{ marginTop: '15px', textAlign:'left'}}>
                        <div className="flex items-center">
                        <div style={{ fontSize: '26px', color: '#000000',marginRight: '15px' ,marginBottom:"30px" }}>
                          <span className='material-symbols--credit-score-sharp' style={{color:'black', marginTop: '11px'}}/>
                          </div>
                          <div className="color-option" style={{ display: 'flex', flexDirection: 'column' }}>
                            <Link className="color" style={{ fontSize: '18px', color: '#000', marginBottom: '4px',textAlign:'left'}}>
                              {/* Entry Score */}
                              IELTS Score
                              </Link>
                            <div className="popover-container">
                              <Link className="color" style={{ fontSize: '16px', color: '#000000',}}>
                                {courseDetails?.score}
                                </Link>
                            
                            </div>
                          </div>
                        </div>
                      </div>) : ''}
                      {/* Sixth color-price */}
                      {/* {courseDetails?.percent ?(<div className="color-price" style={{ marginTop: '40px', textAlign:'left'}}>
                        <div className="flex items-center">
                        <div style={{ fontSize: '26px', color: '#000000', marginRight: '15px' ,marginBottom:"35px" , marginTop: '-8px'}}>
                          <img src={travel} height={25} width={25}  alt="Image Description" className="badge-image"/>
                          </div>
                          <div className="color-option" style={{ display: 'flex', flexDirection: 'column' }}>
                            <Link className="color" style={{ fontSize: '18px', color: '#000', marginBottom: '4px',textAlign:'left'}}>
                              International</Link>
                            <div className="popover-container">
                              <Link className="color" style={{ fontSize: '16px', color: '#000000',}}>Students</Link>
                             
                            </div>
                            <Link className="color" style={{ fontSize: '16px', color: '#000000',textAlign:'left'}}>{courseDetails?.percent}%</Link>
                          </div>
                        </div>
                      </div>) : ''} */}
                      {/* Seventh color-price */}
                      {url ?(<div className="color-price margnItem1" style={{ marginTop: '18px', textAlign:'left'}}>
                        <div className="flex items-center">
                        <div style={{ fontSize: '26px', color: '#000000', marginRight: '15px' ,marginBottom:"35px" }}>
                          <span className='icons8--question-mark' style={{color:'black', marginTop: '11px'}}/>
                          </div>
                          <div className="color-option" style={{ display: 'flex', flexDirection: 'column' }}>
                            <Link className="color underlineeee" style={{ fontSize: '18px', color: '#000', marginBottom: '4px',textAlign:'center'}}
                            onClick={()=>window.open(url, '_blank')}
                            >
                              Want's to Know<br/> More</Link>
                            {/* <div className="popover-container1"> */}
                              <Link  onClick={()=>window.open(url, '_blank')}  className="color" style={{ fontSize: '16px', color: '#000000',textDecoration:'underline'}}>
                              </Link>
                            {/* </div> */}
                          </div>
                        </div>
                      </div>) : ''}
                    </div>
                    </div>
                  </div>   
                  </div>   
                  
                  <div className="container mt-12">
                <div className="md:flex">
                  <div className="lg:w-2/5 md:w-full md:pe-4 md:order-1 md:mt-0 mt-10 lg:mt-0">
                  <div className="sticky top-20 -mt-10">
      {newImg?.map((ele, index) => (
        <div
          key={index}
          className="group relative rounded-xl shadow1 newImgWidth p-5 text-center mt-4 xs:hidden"
          style={{
            display: "flex",
            justifyContent: "center",
            backgroundColor:'black',
            alignContent: "center",
          //   display:'flex',
          // alignItems:'end'
          }}
        >
          <div
            className="relative"
            style={{
              display: "flex",
              // backgroundColor:'black',
              justifyContent: "center",
              alignContent: "center",
            }}
          >
            <img
              style={{
                width: "7rem",
                
                height: "auto",
                maxWidth: "100%",
                verticalAlign: "middle",
                objectFit: "contain",
              }}
              src={ele.image_url}
              alt={`Partner ${index}`}
            />
          </div>
        </div>
      ))}
      <div className="grid relative grid-cols-1 sm:hidden">
                <div className="tiny-four-icon-item">
                    <TinySlider settings={settings1}>
                        {newImg.map((item, index) => (
                            <div className="tiny-slide" key={index}>
                                <div className="group relative overflow-hidden rounded-lg shadow p-4 dark:shadow-gray-800 ease-in-out duration-500 m-2" style={{backgroundColor:'#000'}}>
                                    <img src={item.image_url}  alt={`Partner ${index}`} style={{verticalAlign: "middle",objectFit:'contain',height:'150px',justifyContent:'center',width:'100%'}} className="rounded-lg" />
                                </div>
                            </div>
                        ))}
                    </TinySlider>
                </div>
            </div>
       </div>
                  </div>
  
  
                  <div className="lg:w-3/5 md:w-full md:mb-8 md:order-2"
                    style={{ paddingInline: "1rem" }}
                  >
                    <ol class="c-stepper">
                      <li
                        className="block items-center Mt-new"
                        style={{ marginBottom: "30px", }}
                      >
                        <span
                          style={{
                            color: "#000000",
                            fontWeight: "bold",
                            fontSize: "24px",
                            cursor: "pointer",
  
                          }}
                        >
                          All about the course
                        </span>
                      </li>
                      <li class="c-stepper__item">
                        <div class="c-stepper__content">
                          <a
                            class="stepper-link c-stepper__title"
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <span
                              style={{
                                color: "black",
                                marginLeft: "-48px",
                                fontWeight: "bold",
                                marginTop: "-7px",
                              }}>
                              Info <br /> 01
                            </span>
                            <span
                              style={{
                                marginLeft: "10px",
                                marginTop: "-15px",
                                fontWeight: "bold",
                                fontSize: "20px",
                                flex: "1",
                              }}
                            >
                              {/* {aboutCourse?.title}   */}
                              {universityDetail?.name}
                            </span>
                          </a>
                          <a class="stepper-link travel-advice" style={{ textAlign: "left", marginTop: '-16px', marginLeft: '14px' }}>
                            <span dangerouslySetInnerHTML={{__html:formatDescription(courseDetails?.course_details)}} />
                          </a>
                        </div>
                      </li>
  
                      <li class="c-stepper__item">
                        <div class="c-stepper__content">
                          <a
                            class="stepper-link c-stepper__title"
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <span
                              style={{
                                color: "black",
                                marginLeft: "-48px",
                                fontWeight: "bold",
                                marginTop: "-7px",
                              }}>
                              Info <br /> 02
                            </span>
                            <span
                              style={{
                                marginLeft: "10px",
                                marginTop: "-15px",
                                flex: "1",
                                fontWeight: "bold",
                                fontSize: "20px",
                              }}
                            >
                             Course Structure
                            </span>
                          </a>
                          <a class="stepper-link travel-advice" style={{ textAlign: "left", marginTop: '-16px', marginLeft: '14px' }}>                     
                            <span dangerouslySetInnerHTML={{__html:formatDescription(courseDetails?.course_structure)}} />
                          </a>
                        </div>
                      </li>
  
                      <li class="c-stepper__item">
                        <div class="c-stepper__content">
                          <a
                            class="stepper-link c-stepper__title"
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <span
                              style={{
                                color: "black",
                                marginLeft: "-48px",
                                fontWeight: "bold",
                                marginTop: "-7px",
                              }}>
                              Info <br /> 03
                            </span>
                            <span
                              style={{
                                marginLeft: "10px",
                                marginTop: "-15px",
                                flex: "1",
                                fontWeight: "bold",
                                fontSize: "20px",
                              }}
                            >
                              Entry requirements
                            </span>
                          </a>
                          <a class="stepper-link travel-advice" style={{ textAlign: "left", marginTop: '-16px', marginLeft: '14px' }}>            
                            <span dangerouslySetInnerHTML={{__html:formatDescription(courseDetails?.entry_requirement)}} />
                          </a>
                        </div>
                      </li>
                      <li class="c-stepper__item">
                        <div class="c-stepper__content">
                          <a
                            class="stepper-link c-stepper__title"
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <span
                              style={{
                                color: "black",
                                marginLeft: "-48px",
                                fontWeight: "bold",
                                marginTop: "-7px",
                              }}>
                              Info <br /> 04
                            </span>
                            <span
                              style={{
                                marginLeft: "10px",
                                marginTop: "-15px",
                                flex: "1",
                                fontWeight: "bold",
                                fontSize: "20px",
                              }}
                            >
                              Fees
                            </span>
                          </a>
                          <a class="stepper-link travel-advice" style={{ textAlign: "left", marginTop: '-16px', marginLeft: '14px' }}>              
                            <span dangerouslySetInnerHTML={{__html:formatDescription(courseDetails?.fee_details)}} />
                          </a>
                         
                        </div>
                      </li>
                      <li class="c-stepper__item">
                        <div class="c-stepper__content">
                          <a class="stepper-link c-stepper__title">
                            <span
                              style={{
                                color: "black",
                                marginLeft: "-48px",
                                fontWeight: "bold",
                                marginTop: "-7px",
                              }}>
                              Info <br /> 05
                            </span>
                            <span style={{ marginLeft: "10px", marginTop: "5px", fontWeight: "bold", fontSize: "20px", }}>
                             Pathway to permanent residency
                            </span>
                          </a>
                          <a class="stepper-link travel-advice" style={{ textAlign: "left", marginTop: '-16px', marginLeft: '14px' }}>             
                            <span dangerouslySetInnerHTML={{__html:formatDescription(courseDetails?.permenent_residency)}} />
                          </a>
                         
                        </div>
                      </li>
                      <li class="c-stepper__item">
                        <div class="c-stepper__content">
                          <a class="stepper-link c-stepper__title">
                            <span
                              style={{
                                color: "black",
                                marginLeft: "-48px",
                                fontWeight: "bold",
                                marginTop: "-7px",
                              }}>
                              Info <br /> 06
                            </span>
                            <span style={{ marginLeft: "10px", marginTop: "5px", fontWeight: "bold", fontSize: "20px", }}>
                             Opportunities this qualification provides
                            </span>
                          </a>
                          <a class="stepper-link travel-advice" style={{ textAlign: "left", marginTop: '-16px', marginLeft: '14px' }}>              
                            <span dangerouslySetInnerHTML={{__html:formatDescription(courseDetails?.opportunities)}} />
                          </a>
                          {/* <li class="stepper-item">
                         <span class="stepper-counter"></span>
                         <a class="stepper-link">
                           <span
                             style={{
                               color: "#000000",
                               fontWeight: "bold",
                               color: "black",
                               fontSize: "19px",
                               marginLeft: "-50px",
                               marginTop: "5px",
                             }}
                           >
                             {countryDetails?.no_of_dates} Days
                           </span>
                           <span
                             style={{
                               marginLeft: "10px",
                               paddingTop: 7,
                               background: "#000000",
                             }}
                             className="bagde-flag1"
                           >
                             Guaranteed
                           </span>
                         </a>
                         <span class="stepper-line"></span>
                       </li> */}
                        </div>
                      </li>
                    </ol>
                    
                  </div>
                </div>
              </div>
                <div className="container anothermt">
              {!empty(docReqData)?(
                <div className="md:flex">
                  <div className="lg:w-2/5 md:w-full md:pe-4 md:order-1 md:mt-0 mt-10 md:mt-0 lg:mt-0">
                    <div className="sticky top-20">
                      <li className="block items-center OnlyDesktopSpan1">
                        <span
                          style={{
                            color: "#000000",
                            fontWeight: "bold",
                            fontSize: "22px",
                            cursor: "pointer",
                          }}
                        >
                          Documents Required
                        </span>
                      </li>
  
                      <ol className="c-stepper mt-8 OnlyDesktop">
                        {Array.isArray(docRequirement) && (
  
                          docRequirement.map((step, index) => (
                            <li className="c-stepper__item" key={index} style={{ marginBottom: '-15px' }}>
                              <div className="c-stepper__content">
                                <a className="c-stepper__title">
                                  <span
                                    style={{
                                      color: "black",
                                      marginLeft: "-53px",
                                      fontWeight: "bold",
                                      marginTop: "7px",
                                    }}>
                                    Doc
                                  </span>
                                  <br />
                                  <span
                                    style={{
                                      color: "black",
                                      marginLeft: "-44px",
                                      fontWeight: "bold",
                                      marginTop: "7px",
                                    }}>
                                    0{index + 1}
                                  </span>
                                  <span style={{ marginLeft: "10px", marginTop: "5px" }}>
                                    <div
                                      className="color-option"
                                      style={{ marginTop: "-38px", marginLeft: "15px" }}
                                    >
                                  
                                      <div className="popover-container1">
                                        <span
                                          className="color"
                                          style={{
                                            fontSize: "20px",
                                            color: "#000",
                                            fontWeight: "bold",
                                            cursor: "pointer",
                                          }}
                                          onClick={() => handleClick(step)}
                                        >
                                          <span
                                          className='underlineeee'
                                          >
                                         
                                            {step?.document_type}
                                        </span>
                                        </span>
                                      </div>
                                    </div>
                                  </span>
                                </a>
                              </div>
                            </li>
                          ))
                        )}
                      </ol>
                      {/* <li className="block items-center" style={{marginTop:'-30px',marginBottom:'30px'}}>
                                <span style={{color: '#000000', fontWeight: 'bold', fontSize: '18px', cursor: 'pointer',fontStyle:'italic'}}>...All documents should be verified by authorized country staff's</span>
                              </li> */}
                      {/* <li
                     className="block items-center"
                     style={{ marginTop: "-10px", marginBottom: "30px" }}
                   >
                     <span
                       style={{
                         color: "#000000",
                         fontWeight: "bold",
                         fontSize: "18px",
                         cursor: "pointer",
                         fontStyle: "italic",
                       }}
                     >
                       ...All documents should be verified by authorized country
                       staff's
                     </span>
                   </li> */}
                      <div
                        className="color-price"
                        style={{
                          whiteSpace: "nowrap",
                          marginTop: "5px",
                          //  marginBottom: "26px",
                        }}
                      >
                        {/* <div className="flex items-center">
                       <Link
                         className="px-6 py-2 font-semibold rounded-md w-full transition-all duration-500 ease-in-out shadow1"
                         type="button"
                         onClick={()=>handleDownload(currentFormData?.checkList)}
                         style={{
                           cursor: "pointer",
                           color: "black",
                           display: "flex",
                           alignItems: "center",
                         }}
                       >
                         <span
                           className="material-symbols--download"
                           
                           style={{
                             cursor: "pointer",
                             whiteSpace: "nowrap",
                            //  marginRight: "5px",
                             fontSize: "23px",
                           }}
                         ></span>
                         Download Checklist
                       </Link>
                     </div> */}
                      </div>
                    </div>
                  </div>
  
                  <div className="lg:w-3/5 md:w-full md:mb-8 md:order-2 OnlyDesktop"
                    style={{ padding: "2rem", backgroundColor: 'black', borderRadius: '20px',height:'fit-content' }}
                  >
                    <p
                      style={{
                        color: "white",
                        fontWeight: "bold",
                        textAlign: 'center',
                        marginTop: "7px",
                      }}>
                      {docName}  Documents
                    </p>
                    <div id="accordion-collapseone" data-accordion="collapse" className="mt-12" >
  
                      {docReqData?.map((item, index) => (
                        <div key={index} className="relative shadow1 dark:shadow1 rounded-md overflow-hidden mt-4" style={{ backgroundColor: 'white' }}>
                          <h2 className="text-base font-semibold" id="accordion-collapse-heading-11" >
                            <button onClick={() => toggleAccordions(index)} type="button" style={{ color: 'black' }}
                              className={`flex justify-between items-center p-5 w-full font-semibold text-start ${activeIndexx === index ? 'bg-gray-50 dark:bg-slate-8002 text-violet-6001' : ''}`}
                              data-accordion-target="#accordion-collapse-body-11" aria-expanded="true" aria-controls="accordion-collapse-body-11">
                              <span>{item.title}</span>
                              <svg data-accordion-icon className={`w-4 h-4  shrink-0 ${activeIndexx === index ? "rotate-180" : "rotate-360"}`} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path>
                              </svg>
                            </button>
                          </h2>
                          {activeIndexx === index && (
                            <div id="accordion-collapse-body-11" aria-labelledby="accordion-collapse-heading-11">
                              <div className="p-5" style={{ backgroundColor: '#6e6e6e' }}>
                                <p className='travel-advice' style={{ color: 'white' }}
                                  dangerouslySetInnerHTML={{ __html: formatDescription(item?.description) }}></p>
                              </div>
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
  
                  </div>
                  <div className="lg:w-3/5 md:w-full md:mb-8 md:order-2 OnlyPhone"
                    style={{ padding: "2rem", backgroundColor: 'black', borderRadius: '20px',height:'fit-content' }}
                  >
                    {Array.isArray(docRequirement) && (
    docRequirement.map((step, stepIndex) => (
      <div key={stepIndex}>
        <p
          style={{
            color: "white",
            fontWeight: "bold",
            textAlign: 'center',
            marginTop: "7px",
            textDecoration: activeDocument === step.document_type ? 'underline' : 'none',
          }}
          onClick={() => handleClick(step)} // Trigger the click handler to load relevant documents
        >
          {step?.document_type} Documents
        </p>
  
        {/* Conditional rendering: Show only the relevant documents for the selected document type */}
        {docName === step.document_type && (
          <div id={`accordion-collapse-${stepIndex}`} data-accordion="collapse" className="mt-4">
            {docReqData?.map((item, index) => (
              <div
                key={index}
                className="relative shadow1 dark:shadow1 rounded-md overflow-hidden mt-4"
                style={{ backgroundColor: 'white' }}
              >
                <h2 className="text-base font-semibold" id={`accordion-heading-${stepIndex}-${index}`}>
                  <button
                    onClick={() => toggleAccordions(index)} // Toggle the accordion state
                    type="button"
                    style={{ color: 'black' }}
                    className={`flex justify-between items-center p-5 w-full font-semibold text-start ${
                      activeIndexx === index ? 'bg-gray-50 dark:bg-slate-8002 text-violet-6001' : ''
                    }`}
                    data-accordion-target={`#accordion-body-${stepIndex}-${index}`}
                    aria-expanded={activeIndexx === index}
                    aria-controls={`accordion-body-${stepIndex}-${index}`}
                  >
                    <span>{item.title}</span>
                    <svg
                      data-accordion-icon
                      className={`w-4 h-4 shrink-0 ${activeIndexx === index ? 'rotate-180' : 'rotate-360'}`}
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </button>
                </h2>
  
                {activeIndexx === index && (
                  <div
                    id={`accordion-body-${stepIndex}-${index}`}
                    aria-labelledby={`accordion-heading-${stepIndex}-${index}`}
                  >
                    <div className="p-5" style={{ backgroundColor: '#6e6e6e' }}>
                      <p
                        className="travel-advice"
                        style={{ color: 'white' }}
                        dangerouslySetInnerHTML={{ __html: formatDescription(item?.description) }}
                      ></p>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    ))
  )}
                  </div>
                </div>          
              ):("")}
            
                    {!empty(initialFAQ)?(  <div className="md:flex" style={{ marginTop: '60px' }}>
                          <div className="lg:w-5/5 md:w-full md:mb-8 md:order-2" >
                              <li className="block">
                                  <span style={{ color: '#000000', fontWeight: 'bold', fontSize: '30px', cursor: 'pointer', textAlign: 'left' }}>FAQs</span>
                              </li>
  
                              <div id="accordion-collapseone" data-accordion="collapse" className="mt-12">
                                  {initialFAQ.map((item, index) => (
                                    
                                      <div key={index} className="relative shadow1 dark:shadow1 rounded-md overflow-hidden mt-4">
                                          <h2 className="text-base font-semibold" id="accordion-collapse-heading-1">
                                              <button onClick={() => toggleAccordion(index)} type="button" style={{ color: 'black' }}
                                                  className={`flex justify-between items-center p-5 w-full font-semibold text-start ${activeIndex === index ? 'bg-gray-50 dark:bg-slate-8002 text-violet-6001' : ''}`}
                                                  data-accordion-target="#accordion-collapse-body-1" aria-expanded="true" aria-controls="accordion-collapse-body-1">
                                                  <span>{item.title}</span>
                                                  <svg data-accordion-icon className={`w-4 h-4  shrink-0 ${activeIndex === index ? "rotate-180" : "rotate-360"}`} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                      <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path>
                                                  </svg>
                                              </button>
                                          </h2>
                                          {activeIndex === index && (
                                              <div id="accordion-collapse-body-1" aria-labelledby="accordion-collapse-heading-1">
                                                  <div className="p-5" style={{ backgroundColor: 'rgb(110 110 110 / 15%)' }}>
                                                      <p className='travel-advice' style={{ color: 'black' }}
                                                          dangerouslySetInnerHTML={{ __html: formatDescription(item?.description) }}></p>
                                                  </div>
                                              </div>
                                          )}
                                      </div>
                                  ))}
                              </div>
  
                          </div>
                      </div> ):("")}
          </div>
         
                                    
                  <div className="container" style={{marginLeft:'0px',marginTop:'40px',marginBottom:'-60px'}}>
                    <div className="flex justify-between">
                    <div style={{display: 'flex', justifyContent: 'center',}}>
                   
                    </div>                           
                    </div>
                  </div>               
                  {/* <div className="lg:flex scrollbutton" style={{ marginLeft: '0px',marginTop:'200px',backgroundColor: '#000'}}>
                  <div className="p-6 w-full" style={{ display: 'flex', flexDirection: 'column' ,marginTop:'-25px',}}>
                    <div className="flex flex-wrap justify-between">
                    
                      <div className="color-price" style={{marginTop: '30px',}}>
                        <div className="flex items-center">
                          <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <Link className="color" style={{ fontSize: '19px', color: '#fff', fontWeight: 'bold',marginRight:'70px' }}>
                              ${universityDetail?.fee} {universityDetail?.fee_symbol}</Link>
                          </div>
                        </div>
                      </div>
                               
                      <div className="group rounded-xl bg-white dark:bg-slate-900 w-full mx-auto lg:max-w-2xl " style={{marginTop: '30px',display:'flex',justifyContent:'center',alignContent:'center',alignItems:'center'}}>
  
                          <Link
                            className='font-semibold transition-all duration-500 ease-in-out text-black'
                            type="button"
                            to='#'
                            // onClick={() => setIndex(0)}
                            style={{
                              color: 'black',
                              width: '450px', // default width
                              height: '70px',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              textAlign: 'center',
                              padding: '22px',
                              borderRadius: '14px',
                              backgroundColor: '#fff',                     
                            }}
                          >
                            Get Started Now
                          </Link>
                        
                      </div>
                          {console.log(universityDetail,'-----------------@@@@')}                    
                   
                      <div className="color-price" style={{marginTop: '30px',}}>
                        <div className="flex items-center">
                          <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <Link className="color" style={{ fontSize: '16px',textAlign:'center', color: '#fff', marginBottom: '4px', fontWeight: 'bold' }}>
                              Your offer letter arrives on
                              </Link>
                            <Link className="color" 
                            style={{ fontSize: '18px', color: '#fff', fontWeight: 'bold' }}>
                              {universityDetail?.offer_letter} {universityDetail?.getOfferLetterOn_unit}
                              </Link>
                          </div>
                        </div>
                      </div>                   
                    </div>
                    </div>
                  </div>               */}
                  <div style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, zIndex: 50, overflow: 'hidden', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(0, 0, 0, 0.5)' }} className={`m-auto justify-center items-center flex bg-gray-900 bg-opacity-50 dark:bg-opacity-80 ${placeBid ? "" : "hidden" }`}>
                    <div style={{ position: 'relative', width: '100%', height: 'auto', maxWidth: '600px', padding: '4px' }} className="relative w-full h-auto max-w-2xl p-4">
                        <div style={{ position: 'relative', backgroundColor: 'white', borderRadius: '8px', boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)' }} className="relative bg-white dark:bg-slate-900 rounded-lg shadow dark:shadow-gray-800">
                            {/* Header Section */}
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '6px 12px', borderBottom: '1px solid #e6e6e6' }} className="flex justify-between items-center px-6 py-4 border-b border-gray-100 dark:border-t-gray-800">
                                <h5 style={{ fontSize: '1.5rem', fontWeight: '800', color: 'black' }} className="text-xl font-bold">❤️ Hear from {title} Students</h5>
                                <button style={{ color: '#718096', backgroundColor: 'transparent', ':hover': { backgroundColor: '#edf2f7', color: '#2d3748' }, borderRadius: '8px', fontSize: '1rem', padding: '0.375rem 0.75rem', marginLeft: 'auto' }} type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-lg p-1.5 ms-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white">
                                    <IoMdClose style={{fontSize:'1.8rem',color:'black'}} onClick={() => setPlaceBid(!placeBid)} />
                                </button>
                            </div>
  
                            {/* Scrollable Content Section */}
                            <div id='center' style={{ padding: '1.5rem', overflowY: 'auto', maxHeight: '400px', color: 'black' }} className="p-6 overflow-y-auto max-h-96">
                                {dataArray.map((item, index) => (
                                  <>
                                    <div key={index} className='product-card1 mb-4'> {/* Added margin-bottom here */}
                                        <h5 style={{ fontSize: '1rem', fontWeight: '600', marginBottom: '20px' }} className="text-xl font-semibold">{item.heading}</h5>
                                        <p style={{ textAlign: 'start', color: 'black',fontWeight:'500' }}>
                                            {item.content}
                                        </p>
                                        <img src={item.image} style={{ borderRadius: '0.75rem', marginTop: '20px', width: '10rem', height: '10rem' ,objectFit:'cover'}} alt="Your Image" />
  
                                        <div style={{ paddingTop: '1rem', borderTop: '1px solid #e6e6e6',marginTop:'25px' }} className="pt-4 border-t dark:border-gray-700">
                                            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '1rem' }}>
                                            <p style={{ textAlign: 'start', color: 'black', fontWeight: '500', display: 'flex', alignItems: 'center' }}>
                                                {item.name} 
                                                <span style={{ display: 'flex', alignItems: 'center', marginLeft: '10px',color:'rgb(111 118 133/var(--tw-text-opacity))' }}> — <span class="mingcute--location-fill" style={{ fontSize: '19px', marginRight: '5px',marginLeft:'7px' }}></span>{item.from}</span>
                                            </p>
  
                                            </div>
                                            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '1rem' }}>
                                                <span style={{ background: '#000000', borderRadius: '15px', display: 'flex', alignItems: 'center' }} className="bagde-flag1">
                                                    <span class="mingcute--airplane-line" style={{ fontSize: '20px', marginRight: '5px',}}></span>
                                                   {item.location}
                                                </span>
                                            </div>
                                        </div>          
                                    </div>
                                    </>
                                ))}
                            </div>                          
                        </div>
                      </div>
                    </div>
              </section>
              <Dialog open={open} onClose={handleClose} style={{zIndex:'999999'}} scroll={scroll} >
          <DialogTitle style={{ marginBottom:'2px' }}>Want to chat ?</DialogTitle>
          <DialogContent className="Modal-Width" dividers={scroll === 'paper'}>
            <DialogContentText id="scroll-dialog-description" ref={descriptionElementRef} tabIndex={-1}>
            <FormControl fullWidth error={!!error}>
              <TextField
                variant="outlined"
                multiline
                required
                minRows={3}
                value={inputValue}
                onChange={handleInputChange}
                label="Type your enquiry"
              />
              <FormHelperText>{error}</FormHelperText> {/* Display error message */}
            </FormControl>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} variant="outlined" color="inherit">
              Cancel
            </Button>
            <Button onClick={handleSubmit} style={{background:'black',color:'white'}} variant="contained">
              Submit
            </Button>
          </DialogActions>
        </Dialog>
              <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          rtl={false}
           draggable
          theme="light"
           />
              <Footer />
             
          </>)}
   </>
    )
}
