import React, { useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Modal from "react-modal";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { REST_API_END_POINT } from "../../constants/DefaultValue";
import ContactImgs from "../../assets/images/partners-banner.jpg"
import { Icon } from '@iconify/react';
import chevronDown from '@iconify/icons-ph/caret-down-bold';
import NavbarNew from "../../components/navbar-new";
import Footer from "../../components/footer";

const Choices=[
  {id:4,name:"Insurance"},
  {id:5,name:"University"},
  {id:6,name:"Consultant"},
  {id:7,name:"Languages"},
  {id:8,name:"Credit/Loans"},
]
const ChoicesB=[
  {id:1,name:"For Signup as Partner"},
  {id:2,name:"For Enquiry"},
]

export default function PartnerForm() {
    const httpsRegex = /^(https?:\/\/)/;
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Function to toggle the modal visibility
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };
  const handleGoBack = () => {
    window.history.back();
  };

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    phone_number: "",
    address: "",
    email: "",
    choose_yours: Choices[0],
    comments: '',
    enq_type: ChoicesB[0],
    
  });

  const handleChange = (e) => {
    const { name, value, type, checked,id } = e.target;
 
    if (type === "select-one") {
      const selectedOption = Choices.find((choice) => choice.name === value);
      setFormData((prevState) => {
        if (name === "choose_yours") {
          const selectedOption = Choices.find((choice) => choice.name === value);
          return { ...prevState, choose_yours: selectedOption };
        } else if (name === "enq_type") {
          const selectedComment = ChoicesB.find((comment) => comment.name === value);
          return { ...prevState, enq_type: selectedComment };
        } else {
          return {
            ...prevState,
            [name]: type === "checkbox" ? e.target.checked : value,
          };
        }
      });
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: type === "checkbox" ? checked : value,
      }));
    }
  };

  const handleCheckboxChange = (e) => {
    const { value } = e.target;
    setFormData((prevState) => {
      const interestedAreas = [...prevState.interestedAreas];
      if (interestedAreas.includes(value)) {
        const index = interestedAreas.indexOf(value);
        interestedAreas.splice(index, 1);
      } else {
        interestedAreas.push(value);
      }
      return {
        ...prevState,
        interestedAreas,
      };
    });
  };
 
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const { first_name, email, phone_number, address,comments ,enq_type} = formData;

    if (!first_name || !email || !phone_number || !address) {
      toast.error('Please fill out all required fields');
      return;
    }
    const submissionData = {
        ...formData,
        enq_type: enq_type.name, 
      };
    axios.post(`${REST_API_END_POINT}enquires/add-contact-info`,{values:submissionData,contact_us:0})
    .then((res)=>{
      if(res.data.status==1){
        toast.success(res.data.message);
         handleGoBack()
        setFormData({
          first_name: "",
          last_name: "",
          phone_number: "",
          address: "",
          email: "",
          choose_yours: Choices[0],
          comments:'',
          enq_type:ChoicesB[0],
        })
      }else{
        toast.error(res.data.message);
      }
    })
    .catch((err)=>{
      console.log(err)
    })
  };

  const spanStyle = {
    // background: `linear-gradient(90deg, #f8a8c6, #f8a8c6, #fb53a4)`,
    WebkitBackgroundClip: "text",
    color: "#fff",
    display: "inline-block",
  };


  return (
    <>
      {/* <Navbar /> */}
      {/* backgroundImage:`url(${img1})`, */}
      {/* <section
        className="relative table w-full py-36 bg-no-repeat"
        style={{ backgroundSize: "cover", backgroundPosition: "center",backgroundImage:`url(${ContactImgs})` }}
      >
        <div className="absolute inset-0 bg-gradient-to-b from-transparent to-slate-900"></div>
        <div className="container">
          <div style={{backdropFilter:"blur(20px)",WebkitBackdropFilter:"blur(20px)",borderRadius:'5px'}} className="grid grid-cols-1 pb-8 text-center mt-10">
            <h4 className="font-bold lg:leading-snug leading-snug text-4xl lg:text-6xl mb-4">
              <span style={spanStyle}>Partner </span>
            </h4>
            <p className=" max-w-xl mx-auto text-3xl lg:text-4xl" style={{color:'#e6e6e6'}}>
            Want to collaborate with us? Contact us!{" "}
            </p>
           
          </div>
        </div>
      </section> */}
      <NavbarNew tab={"about"} aboutus={true} />
      <section className="relative lg:pt-24 pt-[74px] overflow-hidden" style={{ backgroundColor: '#000000' }}>
        <div className="container text-center text-white py-100">
          <p className="text-lg font-bold mb-5">Visa Search Partner</p>
          <h1 className="font-bold text-4xl lg:text-6xl">Want to collaborate with us? 
            {/* Contact us!{" "} */}
            </h1>
        </div>
      </section>

      <section className="relative md:py-24 py-16">
        <div
          className="container"
          style={{ marginBottom: "-10px", marginTop: "-180px",}}
        >
          <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
            <div className="lg:col-span-12 md:col-span-12 mt-8 md:mt-0 order-2 md:order-1">
              <div className="lg:me-5">
                <div
                  className="bg-white dark:bg-slate-900 rounded-md shadow dark:shadow-gray-800"
                >
                  <form
                    method="post"
                    name="myForm"
                    id="myForm"
                    onSubmit={handleFormSubmit}
                    style={{
                      paddingLeft: "40px",
                      paddingRight: "40px",
                      paddingTop: "15px",
                      paddingBottom: "15px",
                      width: "100%",
                      backdropFilter: "blur(1000px)",
                      WebkitBackdropFilter: "blur(1000px)",
                      backgroundColor: "#ffffff1a",
                      boxShadow: "0 0 80px #0000004d",
                      borderRadius: "10px",
                    }}
                  >
                    <p className="mb-0" id="error-msg"></p>
                    <div id="simple-msg"></div>
                    <div className="color-price">
                      <div className="color-option">
                        <h3 className="color mb-6 text-3xl leading-snug font-medium">
                         {/* Contact Us */}
                        </h3>
                      </div>
                    </div>
                    <div className="grid lg:grid-cols-12 lg:gap-6">
                      <div className="lg:col-span-4 mb-5">
                        <div className="text-start " style={{color:'black'}}>
                          <label htmlFor="first_name" className="font-semibold">
                          First Name:{" "}
                          <span className="text-red-600">*</span>
                          </label>
                          <input
                            id="first_name"
                            name="first_name"
                            type="text"
                            required
                            className="form-input w-full text-[15px] 
                                                    py-2 px-3 h-10 bg-transparent dark:text-slate-600 
                                                    rounded-full outline-none border border-gray-200 
                                                    focus:border-gray-200 dark:border-gray-200 
                                                    dark:focus:border-gray-200 focus:ring-0 mt-2"
                            placeholder="Enter your first name :"
                            value={formData.first_name}
                            onChange={handleChange}
                          />
                        </div>
                      </div>

                      <div className="lg:col-span-4 mb-5">
                        <div className="text-start" style={{color:'black'}}>
                          <label htmlFor="last_name" className="font-semibold">
                          Last Name:{" "}
                          </label>
                          <input
                            id="last_name"
                            name="last_name"
                            type="text"               
                            className="form-input w-full text-[15px] py-2 px-3
                             h-10 bg-transparent dark:text-slate-600 
                             rounded-full outline-none border border-gray-200 
                             focus:border-gray-200 dark:border-gray-200 
                             dark:focus:border-gray-200 focus:ring-0 mt-2"
                            placeholder="Enter your last name :"
                            value={formData.last_name}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="lg:col-span-4 mb-5">
                        <div className="text-start" style={{color:'black'}}>
                          <label htmlFor="phone_number" className="font-semibold">
                           Phone Number:{" "}
                           <span className="text-red-600">*</span>
                          </label>
                          <input
                            id="phone_number"
                            name="phone_number"
                            type="number"
                              pattern="\d{12}"
                               maxLength="12"
                            required
                            onInput={(e) => {
                              e.target.value = e.target.value.slice(0, 12);
                            }}
                            className="form-input w-full text-[15px] py-2 px-3
                             h-10 bg-transparent dark:text-slate-600 
                             rounded-full outline-none border
                              border-gray-200 focus:border-gray-200 
                              dark:border-gray-200 
                              dark:focus:border-gray-200
                               focus:ring-0 mt-2"
                            placeholder="Enter your Phone Number :"
                            value={formData.phone_number}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="lg:col-span-4 mb-5">
                        <div style={{color:'black'}} className="text-start">
                          <label
                            htmlFor="address"
                            className="font-semibold"
                          >
                             Address:{" "}
                            <span className="text-red-600">*</span>
                          </label>
                          <textarea
                            id="address"
                            name="address"
                            required
                            className="form-input w-full text-[15px] 
                            py-2 px-3 h-28 bg-transparent dark:text-slate-600 
                            rounded-2xl outline-none border border-gray-200 
                            focus:border-gray-200 dark:border-gray-200 
                            dark:focus:border-gray-200 focus:ring-0 mt-2"
                            placeholder="Enter  Address :"
                            value={formData.address}
                            onChange={handleChange}
                          >

                          </textarea>
                        </div>
                      </div>
                      <div className="lg:col-span-4 mb-5">
                        <div className="text-start" style={{color:'black'}}>
                          <label htmlFor="email" className="font-semibold">
                           Email Address:{" "}
                           <span className="text-red-600">*</span>
                          </label>
                          <input
                            id="email"
                            name="email"
                            type="email"
                            required
                            className="form-input w-full text-[15px] 
                            py-2 px-3 h-10 bg-transparent dark:text-slate-600 
                            rounded-full outline-none border border-gray-200 
                            focus:border-gray-200 dark:border-gray-200 
                            dark:focus:border-gray-200 focus:ring-0 mt-2"
                            placeholder="Enter your email address :"
                            value={formData.email}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      {/* <div className="lg:col-span-4 mb-5">
                        <div className="text-start" style={{color:'black'}}>
                          <label htmlFor="company_number" className="font-semibold">
                          Company Phone Number:{" "}
                          </label>
                          <input
                            id="company_number"
                            name="company_number"
                            type="number"
                            required
                            className="form-input w-full text-[15px] py-2 px-3
                             h-10 bg-transparent dark:text-slate-600 
                             rounded-full outline-none border
                              border-gray-200 focus:border-gray-200 
                              dark:border-gray-200 
                              dark:focus:border-gray-200
                               focus:ring-0 mt-2"
                            placeholder="Enter your Company Phone Number :"
                            value={formData.company_number}
                            onChange={handleChange}
                          />
                        </div>
                      </div> */}
                      {/* <div className="lg:col-span-4 mb-5">
                        <div className="text-start" style={{color:'black'}}>
                          <label htmlFor="company_website" className="font-semibold">
                          Company Website (if applicable):
                          </label>
                          <input
                            id="company_website"
                            name="company_website"
                            type="text"                        
                            className="form-input w-full text-[15px] py-2 px-3 h-10
                             bg-transparent dark:text-slate-600 
                             rounded-full outline-none border
                              border-gray-200 focus:border-gray-200 
                              dark:border-gray-200 dark:focus:border-gray-200 
                              focus:ring-0 mt-2"
                            placeholder="Enter your Company Website :"
                            value={formData.company_website}
                            onChange={handleChange}
                          />
                        </div>
                      </div> */}
                      <div className="lg:col-span-4 mb-5">
                        <div className="text-start" style={{color:'black'}}>
                          <label htmlFor="choose_yours" className="font-semibold">
                          Choose Your Business: {" "}
                          <span className="text-red-600">*</span>
                          </label>
                         <div className="dropdown">
                            <select
                            name="choose_yours"
                            className="form-input w-full text-[15px] py-2 px-3
                             h-10 bg-transparent dark:text-slate-600 rounded-full 
                             outline-none border border-gray-200 
                             focus:border-gray-200 dark:border-gray-200
                              dark:focus:border-gray-200 
                              focus:ring-0 mt-2 dropdown-select"
                            value={formData.choose_yours?.name}
                            onChange={handleChange}
                          >
                            {Choices?.map((d,index)=>(
                               <option key={index} className="form-input w-full text-[15px] 
                               py-2 px-3 h-10 bg-transparent dark:bg-slate-900 
                               dark:text-slate-600 rounded-full outline-none 
                               border border-gray-200 focus:border-gray-200 
                               dark:border-gray-800 
                               dark:focus:border-gray-200 
                               focus:ring-0 mt-2">
                                 {d.name}
                               </option>
                            ))}
                          </select>
                          <Icon icon={chevronDown} className="dropdown-icon" />
                         </div>
                        </div>
                      </div>
                      <div className="lg:col-span-4 mb-12">               
                        <div className="text-start">
                          <label
                            htmlFor="comments"
                            className="font-semibold" style={{color:'black'}}
                          >
                            Additional Notes/Comments :
                          </label>
                          <textarea
                            id="comments"
                            name="comments"
                            className="form-input w-full text-[15px] py-2 px-3 h-28 bg-transparent
                             dark:text-slate-600 rounded-2xl outline-none border
                              border-gray-200 focus:border-gray-200 
                              dark:border-gray-200 dark:focus:border-gray-200 
                              focus:ring-0 mt-2"
                            placeholder="Enter any Notes/Comments you have :"                     
                            value={formData.comments}
                            onChange={handleChange}
                          ></textarea>
                        </div>                 
                    </div>
                      <div className="lg:col-span-4 mb-12">               
                        <div className="text-start">
                          <label
                            htmlFor="enq_type"
                            className="font-semibold" style={{color:'black'}}
                          >
                            Select Purpose :
                          </label>
                          <div className="dropdown">
                          <select
  name="enq_type"  // Correct name attribute for comments
  className="form-input w-full text-[15px] py-2 px-3
    h-10 bg-transparent dark:text-slate-600 rounded-full 
    outline-none border border-gray-200 
    focus:border-gray-200 dark:border-gray-200
    dark:focus:border-gray-200 
    focus:ring-0 mt-2 dropdown-select"
  value={formData.enq_type?.name}
  onChange={handleChange}
>
  {ChoicesB.map((d, index) => (
    <option
      key={index}
      value={d?.name}
      className="form-input w-full text-[15px] 
        py-2 px-3 h-10 bg-transparent dark:bg-slate-900 
        dark:text-slate-600 rounded-full outline-none 
        border border-gray-200 focus:border-gray-200 
        dark:border-gray-800 
        dark:focus:border-gray-200 
        focus:ring-0 mt-2"
    >
      {d.name}
    </option>
  ))}
</select>

                          <Icon icon={chevronDown} className="dropdown-icon" />
                         </div>
                        </div>                 
                    </div>
                    </div> 
                                  
                     <button
                        className='font-semibold transition-all duration-500 ease-in-out citizenship-button'
                        type="submit"
                        id="submit"
                        name="send"
                        //  onClick={handleContinueClick}
                         >
                        Submit
                    </button>

                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
        <Modal
          isOpen={isModalOpen}
          onRequestClose={toggleModal}
          contentLabel="Popup"
          className="modal"
          overlayClassName="overlay"
        >
          {/* Content of the modal */}
          <div
            className="popup-content"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              minHeight: "120px",
            }}
          >
            <p>Your Form Has Been Registered Succesfully</p>
            <br />
            <button
              className="btn bg-violet-600 hover:bg-violet-700 border-violet-600 hover:border-violet-700 text-white rounded-full"
              onClick={toggleModal}
            >
              OK
            </button>
          </div>
        </Modal>

        {/* <div className="container mt-24">
          <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-[30px]">
            <div className="text-center px-6 mt-6">
              <div className="w-20 h-20 bg-violet-600/5 text-white rounded-full text-3xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
              
              </div>

              <div className="content mt-7">
                <h5 className="title text-xl font-semibold">Phone</h5>
                <p className="text-slate-400 mt-3">
                  Use the below number to call us
                </p>

                <div className="mt-5">
                  <Link
                    to="tel:4192710396"
                    className="btn btn-link text-violet-600 hover:text-violet-600 after:bg-violet-600 duration-500 ease-in-out"
                  >
                    4192710396
                  </Link>
                </div>
              </div>
            </div>

            <div className="text-center px-6 mt-6">
              <div className="w-20 h-20 bg-violet-600/5 text-white rounded-full text-3xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
               
              </div>

              <div className="content mt-7">
                <h5 className="title text-xl font-semibold">Email</h5>
                <p className="text-slate-400 mt-3">Contact us through Email</p>

                <div className="mt-5">
                  <Link
                    to="mailto:troston@loldao.ai"
                    className="btn btn-link text-violet-600 hover:text-violet-600 after:bg-violet-600 duration-500 ease-in-out"
                  >
                    troston@loldao.ai
                  </Link>
                </div>
              </div>
            </div>

            <div className="text-center px-6 mt-6">
              <div className="w-20 h-20 bg-violet-600/5 text-white rounded-full text-3xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
             
              </div>

              <div className="content mt-7">
                <h5 className="title text-xl font-semibold">Location</h5>
                <p className="text-slate-400 mt-3">
                  3073 Morin PT St Erie
                  <br /> MI 48133
                </p>
              </div>
            </div>
          </div>
        </div> */}
      </section>
      <Footer />
 
    </>
  );
}
