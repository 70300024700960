import React, { useEffect, useState, useRef, useCallback } from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import NavbarNew from "../../components/navbar-new";
import Footer from "../../components/footer";
// import ReactMapGL, { Marker, NavigationControl } from "react-map-gl";
// import mapboxgl from "mapbox-gl";
// import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import { MAP_API_KEY, REST_API_END_POINT } from "../../constants/DefaultValue";
import { empty, formatDescription } from "../../common/Helper";
// import "mapbox-gl/dist/mapbox-gl.css";
// import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import "leaflet/dist/leaflet.css";
import { usePlacesWidget } from "react-google-autocomplete";
import { compose, withProps } from "recompose";
import {  Typography, } from '@material-ui/core';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";


import check from "../../assets/images/tick1.png";
import { MdRoom } from "react-icons/md";

// mapboxgl.accessToken = MAP_API_KEY;

const customStyles = {
  control: (styles, { isFocused, isHovered }) => ({
    ...styles,
    backgroundColor: "transparent",
    marginTop: "-7px",
    color: "white !important",
    borderColor: isHovered ? "white" : "transparent",
    boxShadow: isHovered ? "0 0 0 2px white" : "none",
    borderWidth: 0,
    outline: "none",
    height: "30px",
  }),
  menu: (provided) => ({
    ...provided,
    marginLeft: "-35px",
    zIndex: 999,
  }),
  option: (styles, { isFocused }) => ({
    ...styles,
    backgroundColor: isFocused ? "rgba(255, 255, 255, 0.2)" : "transparent",
    color: isFocused ? "black" : "white",
    zIndex: isFocused ? 999 : 1,
  }),
  singleValue: (styles) => ({
    ...styles,
    color: "white",
  }),
  menuPortal: (base) => ({
    ...base,
    zIndex: 9999,
  }),
  dropdownIndicator: (styles) => ({
    ...styles,
    color: "white",
  }),
  input: (styles) => ({
    ...styles,
    color: "white",
  }),
};



export default function TravelAdvice() {
  const { visaId } = useParams();
  const visaTypeId = visaId;
  const [travelAdvice, setTravelAdvice] = useState([]);
  const [countryDetails, setCountryDetails] = useState({});
  const [showLocalContact, setShowLocalContact] = useState(false); 
  const [emergencyNumber, setEmergencyNumber] = useState([]);
  // const [coordinates, setCoordinates] = useState({
  //   latitude: "28.6448",
  //   longitude: "77.216",
  // });
  const [coordinates,setCoordinates] = useState({latitude:'25.0000',longitude:'135.0000'})
  const [visaDetail, setVisaDetails] = useState({});
  // const [viewport, setViewport] = useState({
  //   width: "100%",
  //   height: "455px",
  //   latitude: parseFloat(coordinates.latitude),
  //   longitude: parseFloat(coordinates.longitude),
  //   zoom: 8,
  // });
  const [address, setAddress] = useState({
    address: "",
    city: "",
    state: "",
    zip_code: "",
    search_google_enabled: false,
  });
  // const geocoderContainerRef = useRef(null);
  // const mapRef = useRef(null); // Reference to the map instance
  const [propertyData, setPropertyData] = useState({
    lot_size: "",
    square_feet: "",
    year_built: "",
    bedrooms: "",
    bathrooms: "",
    heating: "",
    cooling: "",
    parking: "",
  });

  // const handleFetch = async (address) => {
  //   try {
  //     const response = await axios.get(
  //       `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
  //         address
  //       )}.json?access_token=${MAP_API_KEY}`
  //     );
  //     if (response.data.features.length > 0) {
  //       const { center } = response.data.features[0];
  //       setCoordinates({ latitude: center[1], longitude: center[0] });
  //       setViewport((prevViewport) => ({
  //         ...prevViewport,
  //         latitude: center[1],
  //         longitude: center[0],
  //       }));
  //     } else {
  //       console.error("Address not found");
  //     }
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // };

  const handleFetch = (address, zip_code) => {
    const api = MAP_API_KEY;
    axios
      .get(api)
      .then((result) => {
        if (
          result?.data?.bundle.length &&
          result.data.bundle[0].address.zip &&
          result.data.bundle[0].address.house
        ) {
          axios.get(api).then((data) => {
            if (
              data &&
              Object.keys(data).length === 0 &&
              Object.getPrototypeOf(data) === Object.prototype
            ) {
            } else if (
              data?.data?.bundle.length ||
              data.data.bundle[0]?.areas.length ||
              data.data.bundle[0]?.building.length ||
              data.data.bundle[0]?.garages.length
            ) {
              setPropertyData({
                ...propertyData,
                lot_size: data.data.bundle[0].lotSizeSquareFeet,
                square_feet: data.data.bundle[0].areas[0]?.areaSquareFeet,
                year_built: data.data.bundle[0].building[0]?.yearBuilt,
                bedrooms: data.data.bundle[0].building[0]?.bedrooms,
                bathrooms: data.data.bundle[0].building[0]?.fullBaths,
                heating: data.data.bundle[0].building[0]?.heating,
                cooling: data.data.bundle[0].building[0]?.airConditioning,
                parking: data.data.bundle[0].garages.length,
              });
            }
          });
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  // const initializeGeocoder = useCallback((map) => {
  //   const geocoder = new MapboxGeocoder({
  //     accessToken: MAP_API_KEY,
  //     types: "country,region,place,postcode,locality,neighborhood",
  //     mapboxgl: mapboxgl,
  //   });

  //   geocoder.on("result", (event) => {
  //     const { center } = event.result.geometry;
  //     setCoordinates({ latitude: center[1], longitude: center[0] });
  //     setViewport({
  //       ...viewport,
  //       latitude: center[1],
  //       longitude: center[0],
  //       zoom: 8,
  //     });
  //   });

  //   if (geocoderContainerRef.current) {
  //     geocoderContainerRef.current.appendChild(geocoder.onAdd(map));
  //   }
  // }, [viewport]);
  const { ref: inputRef, ...rest } = usePlacesWidget({
    onPlaceSelected: (res) => {
      if (typeof res.address_components !== "undefined") {
        const address_components = res.address_components;

        const address_1 = address_components.find(
          (row) => row.types.indexOf("street_number") !== -1
        );
        const address_2 = address_components.find(
          (row) => row.types.indexOf("route") !== -1
        );

        let addressValue = "";
        if (address_1) addressValue = address_1.long_name;
        if (address_2) addressValue += " " + address_2.long_name;

        const city =
          address_components.find((row) => row.types.indexOf("locality") !== -1)
            ?.long_name || "";

        const state =
          address_components.find(
            (row) => row.types.indexOf("administrative_area_level_1") !== -1
          )?.short_name || "";

        const zip_code =
          address_components.find(
            (row) => row.types.indexOf("postal_code") !== -1
          )?.long_name || "";

        setAddress({
          address: addressValue,
          city: city,
          state: state,
          zip_code: zip_code,
          search_google_enabled: false,
        });

        handleFetch(addressValue, zip_code);
      }
    },
    options: {
      types: ["geocode"],
    },
  });

  // useEffect(() => {
  //   if (mapRef.current) {
  //     initializeGeocoder(mapRef.current.getMap());
  //   }
  // }, [initializeGeocoder]);


  const [currentDate, setCurrentDate] = useState("");

  useEffect(() => {
    const getCurrentDate = () => {
      const options = { day: "numeric", month: "long", year: "numeric" };
      const today = new Date();
      return today.toLocaleDateString("en-US", options);
    };

    setCurrentDate(getCurrentDate());
  }, []);


  useEffect(() => {
    axios.get(`${REST_API_END_POINT}travel-details/${visaTypeId}`).then((res) => {
      if (res.data.status === 1) {
        setTravelAdvice(res.data.travelAdvices);
        // setTravelAdvice(res.data.travelAdvice);
        console.log("54455445454",res.data.travelAdvices);
        setEmergencyNumber(res.data.travelAdvices.customerInfoService);
        setCountryDetails(res.data.countryDetails);
        setVisaDetails(res.data.visaDetails);
        handleFetch(res.data.countryDetails.name);
      }
    });
  }, [visaId]);

  const date = new Date(visaDetail?.travelAdviceUpdatedDate * 1000);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  // const currentDate = new Date().toLocaleDateString("en-US", {
  //   day: "numeric",
  //   month: "long",
  //   year: "numeric",
  // });
  useEffect(()=>{
    getCoordinates()
  },[countryDetails?.name])

  const getCoordinates = async () => {
    try {   
      const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${countryDetails?.name}&key=${MAP_API_KEY}`);
      const data = await response.json();
      if (data.results.length > 0) {
        const { lat, lng } = data.results[0].geometry.location;
        setCoordinates({ latitude: lat, longitude: lng });
      } else {
        console.error('Country not found');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  const MapWithMarkers = compose(
    withProps({
      googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${MAP_API_KEY}&v=3.exp&libraries=geometry,drawing,places`,
      loadingElement: <div style={{ height: `100%` }} />,
      containerElement: <div style={{ height: `324px` }} />,
      mapElement: <div style={{ height: `100%` }} />,
    }),
    withScriptjs,
    withGoogleMap
  )((props) =>
   
          <GoogleMap
           defaultZoom={8} 
          defaultCenter={{ lat: parseFloat(coordinates?.latitude), lng: parseFloat(coordinates?.longitude) }}>
            <Marker            
              position={{ lat: parseFloat(coordinates?.latitude), lng: parseFloat(coordinates?.longitude) }}
               title={countryDetails?.name}
            />
          </GoogleMap>
  );

  return (
    <>
      <NavbarNew tab={"visa"} />
      {!empty(countryDetails?.image) && (
        <section
          className="relative md:py-24 py-16"
          style={{ marginTop: "20px" }}
        >
          <div className="container">
            <div className="md:flex">
              <div
                className="lg:w-5/5 md:w-full md:mb-8 md:order-2"
                style={{ paddingInline: "1rem" }}
              >
                <div
                  style={{
                    position: "relative",
                    width: "100%",
                    maxWidth: "100%",
                    overflow: "hidden",
                  }}
                >
                  <img
                    style={{
                      width: "100%",
                      left: "0",
                      minWidth: "300px",
                      height: "335px",
                      borderRadius: "10px",
                      objectFit: "cover",
                    }}
                    src={countryDetails?.image}
                    alt="Travel Advice Image"
                  />
                  <div className="visa-image">
                    {countryDetails?.name} Travel Advice
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div style={{ marginTop: "45px" }}>
            <div
              className="group rounded-xl bg-white dark:bg-slate-900 w-full mx-auto lg:max-w-2xl "
              style={{
                zIndex: 1,
                marginTop: "0px",
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",paddingLeft:'10px',paddingRight:'9px'
              }}
            >
              <Link
                className="font-semibold transition-all duration-500 ease-in-out text-white bg-violet-6001 "
                type="button"
                style={{
                  color: "black",
                  width: "550px",
                  height: "70px",
                  display: "flex",
                  justifyContent: "center",
                  textAlign: "left",
                  padding: "22px",
                  borderRadius: "40px",
                }}
              >
                <div
                  className="container z-1"
                  // style={{ color: "white", marginTop: "-11px" }}
                  style={{ color: "white",marginLeft:'17px'}}
                >
                 
                  <div className="search-container" style={{ color: "white" }}>
                    <div className="wrapperInner">
                      <div
                        className="search_box phone"
                        style={{
                          background: "#000",
                          border: "1px solid #000",
                        }}
                      >
                         Travel Advice Details
                        {/* <div ref={geocoderContainerRef} id="geocoder" /> */}
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>

            <hr
              style={{
                marginTop: "-36px",
                borderColor: "black",
                borderWidth: "1px",
              }}
            />
          </div>

          <div className="container" style={{ marginTop: "100px" }}>
            <div className="md:flex" style={{ position: "relative", zIndex: 0 }}>
              <div
                className="lg:w-3/5 md:w-full md:mb-8 md:order-2"
                style={{ paddingInline: "1rem" }}
              >
                <div
                  style={{
                    position: "relative",
                    width: "100%",
                    maxWidth: "100%",
                    height: "403px",
                    border: "2px solid #dbdada",
                    overflow: "hidden",
                    borderRadius: "20px",
                  }}
                >
                  <div style={{ height: "324px" }}>
                    {/* <ReactMapGL
                      {...viewport}
                      mapboxApiAccessToken={MAP_API_KEY}
                      onViewportChange={(nextViewport) =>
                        setViewport(nextViewport)
                      }
                      mapStyle="mapbox://styles/mapbox/streets-v11"
                      scrollZoom={true}
                      touchZoomRotate={true}
                      dragPan={true}
                      dragRotate={true}
                      ref={mapRef}
                    >
                      <Marker
                        latitude={parseFloat(coordinates.latitude)}
                        longitude={parseFloat(coordinates.longitude)}
                      >
                       
                      </Marker>
                      <div style={{ position: "absolute", right: 10, top: 10 }}>
                      
                      </div>
                    </ReactMapGL> */}
                    <MapWithMarkers />
                  </div>
                  <li className="block items-center">
                    <div
                      className="popover-container1"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <div
                        className="color-price"
                        style={{ marginTop: "8px", marginLeft: "7px" }}
                      >
                        <div className="flex items-center">
                          <button
                            className="px-6 py-2 font-semibold rounded-md w-full transition-all duration-500 ease-in-out text-white bg-violet-6001"
                            type="button"
                            // to={"#"}
                            onClick={() => setShowLocalContact(!showLocalContact)}
                            style={{ color: "black" }}
                          >
                            Local Contact <span > {showLocalContact ? (
     <span class="icon-park--up"></span>
    ) : (
      <span class="icon-park--down">
        
      </span>)}
      </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </li>
                </div>
                {showLocalContact && (
<>
<div className="  scroll-container" 
style={{ marginTop:'-18px',
 textAlign: "left",
  paddingLeft: '15px',
   paddingTop: '10px',
    zIndex: 999,
     borderBottomLeftRadius: '1px solid #dbdada',
     borderBottomRightRadius: '1px solid #dbdada',
      borderRadius: '10px',
      backgroundColor:'#dbdada',
      cursor: "all-scroll",
      height:'250px',
      overflowY: "scroll", // Ensure scrollbar appears only on the y-axis
      msOverflowStyle: "none", // Internet Explorer 10+
      scrollbarWidth: "thin", // Firefox
       }}>
<style>
                            {`
                              .scroll-container::-webkit-scrollbar {
                                width: 0.4em;
                              }

                              .scroll-container::-webkit-scrollbar-track {
                                box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.00);
                              }

                              .scroll-container::-webkit-scrollbar-thumb {
                                background-color: #000;
                                border-radius: 4px;
                              }
                            `}
                          </style>
  {emergencyNumber?.map((item, index) => (
    <div key={index} style={{paddingBottom:'10px'}}>
      <p style={{ fontSize: "18px", fontWeight: "bold", color: "black" }}>
        {item.emergencyContactName}  
      </p>
      <p style={{ fontSize: "16px", color: "black" }}>
     Call : {item.emergencyContactNumber}
      </p>
    </div>
  ))}
</div>

              </>
            )}
              </div>

              <div className="lg:w-2/5 md:w-full md:pe-4 md:order-1 md:mt-0 mt-10 md:mt-0 lg:mt-0 mb-8">
                <div>
                  <div className="sticky top-20"></div>
                  <div className="offer-badge" style={{ marginTop: "-70px" }}>
                    <div
                      style={{
                        height: 80,
                        width: 80,
                        display: "flex",
                        justifyContent: "center",
                        paddingTop: "24px",
                        marginLeft: "18px",
                      }}
                      className="badge-text-container"
                    >
                      <span
                        className="badge-text1"
                        style={{
                          textAlign: "center",
                          whiteSpace: "nowrap",
                          fontSize: "22px",
                        }}
                      >
                        Overview
                      </span>
                    </div>
                  </div>

                  <div className="product-card">
                {/* <div
                          className="flex flex-col scroll-container"
                          style={{
                            height: "377px",
                            border: "1px solid #e6e6e6",
                            // padding: "10px",
                            borderRadius: "7px",
                            cursor: "all-scroll",
                            overflowY: "scroll", // Ensure scrollbar appears only on the y-axis
                            msOverflowStyle: "none", // Internet Explorer 10+
                            scrollbarWidth: "thin", // Firefox
                          }}
                        > */}
                          {/* <style>
                            {`
                              .scroll-container::-webkit-scrollbar {
                                width: 0.4em;
                              }

                              .scroll-container::-webkit-scrollbar-track {
                                box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.00);
                              }

                              .scroll-container::-webkit-scrollbar-thumb {
                                background-color: #000;
                                border-radius: 4px;
                              }
                            `}
                          </style> */}
                          
                         
                  <hr style={{ marginTop: "70px" }}></hr>
                 
                 <div >                   
                     {/* <div className="color-price">
                     <div className="color-option">
                       <div
                         className="popover-container1"
                         style={{ display: "flex", alignItems: "center" }}
                       >
                         <span style={{ marginRight: "15px", fontSize: "25px" }}>
                           <span
                             className="ri--export-fill"
                             style={{ color: "black", fontSize: "30px" }}
                           ></span>
                         </span>
                         <span className="color">
                          Overview
                          </span>
                       
                       </div>
                     </div>
                     <div className="price">
                       <div className="color-option">
                         <img
                           style={{ marginRight: "10px" }}
                           width={30}
                           height={30}
                           src={check}
                         />
                       </div>
                     </div>
                   </div> */}
                   <hr style={{ marginTop: "15px" }}></hr>
                   </div>
                  {/* { console.log("333333333333333",customInfo?.import)} */}
                   
                 <div >                   
                     <div className="color-price">
                     <div className="color-option">
                       <div
                         className="popover-container1"
                         style={{ display: "flex", alignItems: "center" }}
                       >
                         <span style={{ marginRight: "15px", fontSize: "25px" }}>
                           <span
                             className="ri--export-fill"
                             style={{ color: "black", fontSize: "30px" }}
                           ></span>
                         </span>
                         <span className="color">
                          Safety
                          </span>
                       
                       </div>
                     </div>
                     <div className="price">
                       <div className="color-option">
                         <img
                           style={{ marginRight: "10px" }}
                           width={30}
                           height={30}
                           src={check}
                         />
                       </div>
                     </div>
                   </div>
                   <hr style={{ marginTop: "15px" }}></hr>
                   </div>
                 <div >                   
                     <div className="color-price">
                     <div className="color-option">
                       <div
                         className="popover-container1"
                         style={{ display: "flex", alignItems: "center" }}
                       >
                         <span style={{ marginRight: "15px", fontSize: "25px" }}>
                           <span
                             className="ri--export-fill"
                             style={{ color: "black", fontSize: "30px" }}
                           ></span>
                         </span>
                         <span className="color">
                          Health
                          </span>
                       
                       </div>
                     </div>
                     <div className="price">
                       <div className="color-option">
                         <img
                           style={{ marginRight: "10px" }}
                           width={30}
                           height={30}
                           src={check}
                         />
                       </div>
                     </div>
                   </div>
                   <hr style={{ marginTop: "15px" }}></hr>
                   </div>
                 <div >                   
                     <div className="color-price">
                     <div className="color-option">
                       <div
                         className="popover-container1"
                         style={{ display: "flex", alignItems: "center" }}
                       >
                         <span style={{ marginRight: "15px", fontSize: "25px" }}>
                           <span
                             className="ri--export-fill"
                             style={{ color: "black", fontSize: "30px" }}
                           ></span>
                         </span>
                         <span className="color">
                          Local laws
                          </span>
                       
                       </div>
                     </div>
                     <div className="price">
                       <div className="color-option">
                         <img
                           style={{ marginRight: "10px" }}
                           width={30}
                           height={30}
                           src={check}
                         />
                       </div>
                     </div>
                   </div>
                   <hr style={{ marginTop: "15px" }}></hr>
                   </div>
                 <div >                   
                     <div className="color-price">
                     <div className="color-option">
                       <div
                         className="popover-container1"
                         style={{ display: "flex", alignItems: "center" }}
                       >
                         <span style={{ marginRight: "15px", fontSize: "25px" }}>
                           <span
                             className="ri--export-fill"
                             style={{ color: "black", fontSize: "30px" }}
                           ></span>
                         </span>
                         <span className="color">
                         Travel
                          </span>
                       
                       </div>
                     </div>
                     <div className="price">
                       <div className="color-option">
                         <img
                           style={{ marginRight: "10px" }}
                           width={30}
                           height={30}
                           src={check}
                         />
                       </div>
                     </div>
                   </div>
                   <hr style={{ marginTop: "15px" }}></hr>
                   </div>
                {/* </div> */}
                         
                        </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container" style={{ marginTop: "60px" }}>
            <div className="md:flex">
              <div
                className="lg:w-3/5 md:w-full md:mb-8 md:order-2"
                style={{ paddingInline: "1rem" }}
              >
                <li className="block items-center">
                  <span
                    style={{
                      color: "#000000",
                      fontWeight: "bold",
                      fontSize: "22px",
                      cursor: "pointer",
                    }}
                  >
                    Overview
                  </span>
                </li>
                <div className="grid xl:grid-cols-1 lg:grid-cols-1 md:grid-cols-1 grid-cols-1 gap-[30px]">
                  <div
                    className="group relative rounded-xl bg-white dark:bg-slate-900 shadow1 p-6 text-center mt-10 "
                    style={{
                      display: "flex",
                      justifyContent: "left",
                      alignContent: "left",
                    }}
                  >
                    <div
                      className="relative -mt-16"
                      style={{
                        display: "flex",
                        justifyContent: "left",
                        alignContent: "left",
                        textAlign: "left",
                      }}
                    >
                      <span style={{ marginTop: "60px", textAlign: "left" }}>
                        <li className="block">
                          <span
                            style={{
                              color: "#000000",
                              fontWeight: "bold",
                              fontSize: "22px",
                              cursor: "pointer",
                              textAlign: "left",
                            }}
                          >
                            Latest Update
                          </span>
                        </li>
                        <li className="block mt-4">
                          <span
                            style={{
                              color: "#000000",
                              fontWeight: "bold",
                              fontSize: "16px",
                              cursor: "pointer",
                              textAlign: "left",
                            }}
                          >
                            Still current at:
                          </span>
                          <span
                            style={{
                              color: "#000000",
                              fontWeight: "bold",
                              fontSize: "16px",
                              cursor: "pointer",
                              textAlign: "left",
                              marginLeft: "20px",
                            }}
                          >
                            {currentDate}
                          </span>
                        </li>
                        <li className="block mt-4">
                          <span
                            style={{
                              color: "#000000",
                              fontWeight: "bold",
                              fontSize: "16px",
                              cursor: "pointer",
                              textAlign: "left",
                            }}
                          >
                            Updated:
                          </span>
                          <span
                            style={{
                              color: "#000000",
                              fontWeight: "bold",
                              fontSize: "16px",
                              cursor: "pointer",
                              textAlign: "left",
                              marginLeft: "55px",
                            }}
                          >
                            {`${year}-${month < 10 ? "0" + month : month}-${
                              day < 10 ? "0" + day : day
                            }`}
                          </span>
                        </li>
                        <li className=" mt-4" style={{display:'flex'}}>
                          <span
                          
                            style={{
                              color: "#000000",
                              fontWeight: "bold",
                              fontSize: "16px",
                              cursor: "pointer",
                              textAlign: "left",
                              whiteSpace:'nowrap'
                            }}
                          >
                            Latest update:
                          </span>
                          <span
                            style={{
                              color: "#000000",
                              fontSize: "16px",
                              cursor: "pointer",
                              textAlign: "left",
                              marginLeft: "20px",
                            }}
                          >
                            <span
                              dangerouslySetInnerHTML={{
                                __html: formatDescription(
                                  travelAdvice?.over_view
                                ),
                              }}
                            >
                          </span>
                            {/* {visaDetail?.travel_advice_description} */}
                          </span>
                        </li>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

         
            <div className="container" style={{ marginTop: "60px" }} >
              
              {/* <div className="md:flex">
                <div
                  className="lg:w-5/5 md:w-full md:mb-8 md:order-2"
                  style={{ paddingInline: "1rem" }}
                >
                  <li className="block items-center">
                    <div
                      className="popover-container1"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <span style={{ marginRight: "12px", fontSize: "25px" }}>
                        <span
                          className={"iconamoon--shield-yes-duotone"}
                          style={{ color: "#000", fontSize: "30px" }}
                        ></span>
                      </span>
                      <span
                        className="color"
                        style={{
                          color: "#000000",
                          fontWeight: "bold",
                          fontSize: "24px",
                          cursor: "pointer",
                        }}
                      >
                        Overview
                      </span>
                    </div>
                  </li>
                  <div className="grid xl:grid-cols-1 lg:grid-cols-1 md:grid-cols-1 grid-cols-1 gap-[30px]">
                    <div
                      className="group relative rounded-xl p-6 text-center"
                      style={{
                        display: "flex",
                        justifyContent: "left",
                        alignContent: "left",
                        marginTop: "-20px",
                      }}
                    >
                      <div
                        className="relative -mt-16"
                        style={{
                          display: "flex",
                          justifyContent: "left",
                          alignContent: "left",
                          textAlign: "left",
                        }}
                      >
                        <span style={{ marginTop: "60px", textAlign: "left" }}>
                          <span className="font-medium inline-flex">
                            <span
                              style={{
                                color: "#000000",
                                fontWeight: "bold",
                                fontSize: "16px",
                                cursor: "pointer",
                              }}
                              dangerouslySetInnerHTML={{
                                __html: formatDescription(
                                  travelAdvice?.over_view
                                ),
                              }}
                            ></span>
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="md:flex">
                <div
                  className="lg:w-5/5 md:w-full md:mb-8 md:order-2"
                  style={{ paddingInline: "1rem" }}
                >
                  <li className="block items-center">
                    <div
                      className="popover-container1"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <span style={{ marginRight: "12px", fontSize: "25px" }}>
                        <span
                          className={"iconamoon--shield-yes-duotone"}
                          style={{ color: "#000", fontSize: "30px" }}
                        ></span>
                      </span>
                      <span
                        className="color"
                        style={{
                          color: "#000000",
                          fontWeight: "bold",
                          fontSize: "24px",
                          cursor: "pointer",
                        }}
                      >
                        Safety
                      </span>
                    </div>
                  </li>
                  <div className="grid xl:grid-cols-1 lg:grid-cols-1 md:grid-cols-1 grid-cols-1 gap-[30px]">
                    <div
                      className="group relative rounded-xl text-center"
                      style={{
                        display: "flex",
                        justifyContent: "left",
                        alignContent: "left",
                        marginTop: "-10px",
                        paddingTop:'25px',
                        paddingRight:'10px',
                      }}
                    >
                      <div
                        className="relative -mt-16"
                        style={{
                          display: "flex",
                          justifyContent: "left",
                          alignContent: "left",
                          textAlign: "left",
                        }}
                      >
                        <span style={{ marginTop: "60px", textAlign: "left" }}>
                          <span className="font-medium travel-advice">
                            <span
                              style={{
                                color: "#000000",
                                fontSize: "16px",
                                cursor: "pointer",
                              }}
                              dangerouslySetInnerHTML={{
                                __html: formatDescription(
                                  travelAdvice?.safety
                                ),
                              }}
                            ></span>
                          </span>
                          {/* <Typography
  className="font-medium travel-advice"
  style={{
    color: "#000000",
    fontWeight: "bold",
    fontSize: "15px",
    cursor: "pointer",
  }}
  dangerouslySetInnerHTML={{
    __html: formatDescription(travelAdvice?.safety),
  }}
  component="span"
/> */}

                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="md:flex" style={{marginTop:'-20px'}}>
                <div
                  className="lg:w-5/5 md:w-full md:mb-8 md:order-2"
                  style={{ paddingInline: "1rem" }}
                >
                  <li className="block items-center">
                    <div
                      className="popover-container1"
                      style={{ display: "flex", alignItems: "center",marginTop:'33px' }}
                    >
                      <span style={{ marginRight: "12px", fontSize: "25px" }}>
                        <span
                          className={"iconamoon--shield-yes-duotone"}
                          style={{ color: "#000", fontSize: "30px" }}
                        ></span>
                      </span>
                      <span
                        className="color"
                        style={{
                          color: "#000000",
                          fontWeight: "bold",
                          fontSize: "24px",
                          cursor: "pointer",
                        }}
                      >
                        {/* {section?.title} */}
                        Health
                      </span>
                    </div>
                  </li>
                  <div className="grid xl:grid-cols-1 lg:grid-cols-1 md:grid-cols-1 grid-cols-1 gap-[30px]">
                    <div
                      className="group relative rounded-xl text-center"
                      style={{
                        display: "flex",
                        justifyContent: "left",
                        alignContent: "left",
                        marginTop: "-10px",
                        paddingTop:'25px',
                        paddingRight:'10px',
                        paddingBottom:'20px',
                      }}
                    >
                      <div
                        className="relative -mt-16"
                        style={{
                          display: "flex",
                          justifyContent: "left",
                          alignContent: "left",
                          textAlign: "left",
                        }}
                      >
                        <span style={{ marginTop: "60px", textAlign: "left" }}>
                          <span className="font-medium travel-advice">
                            <span
                              style={{
                                color: "#000000",
                                fontSize: "16px",
                                cursor: "pointer",
                              }}
                              dangerouslySetInnerHTML={{
                                __html: formatDescription(
                                  travelAdvice?.health
                                ),
                              }}
                            ></span>
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="md:flex">
                <div
                  className="lg:w-5/5 md:w-full md:mb-8 md:order-2"
                  style={{ paddingInline: "1rem" }}
                >
                  <li className="block items-center">
                    <div
                      className="popover-container1"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <span style={{ marginRight: "12px", fontSize: "25px" }}>
                        <span
                          className={"iconamoon--shield-yes-duotone"}
                          style={{ color: "#000", fontSize: "30px" }}
                        ></span>
                      </span>
                      <span
                        className="color"
                        style={{
                          color: "#000000",
                          fontWeight: "bold",
                          fontSize: "24px",
                          cursor: "pointer",
                        }}
                      >
                        {/* {section?.title} */}
                       Local laws
                      </span>
                    </div>
                  </li>
                  <div className="grid xl:grid-cols-1 lg:grid-cols-1 md:grid-cols-1 grid-cols-1 gap-[30px]">
                    <div
                      className="group relative rounded-xl text-center"
                      style={{
                        display: "flex",
                        justifyContent: "left",
                        alignContent: "left",
                        marginTop: "-10px",
                        paddingTop:'25px',
                        paddingRight:'10px',
                        paddingBottom:'20px',
                      }}
                    >
                      <div
                        className="relative -mt-16"
                        style={{
                          display: "flex",
                          justifyContent: "left",
                          alignContent: "left",
                          textAlign: "left",
                        }}
                      >
                        <span style={{ marginTop: "60px", textAlign: "left" }}>
                          <span className="font-medium travel-advice">
                            <span
                              style={{
                                color: "#000000",
                                fontSize: "16px",
                                cursor: "pointer",
                              }}
                              dangerouslySetInnerHTML={{
                                __html: formatDescription(
                                  travelAdvice?.local_laws
                                ),
                              }}
                            ></span>
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="md:flex">
                <div
                  className="lg:w-5/5 md:w-full md:mb-8 md:order-2"
                  style={{ paddingInline: "1rem" }}
                >
                  <li className="block items-center">
                    <div
                      className="popover-container1"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <span style={{ marginRight: "12px", fontSize: "25px" }}>
                        <span
                          className={"iconamoon--shield-yes-duotone"}
                          style={{ color: "#000", fontSize: "30px" }}
                        ></span>
                      </span>
                      <span
                        className="color"
                        style={{
                          color: "#000000",
                          fontWeight: "bold",
                          fontSize: "24px",
                          cursor: "pointer",
                        }}
                      >
                        {/* {section?.title} */}
                        Travel
                      </span>
                    </div>
                  </li>
                  <div className="grid xl:grid-cols-1 lg:grid-cols-1 md:grid-cols-1 grid-cols-1 gap-[30px]">
                    <div
                      className="group relative rounded-xl text-center"
                      style={{
                        display: "flex",
                        justifyContent: "left",
                        alignContent: "left",
                        marginTop: "-10px",
                        paddingTop:'25px',
                        paddingRight:'10px',
                        
                      }}
                    >
                      <div
                        className="relative -mt-16"
                        style={{
                          display: "flex",
                          justifyContent: "left",
                          alignContent: "left",
                          textAlign: "left",
                        }}
                      >
                        <span style={{ marginTop: "60px", textAlign: "left" }}>
                          <span className="font-medium travel-advice">
                            <span
                              style={{
                                color: "#000000",
                                fontSize: "16px",
                                cursor: "pointer",
                              }}
                              dangerouslySetInnerHTML={{
                                __html: formatDescription(
                                  travelAdvice?.travel
                                ),
                              }}
                            ></span>
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          
        </section>
      )}
      <div style={{ marginTop: "-80px" }}>
        <Footer />
      </div>
    </>
  );
}
