import React, { useEffect, useState } from 'react'
import Select from 'react-select';
import logo_icon_28 from '../assets/images/logo-icon-50.png';
import logo_dark from '../assets/images/logo-icon.png';
import logo_white from '../assets/images/logo-icon.png';
import logo_new from '../assets/images/logo-icon1.png';
import small from '../assets/images/logo-icon-50-1.png';
import { Link, json, useNavigate } from "react-router-dom";
import { Icon } from '@iconify/react';
import profileBold from '@iconify/icons-iconamoon/profile-bold';
import {LuSearch, PiWalletBold, AiOutlineCopy, AiOutlineUser, LuSettings, LiaSignOutAltSolid, TiUserOutline, TbUserFilled, TbUserSquareRounded, BsPersonCircle, BsPersonFill, MdPersonOutline, MdOutlinePersonOutline, PiUsersFill, PiUserFill, PiUserCircle, PiUserCircleFill, IoMdClose} from "../assets/icons/vander"
import { Users } from 'feather-icons-react/build/IconComponents';
import axios from 'axios';
import { usePlacesWidget } from 'react-google-autocomplete';
import { REST_API, REST_API_END_POINT } from '../constants/DefaultValue';
import { empty } from '../common/Helper';
import { Box, IconButton, Tooltip, Typography } from '@material-ui/core';
const customStyles = {
    control: (styles, { isFocused, isHovered }) => ({
      ...styles,
      backgroundColor: 'transparent',
      color: 'rgb(165 124 1 / var(--tw-bg-opacity)) !important',
      placeholder: {
        color: 'black',
      },
      borderColor: isHovered ? 'white' : '#e6e6e6', 
      boxShadow: isHovered ? '0 0 0 2px white' : 'none', 
      borderWidth: '2px',
      outline: '#e6e6e6',
      height: '45px', 
      paddingLeft: '8px', 
      zIndex:'999'
    }),
    menu: provided => ({
        ...provided,
         zIndex: 999,
      }),
    option: (styles, { isFocused }) => ({
      ...styles,
      backgroundColor: 'white',
      color: 'black',
      zIndex: isFocused ? 999 : 9999,
      
    }),
    singleValue: (styles) => ({
      ...styles,
      color: 'black',
    }),
    menuPortal: (base) => ({
      ...base,
      zIndex: 9999,
    }),
  };
  
const statiCountry={
  added_by: 1,
  custom_info_date :1725351334,
  flag:"https://firebasestorage.googleapis.com/v0/b/visa-search-a8660.appspot.com/o/1716090156728Australia%20flag.png?alt=media&token=e7e0e712-1070-44ee-85f3-74876332e4a3",
  get_visa_on:52,
  get_visa_on_unit:"Days",
  id:20,
  image:"https://firebasestorage.googleapis.com/v0/b/visa-search-a8660.appspot.com/o/1715494759466il_1588xN.2959680360_p8kg.webp?alt=media&token=fd419c26-1682-4746-9b3c-3bad2e5898d7",
  is_passport_ranking:1,
  is_visa_on_arrival:0,
  name:"Australia",
  no_of_visa:500,
  passport_ranking:7,
  status:1,
  student_permission:0,
  timestamp:1715494774,
  travel_advice_date:1725351334,
  travel_permission:1,
  verified:1,
  visa_arrival:null,
  visa_arrival_unit:null,
  visa_detail_status:0,
  work_permission:1
}


const countryToNationality = {
  'Afghanistan': 'Afghan',
  'Albania': 'Albanian',
  'Algeria': 'Algerian',
  'Andorra': 'Andorran',
  'Angola': 'Angolan',
  'Antigua and Barbuda': 'Antiguan or Barbudan',
  'Argentina': 'Argentinian',
  'Armenia': 'Armenian',
  'Australia': 'Australian',
  'Austria': 'Austrian',
  'Azerbaijan': 'Azerbaijani',
  'Bahamas': 'Bahamian',
  'Bahrain': 'Bahraini',
  'Bangladesh': 'Bangladeshi',
  'Barbados': 'Barbadian',
  'Belarus': 'Belarusian',
  'Belgium': 'Belgian',
  'Belize': 'Belizean',
  'Benin': 'Beninese',
  'Bhutan': 'Bhutanese',
  'Bolivia': 'Bolivian',
  'Bosnia and Herzegovina': 'Bosnian or Herzegovinian',
  'Botswana': 'Motswana',
  'Brazil': 'Brazilian',
  'Brunei': 'Bruneian',
  'Bulgaria': 'Bulgarian',
  'Burkina Faso': 'Burkinabe',
  'Burundi': 'Burundian',
  'Cabo Verde': 'Cabo Verdean',
  'Cambodia': 'Cambodian',
  'Cameroon': 'Cameroonian',
  'Canada': 'Canadian',
  'Central African Republic': 'Central African',
  'Chad': 'Chadian',
  'Chile': 'Chilean',
  'China': 'Chinese',
  'Colombia': 'Colombian',
  'Comoros': 'Comorian',
  'Democratic Republic of the Congo': 'Congolese',
  'Republic of the Congo': 'Congolese',
  'Costa Rica': 'Costa Rican',
  "Côte d'Ivoire": 'Ivorian',
  'Croatia': 'Croatian',
  'Cuba': 'Cuban',
  'Cyprus': 'Cypriot',
  'Czech Republic': 'Czech',
  'Denmark': 'Danish',
  'Djibouti': 'Djiboutian',
  'Dominica': 'Dominican',
  'Dominican Republic': 'Dominican',
  'Ecuador': 'Ecuadorian',
  'Egypt': 'Egyptian',
  'El Salvador': 'Salvadoran',
  'Equatorial Guinea': 'Equatorial Guinean',
  'Eritrea': 'Eritrean',
  'Estonia': 'Estonian',
  'Eswatini': 'Swazi',
  'Ethiopia': 'Ethiopian',
  'Fiji': 'Fijian',
  'Finland': 'Finnish',
  'France': 'French',
  'Gabon': 'Gabonese',
  'Gambia': 'Gambian',
  'Georgia': 'Georgian',
  'Germany': 'German',
  'Ghana': 'Ghanaian',
  'Greece': 'Greek',
  'Grenada': 'Grenadian',
  'Guatemala': 'Guatemalan',
  'Guinea': 'Guinean',
  'Guinea-Bissau': 'Bissau-Guinean',
  'Guyana': 'Guyanese',
  'Haiti': 'Haitian',
  'Honduras': 'Honduran',
  'Hungary': 'Hungarian',
  'Iceland': 'Icelandic',
  'India': 'Indian',
  'Indonesia': 'Indonesian',
  'Iran': 'Iranian',
  'Iraq': 'Iraqi',
  'Ireland': 'Irish',
  'Israel': 'Israeli',
  'Italy': 'Italian',
  'Jamaica': 'Jamaican',
  'Japan': 'Japanese',
  'Jordan': 'Jordanian',
  'Kazakhstan': 'Kazakhstani',
  'Kenya': 'Kenyan',
  'Kiribati': 'I-Kiribati',
  'Kuwait': 'Kuwaiti',
  'Kyrgyzstan': 'Kyrgyzstani',
  'Laos': 'Lao',
  'Latvia': 'Latvian',
  'Lebanon': 'Lebanese',
  'Lesotho': 'Basotho',
  'Liberia': 'Liberian',
  'Libya': 'Libyan',
  'Liechtenstein': 'Liechtensteiner',
  'Lithuania': 'Lithuanian',
  'Luxembourg': 'Luxembourger',
  'Madagascar': 'Malagasy',
  'Malawi': 'Malawian',
  'Malaysia': 'Malaysian',
  'Maldives': 'Maldivian',
  'Mali': 'Malian',
  'Malta': 'Maltese',
  'Marshall Islands': 'Marshallese',
  'Mauritania': 'Mauritanian',
  'Mauritius': 'Mauritian',
  'Mexico': 'Mexican',
  'Micronesia': 'Micronesian',
  'Moldova': 'Moldovan',
  'Monaco': 'Monegasque',
  'Mongolia': 'Mongolian',
  'Montenegro': 'Montenegrin',
  'Morocco': 'Moroccan',
  'Mozambique': 'Mozambican',
  'Myanmar': 'Burmese',
  'Namibia': 'Namibian',
  'Nauru': 'Nauruan',
  'Nepal': 'Nepali',
  'Netherlands': 'Dutch',
  'New Zealand': 'New Zealander',
  'Nicaragua': 'Nicaraguan',
  'Niger': 'Nigerien',
  'Nigeria': 'Nigerian',
  'North Korea': 'North Korean',
  'North Macedonia': 'Macedonian',
  'Norway': 'Norwegian',
  'Oman': 'Omani',
  'Pakistan': 'Pakistani',
  'Palau': 'Palauan',
  'Palestine': 'Palestinian',
  'Panama': 'Panamanian',
  'Papua New Guinea': 'Papua New Guinean',
  'Paraguay': 'Paraguayan',
  'Peru': 'Peruvian',
  'Philippines': 'Filipino',
  'Poland': 'Polish',
  'Portugal': 'Portuguese',
  'Qatar': 'Qatari',
  'Romania': 'Romanian',
  'Russia': 'Russian',
  'Rwanda': 'Rwandan',
  'Saint Kitts and Nevis': 'Kittitian or Nevisian',
  'Saint Lucia': 'Saint Lucian',
  'Saint Vincent and the Grenadines': 'Saint Vincentian',
  'Samoa': 'Samoan',
  'San Marino': 'Sammarinese',
  'Sao Tome and Principe': 'Sao Tomean',
  'Saudi Arabia': 'Saudi',
  'Senegal': 'Senegalese',
  'Serbia': 'Serbian',
  'Seychelles': 'Seychellois',
  'Sierra Leone': 'Sierra Leonean',
  'Singapore': 'Singaporean',
  'Slovakia': 'Slovak',
  'Slovenia': 'Slovenian',
  'Solomon Islands': 'Solomon Islander',
  'Somalia': 'Somali',
  'South Africa': 'South African',
  'South Korea': 'South Korean',
  'South Sudan': 'South Sudanese',
  'Spain': 'Spanish',
  'Sri Lanka': 'Sri Lankan',
  'Sudan': 'Sudanese',
  'Suriname': 'Surinamese',
  'Sweden': 'Swedish',
  'Switzerland': 'Swiss',
  'Syria': 'Syrian',
  'Taiwan': 'Taiwanese',
  'Tajikistan': 'Tajikistani',
  'Tanzania': 'Tanzanian',
  'Thailand': 'Thai',
  'Timor-Leste': 'Timorese',
  'Togo': 'Togolese',
  'Tonga': 'Tongan',
  'Trinidad and Tobago': 'Trinidadian or Tobagonian',
  'Tunisia': 'Tunisian',
  'Turkey': 'Turkish',
  'Turkmenistan': 'Turkmen',
  'Tuvalu': 'Tuvaluan',
  'Uganda': 'Ugandan',
  'Ukraine': 'Ukrainian',
  'United Arab Emirates': 'Emirati',
  'United Kingdom': 'British',
  'United States': 'American',
  'Uruguay': 'Uruguayan',
  'Uzbekistan': 'Uzbekistani',
  'Vanuatu': 'Ni-Vanuatu',
  'Vatican City': 'Vatican',
  'Venezuela': 'Venezuelan',
  'Vietnam': 'Vietnamese',
  'Yemen': 'Yemeni',
  'Zambia': 'Zambian',
  'Zimbabwe': 'Zimbabwean',
};


export default function Navbar({setCountryDetails,countryDetails,noHomeTab,openDropdown,isDropdown}) {
    const [isOpenn, setIsOpen] = useState(false);
    const [placeBid, setPlaceBid] = useState(false);
    const [userData,setUserData] = useState({})
    const user = JSON.parse(localStorage.getItem('userVisa'));
    const [hideMenu, setHideMenu] = useState(false);
    const [hideMenu1, setHideMenu1] = useState(false);
    const navigate = useNavigate()
  const toggleDropdown = () => {
    setIsOpen(!isOpenn);
  };


  const [isOpen, setMenu] = useState(true);
  const [isScrolled, setScrolled] = useState(false);
///

  const [open, setOpen] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setOpen(false);
    }, 3000); 
  },[open])

  const handleTooltipOpen = () => {
    setOpen(true);
    // setTimeout(() => {
    //   setOpen(false);
    // }, 3000); 
  };

useEffect(() => {
  // Fetch data based on the current page and search query
  const fetchTicketData = async () => {
    try {
      const response = await axios.post(`${REST_API_END_POINT}user-enquires/get-by-user-id`, {
        user_id: user.user_id
      });
      if (response.data.status === 1) {
        const languageData = response.data.language_enquiry.map(item => {
          const rating = response.data.rating.find(r => r.source_id === item.language_id && r.from_id === 6);
          return { ...item, rating: rating ? rating.rating : 0 };
        });
        if (
          languageData.length === 0 &&          
          response.data.universityEnquiry.length === 0
        ) {
          setHideMenu(true); // Set hideMenu to true if all arrays are empty
        } else {
          setHideMenu(false); // Set hideMenu to false if there's any data
        }
        if (
          
          response.data.visaEnquiry.length === 0 &&
          response.data.prEnquiry.length === 0 
        ) {
          setHideMenu1(true); // Set hideMenu to true if all arrays are empty
        } else {
          setHideMenu1(false); // Set hideMenu to false if there's any data
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  fetchTicketData();
}, []);
useEffect(() => {
  if(!noHomeTab){
  const countryDetailsFromStorage = JSON.parse(localStorage.getItem('country_preference'));
  if (countryDetailsFromStorage) {
      setCountryDetails(countryDetailsFromStorage);
  }
  else{

    setCountryDetails(statiCountry)
  }
}
  const userDataFromStorage = JSON.parse(localStorage.getItem('userVisa'));
  if (userDataFromStorage) {
      setUserData(userDataFromStorage);
  }
}, [setCountryDetails]);
  useEffect(() => {
    activateMenu();
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isScrolled]);

  const handleScroll = () => {
    const scrolled = window.scrollY > 50;
    if (scrolled !== isScrolled) {
      setScrolled(scrolled);
    }
  };

    window.addEventListener("scroll", windowScroll);
    function windowScroll() {
        const navbar = document.getElementById("topnav");
        if (
            document.body.scrollTop >= 50 ||
            document.documentElement.scrollTop >= 50
        ) {
            if (navbar !== null) {
                navbar?.classList.add("nav-sticky");
            }
        } else {
            if (navbar !== null) {
                navbar?.classList.remove("nav-sticky");
            }
        }

        const mybutton = document.getElementById("back-to-top");
        if (mybutton != null) {
            if (document.body.scrollTop > 500 || document.documentElement.scrollTop > 500) {
                mybutton.classList.add("flex");
                mybutton.classList.remove("hidden");
            } else {
                mybutton.classList.add("hidden");
                mybutton.classList.remove("flex");
            }
        }
    }

    const toggleMenu = () => {
        setMenu(!isOpen)
        if (document.getElementById("navigation")) {
            const anchorArray = Array.from(document.getElementById("navigation").getElementsByTagName("a"));
            anchorArray.forEach(element => {
                element.addEventListener('click', (elem) => {
                    const target = elem.target.getAttribute("href")
                    if (target !== "") {
                        if (elem.target.nextElementSibling) {
                            var submenu = elem.target.nextElementSibling.nextElementSibling;
                            submenu.classList.toggle('open');
                        }
                    }
                })
            });
        }
    }

    const getClosest = (elem, selector) => {

        // Element.matches() polyfill
        if (!Element.prototype.matches) {
            Element.prototype.matches =
                Element.prototype.matchesSelector ||
                Element.prototype.mozMatchesSelector ||
                Element.prototype.msMatchesSelector ||
                Element.prototype.oMatchesSelector ||
                Element.prototype.webkitMatchesSelector ||
                function (s) {
                    var matches = (this.document || this.ownerDocument).querySelectorAll(s),
                        i = matches.length;
                    while (--i >= 0 && matches.item(i) !== this) { }
                    return i > -1;
                };
        }

        // Get the closest matching element
        for (; elem && elem !== document; elem = elem.parentNode) {
            if (elem.matches(selector)) return elem;
        }
        return null;

    };
    const activateMenu = () => {
        var menuItems = document.getElementsByClassName("sub-menu-item");
        if (menuItems) {

            var matchingMenuItem = null;
            for (var idx = 0; idx < menuItems.length; idx++) {
                if (menuItems[idx].href === window.location.href) {
                    matchingMenuItem = menuItems[idx];
                }
            }

            if (matchingMenuItem) {
                matchingMenuItem.classList.add('active');


                var immediateParent = getClosest(matchingMenuItem, 'li');

                if (immediateParent) {
                    immediateParent.classList.add('active');
                }

                var parent = getClosest(immediateParent, '.child-menu-item');
                if (parent) {
                    parent.classList.add('active');
                }

                var parent = getClosest(parent || immediateParent, '.parent-menu-item');

                if (parent) {
                    parent.classList.add('active');

                    var parentMenuitem = parent.querySelector('.menu-item');
                    if (parentMenuitem) {
                        parentMenuitem.classList.add('active');
                    }

                    var parentOfParent = getClosest(parent, '.parent-parent-menu-item');
                    if (parentOfParent) {
                        parentOfParent.classList.add('active');
                    }
                } else {
                    var parentOfParent = getClosest(matchingMenuItem, '.parent-parent-menu-item');
                    if (parentOfParent) {
                        parentOfParent.classList.add('active');
                    }
                }
            }
        }
    }

    const metamask = async () => {
        try {
            //Basic Actions Section
            const onboardButton = document.getElementById('connectWallet')

            //   metamask modal
            const modal = document.getElementById('modal-metamask')
            const closeModalBtn = document.getElementById('close-modal')

            //   wallet address
            const myPublicAddress = document.getElementById('myPublicAddress')

            //Created check function to see if the MetaMask extension is installed
            const isMetaMaskInstalled = () => {
                //Have to check the ethereum binding on the window object to see if it's installed
                const { ethereum } = window
                return Boolean(ethereum && ethereum.isMetaMask)
            }

            const onClickConnect = async () => {
                if (!isMetaMaskInstalled()) {
                    //meta mask not installed
                    modal.classList.add('show')
                    modal.style.display = 'block'
                    return
                }
                try {
                    // eslint-disable-next-line no-undef
                    await ethereum.request({ method: 'eth_requestAccounts' })
                    // eslint-disable-next-line no-undef
                    const accounts = await ethereum.request({ method: 'eth_accounts' })
                    myPublicAddress.innerHTML =
                        accounts[0].split('').slice(0, 6).join('') +
                        '...' +
                        accounts[0]
                            .split('')
                            .slice(accounts[0].length - 7, accounts[0].length)
                            .join('')
                } catch (error) {
                    console.error(error)
                }
            }

            const closeModal = () => {
                modal.classList.remove('show')
                modal.style.display = 'none'
            }

            if (isMetaMaskInstalled()) {
                // eslint-disable-next-line no-undef
                const accounts = await ethereum.request({ method: 'eth_accounts' })
                if (!!accounts[0]) {
                    myPublicAddress.innerHTML =
                        accounts[0].split('').slice(0, 6).join('') +
                        '...' +
                        accounts[0]
                            .split('')
                            .slice(accounts[0].length - 7, accounts[0].length)
                            .join('')
                }
            }

            onboardButton.addEventListener('click', onClickConnect)
            closeModalBtn.addEventListener('click', closeModal)
        } catch (error) { }
    }

    
  const [address, setAddress] = useState({
    address: '',
    city: '',
    state: '',
    zip_code: '',
    search_google_enabled: false,
  });

  const [propertyData, setPropertyData] = useState({
    lot_size: '',
    square_feet: '',
    year_built: '',
    bedrooms: '',
    bathrooms: '',
    heating: '',
    cooling: '',
    parking: '',
  });

  const handleFetch = (address, zip_code) => {
    const api = 'AIzaSyBe6vrykAsiKjwIHWOUcfH1Li4VT-2LHTg';
    axios
      .get(api)
      .then((result) => {
        if (
          result?.data?.bundle.length &&
          result.data.bundle[0].address.zip &&
          result.data.bundle[0].address.house
        ) {
          axios
            .get(api)
            .then((data) => {
              if (
                data &&
                Object.keys(data).length === 0 &&
                Object.getPrototypeOf(data) === Object.prototype
              ) {
                console.log('Appointment Data Outside failed');
              } else if (
                data?.data?.bundle.length ||
                data.data.bundle[0]?.areas.length ||
                data.data.bundle[0]?.building.length ||
                data.data.bundle[0]?.garages.length
              ) {
                setPropertyData({
                  ...propertyData,
                  lot_size: data.data.bundle[0].lotSizeSquareFeet,
                  square_feet: data.data.bundle[0].areas[0]?.areaSquareFeet,
                  year_built: data.data.bundle[0].building[0]?.yearBuilt,
                  bedrooms: data.data.bundle[0].building[0]?.bedrooms,
                  bathrooms: data.data.bundle[0].building[0]?.fullBaths,
                  heating: data.data.bundle[0].building[0]?.heating,
                  cooling: data.data.bundle[0].building[0]?.airConditioning,
                  parking: data.data.bundle[0].garages.length,
                });
              }
            });
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  };

  const { ref: inputRef } = usePlacesWidget({
    onPlaceSelected: (res) => {
      if (typeof res.address_components !== 'undefined') {
        const address_components = res.address_components;

        const address_1 = address_components.find(
          (row) => row.types.indexOf('street_number') !== -1
        );
        const address_2 = address_components.find(
          (row) => row.types.indexOf('route') !== -1
        );

        let addressValue = '';
        if (address_1) addressValue = address_1.long_name;
        if (address_2) addressValue += ' ' + address_2.long_name;

        const city = address_components.find(
          (row) => row.types.indexOf('locality') !== -1
        )?.long_name || '';

        const state = address_components.find(
          (row) => row.types.indexOf('administrative_area_level_1') !== -1
        )?.short_name || '';

        const zip_code = address_components.find(
          (row) => row.types.indexOf('postal_code') !== -1
        )?.long_name || '';

        setAddress({
          address: addressValue,
          city: city,
          state: state,
          zip_code: zip_code,
          search_google_enabled: false,
        });

        handleFetch(addressValue, zip_code);
      }
    },
    options: {
      types: ['geocode'],
      //componentRestrictions: { country:['us', 'ca', 'uy'] },
    },
  });

  // State variables
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);
  const [showNoOptionsMessage, setShowNoOptionsMessage] = useState(false); // State variable to control displaying the message
  const [showCitizenship, setShowCitizenship] = useState(false);

  const [SearchInputValue, setSearchInputValue] = useState('');
  const [searchOptions, setSearchOptions] = useState([]);
  const [showNoSearchOptionsMessage, setShowNoSearchOptionsMessage] = useState(false); // State variable to control displaying the message
  
  const handleContinueClick = () => {
    setPlaceBid(!placeBid);
    // setShowCitizenship(!showCitizenship);
};
  const handleEditCitizenshipClick = () => {
    setShowCitizenship(!showCitizenship);
};



  const handleInputChange = (e) => {
      const value = e.target.value;
      setInputValue(value);

      if (value.trim() !== '') {
        axios.get(`${REST_API_END_POINT}country-list/user-navbar/${value}`)
        .then((res)=>{
            if(res.data.status===1){
                let filteredCountry = res.data?.data?.filter(c=>c.id !== countryDetails.id)
                setOptions(filteredCountry)
            }else{
                setShowNoOptionsMessage(true)
            }
        })
        //   const filteredOptions = autoCompleteOptions.filter(option =>
        //       option.toLowerCase().includes(value.toLowerCase())
        //   );
        //   setOptions(filteredOptions);
        //   setShowNoOptionsMessage(filteredOptions.length === 0); 
      } else {
          setOptions([]);
          setShowNoOptionsMessage(false); 
      }
  };



  const handleOptionClick = (option) => {
      localStorage.setItem('country_preference',JSON.stringify(option))
      setCountryDetails(option)
      setInputValue(option.name);
      setOptions([]); 
      setShowNoOptionsMessage(false); 
  };


  const handleSearchInputChange = (e) => {
      const value = e.target.value;
      setSearchInputValue(value);

      if (value.trim() !== '') {
        axios.get(`${REST_API_END_POINT}country-list/user-navbar/${value}`)
        .then((res)=>{
            if(res.data.status===1){
                setSearchOptions(res.data.data)
            }else{
                setShowNoSearchOptionsMessage(true)
            }
        })
        //   const filteredOptions = autoCompleteOptions.filter(option =>
        //       option.toLowerCase().includes(value.toLowerCase())
        //   );
        //   setSearchOptions(filteredOptions);
        //   setShowNoSearchOptionsMessage(filteredOptions.length === 0); 
      } else {
        setSearchOptions([]);
        setShowNoSearchOptionsMessage(false); 
      }
  };

  
  const handleSearchOptionClick = (option) => {

    if(option.verified===1){
        navigate(`/visa-details/${option.id}/visa/${option.name}`)
    }
    // setSearchInputValue(option);
    //   setSearchOptions([]); 
    //   setShowNoSearchOptionsMessage(false); 
  };
  const logout = async () => {
    const response = await axios.post(REST_API+'logout', {
     userId:userData.user_id
    });
    if(response.data.status==1){
      openDropdown(true)
      window.location.reload()
      localStorage.removeItem('user_id')
      localStorage.removeItem('user_type')
      localStorage.removeItem('userVisa')
      navigate('/visa') 
    }
  };


  
  const countryName = countryToNationality[countryDetails?.name]||countryDetails?.name;
  console.log("countryName",countryName);
  

    return (
        <>
            <nav 
        id="topnav"
        className={`defaultscroll ${isScrolled ? 'is-sticky' : ''}`}
        style={{
          
          justifyContent: 'space-between',
        //   background: isScrolled ? 'linear-gradient(to right, #ff8c00, #eecf36, #eecf36, #eecf36, #eecf36, #ff8c00)' : 'transparent',
          backgroundColor: isScrolled ? '#000000' : 'transparent',
        }}
      >
        <div className="container">
          {/* <!-- Logo container--> */}
          <Link className="logo ps-0" to="/">
            <img style={{ width: 70 }} src={isScrolled ? logo_dark : logo_dark} className="inline-block sm:hidden" alt="" />
            <div className="sm:block hidden">
              <img src={small} className="inline-block dark:hidden h-7" alt="" />
              <img
                style={{
                  width: 110,
                  marginTop: isScrolled ? '-15px' : '0', 
                }}
                src={isScrolled ? logo_dark : logo_dark}
                className="hidden dark:inline-block h-77"
                alt=""
              />
            </div>
          </Link>


                    

                 
                    

          <div className="menu-extras" >
                <div className="menu-item" style={{ display: 'flex' }}>
                   {/* {!empty(countryDetails)&&( */}
                     <Link style={{ marginLeft: '0px' }}>
                      <ul className="notification-drop" style={{ marginTop: isScrolled ? '7px' : '22px' }}>
                        <li className="item" onClick={()=> {setPlaceBid(!placeBid);setOpen(false)}}>
                          {/* <img
                            className='i'
                            src={countryDetails?.flag}    
                            aria-hidden="true"
                            style={{ fontSize: '30px',width:'38px',height:'24px',objectFit:'cover',borderRadius:'2px' }}
                            onMouseEnter={handleTooltipOpen}
                          />  
                          <span style={{color:'white'}}>Passport</span>      */}
                
                                  <Tooltip
                                    open={open}
                                    title={
                                      <div style={{whiteSpace:'nowrap'}}>
                                        <Typography variant="body2">You hold {countryName} passport        <div className='material-symbols-light--edit'/></Typography>
                                        
                                      </div>
                                    }
                                    placement="bottom"
                                    arrow
                                  >
                                    <img
                                      className='i'
                                      src={countryDetails?.flag}    
                                      aria-hidden="true"
                                      style={{ fontSize: '30px',width:'38px',height:'24px',objectFit:'cover',borderRadius:'2px' }}
                                      onMouseEnter={handleTooltipOpen}
                                                      />  
                                  </Tooltip>       
                              </li>
                       
                      </ul>
                    </Link>
                  {/* )} */}

                  

                <div style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, zIndex: 50, overflow: 'hidden', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(0, 0, 0, 0.5)' }} className={`m-auto justify-center items-center flex bg-gray-900 bg-opacity-50 dark:bg-opacity-80 ${placeBid ? "" : "hidden" }`}>
                  <div style={{ position: 'relative', width: '100%', height: 'auto', maxWidth: '450px', padding: '4px' }} className="relative w-full h-auto max-w-2xl p-4">
                      <div style={{ position: 'relative', backgroundColor: 'white', borderRadius: '8px', boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)' }} className="relative bg-white dark:bg-slate-900 rounded-lg shadow dark:shadow-gray-800">
                          
                          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '6px 12px', }} className="flex justify-between items-center px-6 py-4">
                              <button style={{ color: '#718096', backgroundColor: 'transparent', ':hover': { backgroundColor: '#edf2f7', color: '#2d3748' }, borderRadius: '8px', fontSize: '1rem', padding: '0.375rem 0.75rem', marginLeft: 'auto' }} type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-lg p-1.5 ms-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white">
                                  <IoMdClose style={{fontSize:'1.8rem',color:'black'}} onClick={() => setPlaceBid(!placeBid)} />
                              </button>
                          </div>

                          
                          <div id='center' style={{ paddingLeft: '1.5rem',paddingRight: '1.5rem',paddingBottom: '1.5rem', overflowY: 'auto', maxHeight: '400px', color: 'black' }} className="overflow-y-auto max-h-96">
                              
                                <>
                                    <div className="grid grid-cols-1">
                                                    <div className="flex items-center">
                                                        <div className="relative inline-block">
                                                            <img src={countryDetails?.flag}  style={{marginTop:'5px',width:'100px',height:'100px',paddingBottom:'12px',objectFit:'cover',borderRadius:'3px'}}
                                                             alt={countryDetails?.name} />
                                                        </div>

                                                        <div className="ms-3">
                                                        <p style={{ fontSize: '1.1rem', marginTop: '0px',color:'#000' }} >Your citizenship is set to <span style={{ color: 'black', fontWeight: 'bold', fontSize: '1rem', }}>{countryDetails?.name}</span></p>
                                                        {showCitizenship && (<div>
                                                            <div style={{display:'flex',justifyContent:'flex-start',marginTop:'10px'}}>
                                                                <p style={{ textAlign: 'start', color: 'black', fontWeight: '500',fontSize: '1.1rem',marginBottom: '0px' }}>Citizenship</p><span style={{marginLeft: '5px',fontSize:'1.2rem',marginTop:'5px',}}><i className="material-symbols--info-outline"></i></span>
                                                            </div>           
                                                            <div className="autocomplete-container wrapper2">
                                                                <div className="search flex items-center">
                                                                    <input
                                                                        type="text"
                                                                        className="location-input"
                                                                        style={{ color: 'black', border: '2px solid #000' }}
                                                                        placeholder="Search Location...."
                                                                        value={inputValue}
                                                                        onChange={handleInputChange}
                                                                    />
                                                                    <div className="icon" style={{ color: 'black' }}>
                                                                        <LuSearch />
                                                                    </div>
                                                                </div>
                                                                {(options.length > 0 || showNoOptionsMessage) && (
                                                                    <ul className="autocomplete-options">
                                                                        {options.length > 0 ? (
                                                                            options.map((option, index) => (
                                                                                <li key={index} onClick={() => handleOptionClick(option)}>
                                                                                    {option.name}
                                                                                </li>
                                                                            ))
                                                                        ) : (
                                                                            <li>No matching country found</li>
                                                                        )}
                                                                    </ul>
                                                                )}
                                                            </div>
                                                        </div>)}
                                                        <div className="group rounded-xl bg-white dark:bg-slate-900 w-full mx-auto lg:max-w-2xl" style={{ marginTop: '10px',}}>
                                                            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                                                                <button
                                                                    className='font-semibold transition-all duration-500 ease-in-out citizenship-button'
                                                                    type="button"
                                                                    onClick={handleContinueClick}
                                                                >
                                                                    Continue
                                                                </button>
                                                                <button
                                                                    type="button"
                                                                    className='citizenship'
                                                                    onClick={handleEditCitizenshipClick}
                                                                >
                                                                    Edit Citizenship
                                                                </button>
                                                            </div>
                                                        </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        </div>                          
                                    </div>
                                  </div>
                                </div>
                              
                    {/* Second link */}
                    {!empty(userData)?(

                       <li style={{
                        marginTop: isScrolled ? '14px' : '29px',
                       }} className="dropdown inline-block relative ps-1">
                       <button 
                            onClick={() => openDropdown(!isDropdown)} 
                            style={{ width: '30px', height: '30px', borderRadius: '50%', padding: 0, overflow: 'hidden' }}
                            className="dropdown-toggle btn btn-icon rounded-full shadow-md"
                            data-dropdown-toggle="dropdown"
                            type="button"
                        >
                            <img 
                                className="w-full h-full object-cover" 
                                src={userData?.profile_pic || "https://png.pngtree.com/png-vector/20190509/ourmid/pngtree-blue-passport-visa-ticket-png-image_854287.jpg"}
                                alt={userData?.first_name || 'Profile Picture'} 
                            />
                        </button>
                       <div className={`dropdown-menu absolute end-0 m-0 mt-4 z-10 w-48 rounded-md
                         overflow-hidden bg-white dark:bg-slate-900 shadow dark:shadow-gray-800 
                         ${isDropdown ? 'hidden' : 'block'}`} >
                           <div className="relative">
                               <div className="py-8  inset-0 bg-gradient-to-b from-transparent to-slate-900">

                               </div>
                               <div className="absolute px-4 -bottom-7 start-0">
                                  <div className="flex items-center">
                                    <img
                                      src={userData?.profile_pic || "https://png.pngtree.com/png-vector/20190509/ourmid/pngtree-blue-passport-visa-ticket-png-image_854287.jpg"}
                                      style={{
                                        width: '40px',
                                        height: '40px',
                                        borderRadius: '50%',
                                        objectFit: 'cover',
                                      }}
                                      className="shadow dark:shadow-gray-700"
                                      alt={userData?.first_name || 'Profile Picture'}
                                    />
                                    <span className="font-semibold text-[15px] ms-2 text-gray-3001 mt-4">
                                      {`${userData?.first_name || ''} ${userData?.last_name || ''}`}
                                    </span>
                                  </div>
                                </div>
                           </div>                                     

                           <ul style={{paddingTop:'35px'}}>
                               {/* <li>
                                   <Link to="/creator-profile" 
                                   className="inline-flex items-center text-[14px] font-semibold
                                    py-1.5 px-4 hover:text-violet-600">
                                      <LuSettings className="text-[16px] align-middle me-1"/> Settings</Link>
                                       Profile</Link>
                               </li> */}
                               <li>
                                        <Link to="/creator-profile-edit" className="inline-flex items-center text-[14px] font-semibold py-1.5 px-4 text-gray-3001 ">
                                        {/* hover:text-gray-100 */}
                                        <AiOutlineUser className="text-[16px] align-middle me-1 "/>
                                       Profile</Link>
                                    </li>
                                    {/* {!hideMenu1&& */}
                                    <li>
                                        <Link to="/track-application" 
                                        className="inline-flex items-center text-[14px] font-semibold py-1.5 px-4 text-gray-3001 ">                              
                                        <span class="tabler--track"></span>
                                       Track My Application </Link>
                                    </li>
                                    {/* } */}
                                    {/* {!hideMenu&& */}
                                    <li>
                                        <Link to="/tickets" 
                                        className="inline-flex items-center text-[14px] font-semibold py-1.5 px-4 text-gray-3001 ">                              
                                       <span class="ion--ticket-sharp"></span>
                                       My Enquires </Link>
                                    </li>
                                    {/* } */}
                               <li className="border-t border-gray-100 dark:border-gray-1001 my-2"></li>
                               <li onClick={logout}>
                                   <Link to="/visa" 
                                   className="inline-flex items-center text-[14px] 
                                   font-semibold py-1.5 px-4 text-gray-3001 ">
                                    {/* hover:text-violet-600 */}
                                    <LiaSignOutAltSolid className="text-[16px] align-middle me-1"/>
                                     Logout</Link>
                               </li>
                           </ul>
                       </div>
                   </li> 
                    ):(
                    <Link to="/signup-visa/visa" className="navbar-toggle" id="isToggle" onClick={toggleMenu} style={{ marginLeft: '0px' }}>
                        <div className="lines" style={{
                            marginTop: isScrolled ? '14px' : '29px',
                            fontSize: '30px'
                        }}>
                            <Icon 
                                style={{ color: 'white' }}
                                icon={profileBold} />
                        </div>
                    </Link>
                    )}
                   
                </div>
            </div>


                    {/* Search bar */}
                    {isScrolled && !noHomeTab && (
                        <div className="autocomplete-container wrapper2" style={{marginTop:'15px',zIndex:0}}>
                        {/* <div className="search flex items-center">
                            <input
                                type="text"
                                className="location-input"
                                style={{ color: 'black', border: '2px solid #000' }}
                                placeholder="Search Location...."
                                value={SearchInputValue}
                                onChange={handleSearchInputChange}
                            />
                            <div className="icon" style={{ color: 'black' }}>
                                <LuSearch />
                            </div>
                        </div> */}
                        {/* {(searchOptions.length > 0 || showNoSearchOptionsMessage) && (
                            <ul className="autocomplete-options1">
                                {searchOptions.length > 0 ? (
                                    searchOptions.map((option, index) => (
                                        <li key={index} onClick={() => handleSearchOptionClick(option)}>
                                            {option.name}
                                        </li>
                                    ))
                                ) : (
                                    <li>No matching country found</li>
                                )}
                            </ul>
                        )} */}
                    </div>  
                    )}
                            


                    {/* <!--Login button Start--> */}
                     <ul className="buy-button list-none mb-0" >
                       
                    </ul>

                    <div id="navigation" style={{backgroundColor:'#eecf36'}} className={`${isOpen === true ? 'hidden' : 'block'}`}>
                        
                        <ul className="navigation-menu justify-end">                        
                        </ul>
                    </div>
                </div>
            </nav>

        </>
    )
}
