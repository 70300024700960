import React, { useEffect, useState } from 'react'

import logo_icon_28 from '../assets/images/logo-icon1.png';
import small from '../assets/images/logo-icon.png';
import logo_dark from '../assets/images/logo-icon1.png';
import logo_white from '../assets/images/logo-icon.png';
import image from '../assets/images/client/05.jpg';
import { Link, useNavigate } from "react-router-dom";
import { Icon } from '@iconify/react';
import profileBold from '@iconify/icons-iconamoon/profile-bold';
import {LuSearch, PiWalletBold, AiOutlineCopy, AiOutlineUser, LuSettings, LiaSignOutAltSolid, TiUserOutline, TbUserFilled, TbUserSquareRounded, BsPersonCircle, BsPersonFill, MdPersonOutline, MdOutlinePersonOutline, PiUsersFill, PiUserFill, PiUserCircle, PiUserCircleFill} from "../assets/icons/vander"
import { Users } from 'feather-icons-react/build/IconComponents';
import { empty } from '../common/Helper';
import { REST_API } from '../constants/DefaultValue';
import axios from 'axios';


export default function NavbarNew({ tab,notification,aboutus }) {
    const [isOpenn, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpenn);
  };
  
    const [isDropdown, openDropdown] = useState(true);
    const [isOpen, setMenu] = useState(true);
    const [isScrolled, setScrolled] = useState(false);
    const [userData,setUserData] = useState({})
    const navigate=useNavigate()
    useEffect(() => {

        const userDataFromStorage = JSON.parse(localStorage.getItem('userVisa'));
        if (userDataFromStorage) {
            setUserData(userDataFromStorage);
        }

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };

        
    }, [isScrolled]);

    const handleScroll = () => {
        const scrolled = window.scrollY > 50;
        setScrolled(scrolled);
    };

    

    window.addEventListener("scroll", windowScroll);
    function windowScroll() {
        const navbar = document.getElementById("topnav");
        if (
            document.body.scrollTop >= 50 ||
            document.documentElement.scrollTop >= 50
        ) {
            if (navbar !== null) {
                navbar?.classList.add("nav-sticky");
            }
        } else {
            if (navbar !== null) {
                navbar?.classList.remove("nav-sticky");
            }
        }

        const mybutton = document.getElementById("back-to-top");
        if (mybutton != null) {
            if (document.body.scrollTop > 500 || document.documentElement.scrollTop > 500) {
                mybutton.classList.add("flex");
                mybutton.classList.remove("hidden");
            } else {
                mybutton.classList.add("hidden");
                mybutton.classList.remove("flex");
            }
        }
    }

    const toggleMenu = () => {
        setMenu(!isOpen)
        if (document.getElementById("navigation")) {
            const anchorArray = Array.from(document.getElementById("navigation").getElementsByTagName("a"));
            anchorArray.forEach(element => {
                element.addEventListener('click', (elem) => {
                    const target = elem.target.getAttribute("href")
                    if (target !== "") {
                        if (elem.target.nextElementSibling) {
                            var submenu = elem.target.nextElementSibling.nextElementSibling;
                            submenu.classList.toggle('open');
                        }
                    }
                })
            });
        }
    }

    const getClosest = (elem, selector) => {

        // Element.matches() polyfill
        if (!Element.prototype.matches) {
            Element.prototype.matches =
                Element.prototype.matchesSelector ||
                Element.prototype.mozMatchesSelector ||
                Element.prototype.msMatchesSelector ||
                Element.prototype.oMatchesSelector ||
                Element.prototype.webkitMatchesSelector ||
                function (s) {
                    var matches = (this.document || this.ownerDocument).querySelectorAll(s),
                        i = matches.length;
                    while (--i >= 0 && matches.item(i) !== this) { }
                    return i > -1;
                };
        }

        // Get the closest matching element
        for (; elem && elem !== document; elem = elem.parentNode) {
            if (elem.matches(selector)) return elem;
        }
        return null;

    };
    const activateMenu = () => {
        var menuItems = document.getElementsByClassName("sub-menu-item");
        if (menuItems) {

            var matchingMenuItem = null;
            for (var idx = 0; idx < menuItems.length; idx++) {
                if (menuItems[idx].href === window.location.href) {
                    matchingMenuItem = menuItems[idx];
                }
            }

            if (matchingMenuItem) {
                matchingMenuItem.classList.add('active');


                var immediateParent = getClosest(matchingMenuItem, 'li');

                if (immediateParent) {
                    immediateParent.classList.add('active');
                }

                var parent = getClosest(immediateParent, '.child-menu-item');
                if (parent) {
                    parent.classList.add('active');
                }

                var parent = getClosest(parent || immediateParent, '.parent-menu-item');

                if (parent) {
                    parent.classList.add('active');

                    var parentMenuitem = parent.querySelector('.menu-item');
                    if (parentMenuitem) {
                        parentMenuitem.classList.add('active');
                    }

                    var parentOfParent = getClosest(parent, '.parent-parent-menu-item');
                    if (parentOfParent) {
                        parentOfParent.classList.add('active');
                    }
                } else {
                    var parentOfParent = getClosest(matchingMenuItem, '.parent-parent-menu-item');
                    if (parentOfParent) {
                        parentOfParent.classList.add('active');
                    }
                }
            }
        }
    }

    const metamask = async () => {
        try {
            //Basic Actions Section
            const onboardButton = document.getElementById('connectWallet')

            //   metamask modal
            const modal = document.getElementById('modal-metamask')
            const closeModalBtn = document.getElementById('close-modal')

            //   wallet address
            const myPublicAddress = document.getElementById('myPublicAddress')

            //Created check function to see if the MetaMask extension is installed
            const isMetaMaskInstalled = () => {
                //Have to check the ethereum binding on the window object to see if it's installed
                const { ethereum } = window
                return Boolean(ethereum && ethereum.isMetaMask)
            }

            const onClickConnect = async () => {
                if (!isMetaMaskInstalled()) {
                    //meta mask not installed
                    modal.classList.add('show')
                    modal.style.display = 'block'
                    return
                }
                try {
                    // eslint-disable-next-line no-undef
                    await ethereum.request({ method: 'eth_requestAccounts' })
                    // eslint-disable-next-line no-undef
                    const accounts = await ethereum.request({ method: 'eth_accounts' })
                    myPublicAddress.innerHTML =
                        accounts[0].split('').slice(0, 6).join('') +
                        '...' +
                        accounts[0]
                            .split('')
                            .slice(accounts[0].length - 7, accounts[0].length)
                            .join('')
                } catch (error) {
                    console.error(error)
                }
            }

            const closeModal = () => {
                modal.classList.remove('show')
                modal.style.display = 'none'
            }

            if (isMetaMaskInstalled()) {
                // eslint-disable-next-line no-undef
                const accounts = await ethereum.request({ method: 'eth_accounts' })
                if (!!accounts[0]) {
                    myPublicAddress.innerHTML =
                        accounts[0].split('').slice(0, 6).join('') +
                        '...' +
                        accounts[0]
                            .split('')
                            .slice(accounts[0].length - 7, accounts[0].length)
                            .join('')
                }
            }

            onboardButton.addEventListener('click', onClickConnect)
            closeModalBtn.addEventListener('click', closeModal)
        } catch (error) { }
    }

    const logout = async () => {
        const response = await axios.post(REST_API+'logout', {
         userId:userData.user_id
        });
        if(response.data.status==1){
          openDropdown(true)
          window.location.reload()
          localStorage.removeItem('user_id')
          localStorage.removeItem('user_type')
          localStorage.removeItem('userVisa')
          navigate('/visa') 
        }
      };

    return (
        <>
            {!isScrolled && (
                <nav
                    id="topnav"
                    className={`defaultscroll ${notification ? 'navMt' : ''}`}
                    style={{
                        justifyContent: 'space-between',
                        backgroundColor: isScrolled ? 'transparent' : 'transparent',
                    }}
                >
                    <div className="container">
                        {/* <!-- Logo container--> */}
                        <Link className="logo ps-0" to={'/'+tab}>
                            <img style={{width: 70}} src={!aboutus ? logo_icon_28 : small} className="inline-block sm:hidden" alt="" />
                            <div className="sm:block hidden">
                                <img src={!aboutus?logo_dark:logo_white} className="inline-block dark:hidden h-7" alt="" />
                                <img
                                    style={{
                                        width: 110,
                                        marginTop: isScrolled ? '-20px' : '0', // Conditionally apply marginTop
                                    }}
                                    src={!aboutus?logo_dark:logo_white}
                                    className="hidden dark:inline-block h-77"
                                    alt=""
                                />
                            </div>
                        </Link>

                        <div className="menu-extras">
                            <div className="menu-item" style={{ display: 'flex' }}>
                                {/* First link */}
                                {/* <Link>
                                    <img
                                    src='https://minimal-kit-react.vercel.app/assets/icons/ic_flag_en.svg' style={{
                                    marginTop: isScrolled ? '15px' : '35px',
                                    fontSize: '30px'
                                    }}
                                    />
                                </Link> */}
                                 {/* <Link style={{ marginLeft: '0px' }}>
                                  <ul className="notification-drop" style={{ marginTop: isScrolled ? '5px' : '25px' }}>
                                    <li className="item" onClick={toggleDropdown}>
                                      <img
                                        className='i'
                                        src={`https://minimal-kit-react.vercel.app/assets/icons/ic_flag_en.svg`}
                                        aria-hidden="true"
                                        style={{ fontSize: '30px' }}
                                      />
                                      <ul style={{ display: isOpenn ? 'block' : 'none', padding: '0', listStyle: 'none' }}>
                                        <li style={{ display: 'flex', alignItems: 'center' }} onClick={toggleDropdown}>
                                          <img src="https://minimal-kit-react.vercel.app/assets/icons/ic_flag_en.svg" style={{ marginRight: '10px' }} alt="Icon 3" />
                                          <span style={{ marginLeft: '5px' }}>English</span>
                                        </li>
                                        <li style={{ display: 'flex', alignItems: 'center' }} onClick={toggleDropdown}>
                                          <img src="https://minimal-kit-react.vercel.app/assets/icons/ic_flag_fr.svg" style={{ marginRight: '10px' }} alt="Icon 1" />
                                          <span style={{ marginLeft: '5px' }}>French</span>
                                        </li>
                                        <li style={{ display: 'flex', alignItems: 'center' }} onClick={toggleDropdown}>
                                          <img src="https://minimal-kit-react.vercel.app/assets/icons/ic_flag_de.svg" style={{ marginRight: '10px' }} alt="Icon 2" />
                                          <span style={{ marginLeft: '5px' }}>German</span>
                                        </li>
                                      </ul>
                                    </li>
                                  </ul>
                                </Link> */}
                                
                                {/* Second link */}
                                {!empty(userData)?(

                        <li style={{
                         marginTop: isScrolled ? '10px' : '29px',
                        }} className="dropdown inline-block relative ps-1">
                        <button 
                            onClick={() => openDropdown(!isDropdown)} 
                            style={{ width: '35px', height: '35px', borderRadius: '50%', padding: 0, overflow: 'hidden' }}
                            data-dropdown-toggle="dropdown"
                            className="dropdown-toggle btn btn-icon rounded-full shadow-md"
                            type="button"
                        >
                            <img 
                                className="w-full h-full object-cover" 
                                src={userData?.profile_pic || "https://png.pngtree.com/png-vector/20190509/ourmid/pngtree-blue-passport-visa-ticket-png-image_854287.jpg"}
                                alt={userData?.first_name || 'Profile Picture'} 
                            />
                        </button>

<div className={`dropdown-menu absolute end-0 m-0 mt-4 z-10 w-48 rounded-md
  overflow-hidden bg-white dark:bg-slate-900 shadow dark:shadow-gray-800 
  ${isDropdown ? 'hidden' : 'block'}`} >
    <div className="relative">
        <div className="py-8  inset-0 bg-gradient-to-b from-transparent to-slate-900">

        </div>
        <div className="absolute px-4 -bottom-7 start-0">
          <div className="flex items-center">
            <img
              src={userData?.profile_pic || "https://png.pngtree.com/png-vector/20190509/ourmid/pngtree-blue-passport-visa-ticket-png-image_854287.jpg"}
              style={{
                width: '40px',
                height: '40px',
                borderRadius: '50%',
                objectFit: 'cover',
              }}
              className="shadow dark:shadow-gray-700"
              alt={userData?.first_name || 'Profile Picture'}
            />
            <span className="font-semibold text-[15px] ms-2 text-gray-3001 mt-4">
              {`${userData?.first_name || ''} ${userData?.last_name || ''}`}
            </span>
          </div>
        </div>
    </div>                                     

    <ul style={{paddingTop:'35px'}}>
        {/* <li>
            <Link to="/creator-profile" 
            className="inline-flex items-center text-[14px] font-semibold
             py-1.5 px-4 hover:text-violet-600">
               <LuSettings className="text-[16px] align-middle me-1"/> Settings</Link>
                Profile</Link>
        </li> */}
        <li>
                 <Link to="/creator-profile-edit" className="inline-flex items-center text-[14px] font-semibold py-1.5 px-4 text-gray-3001 ">
                 {/* hover:text-gray-100 */}
                 <AiOutlineUser className="text-[16px] align-middle me-1 "/>
                Profile</Link>
             </li>
       
        <li className="border-t border-gray-100 dark:border-gray-1001 my-2"></li>
        <li onClick={logout}>
            <Link to="/visa" 
            className="inline-flex items-center text-[14px] 
            font-semibold py-1.5 px-4 text-gray-3001 ">
             {/* hover:text-violet-600 */}
             <LiaSignOutAltSolid className="text-[16px] align-middle me-1"/>
              Logout</Link>
        </li>
    </ul>
</div>
</li> 
):(
<Link to="/signup-visa/visa" className="navbar-toggle" id="isToggle" onClick={toggleMenu} style={{ marginLeft: '0px' }}>
 <div className="lines" style={{
     marginTop: isScrolled ? '14px' : '29px',
     fontSize: '30px'
 }}>
     <Icon 
         style={{ color:aboutus ? 'white':'black' }}
         icon={profileBold} />
 </div>
</Link>
)}
                            </div>
                        </div>
                    </div>
                </nav>
            )}

        </>
    )
}
