import React, { useState, useEffect } from 'react';
import Navbar from '../../components/navbar';
import Footer from '../../components/footer';
import VisaListing from '../../pages/index/visa-listing';
import InsuranceListing from './insurance-listing';
import { Link, useParams } from 'react-router-dom';
import {
  LuSearch,
  LuSchool,
  FaLanguage,
  CgClose,
} from "../../assets/icons/vander";
import UniversityListing from './university-listing';
import { Globe } from 'react-feather';
import PrPathwayListing from './pr-pathway-listing';
import ConsultantsListing from './consultants-listing';
import LanguagesListing from './languages-listing';
import CreditsLoanListing from './credits/loan-listing';
import CheckMyVisa from './check-my-visa';
import axios from 'axios';
import { usePlacesWidget } from 'react-google-autocomplete';
import { REST_API_END_POINT, MAP_API_KEY } from '../../constants/DefaultValue';



const Languages = [
  { id: 1, value: 'English', label: 'English' },
  { id: 2, value: 'German', label: 'German' },
  { id: 3, value: 'Dutch', label: 'Dutch' },
  { id: 4, value: 'French', label: 'French' },
  { id: 5, value: 'Danish', label: 'Danish' },
  { id: 6, value: 'Estonian', label: 'Estonian' },
  { id: 7, value: 'Russian', label: 'Russian' },
  { id: 8, value: 'Germany', label: 'Germany' },
  { id: 9, value: 'Hausa', label: 'Hausa' },
  { id: 10, value: 'Spanish', label: 'Spanish' },
  { id: 11, value: 'Portuguese', label: 'Portuguese' },
  { id: 12, value: 'Hindi', label: 'Hindi' },
  { id: 13, value: 'Malayalam', label: 'Malayalam' },
];
const courseData = [
  'Accounting',
  'Accounting & Finance',
  'Accounting and Bookkeeping',
  'Acting and Film-making',
  'Actuarial Science',
  'Actuarial Studies',
  'Administrative Law',
  'Adolescent Health & Wellbeing',
  'Advanced Programming',
  'Advanced Seminar & Shorter Thesis',
  'Advertising',
  'Advertising & Brand Management',
  'Advertising Management',
  'Aeronautical Engineering',
  'Aerospace Engineering',
  'Agri Business Management',
  'Agricultural Engineering',
  'Agricultural Sciences',
  'Agriculture',
  'Air & Space Law',
  'Air Travel Management',
  'Airlines & Airport Management',
  'Airport Management',
  'Alcohol & Other Drugs',
  'Allied Health Assistance',
  'Anaesthesia',
  'Anaesthesia Technology',
  'Analytical Chemistry',
  'Analytics',
  'Ancient History',
  'Animal',
  'Animal Husbandry',
  'Animation',
  'Animation & Gaming',
  'Animation & Visual Effects Course',
  'Animation & Multimedia',
  'Anthropology',
  'Apparel & Fashion',
  'Apparel Merchandising',
  'Appliance Service',
  'Applied Business Analytics',
  'Applied Data Analytics',
  'Arboriculture',
  'Archaeology',
  'Architectural Assistantship',
  'Architectural Design',
  'Architectural Engineering',
  'Architecture',
  'Architecture, Building & Planning',
  'Art Curatorship',
  'Artificial Intelligence',
  'Arts',
  'Arts & Cultural Management',
  'Asian Law',
  'Astrology',
  'Astronomy',
  'Atmospheric Sciences',
  'Audiology & Speech Language Pathology',
  'Automobile Engineering',
  'Automotive & Marine Trimming Technology',
  'Aviation',
  'Aviation Management',
  'Avionics Engineering',
  'Ayurveda',
  'Ayurveda Medical & Surgery',
  'Media Animation & Design',
  'Legislative Law',
  'Bank Management',
  'Banking & Finance',
  'Banking & Finance Law',
  'Banking & Insurance',
  'Beauty Services',
  'Beauty Therapy',
  'Biochemical Engineering',
  'Biochemistry',
  'Bioinformatics',
  'Biological Sciences',
  'Biology',
  'Biomedical & Exercise Science',
  'Biomedical Engineering',
  'Biomedical Science',
  'Biomedicine',
  'BioSciences',
  'Biostatistics',
  'Biotechnology',
  'Block Teaching',
  'Blockchain Technology',
  'Botany',
  'Brand Management',
  'Bricklaying & Blocklaying',
  'Broadcast Journalism',
  'Building & Construction Management',
  'Building Design',
  'Building Surveying',
  'Business',
  'Business Administration',
  'Business Analytics',
  'Business Economics',
  'Business Law',
  'Business Management',
  'Business & Human Resource Management',
  'Business Records & Information Management',
  'Business Studies',
  'Cabinet Making & Timber Technology',
  'Cancer Nursing',
  'Cancer Sciences',
  'Capital Markets',
  'Cardiac Care Technology',
  'Cardiac Technology',
  'Carpentry',
  'Catering & Culinary Arts',
  'Ceramic Engineering',
  'Chemical Engineering',
  'Chemistry',
  'Child & Adolescent Mental Health',
  'Child Health Nursing',
  'Civil Construction',
  'Civil Engineering',
  'Climate Change & Health',
  'Climate Science',
  'Clinical Audiology',
  'Clinical Dentistry',
  'Clinical Education',
  'Clinical Exercise & Rehabilitation',
  'Clinical Leadership',
  'Clinical Optometry',
  'Clinical Pathology',
  'Clinical Psychology',
  'Clinical Rehabilitation',
  'Clinical Research',
  'Clinical Ultrasound',
  'Cloud Computing',
  'Co-operation',
  'Commerce',
  'Commercial Cookery',
  'Commercial Law',
  'Communication Design',
  'Communication Management',
  'Communications Law',
  'Community Development',
  'Community Science',
  'Community Services',
  'Company Law',
  'Computer Application',
  'Computer Engineering',
  'Computer Management',
  'Computer Science',
  'Computerised Accounting',
  'Conservation & Ecosystem Management',
  'Construction Law',
  'Construction Management',
  'Construction Management Honours',
  'Construction Management/Property',
  'Construction Waterproofing',
  'Consultancy Management',
  'Contemporary Art',
  'Contemporary Chinese Studies',
  'Convergent Journalism',
  'Corporate Law',
  'Corporate Social Responsibility',
  'Counselling',
  'Countering Violent Extremism',
  'Creative Arts Therapy',
  'Creative Writing, Publishing & Editing',
  'Creativity & Health',
  'Crime Prevention',
  'Criminal Justice',
  'Criminal Justice & Psychological Studies',
  'Criminal Law',
  'Criminology',
  'Criminology & Psychological Studies',
  'Criminology Forensic Disability',
  'Critical Care Technology',
  'Culinary Arts',
  'Cultural Materials Conservation',
  'Customer Relationship Management',
  'Cyber Law',
  'Cyber Security',
  'Dairy Management',
  'Dairy Technology',
  'Dance',
  'Data Analytics',
  'Data Analytics for Sport Performance',
  'Data Science',
  'Decision, Risk & Financial Sciences',
  'Dental Assisting',
  'Dental Hygienist',
  'Dental Public Health',
  'Dental Science',
  'Dental Surgery',
  'Dental Technology',
  'Dermal Sciences',
  'Design',
  'Development Studies',
  'Dialysis Technician',
  'Dialysis Therapy',
  'Diet & Health',
  'Dietetics',
  'Digital Business Skills',
  'Digital Construction Management',
  'Digital Content Creation',
  'Digital Infrastructure Engineering',
  'Digital Learning & Teaching',
  'Digital Marketing',
  'Digital Media',
  'Diplomate of National Board',
  'Disability Support',
  'Disaster & Terror Medicine',
  'Disaster Management',
  'Dispute Resolution',
  'E-Commerce',
  'Early Childhood Education',
  'Early Childhood Education & Care',
  'Early Childhood Education & Leadership',
  'Early Childhood Research',
  'Early Childhood Teaching',
  'Earth Sciences',
  'Econometrics',
  'Economics',
  'Ecosystem Management & Conservation',
  'Education',
  'Education in Evidence-Based Teaching',
  'Education Management',
  'Education Primary',
  'Education Research',
  'Education Studies',
  'Electrical',
  'Electrical Engineering',
  'Electrical Fitting',
  'Electronics',
  'Electronics & Communication Engineering',
  'Elementary Education',
  'Emergency Health Care',
  'Emerging Technologies',
  'Employment & Labour Relations Law',
  'Empowering Health in Aboriginal Communities',
  'Energy & Environment',
  'Energy & Resources Law',
  'Energy Systems',
  'Engineering',
  'Engineering Fundamentals',
  'Engineering Management',
  'Engineering Structures',
  'English',
  'Enterprise & Resource Planning Systems',
  'Enterprise Management',
  'Enterprise Resource Planning',
  'Entrepreneurship',
  'Environment',
  'Environmental Design',
  'Environmental Engineering',
  'Environmental Law',
  'Environmental Management',
  'Environmental Science',
  'Environmental Sciences',
  'Environmental Systems Engineering',
  'Environmental Technology',
  'Epidemiology',
  'Evaluation',
  'Event Management',
  'Exercise Clinical Practice',
  'Exercise Sport Practice',
  'Fabrication Trade',
  'Fashion & Apparel',
  'Fashion Communication',
  'Fashion Design',
  'Fashion Management',
  'Fashion Technology',
  'Finance',
  'Finance & Control',
  'Finance & Accounting',
  'Finance & Mortgage Broking',
  'Financial Management',
  'Financial Markets',
  'Financial Planning',
  'Financial Technology',
  'Fine Arts',
  'Fine Arts & Music',
  'Fintech',
  'Fisheries Science',
  'Fitness',
  'Flooring Technology',
  'Floristry',
  'Food & Packaging Innovation',
  'Food Processing Technology',
  'Food Science',
  'Food Technology',
  'Foreign Trade',
  'Foreign Trade Management',
  'Forensic Science',
  'Forestry',
  'French',
  'Front End Web Development',
  'Full Stack Development',
  'Furniture & Manufacturing',
  'Game & Animation',
  'Game Art & Design',
  'Gaming Development',
  'Garden Design',
  'Genetic Counselling',
  'Genetic Engineering',
  'Genetics',
  'Genomics & Health',
  'Geo Technical Engineering',
  'Geography',
  'Geoinformatics',
  'Geology',
  'Geoscience',
  'Gerontology',
  'Glass & Glazing',
  'Global Competition & Consumer Law',
  'Global Financial Operations',
  'Global Media Communication',
  'Global Public Health',
  'Government Law',
  'Graphic Design',
  'Gynaecology & Obstetrics',
  'Hairdressing',
  'Health Administration',
  'Health & Human Services',
  'Health & Medical Law',
  'Health Inspector',
  'Health Science',
  'Health Services Assistance',
  'Healthcare Management',
  'Hearing, Language & Speech',
  'Hindi',
  'History',
  'Home Science',
  'Homeopathic Medicine & Surgery',
  'Honours Biomedical Sciences',
  'Horticulture',
  'Hospital Administration',
  'Hospital & Health Management',
  'Hospital Management',
  'Hospitality',
  'Hospitality & Hotel Administration',
  'Hospitality & Hotel Management',
  'Hospitality Management',
  'Hotel & Tourism Management',
  'Hotel Management',
  'Hotel Management & Catering Technology',
  'Human Nutrition',
  'Human Resource Management',
  'Human Resources',
  'Human Rights Law',
  'Hydrology',
  'Immunology',
  'Implementation Science',
  'Indigenous Knowledge',
  'Individual Support Ageing',
  'Individual Support Ageing & Disability',
  'Industrial Automation & Control',
  'Industrial Designing',
  'Industrial Engineering',
  'Industrial Management',
  'Industrial Research Chemistry',
  'Information Management',
  'Information Professional',
  'Information System Management',
  'Information Systems',
  'Information Technology',
  'Infrastructure Management',
  'Instructional Leadership',
  'Instrumentation Engineering',
  'Insurance & Investment Management',
  'Intellectual Property Law',
  'Interior Decoration',
  'Interior Designing',
  'International Business',
  'International Business & Finance',
  'International Community Development',
  'International Economic Law',
  'International Journalism',
  'International Law',
  'International Relations',
  'International Tax',
  'Interpersonal & Organisational Skills',
  'Investment Banking',
  'Investment Management',
  'Jewellery Design',
  'Journalism',
  'Journalism & Mass Communication',
  'Justice',
  'Kannada',
  'Kitchen Management',
  'Knitwear Design',
  'Laboratory Skills',
  'Laboratory Techniques',
  'Labour Law',
  'Landscape Architecture',
  'Landscape Construction',
  'Landscape Design',
  'Language & Cultural Literacy',
  'Laser Safety',
  'Law',
  'Law & Development',
  'Leadership & Management',
  'Leadership for Development',
  'Learning Intervention',
  'Legal Leadership',
  'Legal Practice',
  'Legal Services',
  'Leisure & Health',
  'Library',
  'Library Science',
  'Life Sciences',
  'Linguistics',
  'Logistics & Supply Chain Management',
  'Malayalam',
  'Management',
  'Management in Marketing',
  'Management Studies',
  'Marine Biology',
  'Marine Engineering',
  'Marketing',
  'Marketing Communications',
  'Mass Communication',
  'Mass Media',
  'Material & Metallurgical Engineering',
  'Materials Management',
  'Maternity Nursing',
  'Mathematics',
  'Mechanical Engineering',
  'Mechanical Trade',
  'Mechatronics Engineering',
  'Media & Mass Communication',
  'Media Management',
  'Medical Imaging Technology',
  'Medical Laboratory Technology',
  'Medical Sociology',
  'Medical-Surgical Nursing',
  'Medicine',
  'Medicine & Surgery',
  'Medicine Cardiology',
  'Medicine Gastroenterology',
  'Medicine Neurology',
  'Medicine, Dentistry & Health Sciences',
  'Mental Health',
  'Mental Health Nursing',
  'Metallurgical Engineering',
  'Microbiology',
  'Migration Law',
  'Mining Engineering',
  'Modern Languages Education',
  'Multimedia',
  'Multimedia Communication',
  'Music',
  'Music Composition',
  'Music Ethnomusicology',
  'Music Musicology',
  'Music Opera Performance',
  'Music Orchestral Performance',
  'Music Practical Music',
  'Music Research',
  'Music Therapy',
  'Natural Resource Management',
  'Naturopathy',
  'Naturopathy & Yogic Sciences',
  'Nautical Science',
  'Neonatal Care',
  'Neonatal Intensive Care',
  'Neurophysiology Technology',
  'Non Medical',
  'Non-Emergency Patient Transport',
  'Nuclear Medicine Technology',
  'Nursery Teacher Training',
  'Nursing',
  'Nursing & Midwifery',
  'Nursing Care Assistant',
  'Nursing Practice',
  'Nutrition',
  'Nutritional Science',
  'Occupational Therapy',
  'Oceanography',
  'Oil & Gas Management',
  'Operation Management',
  'Operation Theatre Technology',
  'Ophthalmic Technology',
  'Optometry',
  'Optometry Technology',
  'Oral Health',
  'Organic Chemistry',
  'Osteopathy',
  'Outdoor Education & Environmental Science',
  'Outdoor Leadership',
  'Paediatric Critical Care Nursing',
  'Paediatric Intensive Care Nursing',
  'Paediatric Nursing',
  'Painting & Decorating',
  'Paramedical Technology',
  'Paramedicine',
  'Parks & Gardens',
  'Pathology',
  'Pathology Collection',
  'Patisserie',
  'Performing Arts',
  'Perfusion Technology',
  'Perioperative Medicine',
  'Petroleum Engineering',
  'Pharmaceutical Management',
  'Pharmacy',
  'Pharmacy Clinical Practice & Research',
  'Pharmacy Honours',
  'Pharmacy Industrial Pharmacy',
  'Pharmacy Pharmaceutical Analysis',
  'Pharmacy Pharmaceutical Biotechnology',
  'Pharmacy Pharmaceutics',
  'Pharmacy Pharmacognosy',
  'Pharmacy Pharmacology',
  'Pharmacy Unani',
  'Philosophy',
  'Philosophy Law',
  'Philosophy Nursing',
  'Physical Education',
  'Physical Education & Sport Science',
  'Physical Science',
  'Physician Assistant',
  'Physics',
  'Physiology',
  'Physiotherapy',
  'Physiotherapy Paediatrics',
  'Physiotherapy Pelvic Health',
  'Planetary Health',
  'Planning',
  'Plastic Engineering',
  'Plumbing',
  'Plumbing & Services',
  'Political Science',
  'Population & Global Health',
  'PostTextile Design',
  'Power Engineering',
  'Power Management',
  'Primary Care Nursing',
  'Primary Health Care',
  'Printing',
  'Private Law',
  'Product Design',
  'Production Engineering',
  'Professional Accounting',
  'Professional Psychology',
  'Professional Skills for Scientists',
  'Programming',
  'Project Management',
  'Property',
  'Property Valuation',
  'Prosthetics & Orthotics',
  'Psychiatry',
  'Psychological Sciences',
  'Psychological Studies',
  'Psychological Studies Honours',
  'Psychology',
  'Psychology Advanced',
  'Psychology Honours',
  'Public Administration',
  'Public & International Law',
  'Public Health',
  'Public Policy',
  'Public Policy Management',
  'Publishing & Communications',
  'Publishing & Communications Advanced',
  'Radiography',
  'Radiology',
  'Radiotherapy',
  'Railway Engineering',
  'Real Estate & Urban Infrastructure',
  'Real Estate Management',
  'Remedial Massage',
  'Research',
  'Research Practice for Scientists',
  'Respiratory Therapy',
  'Retail Management',
  'Risk Management',
  'Robotics Engineering',
  'Roof Tiling',
  'Rural Development',
  'Rural Development Management',
  'Rural Health Care',
  'Safety Management',
  'Sales & Marketing',
  'Sales Management',
  'Sanskrit',
  'School Based Education Support',
  'Science',
  'Screen Media',
  'Screenwriting',
  'Sericulture',
  'Service Management',
  'Service Navigation',
  'Sexual Health',
  'Shipping & Logistics',
  'Shopfitting',
  'Social Policy',
  'Social Work',
  'Sociology',
  'Software Engineering',
  'Special Education',
  'Speech Pathology',
  'Sport',
  'Sport & Exercise Science',
  'Sport Business',
  'Sport Integrity',
  'Sport Management',
  'Sport Science',
  'Sports Management',
  'Sports Media',
  'Sports Medicine',
  'Sports Rehabilitation',
  'Sports Turf Management',
  'Statistics',
  'Stonemasonry',
  'Strength & Conditioning',
  'Structural Engineering',
  'Supply Chain & Logistics Management',
  'Supply Chain Management',
  'Surgery',
  'Surgical Anatomy',
  'Surgical Education',
  'Sustainable Business',
  'Sustainable Construction Management',
  'Sustainable Management',
  'Systems Management',
  'Tamil',
  'Tax',
  'Taxation',
  'Teaching Early Childhood',
  'Teaching Primary',
  'Teaching Secondary',
  'Technology',
  'Telecom Management',
  'Telecommunication Engineering',
  'Telugu',
  'Tertiary Preparation',
  'TESOL',
  'Textile',
  'Textile Design',
  'Textile Engineering',
  'Textile Management',
  'Theatre Directing',
  'Theatre Dramaturgy',
  'Theatre Writing',
  'Thermal Engineering',
  'Tourism',
  'Tourism Administration',
  'Tourism & Destination Management',
  'Tourism & Hotel Management',
  'Tourism & Management',
  'Tourism & Travel Management',
  'Tourism Hospitality & Events Management',
  'Tourism Management',
  'Training & Assessment',
  'Translation',
  'Translation & Interpreting',
  'Transport Systems',
  'Travel & Tourism',
  'Unani Medicine & Surgery',
  'University Teaching',
  'Upholstery',
  'Urban & Cultural Heritage',
  'Urban & Rural Planning',
  'Urban Design',
  'Urban Horticulture',
  'Urban Planning',
  'UX/ UI Interaction Course',
  'Veterinary',
  'Veterinary Medicine',
  'Veterinary Professional Leadership & Management',
  'Veterinary Science',
  'Veterinary Surgery Animal Production & Management',
  'Veterinary Surgery Veterinary Surgery & Radiology',
  'Virology',
  'Visual Arts',
  'Visual Communication',
  'Vocation Healthcare Management',
  'Vocational Education',
  'Web Development',
  'Web Development & Programming',
  'Wellbeing Science',
  'Wireless Communication & Network',
  'Work Health & Safety',
  'X-Ray Technology',
  'Yoga',
  'Youth Mental Health',
  'Youth',
  'Youth Work',
  'Youth Work & Criminal Justice',
  'Zoology'
];



const customStyles = {
  control: (styles, { isFocused, isHovered }) => ({
    ...styles,
    backgroundColor: 'transparent',
    marginTop: "-7px",
    color: 'black !important',
    placeholder: {
      color: 'black',
    },
    borderColor: isHovered ? 'white' : 'transparent',
    boxShadow: isHovered ? '0 0 0 2px white' : 'none',
    borderWidth: 0,
    outline: 'none',
    height: '30px',
  }),

  menu: provided => ({
    ...provided,
    marginLeft: '-35px',
    zIndex: 999,
  }),

  option: (styles, { isFocused }) => ({
    ...styles,

    backgroundColor: 'white',
    color: 'black',
    zIndex: isFocused ? 999 : 999,
  }),
  singleValue: (styles) => ({
    ...styles,
    color: 'black',
  }),
  menuPortal: (base) => ({
    ...base,
    zIndex: 9999,
  }),
  dropdownIndicator: (styles) => ({
    ...styles,
    color: 'black',
    '.css-tj5bde-Svg': {
      color: 'black',
    },
  }),
  input: (styles) => ({
    ...styles,
    color: 'black',
  }),
};
const VISA_REQUIREMENT = [
  { id: 0, value: 'ALL' },
  { id: 1, value: 'Visa Required' },
  { id: 2, value: 'Visa On Arrival' },
  { id: 3, value: 'eVisa' },
  { id: 4, value: 'Visa Free' },
  { id: 5, label: 'eTA' },
]
const navItems = [
  // { text: "All", color: "black", id: 0 },
  { text: "Travel", color: "black", id: 1 },
  { text: "Work", color: "black", id: 2 },
  { text: "Student", color: "black", id: 3 },
];
const navItemsIns = [
  { text: "All", color: "black", id: 0 },
  { text: "Travel", color: "black", id: 1 },
  { text: "Work", color: "black", id: 2 },
  { text: "Student", color: "black", id: 3 },
];
// const language=
//   [
//     { id: '0', label: 'All' ,color: "black"},
//     { id: '1', label: 'Arabic' ,color: "black"},
//     { id: '2', label: 'CAE' ,color: "black"},
//     { id: '3', label: 'French',color: "black" },
//     { id: '4', label: 'German',color: "black" },
//     { id: '5', label: 'Hindi' ,color: "black"},
//     { id: '6', label: 'IELTS',color: "black" },
//     { id: '7', label: 'Japanese',color: "black" },
//     { id: '8', label: 'Korean',color: "black" },
//     { id: '9', label: 'Mandarin' ,color: "black"},
//     { id: '10', label: 'NAATI' ,color: "black"},
//     { id: '11', label: 'OET',color: "black" },
//     { id: '12', label: 'PTE',color: "black" },
//     { id: '13', label: 'Russian',color: "black" },
//     { id: '14', label: 'Spanish' ,color: "black"},
//     { id: '15', label: 'TOEFL',color: "black" },
//     { id: '16', label: 'Urdu' ,color: "black"}
//   ]
const language = [
  { id: '0', label: 'All', color: 'black' },
  { id: '1', label: 'Arabic', color: 'black' },
  { id: '2', label: 'French', color: 'black' },
  { id: '3', label: 'German', color: 'black' },
  { id: '4', label: 'Hindi', color: 'black' },
  { id: '5', label: 'Japanese', color: 'black' },
  { id: '6', label: 'Korean', color: 'black' },
  { id: '7', label: 'Mandarin', color: 'black' },
  { id: '8', label: 'Russian', color: 'black' },
  { id: '9', label: 'Spanish', color: 'black' },
  { id: '10', label: 'Urdu', color: 'black' }
];


const visaServices=[
  {id:'0' ,label: 'All Services' ,color: "black"},
  {id:'1' ,label: 'Travel Visa',color: "black" },
  {id:'2' ,label: 'Work Visa',color: "black" },
  {id:'3' ,label: 'Student Visa',color: "black" },
  {id:'4' ,label: 'Family Visa',color: "black"},
  {id:'5' ,label: 'Permanent Residency Visa' ,color: "black"},
  {id:'6' ,label: 'Citizenship',color: "black" },
  {id:'7' ,label: 'Other Visa & Services',color: "black" },
]
const languageData = [
  { id: '0', label: 'All', color: 'black' },
  { id: '1', label: 'IELTS', color: 'black' },
  { id: '2', label: 'PTE', color: 'black' },
  { id: '3', label: 'OET', color: 'black' },
  { id: '4', label: 'TOEFL', color: 'black' },
  { id: '5', label: 'CAE', color: 'black' },
  { id: '6', label: 'NAATI', color: 'black' },
  { id: '7', label: 'Arabic', color: 'black' },
  { id: '8', label: 'French', color: 'black' },
  { id: '9', label: 'German', color: 'black' },
  { id: '10', label: 'Hindi', color: 'black' },
  { id: '11', label: 'Japanese', color: 'black' },
  { id: '12', label: 'Korean', color: 'black' },
  { id: '13', label: 'Mandarin', color: 'black' },
  { id: '14', label: 'Russian', color: 'black' },
  { id: '15', label: 'Spanish', color: 'black' },
  { id: '16', label: 'Urdu', color: 'black' }
];


const creditType = [
  { id: '0', label: 'All', color: 'black' },
  { id: '1', label: 'Personal', color: 'black' },
  { id: '2', label: 'Student', color: 'black' },
  { id: '3', label: 'Vehicle', color: 'black' },
  { id: '4', label: 'Credit card', color: 'black' }
];



// const languageDatas = languageData.sort((a, b) => a.label.localeCompare(b.label));
export default function HomeVisa() {
  const { tab } = useParams();
  const [activeIndex, setIndex] = useState((tab));
  const [typeId, setTypeId] = useState(1)
  const [visaSelect, setVisaSelect] = useState(VISA_REQUIREMENT[0])
  const [searchPlaceholder, setSearchPlaceholder] = useState("Where to?...");
  const [countryDetails, setCountryDetails] = useState({})
  const [countryDetailsSearch, setCountryDetailsSearch] = useState({})
  const [insSearch, setInsSearch] = useState(false)
  const [selectedData, setSelectedData] = useState({})
  const [courses, setCourses] = useState([])
  const [universitySearch, setUniversitySearch] = useState(true)
  const [CountrySelected, setCountrySelected] = useState(true);
  const [courseInput, setCourseInput] = useState('')
  const [SearchInputValue, setSearchInputValue] = useState('');
  const [SearchInputValueCountry, setSearchInputValueCountry] = useState('');
  const [SearchInputValueCourse, setSearchInputValueCourse] = useState('');
  const [searchOptions, setSearchOptions] = useState([]);
  const [searchOptionsCountry, setSearchOptionsCountry] = useState([]);
  const [searchOptionsCourse, setSearchOptionsCourse] = useState([]);
  const [searchOptionsCourses, setSearchOptionsCourses] = useState([]);
  const [isDropdown, openDropdown] = useState(true); 
  const [showNoSearchOptionsMessage, setShowNoSearchOptionsMessage] = useState(false);
  const [showNoSearchOptionsMessages, setShowNoSearchOptionsMessages] = useState(false);
  const [showNoSearchOptionsMessagess, setShowNoSearchOptionsMessagess] = useState(false);
  const [SearchInputServiceValue, setSearchServiceInputValue] = useState('');
  // const [searchServiceOptions, setSearchServiceOptions] = useState([]);
  const [showNoSearchServiceOptionsMessage, setShowNoSearchServiceOptionsMessage] = useState(false);
  const [SearchInputLanguageValue, setSearchLanguageInputValue] = useState('');
  const [searchLanguageOptions, setSearchLanguageOptions] = useState([]);
  const [showNoSearchLanguageOptionsMessage, setShowNoSearchLanguageOptionsMessage] = useState(false);
  const [SearchInputLanguageConsultValue, setSearchLanguageConsultInputValue] = useState('');
  const [searchLanguageConsultOptions, setSearchLanguageConsultOptions] = useState([]);
  const [showNoSearchLanguageConsultOptionsMessage, setShowNoSearchLanguageConsultOptionsMessage] = useState(false);
  const [refresh, setRefresh] = useState(false)
  const [activIndex, setActivIndex] = useState(0);
  const [activIndexConsultant, setActivIndexConsultant] = useState(0);
  const [activIndexConsultantService, setActivIndexConsultantService] = useState(0);
  const [activIndexLanguage, setActivIndexLanguages] = useState(0);
  const [selectedItem, setSelectedItem] = useState(activIndex);
  const [activeTab, setActiveTab] = useState(0);
  const [insTypeId, setInsTypeId] = useState(0)
  const [consLangId, setConsLangId] = useState(0)
  const [consSerId, setConsSerId] = useState(0)
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownOpenLang, setDropdownOpenLang] = useState(false);
  const [dropdownOpenservice, setDropdownOpenservice] = useState(false);
  const [address, setAddress] = useState({
    address: '',
    city: '',
    state: '',
    zip_code: '',
    search_google_enabled: false,
  });

  const [propertyData, setPropertyData] = useState({
    lot_size: '',
    square_feet: '',
    year_built: '',
    bedrooms: '',
    bathrooms: '',
    heating: '',
    cooling: '',
    parking: '',
  });
  const [locationData, setLocationData] = useState({
    address: '',
    city: '',
    state: '',
    zip_code: '',
    country: '',
    district: '',
    latitude: '',
    longitude: '',
  });
console.log("locationDatalocationData",locationData);

  const { ref: materialRef } = usePlacesWidget({
    apiKey: MAP_API_KEY, 
    onPlaceSelected: (res) => {
      if (res.address_components) {
        const address_1 = res.address_components.find((comp) => comp.types.includes('street_number'))?.long_name || '';
        const address_2 = res.address_components.find((comp) => comp.types.includes('route'))?.long_name || '';
        const city = res.address_components.find((comp) => comp.types.includes('locality'))?.long_name || '';
        const state = res.address_components.find((comp) => comp.types.includes('administrative_area_level_1'))?.short_name || '';
        const zip_code = res.address_components.find((comp) => comp.types.includes('postal_code'))?.long_name || '';
        const country = res.address_components.find((comp) => comp.types.includes('country'))?.long_name || '';
        const district = res.address_components.find((comp) => comp.types.includes('administrative_area_level_2'))?.long_name || '';
  
        const latitude = res.geometry.location.lat();
        const longitude = res.geometry.location.lng();
  
  
        // Set these fields in Formik
        // setFieldValue('address', `${address_1} ${address_2}`.trim());
        // setFieldValue('city', city);
        // setFieldValue('state', state);
        // setFieldValue('zip_code', zip_code);
        // setFieldValue('country', country);
        // setFieldValue('district', district);
        
        // setFieldValue('latitude', latitude);
        // setFieldValue('longitude', longitude);
  
        // Optionally, set the full location string if required
        // if(address_1 || address_2){
        //   setFieldValue('location', `${address_1} ${address_2}, ${city}, ${state}, ${country}`);
        // }else{
        //   setFieldValue('location', ` ${city}, ${state}, ${country}`);
        // }
  
        const locationParts = [
          `${address_1} ${address_2}`.trim(),
          city,
          state,
          country
        ].filter(Boolean); // This filters out any empty values
  
        const location = locationParts.join(', '); // Join the remaining non-empty parts with a comma and space
  // setSearchOptionsCountry(location)
        // Set the full location string in Formik
        // setFieldValue('location', location);
        setLocationData({
          address: `${address_1} ${address_2}`.trim(),
          city,
          state,
          zip_code,
          country,
          district,
          latitude,
          longitude,
          location,
        });
      }
    },
    options: {
      types: ['geocode'],
    },
    
  });

  const handleFetch = (address, zip_code) => {
    const api = MAP_API_KEY;
    axios
      .get(api)
      .then((result) => {
        if (
          result?.data?.bundle.length &&
          result.data.bundle[0].address.zip &&
          result.data.bundle[0].address.house
        ) {
          axios
            .get(api)
            .then((data) => {
              if (
                data &&
                Object.keys(data).length === 0 &&
                Object.getPrototypeOf(data) === Object.prototype
              ) {
                console.log('Appointment Data Outside failed');
              } else if (
                data?.data?.bundle.length ||
                data.data.bundle[0]?.areas.length ||
                data.data.bundle[0]?.building.length ||
                data.data.bundle[0]?.garages.length
              ) {
                setPropertyData({
                  ...propertyData,
                  lot_size: data.data.bundle[0].lotSizeSquareFeet,
                  square_feet: data.data.bundle[0].areas[0]?.areaSquareFeet,
                  year_built: data.data.bundle[0].building[0]?.yearBuilt,
                  bedrooms: data.data.bundle[0].building[0]?.bedrooms,
                  bathrooms: data.data.bundle[0].building[0]?.fullBaths,
                  heating: data.data.bundle[0].building[0]?.heating,
                  cooling: data.data.bundle[0].building[0]?.airConditioning,
                  parking: data.data.bundle[0].garages.length,
                });
              }
            });
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  };

  const { ref: inputRef, ...rest } = usePlacesWidget({
    onPlaceSelected: (res) => {
      if (typeof res.address_components !== 'undefined') {
        const address_components = res.address_components;

        const address_1 = address_components.find(
          (row) => row.types.indexOf('street_number') !== -1
        );
        const address_2 = address_components.find(
          (row) => row.types.indexOf('route') !== -1
        );

        let addressValue = '';
        if (address_1) addressValue = address_1.long_name;
        if (address_2) addressValue += ' ' + address_2.long_name;

        const city = address_components.find(
          (row) => row.types.indexOf('locality') !== -1
        )?.long_name || '';

        const state = address_components.find(
          (row) => row.types.indexOf('administrative_area_level_1') !== -1
        )?.short_name || '';

        const zip_code = address_components.find(
          (row) => row.types.indexOf('postal_code') !== -1
        )?.long_name || '';

        setAddress({
          address: addressValue,
          city: city,
          state: state,
          zip_code: zip_code,
          search_google_enabled: false,
        });

        handleFetch(addressValue, zip_code);
      }
    },
    options: {
      types: ['geocode'],
      //componentRestrictions: { country:['us', 'ca', 'uy'] },
    },
  });

  ////////////////////
  const fetchCountryDetailsByName = (country_name) => {
    axios.get(`${REST_API_END_POINT}country/${country_name}`)
      .then((res) => {
        if (res.data.status === 1) {
          setCountryDetails(res.data.data)
          localStorage.setItem('country_preference', JSON.stringify(res.data.data))
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  useEffect(() => {
    const fetchCountryByIP = async () => {
      try {
        const hasRun = localStorage.getItem('hasRunFetchCountry');
        if (!hasRun) {
        const response = await fetch('https://ipinfo.io/json');
        const data = await response.json();
        const countryName = new Intl.DisplayNames(['en'], { type: 'region' }).of(data.country);
        fetchCountryDetailsByName(countryName)
        localStorage.setItem('hasRunFetchCountry', 'true');
        }
      } catch (error) {
        console.error('Error fetching country:', error);
      }
    };

    fetchCountryByIP();
  }, []);
  useEffect(() => {
    if(SearchInputValue.length>=0 || SearchInputValueCountry.length>=0 ||SearchInputLanguageConsultValue.length>=0||SearchInputValueCourse?.length>=0){
      openDropdown(true)
    }
  },[SearchInputValue,SearchInputValueCountry,SearchInputLanguageConsultValue,SearchInputValueCourse])

  useEffect(() => {

    if (activeIndex === 'check-my-visa') {
      setSearchPlaceholder("Choose Country to Check Your Visa");
    } else if (activeIndex === 'pr-pathway') {
      setSearchPlaceholder("Choose country for your path to permanent residency");
    } else if (activeIndex === 'insurance') {
      setSearchPlaceholder("Choose country you are traveling to");
    } else if (activeIndex === 'universities') {
      setSearchPlaceholder("Search Universities?...");
    } else if (activeIndex === 'credits-loan') {
      setSearchPlaceholder("Discover Credits/Loans For You...");
    } else {
      setSearchPlaceholder("Where to?...");
    }
  }, [activeIndex]);


  const handleRemove = () => {
    setCourses([])
    setCountryDetailsSearch({})
    setCourseInput('')
    setInsSearch(false)
    setRefresh(!refresh)
    setSearchInputValue('')
    setSearchInputValueCountry('')
    setSearchInputValueCourse('')
    setShowNoSearchOptionsMessage(false)
    setShowNoSearchOptionsMessages(false)
    setShowNoSearchOptionsMessagess(false)
    setSearchOptions([])
    setSearchOptionsCountry([])
    setSearchOptionsCourse([])
    setSearchOptionsCourses([])
    setSearchServiceInputValue('')
    // setSearchServiceOptions([])
    setSearchLanguageConsultOptions([])
    setSearchLanguageConsultInputValue('')
    setUniversitySearch(true)
  }
  const handleRemoveConsultant = () => {
    // setCourses([])
    // setCountryDetailsSearch({})
    // setCourseInput('')
    // setInsSearch(false)
    setRefresh(!refresh)
    setSearchInputValue('')
    // setSearchInputValueCountry('')
    setSearchInputValueCourse('')
    setShowNoSearchOptionsMessage(false)
    setShowNoSearchOptionsMessages(false)
    setShowNoSearchOptionsMessagess(false)
    setSearchOptions([])
    // setSearchOptionsCountry([])
    setSearchOptionsCourse([])
    setSearchOptionsCourses([])
    // setSearchServiceInputValue('')
    // setSearchServiceOptions([])
    setSearchLanguageConsultOptions([])
    setSearchLanguageConsultInputValue('')
    setUniversitySearch(true)
  }
  const handleRemoveUniversityCountry = () => {
    setCourses([])
    setCountryDetailsSearch({})
    setCourseInput('')
    setInsSearch(false)
    setRefresh(!refresh)
    setSearchInputValue('')
    setSearchInputValueCountry('')
    setSearchInputValueCourse('')
    setShowNoSearchOptionsMessage(false)
    setShowNoSearchOptionsMessages(false)
    setShowNoSearchOptionsMessagess(false)
    setSearchOptions([])
    setSearchOptionsCountry([])
    setSearchOptionsCourse([])
    setSearchOptionsCourses([])
    setSearchServiceInputValue('')
    // setSearchServiceOptions([])
    setSearchLanguageConsultOptions([])
    setSearchLanguageConsultInputValue('')
    setUniversitySearch(true)
  }
  const handleRemoveUniversity = () => {
    setCourses([])
    // setCountryDetailsSearch({})
    setCourseInput('')
    // setInsSearch(false)
    setRefresh(!refresh)
    setSearchInputValue('')
    // setSearchInputValueCountry('')
    setSearchInputValueCourse('')
    setShowNoSearchOptionsMessage(false)
    setShowNoSearchOptionsMessages(false)
    // setShowNoSearchOptionsMessagess(false)
    setSearchOptions([])
    // setSearchOptionsCountry([])
    setSearchOptionsCourse([])
    setSearchOptionsCourses([])
    setSearchServiceInputValue('')
    // setSearchServiceOptions([])
    setSearchLanguageConsultOptions([])
    setSearchLanguageConsultInputValue('')
    setUniversitySearch(true)
  }
  useEffect(() => {
    setSearchInputValue('')
    setSearchInputValueCountry('')
    setSearchInputValueCourse('')
    setSelectedData({})
    setCountryDetailsSearch({})
    setShowNoSearchOptionsMessage(false)
    setShowNoSearchOptionsMessages(false)
    setShowNoSearchOptionsMessagess(false)
    setSearchOptions([])
    setSearchOptionsCountry([])
    setSearchOptionsCourse([])
    setSearchOptionsCourses([])
  }, [activeIndex])

  const handleItemClick = (index, type_id) => {
    setActiveTab(index);
    setSearchInputValue('')
  };
  const handleItemClickTab = (index, type_id) => {
    setInsTypeId(type_id)
    setActivIndex(index);
    // setSearchInputValue('')
  };
  const handleItemClickTabLanguages = (index, type_id) => {
    if (index >= 0 && index < languageData.length) {
      // setConsLangId(type_id);
      let typeID=+type_id
      console.log("type_idtype_idtype_id", index);
      console.log("type_idtype_idtype_id", typeID);

      
      setActivIndexLanguages(typeID);
      // Other logic related to selecting a language
    } else {
      console.error('Invalid index:', index);
    }
  };
  const handleItemClickTabConsultant = (index, type_id) => {
    if (index >= 0 && index < language.length) {
      setConsLangId(type_id);
      setActivIndexConsultant(index);
      // Other logic related to selecting a language
    } else {
      console.error('Invalid index:', index);
    }
  };
  const handleItemClickTabConsultantService = (index, type_id) => {
    if (index >= 0 && index < visaServices.length) {
      setConsSerId(type_id);
      setActivIndexConsultantService(index);
      // Other logic related to selecting a language
    } else {
      console.error('Invalid index:', index);
    }
  };
  console.log("countryDetailscountryDetails1",countryDetailsSearch); 
  console.log("countryDetailscountryDetails1",countryDetails); 
  const handleFindCourse = async (e) => {
    const value = e.target.value;
    setSearchInputValueCourse(value);
    if (value.trim() !== '') {
      let response = []
      //  response = await axios.post(`${REST_API_END_POINT}university/get-all-list`,{search:value})
      response = await axios.post(`${REST_API_END_POINT}university/get-all-course-with-university`, { search: value })
      if (response.data.status === 1) {
        console.log("response.data?.data",response.data?.data);
        
        // setSearchOptionsCourse([])
        // let filteredData=response.data?.data
        let filteredData=[]
        console.log("countryDetailscountryDetails1",countryDetailsSearch);
        if(insSearch){
           filteredData = response?.data?.data
        .filter(d => d.status === 1 )
        .filter(d => d.location?.toLowerCase().includes(countryDetailsSearch?.name?.toLowerCase()));
        console.log('qqqqqqqqqqqqqqqqqqq',filteredData);
        
      }
        else{
          console.log("countryDetailscountryDetails",countryDetails);
          
           filteredData = response?.data?.data
          .filter(d => d.status === 1 )
          .filter(d => d.location?.toLowerCase().includes(countryDetails.name.toLowerCase()));
          console.log('qqqqqqqqqqqqqqqqqqq',filteredData);
        }
        setShowNoSearchOptionsMessages(filteredData.length === 0);
   

        
        const filteredOptions = filteredData.filter(data => {
          const name = data.name ? data.name.toLowerCase() : "";
          const courseName = data.course_name ? data.course_name.toLowerCase() : "";
          return name.includes(value.toLowerCase()) || courseName.includes(value.toLowerCase());
        });

      //   const filteredOptions = filteredData.filter(data => {
      //     const name = data.name && data.university_name
      //         ? `${data.name.toLowerCase()} (${data.university_name.toLowerCase()})`
      //         : ""; // Handle undefined values
      
      //     const courseName = data.course_name && data.university_name
      //         ? `${data.course_name.toLowerCase()} (${data.university_name.toLowerCase()})`
      //         : ""; // Handle undefined values
      
      //     // Check if either name or courseName includes the search value
      //     return name.includes(value.toLowerCase()) || courseName.includes(value.toLowerCase());
      // });
        const sortedOptions = filteredOptions.sort((a, b) => {
          const nameA = a.name ? a.name.toLowerCase() : "";
          const nameB = b.name ? b.name.toLowerCase() : "";
          return nameA.localeCompare(nameB);
        });
        console.log("sortedOptions===========",sortedOptions);
        // const courseDataLowercase = courseData.map(course => course.toLowerCase());
        // const filteredSortedOptions = sortedOptions.filter(option => 
        //   courseDataLowercase.includes(option.course_name.toLowerCase())
        // );

        const courseDataLowercase = courseData.map(course => course.toLowerCase());

        // Create a Set to keep track of which courses we have already added from courseData
        const selectedCourses = new Set();
        
        // Filter courseData based on whether the course_name exists in sortedOptions
        const filteredCourseData = courseData
        .filter(course => {
          const courseLowerCase = course.toLowerCase();
          const matchExists = sortedOptions.some(option => 
            option.course_name.toLowerCase() === courseLowerCase
          );
          if (matchExists && !selectedCourses.has(courseLowerCase)) {
            selectedCourses.add(courseLowerCase); 
          }
        
          return false;
        });
        
        console.log("Filtered courseData based on sortedOptions:", filteredCourseData);
        const filteredCourseDatas = courseData.filter(course =>
          course.toLowerCase().includes(value.toLowerCase())
        );
  
        console.log("Filtered Course Data:", filteredCourseDatas);

        // setSearchOptionsCourse(sortedOptions);
        setSearchOptionsCourse(filteredCourseDatas);
        setSearchOptionsCourses(sortedOptions);
        setShowNoSearchOptionsMessages(filteredOptions.length === 0);
      }
    } else {
      setSearchOptionsCourse([]);
      setSearchOptionsCourses([])
      setShowNoSearchOptionsMessages(false);
    }
  }
  // console.log("universitySearch111111111111", universitySearch);
  const handleSearchInputChange = async (e) => {
    let value = null
    value = e.target.value;
    // console.log("value11111111111", value.length === 0);
    // console.log("universitySearch11111111111", universitySearch);
    // console.log("unive11111111111", value);

    if (SearchInputValue ==='') {
      setUniversitySearch(false)
      setCountrySelected(false)
      
    } else {
      setUniversitySearch(true)
      setCountrySelected(true)
    }
    // console.log("universitySearch111111111111", universitySearch);

    setSearchInputValue(value);
    if (value.trim() !== '') {
      let response = []

      if (activeIndex === 'visa') {
        response = await axios.post(`${REST_API_END_POINT}country/list-for-user`, {
          search: value, c_id: countryDetails.id, typeId, visa_required_type: visaSelect.id
        });
      }
      else if (activeIndex === 'check-my-visa') {
        response = await axios.post(`${REST_API_END_POINT}country-listing`, { search: value });
      }
      else if (activeIndex === 'pr-pathway') {
        response = await axios.post(`${REST_API_END_POINT}pr_pathway/get-all-pr`, { search: value })
      }
      else if (activeIndex === 'insurance') {
        response = await axios.post(`${REST_API_END_POINT}insurance/get-insurance-company-search`, { search: value })
        
      }
      else if (activeIndex === 'universities') {
        response = await axios.post(`${REST_API_END_POINT}university/get-all-list`, { search: value })
        //  response = await axios.post(`${REST_API_END_POINT}university/get-all-course-with-university`,{search:value})

      }
      else if (activeIndex === 'credits-loan') {
        response = await axios.post(`${REST_API_END_POINT}bank/search-name`, { search: value })
        // response = await axios.get(`${REST_API_END_POINT}country-list/user-navbar/${value}`)
      } else if (activeIndex === 'consultants') {
        response = await axios.post(`${REST_API_END_POINT}consultant/get-all-consultant`, { search: value })
      }
      if (response.data.status === 1) {
        if (activeIndex === 'insurance') {
          // setSearchOptions(response.data?.data);
          // const filteredData=response.data?.data
          const filteredData=response.data?.data?.data

          // if (insTypeId === 1) {
          //   // Travel
          //   filteredData = filteredData.filter(d => d.insurance_type?.split(',').includes('1'));
          // } else if (insTypeId === 2) {
          //   // Work
          //   filteredData = filteredData.filter(d => d.insurance_type?.split(',').includes('2'));
          // } else if (insTypeId === 3) {
          //   // Student 
          //   filteredData = filteredData.filter(d => d.insurance_type?.split(',').includes('3'));
          // }
  
          // Log the final filtered data
          console.log("Filtered data:-----------------", filteredData);

          setSearchOptions(filteredData);
          // setSearchOptions(response.data?.data);
          setShowNoSearchOptionsMessage(filteredData.length === 0);
        }
        
        else if (activeIndex === 'credits-loan') {
          console.log("Filtered data:-----------------",response.data?.data);
          let filteredData=[]
          if(insSearch){
             filteredData = response?.data?.data
          .filter(d => d.status === 1 )
          .filter(d => d.location?.toLowerCase().includes(countryDetailsSearch.name.toLowerCase()));
          // setSearchOptions(filteredData)
        }
          else{
             filteredData = response?.data?.data
            .filter(d => d.status === 1 )
            .filter(d => d.location?.toLowerCase().includes(countryDetails.name.toLowerCase()));
            // setSearchOptions(filteredData)
          }
          setShowNoSearchOptionsMessage(filteredData.length === 0);


          const filteredOptions = filteredData.filter(data => {
            const name = data.name ? data.name.toLowerCase() : "";
            const courseName = data.course_name ? data.course_name.toLowerCase() : "";
            return name.includes(value.toLowerCase()) || courseName.includes(value.toLowerCase());
          });
          const sortedOptions = filteredOptions.sort((a, b) => {
            const nameA = a.name ? a.name.toLowerCase() : "";
            const nameB = b.name ? b.name.toLowerCase() : "";
            return nameA.localeCompare(nameB);
          });
          setSearchOptions(sortedOptions);
          setShowNoSearchOptionsMessage(filteredOptions.length === 0);


        }
        else if (activeIndex === 'consultants') {
          console.log("Filtered data:-----------------",response.data?.data);
          let filteredData=[]
          if(insSearch){
             filteredData = response?.data?.data
          .filter(d => d.status === 1 )
          .filter(d => d.location?.toLowerCase().includes(countryDetailsSearch.name.toLowerCase()));
          // setSearchOptions(filteredData)
        }
          else{
             filteredData = response?.data?.data
            .filter(d => d.status === 1 )
            .filter(d => d.location?.toLowerCase().includes(countryDetails.name.toLowerCase()));
            // setSearchOptions(filteredData)
          }
          setShowNoSearchOptionsMessage(filteredData.length === 0);


          const filteredOptions = filteredData.filter(data => {
            const name = data.name ? data.name.toLowerCase() : "";
            const courseName = data.course_name ? data.course_name.toLowerCase() : "";
            return name.includes(value.toLowerCase()) || courseName.includes(value.toLowerCase());
          });
          const sortedOptions = filteredOptions.sort((a, b) => {
            const nameA = a.name ? a.name.toLowerCase() : "";
            const nameB = b.name ? b.name.toLowerCase() : "";
            return nameA.localeCompare(nameB);
          });
          setSearchOptions(sortedOptions);
          setShowNoSearchOptionsMessage(filteredOptions.length === 0);


        }
        else if (activeIndex === 'universities') {
          console.log("Filtered data:-----------------",response.data?.data);
          let filteredData=[]
          if(insSearch){
             filteredData = response?.data?.data
          .filter(d => d.status === 1 )
          .filter(d => d.location?.toLowerCase().includes(countryDetailsSearch?.name?.toLowerCase()));
          setSearchOptions(filteredData)
        }
          else{
             filteredData = response?.data?.data
            .filter(d => d.status === 1 )
            .filter(d => d.location?.toLowerCase().includes(countryDetails?.name?.toLowerCase()));
            setSearchOptions(filteredData)
          }
          setShowNoSearchOptionsMessage(filteredData.length === 0);
        }
        
        else {
console.log("response.data?.dataresponse.data?.data",response.data?.data);


          const filteredOptions = response.data?.data?.filter(data => {
           
            
            const name = data.name ? data.name.toLowerCase() : "";
            const courseName = data.course_name ? data.course_name.toLowerCase() : "";
            return name.includes(value.toLowerCase()) || courseName.includes(value.toLowerCase());
          });
          const sortedOptions = filteredOptions.sort((a, b) => {
            const nameA = a.name ? a.name.toLowerCase() : "";
            const nameB = b.name ? b.name.toLowerCase() : "";
            return nameA.localeCompare(nameB);
          });
          setSearchOptions(sortedOptions);
          setShowNoSearchOptionsMessage(filteredOptions.length === 0);
          // console.log("filteredOptions.length === 0====================",filteredOptions.length);
          if (filteredOptions.length === 0) {
            setUniversitySearch(true);

          }
        }

      }
      
    } else {
      setSearchOptions([]);
      setShowNoSearchOptionsMessage(false);
    }
  };
  const handleSearchInputChangeCountry = async (e) => {
    let value = null
    value = e.target.value;
    if (SearchInputValueCountry ==='') {
      setUniversitySearch(false)
      
    } else {
      setUniversitySearch(true)
    }

    setSearchInputValueCountry(value);
  

       if (activeIndex === 'universities') {
      let  response = await axios.get(`${REST_API_END_POINT}country-list/user-navbar/${value}`)
      
      if (response.data.status === 1) {
        
          const filteredData=response.data.data
          console.log("Filtered data:-----------------", filteredData);

          setSearchOptionsCountry(filteredData);
          setShowNoSearchOptionsMessagess(filteredData.length === 0);
          console.log("Filtered data:-----------------1", filteredData.length === 0);
        } else {
          setSearchOptionsCountry([]);
          console.log("Filtered data:-----------------12", response.data.length === 0);
          setShowNoSearchOptionsMessagess(response.data.status===0);
        }
      
    }
     else if (activeIndex === 'credits-loan') {
      let  response = await axios.get(`${REST_API_END_POINT}country-list/user-navbar/${value}`)
      
      if (response.data.status === 1) {
        
          const filteredData=response.data.data
          console.log("Filtered data:-----------------", filteredData);

          setSearchOptionsCountry(filteredData);
          setShowNoSearchOptionsMessagess(filteredData.length === 0);
          console.log("Filtered data:-----------------1", filteredData.length === 0);
        } else {
          setSearchOptionsCountry([]);
          console.log("Filtered data:-----------------12", response.data.length === 0);
          setShowNoSearchOptionsMessagess(response.data.status===0);
        }
      
    }
    else if (activeIndex === 'consultants') {
      let  response = await axios.get(`${REST_API_END_POINT}country-list/user-navbar/${value}`)
      
      if (response.data.status === 1) {
        
          const filteredData=response.data.data
          console.log("Filtered data:-----------------", filteredData);

          setSearchOptionsCountry(filteredData);
          setShowNoSearchOptionsMessagess(filteredData.length === 0);
          console.log("Filtered data:-----------------1", filteredData.length === 0);
        } else {
          setSearchOptionsCountry([]);
          console.log("Filtered data:-----------------12", response.data.length === 0);
          setShowNoSearchOptionsMessagess(response.data.status===0);
        }
      
    }
    else if (activeIndex === 'language-consultants') {
      // let  response = await axios.get(`${REST_API_END_POINT}language/location-list${value}`)
      let  response = await axios.get(`${REST_API_END_POINT}country-list/user-navbar/${value}`)
      if (response.data.status === 1) {
        
          const filteredData=response.data.data
          console.log("Filtered data:-----------------", filteredData);

          setSearchOptionsCountry(filteredData);
          setShowNoSearchOptionsMessagess(filteredData.length === 0);
          console.log("Filtered data:-----------------1", filteredData.length === 0);
        } else {
          setSearchOptionsCountry([]);
          console.log("Filtered data:-----------------12", response.data.length === 0);
          setShowNoSearchOptionsMessagess(response.data.status===0);
        }
      
    }
     else {
      setSearchOptionsCountry([]);
      setShowNoSearchOptionsMessagess(false);
    }
  }
  

  const handleSearchOptionClick = (option) => {
    if (activeIndex === 'credits-loan') {
      let name = option.loan_name ? `${option.loan_name} (${option.name})` : option.name;
      setSearchInputValue(name)
      
    } else {
      setSearchInputValue(option.name);
    }
    if (activeIndex === 'universities') {
      axios.post(`${REST_API_END_POINT}university/get-all-course`, { u_id: option?.id, getUniverity: true })
        .then((res) => {
          if (res.data.status === 1) {
            

            setCourses(res.data.data)
            setSearchOptions([]);
            setShowNoSearchOptionsMessage(false);
            setSelectedData(option)
          }
        })
    }
     else {
      setSelectedData(option)
      setSearchOptions([]);
      setShowNoSearchOptionsMessage(false);
    }
    if (activeIndex === 'insurance') {
      if (activeIndex === 'insurance') {
          // setCountryDetails(option)
      setInsSearch(true)
      setCountryDetailsSearch(option)
    } 
    else {
      setCountryDetailsSearch({})
      setInsSearch(false)
      setSelectedData(option)
      setSearchOptions([]);
      setShowNoSearchOptionsMessage(false);
    }
  }

  };
  const handleSearchOptionClickCourse = (option) => {
    
    setSearchInputValueCourse(option)
    // navigate(`/university-details/${option.course_id}/${activeIndex}`)
   
    
    // return
    
    // if (activeIndex === 'universities') {
    //   axios.post(`${REST_API_END_POINT}university/get-all-university-with-course`, { id: option?.university_id, name: option?.name })
    //     .then((res) => {
    //       if (res.data.status == 1) {
    //         setCourses(res.data.data)
    //         setUniversities(res.data.university)
    //         setSearchOptionsCourse([])
    //         setShowNoSearchOptionsMessage(false);
    //         setSelectedData(res.data.university)
    //       }
    //     })
    // } else {
    //   // setSelectedData(option)

    //   setSearchOptions([]);
    //   setShowNoSearchOptionsMessage(false);
    // }
    if (activeIndex === 'universities') {
      let value=option
    axios.post(`${REST_API_END_POINT}university/get-all-course-with-university`, { search: value })
    .then((response) => {
    if (response.data.status === 1) {
      console.log("response.data?.data",response.data?.data);
      // setSearchOptionsCourse([])
      // setSearchOptionsCourse([])
      // let filteredData=response.data?.data
      let filteredData=[]
      console.log("countryDetailscountryDetails1",countryDetailsSearch);
      if(insSearch){
         filteredData = response?.data?.data
      .filter(d => d.status === 1 )
      .filter(d => d.location?.toLowerCase().includes(countryDetailsSearch?.name?.toLowerCase()));
      console.log('qqqqqqqqqqqqqqqqqqq',filteredData);
      
    }
      else{
        console.log("countryDetailscountryDetails",countryDetails);
        
         filteredData = response?.data?.data
        .filter(d => d.status === 1 )
        .filter(d => d.location?.toLowerCase().includes(countryDetails.name.toLowerCase()));
        console.log('qqqqqqqqqqqqqqqqqqq',filteredData);
      }
      setShowNoSearchOptionsMessages(filteredData.length === 0);
 

      
      const filteredOptions = filteredData.filter(data => {
        const name = data.name ? data.name.toLowerCase() : "";
        const courseName = data.course_name ? data.course_name.toLowerCase() : "";
        return name.includes(value.toLowerCase()) || courseName.includes(value.toLowerCase());
      })

      const sortedOptions = filteredOptions.sort((a, b) => {
        const nameA = a.name ? a.name.toLowerCase() : "";
        const nameB = b.name ? b.name.toLowerCase() : "";
        return nameA.localeCompare(nameB);
      });
      console.log("sortedOptions===========",sortedOptions);
      setSearchOptionsCourse([])
      setSearchOptionsCourses(sortedOptions);
      setShowNoSearchOptionsMessages(filteredOptions.length === 0);
    }
  })} else {
    setSearchOptionsCourse([]);
    setSearchOptionsCourses([]);
    setShowNoSearchOptionsMessages(false);
  }
  };

  // console.log("selectedData", selectedData);
  const handleCourseClick = (option) => {
    setCourseInput(option.course_name)
    // setSelectedData(option)

    if (activeIndex === 'universities') {
      let value=option?.course_name
    axios.post(`${REST_API_END_POINT}university/get-all-course-with-university`, { search: value })
    .then((response) => {
    if (response.data.status === 1) {
      console.log("response.data?.data",response.data?.data);
      // setSearchOptionsCourse([])
      // setSearchOptionsCourse([])
      // let filteredData=response.data?.data
      let filteredData=[]
      console.log("countryDetailscountryDetails1",countryDetailsSearch);
      if(insSearch){
         filteredData = response?.data?.data
      .filter(d => d.status === 1 )
      .filter(d => d.location?.toLowerCase().includes(countryDetailsSearch?.name?.toLowerCase()));
      console.log('qqqqqqqqqqqqqqqqqqq',filteredData);
      
    }
      else{
        console.log("countryDetailscountryDetails",countryDetails);
        
         filteredData = response?.data?.data
        .filter(d => d.status === 1 )
        .filter(d => d.location?.toLowerCase().includes(countryDetails.name.toLowerCase()));
        console.log('qqqqqqqqqqqqqqqqqqq',filteredData);
      }
      setShowNoSearchOptionsMessages(filteredData.length === 0);
 

      
      const filteredOptions = filteredData.filter(data => {
        const name = data.name ? data.name.toLowerCase() : "";
        const courseName = data.course_name ? data.course_name.toLowerCase() : "";
        return name.includes(value.toLowerCase()) || courseName.includes(value.toLowerCase());
      })

      const sortedOptions = filteredOptions.sort((a, b) => {
        const nameA = a.name ? a.name.toLowerCase() : "";
        const nameB = b.name ? b.name.toLowerCase() : "";
        return nameA.localeCompare(nameB);
      });
      console.log("sortedOptions===========",sortedOptions);
      setSearchOptionsCourse([])
      setSearchOptionsCourses(sortedOptions);
      setShowNoSearchServiceOptionsMessage(filteredOptions.length === 0);
    }
  })} else {
    setSearchOptionsCourse([]);
    setSearchOptionsCourses([]);
    // setShowNoSearchServiceOptionsMessage(false);
  }




    setCourses([])
    setShowNoSearchServiceOptionsMessage(false)
  }
  const handleSearchOptionClickCountry = (option) => {
    setSearchInputValueCountry(option.name)
    setSearchOptionsCountry([])
    setCountryDetailsSearch(option)
    setInsSearch(true)
    setCountrySelected(true)
    setShowNoSearchServiceOptionsMessage(false)
  }



  // const handleServiceSearchInputChange = (e) => {
  //     const value = e.target.value;

  //     if (value.trim() !== '') {
  //        axios.post(`${REST_API_END_POINT}consultant/get-all-consultant`,{search:value})
  //        .then((res)=>{
  //         if(res.data.status===1){
  //           console.log(res.data.data,'-------------@@@@@@@@')
  //         setSearchServiceOptions(res.data?.data);
  //         setShowNoSearchServiceOptionsMessage(res.data?.data?.length === 0); 
  //         }
  //        })
  //     } else {
  //       setSearchServiceOptions([]);
  //         setShowNoSearchServiceOptionsMessage(false); 
  //     }
  // };

  const handleServiceSearchOptionClick = (option) => {
    setSearchInputValue(option.name);
    setSelectedData(option)
    // setSearchServiceOptions([]);
    setShowNoSearchServiceOptionsMessage(false);
    setSearchOptions([]);
    setShowNoSearchOptionsMessage(false);
  };



  const handleLanguageSearchInputChange = (e) => {
    const value = e.target.value;
    setSearchLanguageInputValue(value);

    if (value.trim() !== '') {
      const filteredOptions = Languages.filter(course =>
        course.label.toLowerCase().includes(value.toLowerCase())
      );
      setSearchLanguageOptions(filteredOptions.map(option => option.label));
      setShowNoSearchLanguageOptionsMessage(filteredOptions.length === 0);
    } else {
      setSearchLanguageOptions([]);
      setShowNoSearchLanguageOptionsMessage(false);
    }
  };

  const handleLanguageSearchOptionClick = (option) => {
    setSearchLanguageInputValue(option);
    setSearchLanguageOptions([]);
    setShowNoSearchLanguageOptionsMessage(false);
  };



  const handleLanguageConsultSearchInputChange = (e) => {
    const value = e.target.value;
    setSearchLanguageConsultInputValue(value);

    if (value.trim() !== '') {
      axios.post(`${REST_API_END_POINT}language/language-list`, { search: value })
        .then((res) => {
          let filteredData={}
          if (res.data.status === 1) {
            if(insSearch){
              filteredData = res?.data?.data
           .filter(d => d.status === 1 )
           .filter(d => d.location?.toLowerCase().includes(countryDetailsSearch.name.toLowerCase()));
           // setSearchOptions(filteredData)
           setSearchLanguageConsultOptions(filteredData)

         }
           else{
              filteredData = res?.data?.data
             .filter(d => d.status === 1 )
             .filter(d => d.location?.toLowerCase().includes(countryDetails.name.toLowerCase()));
             // setSearchOptions(filteredData)
             setSearchLanguageConsultOptions(filteredData)
           }
            // setSearchLanguageConsultOptions(res.data?.data);
            setShowNoSearchLanguageConsultOptionsMessage(filteredData?.length === 0);
          }
        })

    } else {
      setSearchLanguageConsultOptions([]);
      setShowNoSearchLanguageConsultOptionsMessage(false);
    }
  };

  const handleLanguageConsultSearchOptionClick = (option) => {
    setSearchLanguageConsultInputValue(option.name);
    setSelectedData(option)
    setSearchLanguageConsultOptions([]);
    setShowNoSearchLanguageConsultOptionsMessage(false);


  };
   const handleChange = (event) => {
    const selectedIndex = event.target.value;
    setSelectedItem(selectedIndex);
    handleItemClickTab(selectedIndex, navItems[selectedIndex].id);
  };

  return (
    <>
      <Navbar setCountryDetails={setCountryDetails} countryDetails={countryDetails} openDropdown={openDropdown} isDropdown={isDropdown} />

      <section className="relative lg:pt-24 pt-[74px] overflow-hidden"
        //  style={{ background: 'linear-gradient(to right, #ff8c00, #eecf36, #eecf36, #eecf36, #eecf36, #ff8c00)' }}
        style={{ backgroundColor: '#000000' }}
      //  style={{ backgroundColor: '#5057ea' }}
      >
        <div className="container-fluid relative overflow-hidden">
          <div className="lg:pt-26 lg:pb-26 pt-26 pb-366 shadow dark:shadow-gray-800">
            <div className="container">
              <div className="grid grid-cols-1 justify-center text-center">
                <div className="relative">
                  {activeIndex === 'visa' && (
                    <>
                      <p className="text-lg max-w-xl mx-auto mb-5"
                        style={{
                          //  color: '#4eee37',
                          color: '#ffffff',
                          fontWeight: 'bold'
                        }}>99.2% User Satisfaction</p>
                      <div className="relative ">
                        <h1 className="font-bold lg:leading-snug leading-snug text-4xl lg:text-6xl text-white">Get All Your Visa Questions Answered.</h1>
                      </div>
                    </>
                  )}
                  {activeIndex === 'check-my-visa' && (
                    <>
                      <p className="text-lg max-w-xl mx-auto mb-5" style={{ color: 'white', fontWeight: 'bold' }}>Before You Fly</p>
                      <div className="relative ">
                        <h1 className="font-bold lg:leading-snug leading-snug text-4xl lg:text-6xl text-white">Check Your Visa Details & Be Sure.</h1>
                      </div>
                    </>
                  )}
                  {activeIndex === 'pr-pathway' && (
                    <>
                      <p className="text-lg max-w-xl mx-auto mb-5" style={{ color: 'white', fontWeight: 'bold' }}>Ensures 99.2% On-Time PR Pathways</p>
                      <div className="relative ">
                        <h1 className="font-bold lg:leading-snug leading-snug text-4xl lg:text-6xl text-white">Embark On Your Permanent Residency.</h1>
                      </div>
                    </>
                  )}
                  {activeIndex === 'universities' && (
                    <>
                      <p className="text-lg max-w-xl mx-auto mb-5" style={{ color: 'white', fontWeight: 'bold' }}>Empowering Your Education</p>
                      <div className="relative ">
                        <h1 className="font-bold lg:leading-snug leading-snug text-4xl lg:text-6xl text-white">Your Academic Journey With Visa Search.</h1>
                      </div>
                    </>
                  )}
                  {activeIndex === 'insurance' && (
                    <>
                      {/* <p className="text-lg max-w-xl mx-auto mb-5" style={{ color: 'white', fontWeight: 'bold' }}>Visa Search Insurance Service</p> */}
                      <p className="text-lg max-w-xl mx-auto mb-5" style={{ color: 'white', fontWeight: 'bold' }}>Top Insurance Providers</p>
                      <div className="relative ">
                        {/* <h1 className="font-bold lg:leading-snug leading-snug text-4xl lg:text-6xl text-white">Travel Insurance</h1> */}
                        <h1 className="font-bold lg:leading-snug leading-snug text-4xl lg:text-6xl text-white">Find Travel Insurance You Need.</h1>
                      </div>
                    </>
                  )}
                  {activeIndex === 'consultants' && (
                    <>
                      <p className="text-lg max-w-xl mx-auto mb-5" style={{ color: 'white', fontWeight: 'bold' }}>99.2% Satisfied Clients</p>
                      <div className="relative ">
                        {/* <h1 className="font-bold lg:leading-snug leading-snug text-4xl lg:text-6xl text-white">Consultants You Can Trust</h1> */}
                        <h1 className="font-bold lg:leading-snug leading-snug text-4xl lg:text-6xl text-white">Find Consultants You Can Trust.</h1>
                      </div>
                    </>
                  )}
                  {activeIndex === 'language-consultants' && (
                    <>
                      <p className="text-lg max-w-xl mx-auto mb-5" style={{ color: 'white', fontWeight: 'bold' }}>Your Gateway to Proficiency</p>
                      <div className="relative ">
                        {/* <h1 className="font-bold lg:leading-snug leading-snug text-4xl lg:text-6xl text-white">Visa Search Language Center</h1> */}
                        <h1 className="font-bold lg:leading-snug leading-snug text-4xl lg:text-6xl text-white">Find Your Best Suited Language Centre.</h1>
                      </div>
                    </>
                  )}
                  {activeIndex === 'credits-loan' && (
                    <>
                      {/* <p className="text-lg max-w-xl mx-auto mb-5" style={{ color: 'white', fontWeight: 'bold' }}>Credits/Loan</p> */}
                      <p className="text-lg max-w-xl mx-auto mb-5" style={{ color: 'white', fontWeight: 'bold' }}>Top Credit/Loan Providers</p>
                      <div className="relative ">
                        {/* <h1 className="font-bold lg:leading-snug leading-snug text-4xl lg:text-6xl text-white">Visa Search Credits/Loan Provider</h1> */}
                        <h1 className="font-bold lg:leading-snug leading-snug text-4xl lg:text-6xl text-white">Discover The Best Credit/Loan Providers.</h1>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="relative md:pb-24 pb-16 -mt-16">
        <div className="container z-1">
          <div className="search-container">
            <div className="wrapperr">
              <div className="search_box">
                {activeIndex === 'universities' && (
                  <div className="search">
                    {/* <div onClick={handleRemove} */}
                    <div onClick={handleRemoveUniversityCountry}
                      className="icon-remove"
                    >
                      <CgClose style={{ color: 'black' }} />
                    </div>
                     <input
                      type="text"
                      inputRef={materialRef}
                      style={{ color: 'black', fontSize: '15px' }}
                      className="location-input"
                      value={SearchInputValueCountry}
                      onChange={handleSearchInputChangeCountry}
                      placeholder='Choose Country' /> 
                                              {/* <TextField
      placeholder="Choose Country"
      inputRef={materialRef}
      value={SearchInputValueCountry}
      onChange={handleSearchInputChangeCountry}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Globe style={{ color: 'black' }} />
          </InputAdornment>
        ),
        
        disableUnderline: true, // Removes the underline
      }}
      variant="standard"
      fullWidth
      style={{
        
        width: '80%',
        boxSizing: 'border-box',
      }}
      InputLabelProps={{
        style: { fontSize: '15px' }, 
      }}
      inputProps={{
        style: {
          fontSize: '15px',
          color: 'black',
          paddingLeft: '8px',
        },
      }}
    /> */}
                    <div className="icon">
                      <Globe style={{ color: 'black' }} />
                    </div> 
                   {(searchOptionsCountry.length > 0 || showNoSearchOptionsMessagess) && (
                      <ul className="autocomplete-options1">
                        {searchOptionsCountry.length > 0 ? (
                          searchOptionsCountry.map((option, index) => (
                            <li key={index} style={{ fontSize: '15px' }}
                              onClick={() => handleSearchOptionClickCountry(option)}>
                              {option.name}
                            </li>
                          ))
                        ) : (
                          <li>No matching country found</li>
                        )}
                      </ul>
                    )} 
                  </div>
                )}
                {activeIndex === 'universities' && (
                  <div className="search">
                    <div onClick={handleRemoveUniversity}
                      className="icon-remove"
                    >
                      <CgClose style={{ color: 'black' }} />
                    </div>
                    <input
                      type="text"
                      style={{ color: 'black', fontSize: '15px' }}
                      className="location-input"
                      value={SearchInputValue}
                      onChange={handleSearchInputChange}
                      placeholder={searchPlaceholder} />

                    <div className="icon">
                      <LuSearch style={{ color: 'black' }} />
                    </div>
                    {(searchOptions.length > 0 || showNoSearchOptionsMessage) && (
                      <ul className="autocomplete-options1">
                        {searchOptions.length > 0 ? (
                          searchOptions.map((option, index) => (
                            <li key={index} style={{ fontSize: '15px' }}
                              onClick={() => handleSearchOptionClick(option)}>
                              {option.name}
                            </li>
                          ))
                        ) : (
                          <li>No matching university found</li>
                        )}
                      </ul>
                    )}
                  </div>
                )}
                {/* {activeIndex === 'universities' && (
                  <>
                    {universitySearch ? (
                      <div className="autocomplete-container search" style={{ marginTop: '0px' }}>
                        <input
                          type="text"
                          className="location-input"
                          style={{ color: 'black', fontSize: '15px' }}
                          // placeholder="What do you want to study ?"
                          placeholder="Your desired course"
                          // value={courseInput}
                          value={SearchInputValueCourse}
                          onChange={handleFindCourse}
                        />


                        <div className="icon" style={{ color: 'black' }}>
                          <LuSchool style={{ color: 'black' }} />
                        </div>

                        {(searchOptionsCourse.length > 0 || showNoSearchOptionsMessages) && (
                          <ul className="autocomplete-options1">
                            {searchOptionsCourse.length > 0 ? (
                              searchOptionsCourse.map((option, index) => (
                                <li key={index} style={{ fontSize: '15px' }}
                                  onClick={() => handleSearchOptionClickCourse(option)}>
                                  {option.name}
                                </li>
                              ))
                            ) : (
                              <li>No matching course found</li>
                            )}
                          </ul>
                        )}
                      </div>


                    ) : (
                      <div className="autocomplete-container search" style={{ marginTop: '0px' }}>
                        <input
                          type="text"
                          className="location-input"
                          style={{ color: 'black', fontSize: '15px' }}
                          // placeholder="What do you want to study ?"
                          placeholder="Your desired course"
                          value={courseInput}
                        // onChange={handleSearchInputChange}
                        />
                        <div className="icon" style={{ color: 'black' }}>
                          <LuSchool style={{ color: 'black' }} />
                        </div>

                        {(courses.length > 0) && (
                          <ul className="autocomplete-options1">
                            {courses.length > 0 ? (
                              courses.map((option, index) => (
                                <li key={index} style={{ fontSize: '15px' }}
                                  onClick={() => handleCourseClick(option)}>
                                  {option.course_name}
                                </li>
                              ))
                            ) : (
                              <li>No matching course found</li>
                            )}
                          </ul>
                        )}
                      </div>


                    )}
                  </>
                )} */}
                 {activeIndex === 'universities' && (
                  <>
                    {universitySearch ? (
                      <div className="autocomplete-container search" style={{ marginTop: '0px' }}>
                        <input
                          type="text"
                          className="location-input"
                          style={{ color: 'black', fontSize: '15px',overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            display: 'inline-block',
                            maxWidth: '275px', }}
                          // placeholder="What do you want to study ?"
                          placeholder="Your desired course"
                          // value={courseInput}
                          value={SearchInputValueCourse}
                          onChange={handleFindCourse}
                        />


                        <div className="icon" style={{ color: 'black' }}>
                          <LuSchool style={{ color: 'black' }} />
                        </div>

                        {(searchOptionsCourse.length > 0 || showNoSearchOptionsMessages) && (
                          <ul className="autocomplete-options1">
                            {searchOptionsCourse.length > 0 ? (
                              searchOptionsCourse.map((option, index) => (
                                <li key={index} style={{ fontSize: '15px' }}
                                  onClick={() => handleSearchOptionClickCourse(option)}>
                                  {option}
                                </li>
                              ))
                            ) : (
                              <li>No matching course found</li>
                            )}
                          </ul>
                        )}
                      </div>


                    ) : (
                      <div className="autocomplete-container search" style={{ marginTop: '0px' }}>
                        <input
                          type="text"
                          className="location-input"
                          style={{ color: 'black', fontSize: '15px' }}
                          // placeholder="What do you want to study ?"
                          placeholder="Your desired course"
                          value={courseInput}
                        // onChange={handleSearchInputChange}
                        />
                        <div className="icon" style={{ color: 'black' }}>
                          <LuSchool style={{ color: 'black' }} />
                        </div>

                        {(courses.length > 0) && (
                          <ul className="autocomplete-options1">
                            {courses.length > 0 ? (
                              courses.map((option, index) => (
                                <li key={index} style={{ fontSize: '15px' }}
                                  onClick={() => handleCourseClick(option)}>
                                  {option.course_name}
                                </li>
                              ))
                            ) : (
                              <li>No matching course found</li>
                            )}
                          </ul>
                        )}
                      </div>


                    )}
                  </>
                )}

                {activeIndex === 'consultants' && (

                  <>
                    <div className="autocomplete-container search" style={{ marginTop: '0px', zIndex: 99999 }}>
                      <div onClick={handleRemove}
                        className="icon-remove"
                      >
                        <CgClose style={{ color: 'black' }} />
                      </div>
                      <input
                        type="text"
                        className="location-input"
                        style={{ color: 'black', fontSize: '15px' }}
                        placeholder="Choose Country"
                        value={SearchInputValueCountry}
                      onChange={handleSearchInputChangeCountry}
                      />
                      <div className="icon" style={{ color: 'black' }}>
                        <Globe style={{ color: 'black' }} />
                      </div>
  
                      {(searchOptionsCountry.length > 0 || showNoSearchOptionsMessagess) && (
                        <ul className="autocomplete-options1">
                          {searchOptionsCountry.length > 0 ? (
                            searchOptionsCountry.map((option, index) => (
                              <li key={index} style={{ fontSize: '15px' }}
                              onClick={() => handleSearchOptionClickCountry(option)}>
                              {option.name}
                            </li>
                            ))
                          ) : (
                            <li>No matching found</li>
                          )}
                        </ul>
                      )}
                    </div>
                    <div className="autocomplete-container search" style={{ marginTop: '0px',}}>
                      <div onClick={handleRemoveConsultant}
                        className="icon-remove"
                      >
                        <CgClose style={{ color: 'black' }} />
                      </div>
                      <input
                        type="text"
                        className="location-input"
                        style={{ color: 'black', fontSize: '15px' }}
                        placeholder="Search migration consultant..."
                        // placeholder="Choose services"
                        value={SearchInputValue}
                        onChange={handleSearchInputChange}
                      />
                      <div className="icon" style={{ color: 'black' }}>
                        <Globe style={{ color: 'black' }} />
                      </div>
  
                      {(searchOptions.length > 0 || showNoSearchOptionsMessage) && (
                        <ul className="autocomplete-options1">
                          {searchOptions.length > 0 ? (
                            searchOptions.map((option, index) => (
                              <li key={index} style={{ fontSize: '15px' }}
                                onClick={() => handleServiceSearchOptionClick(option)}>
                                {option.name}
                              </li>
                            ))
                          ) : (
                            <li>No matching found</li>
                          )}
                        </ul>
                      )}
                    </div>
                    <div className='PaddingInHome' style={{marginTop: '7px',marginRight:'7px'}}>
  <button
    className={`inline-flex justify-center widthinHome font-semibold px-4 py-2 bg-white border border-gray-300 rounded-lg shadow-sm focus:outline-none ${language[activIndexConsultantService].color}`}
    style={{
      borderRadius: '10px',
      color: visaServices[activIndexConsultantService].color,
      
    }}
    // onClick={() => setDropdownOpen(!dropdownOpen)}
    onClick={() => {
      setDropdownOpenservice(!dropdownOpenservice);
      if(dropdownOpen){

        setDropdownOpen(!dropdownOpen);
      }
    }}
  >

    <div style={{
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: 'inline-block',
      maxWidth: '110px',
      whiteSpace:'nowrap'}}>
      {visaServices[activIndexConsultantService].label}
  
  
    </div>
<div style={{marginLeft:'8px',marginTop:'4px'}}>
  
      <svg
            className={`w-4 h-4 ml- transition-transform transform ${
              dropdownOpenservice ? "rotate-180" : "rotate-0"
            }`}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M19 9l-7 7-7-7"
            />
          </svg>
          
</div>
  </button>

  {dropdownOpenservice && (
    <ul className="absolute z-10 mt-2  bg-white border border-gray-300 rounded-lg shadow-lg" 
    // style={{width:'fit-content'}}
    style={{
      width: 'fit-content',
      maxHeight: '200px', // Adjust as needed
      overflowY: 'auto',
      overflowX: 'hidden'
    }}
    >
      {visaServices.map((item, index) => (
       <li
       key={index}
       className="cursor-pointer px-4 py-2 text-left"
       style={{
        //  color: index === activIndexConsultant ? item.color : "black",
         color: index === activIndexConsultantService ? item.color : "black",
         backgroundColor: "white",
         transition: "background-color 0.3s",
       }}
       onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = "grey")}
       onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = "white")}
       onClick={() => {
        handleItemClickTabConsultantService(index, item.id);
        setDropdownOpenservice(false);
       }}
     >
       {item.label}
     </li>
     
      ))}
    </ul>
  )}
</div>
                    <div className='PaddingInHome' style={{marginTop: '7px',marginRight:'7px'}}>
  <button
    className={`inline-flex justify-center widthinHome font-semibold px-4 py-2 bg-white border border-gray-300 rounded-lg shadow-sm focus:outline-none ${language[activIndexConsultant].color}`}
    style={{
      borderRadius: '10px',
      color: language[activIndexConsultant].color,
    }}
    // onClick={() => setDropdownOpen(!dropdownOpen)}
    onClick={() => {
      setDropdownOpen(!dropdownOpen);
      if(dropdownOpenservice){

        setDropdownOpenservice(!dropdownOpenservice);
      }
    }}
  >
   <div style={{
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: 'inline-block',
      maxWidth: '110px',
      whiteSpace:'nowrap'}}>
      {language[activIndexConsultant].label}
      
   </div>
<div style={{marginLeft:'8px',marginTop:'4px'}}>
  
      <svg
            className={`w-4 h-4 ml- transition-transform transform ${
              dropdownOpen ? "rotate-180" : "rotate-0"
            }`}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M19 9l-7 7-7-7"
            />
          </svg>
          
</div>
  </button>

  {dropdownOpen && (
    <ul className="absolute z-10 mt-2  bg-white border border-gray-300 rounded-lg shadow-lg"
    //  style={{width:'fit-content'}}
    style={{
      width: 'fit-content',
      maxHeight: '200px', // Adjust as needed
      overflowY: 'auto',
      overflowX: 'hidden'
    }}
     >
      {language.map((item, index) => (
       <li
       key={index}
       className="cursor-pointer px-4 py-2 text-left"
       style={{
         color: index === activIndexConsultant ? item.color : "black",
         backgroundColor: "white",
         transition: "background-color 0.3s",
       }}
       onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = "grey")}
       onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = "white")}
       onClick={() => {
         handleItemClickTabConsultant(index, item.id);
         setDropdownOpen(false);
       }}
     >
       {item.label}
     </li>
     
      ))}
    </ul>
  )}
</div>
                  </>

                )}
                {activeIndex === 'visa' && (
                  <div className="autocomplete-container search" style={{ marginTop: '0px' }}>
                    <div onClick={handleRemove}
                      className="icon-remove"
                    >
                      <CgClose style={{ color: 'black' }} />
                    </div>
                    <input
                      type="text"
                      className="location-input"
                      style={{ color: 'black', fontSize: '15px' }}
                      placeholder="Choose Country ?"
                      value={SearchInputValue}
                      onChange={handleSearchInputChange}
                    />
                    <div className="icon" style={{ color: 'black' }}>
                      <Globe style={{ color: 'black' }} />
                    </div>

                    {(searchOptions.length > 0 || showNoSearchOptionsMessage) && (
                      <ul className="autocomplete-options1">
                        {searchOptions.length > 0 ? (
                          searchOptions.map((option, index) => (
                            <li key={index} style={{ fontSize: '15px' }}
                              onClick={() => handleSearchOptionClick(option)}>
                              {option.name}
                            </li>
                          ))
                        ) : (
                          <li>No matching  found</li>
                        )}
                      </ul>
                    )}
                  </div>
                )}
                {activeIndex === 'check-my-visa' && (
                  <div className="autocomplete-container search" style={{ marginTop: '0px' }}>
                    <div onClick={handleRemove}
                      className="icon-remove"
                    >
                      <CgClose style={{ color: 'black' }} />
                    </div>
                    <input
                      type="text"
                      className="location-input"
                      style={{ color: 'black', fontSize: '15px' }}
                      placeholder="Choose Country to Check Your Visa"
                      value={SearchInputValue}
                      onChange={handleSearchInputChange}
                    />
                    <div className="icon" style={{ color: 'black' }}>
                      <Globe style={{ color: 'black' }} />
                    </div>

                    {(searchOptions.length > 0 || showNoSearchOptionsMessage) && (
                      <ul className="autocomplete-options1">
                        {searchOptions.length > 0 ? (
                          searchOptions.map((option, index) => (
                            <li key={index} style={{ fontSize: '15px' }}
                              onClick={() => handleSearchOptionClick(option)}>
                              {option.name}
                            </li>
                          ))
                        ) : (
                          <li>No matching found</li>
                        )}
                      </ul>
                    )}
                  </div>
                )}
                {activeIndex === 'pr-pathway' && (
                  <div className="autocomplete-container search" style={{ marginTop: '0px' }}>
                    <div onClick={handleRemove}
                      className="icon-remove"
                    >
                      <CgClose style={{ color: 'black' }} />
                    </div>
                    <input
                      type="text"
                      className="location-input"
                      style={{ color: 'black', fontSize: '15px' }}
                      placeholder="Choose country for your path to permanent residency"
                      value={SearchInputValue}
                      onChange={handleSearchInputChange}
                    />
                    <div className="icon" style={{ color: 'black' }}>
                      <LuSchool style={{ color: 'black' }} />
                    </div>

                    {(searchOptions.length > 0 || showNoSearchOptionsMessage) && (
                      <ul className="autocomplete-options1">
                        {searchOptions.length > 0 ? (
                          searchOptions.map((option, index) => (
                            <li key={index} style={{ fontSize: '15px' }}
                              onClick={() => handleSearchOptionClick(option)}>
                              {option.name}
                            </li>
                          ))
                        ) : (
                          <li>No matching found</li>
                        )}
                      </ul>
                    )}
                  </div>
                )}
                {activeIndex === 'insurance' && (
                  <>
                  <div className="search" style={{ marginTop: '0px' }}>
                    <div onClick={handleRemove}
                      className="icon-remove"
                    >
                      <CgClose style={{ color: 'black' }} />
                    </div>
                    <input
                      type="text"
                      className="location-input"
                      style={{ color: 'black', fontSize: '15px' }}
                      placeholder="Which Place Are Looking For Travelling to..."
                      value={SearchInputValue}
                      onChange={handleSearchInputChange}
                    />
                    <div className="icon" style={{ color: 'black' }}>
                      <LuSchool style={{ color: 'black' }} />
                    </div>

                    {(searchOptions.length > 0 || showNoSearchOptionsMessage) && (
                      <ul className="autocomplete-options1">
                        {searchOptions.length > 0 ? (
                          searchOptions.map((option, index) => (
                            <li key={index} style={{ fontSize: '15px' }}
                              onClick={() => handleSearchOptionClick(option)}>
                              {option.name}
                            </li>
                          ))
                        ) : (
                          <li>No matching country found</li>
                        )}
                      </ul>
                    )}

                  </div>
                  {/* <div className="autocomplete-container search" style={{ marginTop: '-14px' }}>
      <Select
        value={selectedItem}
        onChange={handleChange}
        style={{ marginLeft: '62px', color: navItems[selectedItem].color }}
        className="nav-dropdown"
      >
        {navItems.map((item, index) => (
          <menuitem key={index} value={index} style={{ color: item.color }}>
            {item.text}
          </menuitem>
        ))}
      </Select>
    </div> */}
  {/* <div className="nav-dropdown"> */}
    {/* <select
      className=" font-semibold"
      value={activIndex}
      onChange={(e) => handleItemClickTab(parseInt(e.target.value), navItems[parseInt(e.target.value)].id)}
      style={{
        borderRadius:'10px',
        color: navItems[activIndex].color,
      }}
    >
      {navItems.map((item, index) => (
        <option
          key={index}
          value={index}
          style={{
            color: index === activIndex ? item.color : "black",
          }}
        >
          {item.text}
        </option>
      ))}
    </select> */}
  {/* </div> */}
  {/* <div className="relative"> */}
  <div className='PaddingInHome' style={{marginTop: '7px',marginRight:'7px'}}>
  <button
    className={`inline-flex justify-center widthinHome font-semibold px-4 py-2 bg-white border border-gray-300 rounded-lg shadow-sm focus:outline-none ${navItemsIns[activIndex].color}`}
    style={{
      borderRadius: '10px',
      color: navItemsIns[activIndex].color,
    }}
    onClick={() => setDropdownOpen(!dropdownOpen)}
  >
    {navItemsIns[activIndex].text}
<div style={{marginLeft:'8px',marginTop:'4px'}}>
  
      <svg
            className={`w-4 h-4 ml- transition-transform transform ${
              dropdownOpen ? "rotate-180" : "rotate-0"
            }`}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M19 9l-7 7-7-7"
            />
          </svg>
          
</div>
  </button>

  {dropdownOpen && (
    <ul className="absolute z-10 mt-2  bg-white border border-gray-300 rounded-lg shadow-lg"
    //  style={{width:'fit-content'}}
    style={{
      width: 'fit-content',
      maxHeight: '200px', // Adjust as needed
      overflowY: 'auto',
      overflowX: 'hidden'
    }}
     >
      {navItemsIns.map((item, index) => (
       <li
       key={index}
       className="cursor-pointer px-4 py-2 text-left"
       style={{
         color: index === activIndex ? item.color : "black",
         backgroundColor: "white",
         transition: "background-color 0.3s",
       }}
       onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = "grey")}
       onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = "white")}
       onClick={() => {
         handleItemClickTab(index, item.id);
         setDropdownOpen(false);
       }}
     >
       {item.text}
     </li>
     
      ))}
    </ul>
  )}
</div>


                   
                      {/* <div className="autocomplete-container search" style={{ marginTop: '-14px' }}>
   <nav className="nav" style={{marginLeft:'62px'}}>
                      {navItems.map((item, index) => (
                        <a
                          key={index}
                          className={`nav-item-one font-semibold ${index === activIndex ? "is-active" : ""
                            }`}
                          active-color={item.color}
                          onClick={() => handleItemClickTab(index, item.id)}
                          style={{
                            color:
                              index === activIndex ? item.color : "#83818c",
                          }}
                        >
                          {item.text}
                        </a>
                      ))}
                      <span
                        className="nav-indicator"
                        style={{
                          width: `${100 / navItems.length}%`,
                          left: `${activIndex * (100 / navItems.length) +
                            (activIndex == 0 ? 3 : activIndex == 2 ? -4 : 0)
                            }%`,
                          backgroundColor: navItems[activIndex].color,
                        }}
                      ></span>
                    </nav>
                      </div> */}
                      </>
                )}
                {activeIndex === 'credits-loan' && (
                  <>
                  <div className="autocomplete-container search" style={{ marginTop: '0px' }}>
                    <div onClick={handleRemove}
                      className="icon-remove"
                    >
                      <CgClose style={{ color: 'black' }} />
                    </div>
                    <input
                      type="text"
                      className="location-input"
                      style={{ color: 'black', fontSize: '15px' }}
                      // placeholder="Discover Credits/Loans For You..."
                      placeholder="Choose Country"
                      value={SearchInputValueCountry}
                      onChange={handleSearchInputChangeCountry}
                    />
                    {/* <div className="icon" style={{ color: 'black' }}>
                      <LuSchool style={{ color: 'black' }} />
                    </div> */}
                      <div className="icon" style={{ color: 'black' }}>
                      <Globe style={{ color: 'black' }} />
                    </div>
                    {(searchOptionsCountry.length > 0 || showNoSearchOptionsMessagess) && (
                      <ul className="autocomplete-options1">
                        {searchOptionsCountry.length > 0 ? (
                          searchOptionsCountry.map((option, index) => (
                            <li key={index} style={{ fontSize: '15px' }}
                              onClick={() => handleSearchOptionClickCountry(option)}>
                              {option.name}
                            </li>
                          ))
                        ) : (
                          <li>No matching found</li>
                        )}
                      </ul>
                    )}

                  </div>
                  {/* <div className="autocomplete-container search" style={{ marginTop: '0px' }}>
                    <div onClick={handleRemove}
                      className="icon-remove"
                    >
                      <CgClose style={{ color: 'black' }} />
                    </div>
                    <input
                      type="text"
                      className="location-input"
                      style={{ color: 'black', fontSize: '15px' }}
                      placeholder="Discover Credits/Loans For You..."
                   
                      value={SearchInputValue}
                      onChange={handleSearchInputChange}
                    />
                    <div className="icon" style={{ color: 'black' }}>
                      <LuSchool style={{ color: 'black' }} />
                    </div>
                 
                    {(searchOptions.length > 0 || showNoSearchOptionsMessage) && (
                      <ul className="autocomplete-options1">
                        {searchOptions.length > 0 ? (
                          searchOptions.map((option, index) => (
                            <li key={index} style={{ fontSize: '15px' }}
                              onClick={() => handleSearchOptionClick(option)}>
                              {option.loan_name} ({option.name})
                            </li>
                          ))
                        ) : (
                          <li>No matching found</li>
                        )}
                      </ul>
                    )}

                  </div> */}

                                      <div className='PaddingInHome' style={{ marginTop: '7px', marginRight: '7px' }}>
  <button
    className={`inline-flex justify-center widthinHome font-semibold px-4 py-2 bg-white border border-gray-300 rounded-lg shadow-sm focus:outline-none ${creditType[activIndexLanguage].color}`}
    style={{
      borderRadius: '10px',
      color: creditType[activIndexLanguage].color,
    }}
    onClick={() => {
      setDropdownOpenLang(!dropdownOpenLang);
    }}
  >
    <div style={{
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: 'inline-block',
      maxWidth: '110px',
      whiteSpace: 'nowrap'
    }}>
      {console.log("languageData[activIndexLanguage].label",creditType)}
      {console.log("languageData[activIndexLanguage].label",activIndexLanguage)}
      
      {creditType[activIndexLanguage].label}
    </div>
    <div style={{ marginLeft: '8px', marginTop: '4px' }}>
      <svg
        className={`w-4 h-4 ml- transition-transform transform ${
          dropdownOpenLang ? "rotate-180" : "rotate-0"
        }`}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M19 9l-7 7-7-7"
        />
      </svg>
    </div>
  </button>

  {dropdownOpenLang && (
    <ul
      className="absolute z-10 mt-2 bg-white border border-gray-300 rounded-lg shadow-lg"
      style={{
        width: 'fit-content',
        maxHeight: '200px', // Adjust as needed
        overflowY: 'auto',
        overflowX: 'hidden'
      }}
    >
      {creditType.map((item, index) => (
        <li
          key={index}
          className="cursor-pointer px-4 py-2 text-left"
          style={{
            color: index === activIndexLanguage ? item.color : "black",
            backgroundColor: "white",
            transition: "background-color 0.3s",
          }}
          onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = "grey")}
          onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = "white")}
          onClick={() => {
            handleItemClickTabLanguages(index, item.id);
            setDropdownOpenLang(false);
          }}
        >
          {item.label}
        </li>
      ))}
    </ul>
  )}
</div>
                  </>
                )}
                {/* <div className="search">
                  <input
                    type="text"
                    style={{color:'black',fontSize:'15px'}}
                    className="location-input"
                    ref={inputRef}
                    placeholder={searchPlaceholder} />
                    <div className="icon">
                      <LuSearch style={{color:'black'}} />
                    </div>
                  </div> */}
                {/* {activeIndex === 'consultants' && (
                      <div className="autocomplete-container search" style={{marginTop:'0px'}}>
                          <input
                              type="text"
                              className="location-input"
                              style={{ color: 'black', fontSize:'15px'}}
                              placeholder="Select language"
                              value={SearchInputLanguageValue}
                              onChange={handleLanguageSearchInputChange}
                          />
                          <div className="icon" style={{ color: 'black' }}>
                              <FaLanguage style={{color:'black'}} />
                          </div>

                          {(searchLanguageOptions.length > 0 || showNoSearchLanguageOptionsMessage) && (
                              <ul className="autocomplete-options1">
                                  {searchLanguageOptions.length > 0 ? (
                                      searchLanguageOptions.map((option, index) => (
                                          <li key={index} style={{fontSize:'15px'}} onClick={() => handleLanguageSearchOptionClick(option)}>
                                              {option}
                                          </li>
                                      ))
                                  ) : (
                                      <li>No matching course found</li>
                                  )}
                              </ul>
                          )}
                      </div>
                  )} */}
                {activeIndex === 'language-consultants' && (

                  <>
                  <div className="autocomplete-container search" style={{ marginTop: '0px', zIndex: 99999 }}>
                      <div onClick={handleRemove}
                        className="icon-remove"
                      >
                        <CgClose style={{ color: 'black' }} />
                      </div>
                      <input
                        type="text"
                        className="location-input"
                        style={{ color: 'black', fontSize: '15px' }}
                        placeholder="Choose Country"
                        value={SearchInputValueCountry}
                      onChange={handleSearchInputChangeCountry}
                      />
                      <div className="icon" style={{ color: 'black' }}>
                        <Globe style={{ color: 'black' }} />
                      </div>
  
                      {(searchOptionsCountry.length > 0 || showNoSearchOptionsMessagess) && (
                        <ul className="autocomplete-options1">
                          {searchOptionsCountry.length > 0 ? (
                            searchOptionsCountry.map((option, index) => (
                              <li key={index} style={{ fontSize: '15px' }}
                              onClick={() => handleSearchOptionClickCountry(option)}>
                              {option.name}
                            </li>
                            ))
                          ) : (
                            <li>No matching found</li>
                          )}
                        </ul>
                      )}
                    </div>
                    <div className="autocomplete-container search" style={{ marginTop: '0px' }}>
                      <div onClick={handleRemoveConsultant}
                        className="icon-remove"
                      >
                        <CgClose style={{ color: 'black' }} />
                      </div>
                      <input
                        type="text"
                        className="location-input"
                        style={{ color: 'black', fontSize: '15px' }}
                        placeholder="Search Language Consultant"
                        value={SearchInputLanguageConsultValue}
                        onChange={handleLanguageConsultSearchInputChange}
                      />
                      <div className="icon" style={{ color: 'black' }}>
                        <FaLanguage style={{ color: 'black' }} />
                      </div>
  
                      {(searchLanguageConsultOptions.length > 0 || showNoSearchLanguageConsultOptionsMessage) && (
                        <ul className="autocomplete-options1">
                          {searchLanguageConsultOptions.length > 0 ? (
                            searchLanguageConsultOptions.map((option, index) => (
                              <li key={index} style={{ fontSize: '15px' }} onClick={() => handleLanguageConsultSearchOptionClick(option)}>
                                {option.name}
                              </li>
                            ))
                          ) : (
                            <li>No matching found</li>
                          )}
                        </ul>
                      )}
                    </div>
                    <div className='PaddingInHome' style={{ marginTop: '7px', marginRight: '7px' }}>
  <button
    className={`inline-flex justify-center widthinHome font-semibold px-4 py-2 bg-white border border-gray-300 rounded-lg shadow-sm focus:outline-none ${languageData[activIndexLanguage].color}`}
    style={{
      borderRadius: '10px',
      color: languageData[activIndexLanguage].color,
    }}
    onClick={() => {
      setDropdownOpenLang(!dropdownOpenLang);
    }}
  >
    <div style={{
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: 'inline-block',
      maxWidth: '110px',
      whiteSpace: 'nowrap'
    }}>
      {console.log("languageData[activIndexLanguage].label",languageData)}
      {console.log("languageData[activIndexLanguage].label",activIndexLanguage)}
      
      {languageData[activIndexLanguage].label}
    </div>
    <div style={{ marginLeft: '8px', marginTop: '4px' }}>
      <svg
        className={`w-4 h-4 ml- transition-transform transform ${
          dropdownOpenLang ? "rotate-180" : "rotate-0"
        }`}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M19 9l-7 7-7-7"
        />
      </svg>
    </div>
  </button>

  {dropdownOpenLang && (
    <ul
      className="absolute z-10 mt-2 bg-white border border-gray-300 rounded-lg shadow-lg"
      style={{
        width: 'fit-content',
        maxHeight: '200px', // Adjust as needed
        overflowY: 'auto',
        overflowX: 'hidden'
      }}
    >
      {languageData.map((item, index) => (
        <li
          key={index}
          className="cursor-pointer px-4 py-2 text-left"
          style={{
            color: index === activIndexLanguage ? item.color : "black",
            backgroundColor: "white",
            transition: "background-color 0.3s",
          }}
          onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = "grey")}
          onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = "white")}
          onClick={() => {
            handleItemClickTabLanguages(index, item.id);
            setDropdownOpenLang(false);
          }}
        >
          {item.label}
        </li>
      ))}
    </ul>
  )}
</div>

                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        <div style={{paddingLeft:'10px',paddingRight:'10px',marginBottom: '30px',display:'flex',justifyContent:'center',flexDirection:'row',alignContent:'center',alignItems:'center'}}>
          <ul className="justify-center text-center p-2 bg-white dark:bg-slate-900 shadow dark:shadow-gray-8001 homeTabHeight"  id="myTab"
            data-tabs-toggle="#StarterContent"
            role="tablist"
            style={{ borderRadius: '20px', whiteSpace: 'nowrap',overflowX: 'auto',zIndex:0,overflowY:'hidden',scrollbarWidth: 'none',msOverflowStyle: 'none', '&::-webkit-scrollbar': {
              display: 'none',
            },  }}>
            <li role="presentation" className="inline-block w-auto">
              <Link
                to={`/visa`}
                className={`px-6 py-2 font-semibold roundedd-md w-full transition-all duration-500 ease-in-out ${activeIndex === 'visa' ? 'text-white bg-violet-6001' : ''}`}
                type="button"
                onClick={() => setIndex('visa')}
                style={{ color: 'black',zIndex:999}}
              >
                Visa
              </Link>
            </li>
            <li role="presentation" className="inline-block w-auto">
              <Link
                to={`/check-my-visa`}
                className={`px-6 py-2 font-semibold roundedd-md w-full transition-all duration-500 ease-in-out ${activeIndex === 'check-my-visa' ? 'text-white bg-violet-6001' : ''}`}
                type="button"
                onClick={() => setIndex('check-my-visa')}
                style={{ color: 'black' }}
              >
                Check My Visa
              </Link>
            </li>
            <li role="presentation" className="inline-block w-auto">
              <Link
                to={`/pr-pathway`}
                className={`px-6 py-2 font-semibold roundedd-md w-full transition-all duration-500 ease-in-out ${activeIndex === 'pr-pathway' ? 'text-white bg-violet-6001' : ''}`}
                type="button"
                onClick={() => setIndex('pr-pathway')}
                style={{ color: 'black' }}
              >
                {/* Insurance  */}
                PR Pathways
              </Link>
            </li>
            <li role="presentation" className="inline-block w-auto">
              <Link
                to={`/insurance`}
                className={`px-6 py-2 font-semibold roundedd-md w-full transition-all duration-500 ease-in-out ${activeIndex === 'insurance' ? 'text-white bg-violet-6001' : ''}`}
                type="button"
                onClick={() => {
                  setIndex('insurance')
                  setSearchInputValue('')
                  // setSelectedData({})
                  setShowNoSearchOptionsMessage(false)
                  setSearchOptions([])
                }}
                style={{ color: 'black' }}
              >
                Insurance

              </Link>
            </li>
            <li role="presentation" className="inline-block w-auto">
              <Link
                to={`/universities`}
                className={`px-6 py-2 font-semibold roundedd-md w-full transition-all duration-500 ease-in-out ${activeIndex === 'universities' ? 'text-white bg-violet-6001' : ''}`}
                type="button"
                onClick={() => setIndex('universities')}
                style={{ color: 'black' }}

              >
                Universities
              </Link>
            </li>
            <li role="presentation" className="inline-block w-auto">
              <Link
                to={`/consultants`}
                className={`px-6 py-2 font-semibold roundedd-md w-full transition-all duration-500 ease-in-out ${activeIndex === 'consultants' ? 'text-white bg-violet-6001' : ''}`}
                type="button"
                onClick={() => setIndex('consultants')}
                style={{ color: 'black' }}

              >
                Consultants
              </Link>
            </li>
            <li role="presentation" className="inline-block w-auto">
              <Link
                to={`/language-consultants`}
                className={`px-6 py-2 font-semibold roundedd-md w-full transition-all duration-500 ease-in-out ${activeIndex === 'language-consultants' ? 'text-white bg-violet-6001' : ''}`}
                type="button"
                onClick={() => {
                  setIndex('language-consultants')
                }}
                style={{ color: 'black' }}

              >
                Language
              </Link>
            </li>
            <li role="presentation" className="inline-block w-auto">
              <Link
                to={`/credits-loan`}
                className={`px-6 py-2 font-semibold roundedd-md w-full transition-all duration-500 ease-in-out ${activeIndex === 'credits-loan' ? 'text-white bg-violet-6001' : ''}`}
                type="button"
                onClick={() => setIndex('credits-loan')}
                style={{ color: 'black' }}

              >
                Credits/Loan
              </Link>
            </li>
          </ul>
        </div>
      </section>


      <section id='listing' style={{ marginTop: '-100px' }}>

        {activeIndex === 'visa' &&  <VisaListing typeId={typeId}
          setTypeId={setTypeId} setVisaSelect={setVisaSelect}
          activeIndex={activeIndex} navItems={navItems}
          VISA_REQUIREMENT={VISA_REQUIREMENT} refresh={refresh}
          countrySelected={selectedData}
          countryPrefer={countryDetails}
          setSearchInputValue={setSearchInputValue}
          
        />}
        {activeIndex === 'check-my-visa' && <CheckMyVisa refresh={refresh}
         countryPrefer={countryDetails}
          countrySelected={selectedData}
           activeIndex={activeIndex} />}
        {activeIndex === 'pr-pathway' && <PrPathwayListing refresh={refresh} activeIndex={activeIndex} prSelected={selectedData} />}
        {activeIndex === 'insurance' && <InsuranceListing setInsTypeId={setInsTypeId} insTypeId={insTypeId} countryPrefer={insSearch?countryDetailsSearch:countryDetails} refresh={refresh} activeIndex={activeIndex} insuranceSelected={selectedData} activIndex={activIndex} setActivIndex={setActivIndex} />}
        {activeIndex === 'universities' && <UniversityListing countryPrefer={insSearch?countryDetailsSearch:countryDetails}  refresh={refresh} activeIndex={activeIndex} universitySelected={selectedData} courseData={searchOptionsCourses}/>}
        {activeIndex === 'consultants' && <ConsultantsListing setConsLangId={setConsLangId} consLangId={consLangId} consSerId={consSerId} countryPrefer={insSearch?countryDetailsSearch:countryDetails} refresh={refresh} activeIndex={activeIndex} consultSelect={selectedData} activIndexConsultant={activIndexConsultant} activIndexConsultantService={activIndexConsultantService}/>}
        {activeIndex === 'language-consultants' && <LanguagesListing countryPrefer={insSearch?countryDetailsSearch:countryDetails} languageSelect={selectedData} refresh={refresh} activeIndex={activeIndex} activIndexLanguage={activIndexLanguage} />}
        {activeIndex === 'credits-loan' && <CreditsLoanListing countryPrefer={insSearch?countryDetailsSearch:countryDetails} refresh={refresh} activeIndex={activeIndex} loanselect={selectedData} activIndexLoan={activIndexLanguage}/>}
      </section>

      <Footer />

    </>
  )
}
