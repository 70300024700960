import React from 'react';
import NavbarNew from '../../components/navbar-new';
import Footer from '../../components/footer';

export default function AboutUs() {
  const aboutUsData = [
    {
      title: "About Us",
      content: [
        "At Visa Search, we are dedicated to providing comprehensive global immigration information services to assist individuals in navigating the complexities of international travel. Our mission is to empower you with the right resources and support for a successful migration journey."
      ]
    },
    {
      title: "Our Services",
      subheadings: [
        {
          subheading: "Visa Information Assistance",
          details: [
            "Expert guidance on travel, work, student, and permanent residency visas.",
            "Timely and accurate information to simplify your visa application process."
          ]
        },
        {
          subheading: "Global University Information",
          details: [
            "Detailed insights into top global universities and their courses.",
            "Easy access to essential information for choosing the right study path."
          ]
        },
        {
          subheading: "Immigration Consultant Connections",
          details: [
            "Direct connections to reputable immigration consultants.",
            "Protection against immigration fraud by ensuring you work with trusted professionals."
          ]
        },
        {
          subheading: "Language Learning Resources",
          details: [
            "Access to providers for global language services to enhance your international communication skills and to meet visa requirements"
          ]
        },
        {
          subheading: "Insurance providers suggestion",
          details: [
            "Recommendations for leading travel and migration insurance providers globally"
          ]
        },
        {
          subheading: "Credit/Loan providers suggestion",
          details: [
            "Guidance on credit providers and student loans to support your immigration needs."
          ]
        },
      ]
    },
    {
      title: "Our Commitment",
      subheadings: [
        {
          subheading: "Empowering Decisions",
          details: [
            "We aim to equip you with the knowledge needed to make informed choices about your migration journey."
          ]
        },
        {
          subheading: "Efficiency and Reliability",
          details: [
            "Our services are designed to save you time while providing trustworthy information."
          ]
        }
      ],
      closingStatement: "At Visa Search, we believe in making global migration accessible and secure for everyone. Let us help you take the next step in your international adventure!"
    }
  ];

  return (
    <>
      <NavbarNew tab={"visa"} aboutus={true} />
      <section className="relative lg:pt-24 pt-[74px] overflow-hidden" style={{ backgroundColor: '#000000' }}>
        <div className="container text-center text-white py-10">
          <p className="text-lg font-bold mb-5">About Visa Search</p>
          <h1 className="font-bold text-4xl lg:text-6xl">Your Trusted Partner in Migration</h1>
        </div>
      </section>

      <section className="relative">
        <div style={{ paddingLeft: '45px', paddingRight: '25px' }}>
          <div className="p-1">
            <div className="p-1 bg-white rounded-md">
              {aboutUsData.map((section, index) => (
                <div key={index}>
                  <h5 className="text-xlabc font-semibold mb-4 mt-4">{section.title}</h5>

                  {section.content && section.content.map((text, i) => (
                    <p key={i} className="text-slate-400">{text}</p>
                  ))}

                  {section.subheadings && section.subheadings.map((sub, j) => (
                    <div key={j} className="mt-6">
                      <h6 className="text-lgabc font-semibold mb-3">{sub.subheading}</h6>

                      <ul className="list-none text-slate-400 mt-4">
                        {sub.details.map((detail, k) => (
                          <li key={k} className="flex items-start mt-2">
                            <span className="text-violet-600 text-base" style={{ marginRight: '8px' }}>
                              <span style={{ display: 'inline-flex' }}>●</span>
                            </span>
                            <span>{detail}</span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  ))}

                  {section.closingStatement && (
                    <p className="text-slate-400 mt-4">{section.closingStatement}</p>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
