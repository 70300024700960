import React from 'react'
import Navbar from '../components/navbar'
import Footer from '../components/footer'
import Switcher from '../components/switcher'
import { Link } from 'react-router-dom'
import {MdOutlineArrowForward} from "../assets/icons/vander"
import NavbarNew from '../components/navbar-new'

export default function PrivacyPolicy() {
    // const sections = [
    //     {
    //       title: "Overview :",
    //       content: [
    //         "It seems that only fragments of the original text remain in the Lorem Ipsum texts used today. One may speculate that over the course of time certain letters were added or deleted at various positions within the text.",
    //         "In the 1960s, the text suddenly became known beyond the professional circle of typesetters and layout designers when it was used for Letraset sheets (adhesive letters on transparent film, popular until the 1980s) Versions of the text were subsequently included in DTP programmes such as PageMaker etc.",
    //         "There is now an abundance of readable dummy texts. These are usually used when a text is required purely to fill a space. These alternatives to the classic Lorem Ipsum texts are often amusing and tell short, funny or nonsensical stories."
    //       ]
    //     },
    //     {
    //       title: "We use your information to :",
    //       list: [
    //         "Digital Marketing Solutions for Tomorrow",
    //         "Our Talented & Experienced Marketing Agency",
    //         "Create your own skin to match your brand",
    //         "Digital Marketing Solutions for Tomorrow",
    //         "Our Talented & Experienced Marketing Agency",
    //         "Create your own skin to match your brand"
    //       ]
    //     },
    //     {
    //       title: "Information Provided Voluntarily :",
    //       content: [
    //         "In the 1960s, the text suddenly became known beyond the professional circle of typesetters and layout designers when it was used for Letraset sheets (adhesive letters on transparent film, popular until the 1980s) Versions of the text were subsequently included in DTP programmes such as PageMaker etc."
    //       ]
    //     }
    //   ];
    const privacyPolicyData = [
        {
          title: "Privacy Policy for Visa Search",
          content: [
            "Welcome to Visa Search. We are dedicated to safeguarding your privacy and ensuring that your personal information is respected. This Privacy Policy outlines our practices regarding the collection, use, disclosure, and transfer of your information by Visa Search and its parent, subsidiaries, group entities, and affiliates (collectively referred to as 'Company,' 'we,' or 'us') when you use our services. This policy is part of our Terms of Use. Terms defined here but not specified will have the meanings assigned to them in the Terms of Use.",
            "By using our services, you consent to the collection, storage, and use of your personal data as described in this Privacy Policy."
          ]
        },
        {
          title: "Definitions",
        //   content: [
        //     "The following capitalized terms have the meanings defined below:"
        //   ],
          list: [
            "Account: A unique account created for you to access our services.",
            "Affiliate: An entity that controls, is controlled by, or is under common control with another party, where 'control' refers to ownership of 50% or more of shares or voting rights.",
            "Application: The website ('Site') and the software provided by the Company, accessible or downloadable on any device, referred to as Visa Search.",
            "Company: Refers to Visa Search, also known as 'We,' 'Us,' or 'Our.'",
            "Device: Any device capable of accessing our services, such as a computer, mobile phone, or tablet.",
            "Personal Data: Any information relating to an identified or identifiable individual.",
            "Service: Refers to the Site.",
            "Service Provider: Any person or entity that processes data on behalf of the Company, including third-party companies or individuals that assist in delivering our services.",
            "Third-party Social Media Service: Any website or social media platform that allows users to log in or create an account to access our services.",
            "Usage Data: Automatically collected data, which may include your device's IP address, browser type, pages visited, date and time of access, duration of visits, unique device identifiers, and other diagnostic data.",
            "You: The individual using the service, or the company or legal entity on whose behalf the individual is accessing or using the service."
          ]
        },
        {
            title: "Information Collected",
            subheadings: [
              {
                subheading: "Personal Data",
                content: [
                  "To access our services, you must provide certain personally identifiable information during the registration process and visa processing. This may include:"
                ],
                list: [
                  "Email address",
                  "First and last name",
                  "Gender",
                  "Date of birth",
                  "Bank account, credit card, or debit card information",
                  "Medical records and history",
                  "Sexual orientation",
                  "Biometric data",
                  "Photo (uploaded or taken via the app/website)",
                  "Password",
                  "Phone number",
                  "Address, including city, state, province, and postal code",
                  "Usage data",
                  "Occupation and interests"
                ],
                content2: [
                  "Any personally identifiable information you provide will not be deemed sensitive if it is publicly available. You may choose not to provide certain information; however, this may limit your access to some services. We will inform you of any limitations during registration and are not liable for any services you cannot access due to a lack of provided personal information."
                ]
              },{
          subheading: "Usage Data",
          content: [
            "Usage Data is automatically collected while using our services and may include:"
          ],
          list2: [
            "Your device's IP address",
            "Browser type and version",
            "Pages visited on our service",
            "Time and date of visits",
            "Duration on pages",
            "Unique device identifiers"
          ],
      
          content3: [
            "When accessing our service through a mobile device, we may collect additional information, including the type of device, unique ID, IP address, operating system, and mobile browser type."
          ]
        } ]
    },
        {
          title: "Use of Information",
          content:["The Company may use your information for various purposes, including:"],
          list: [
            "To provide and maintain our services and monitor usage.",
            "To manage your user registration, giving you access to different functionalities.",
            "To fulfill contracts and manage your requests.",
            "To contact you via email, phone, SMS, or app notifications regarding updates or relevant communications.",
            "To provide you with news, offers, and information about our services that may interest you. You may opt-out of these communications at any time.",
            "For data analysis, identifying trends, evaluating promotional campaigns, and improving our services.",
            "For conducting business transactions such as mergers or asset transfers, including your personal data."
          ]
        },
        {
          title: "Information Sharing",
          content: [
            "We may share your personal information with third parties in the following circumstances:"
          ],
          list: [
            "With Service Providers to analyze service usage, typically in aggregated form.",
            "Within our group companies and affiliates for processing personal information on our behalf. Third-party vendors may access your personal data to provide requested services and must comply with our Privacy Policy.",
            "Protection of Facial Data: We prioritize the protection of facial data and do not share this information with third parties. It is stored and processed within our secure systems according to applicable regulations.",
            "To process your visa application, we may share your information with government agencies or authorized service providers as needed.",
            "When legally required to disclose information for identity verification or to prevent and investigate incidents.",
            "If you interact publicly with other users, such information may be visible to all users.",
            "We may disclose your personal data to protect our rights or comply with legal obligations.",
            "We may share your information for any other purpose with your explicit consent."
          ]
        },
        {
          title: "Accessing and Updating Personal Information",
          content: [
            "We may periodically contact you to update your personal information to enhance your experience. Upon request, we will provide you with access to your personal data and ensure corrections are made if inaccuracies are found, unless legal obligations require otherwise. We may ask you to verify your identity before processing such requests.",
            <>
            To cancel your registration or withdraw consent for data processing, please email us at{" "}
            <a href="mailto:office@visasearch.info" style={{ color: '#1D4ED8', textDecoration: 'underline' }} >
              office@visasearch.info
            </a>
            . We will make reasonable efforts to respond within 24 hours.
          </>
          ]
        },
        {
          title: "Retention of Information",
          content: [
            "We will retain your personal data only as long as necessary to fulfill the purposes outlined in this Privacy Policy and to comply with legal obligations. Personal data will be retained for 180 days after registration cancellation or withdrawal.",
            "Usage data is retained for internal analysis and generally kept for shorter periods, unless needed for security or legal compliance."
          ]
        },
        {
          title: "Transfer of Your Personal Data",
          content: [
            "Your information, including personal data, may be processed at our operating offices and other locations where processing parties are based. This may involve transferring your data to locations with different data protection laws. By providing your information, you consent to this transfer.",
            "We will ensure adequate protections are in place to secure your data during transfer."
          ]
        },
        {
          title: "Information Security",
          content: [
            "We implement appropriate security measures to protect against unauthorized access or alteration of your data, including encryption and physical security protocols. While we strive to secure our database, we cannot guarantee complete security."
          ]
        },
        {
          title: "Analytics",
          content: [
            "We may use third-party service providers to monitor and analyze our service usage.",
      <>
        For more information on Google’s privacy practices, please visit:{" "}
        <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer" style={{ color: '#1D4ED8', textDecoration: 'underline' }} >
          Google Privacy & Terms
        </a>.
      </>
          ]
        },
        {
          title: "Payments",
          content: [
            "For paid services, we use third-party payment processors, and we do not store your payment information. Their privacy policies govern how your data is handled."
          ]
        },
        {
          title: "Links to Other Websites",
          content: [
            "Our service may include links to third-party websites. We recommend reviewing the privacy policies of these sites as we have no control over their content or practices."
          ]
        },
        {
          title: "Updates/Changes",
          content: [
            "We may update our Privacy Policy occasionally. We will notify you of changes by posting the new policy on this page and updating the effective date. Your continued use of our services after any changes indicates your acceptance of the new policy."
          ]
        },
        {
          title: "Contact Us/Grievance Redressal",
          content: [
            <>
            For questions or suggestions regarding this Privacy Policy, please contact us at{" "}
            <a href="mailto:office@visasearch.info" style={{ color: '#1D4ED8', textDecoration: 'underline' }} >
              office@visasearch.info
            </a>
            .
          </>,
            "As per the Information Technology (Intermediary Guidelines and Digital Media Ethics Code) 2021, the Grievance Officer will resolve complaints within 15 days. Please include the following information in your complaint:"
          ],
          list: [
            "Identification of the information in question",
            "A clear statement on whether it is personal or sensitive data",
            "Your contact information",
            "A statement of good faith belief regarding the information",
            "A statement, under penalty of perjury, confirming the accuracy of your notice",
            "If unsatisfied with the Grievance Officer’s decision, you may appeal to the Grievance Appellate Committee within 30 days."
          ]
        }
      ];
      
      
      return (
        <>
          <NavbarNew tab={"visa"} aboutus={true}/>
          <section className="relative lg:pt-24 pt-[74px] overflow-hidden" style={{ backgroundColor: '#000000' }}>
            <div className="container text-center text-white py-10">
              <p className="text-lg font-bold mb-5">Privacy Policy for Visa Search</p>
              <h1 className="font-bold text-4xl lg:text-6xl">Your Privacy, Our Priority</h1>
            </div>
          </section>
    
          <section className="relative">
            <div style={{paddingLeft:'45px',paddingRight:'25px'}}>
              
                <div className="p-1">
                  <div className="p-1 bg-white rounded-md">
                    {privacyPolicyData.map((section, index) => (
                      <div key={index}>
                        <h5 className="text-xlabc font-semibold mb-4 mt-4">{section.title}</h5>
    
                        {section.content && section.content.map((text, i) => (
                          <p key={i} className="text-slate-400">{text}</p>
                        ))}
    
                        {section.list && (
                          <ul className="list-none text-slate-400 mb-4 mt-4">
                            {section.list.map((item, i) => (
                              <li key={i} className="flex items-start mb-4 mt-4">
                                <span className="text-violet-600 text-base" style={{ marginRight: '8px',}}><span style={{display:'inline-flex'}}>●</span></span>
                                <span>{item}</span>
                              </li>
                            ))}
                          </ul>
                        )}
    
                        {section.subheadings && section.subheadings.map((sub, j) => (
                          <div key={j} className="mt-6">
                            <h6 className="text-lgabc font-semibold mb-3">{sub.subheading}</h6>
    
                            {sub.content && sub.content.map((text, k) => (
                              <p key={k} className="text-slate-400">{text}</p>
                            ))}
    
                            {sub.list && (
                              <ul className="list-none text-slate-400 mt-4">
                                {sub.list.map((item, l) => (
                                  <li key={l} className="flex items-start mt-2">
                                    <span className="text-violet-600 text-base" style={{ marginRight: '8px',}}><span style={{display:'inline-flex'}}>●</span></span>
                                    <span>{item}</span>
                                  </li>
                                ))}
                              </ul>
                            )}
    
                            {sub.content2 && sub.content2.map((text, m) => (
                              <p key={m} className="text-slate-400 mt-4">{text}</p>
                            ))}
    
                            {sub.list2 && (
                              <ul className="list-none text-slate-400 mt-4">
                                {sub.list2.map((item, n) => (
                                  <li key={n} className="flex items-start mt-2">
                                    <span className="text-violet-600 text-base" style={{ marginRight: '8px',}}><span style={{display:'inline-flex'}}>●</span></span>
                                    <span>{item}</span>
                                  </li>
                                ))}
                              </ul>
                            )}
    
                            {sub.content3 && sub.content3.map((text, o) => (
                              <p key={o} className="text-slate-400 mt-4">{text}</p>
                            ))}
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>
                </div>
            </div>
          </section>
          <Footer />
        </>
      );
    }