import React from 'react'
import Navbar from '../components/navbar'
import Footer from '../components/footer'
import Switcher from '../components/switcher'
import { Link } from 'react-router-dom'
import {MdOutlineArrowForward} from "../assets/icons/vander"
import NavbarNew from '../components/navbar-new'

export default function PrivacyPolicy() {
    const termsOfUseData = [
        {
            title: "Definitions and Interpretation",
            content: [
                "Words with an initial capital letter have meanings defined under these conditions.",
                "These definitions will apply equally whether in singular or plural form."
            ],
            list: [
                "Platform: Refers to the software program named Visa Search, provided by the Company and downloaded by you onto any electronic device.",
                "You: Denotes the individual using the Service, or the organization or legal entity on whose behalf the individual is accessing the Service."
            ]
        },
        {
            title: "Acknowledgment",
            list: [
                "These Terms and Conditions govern your use of the Service and outline the agreement between you and the Company, specifying the rights and responsibilities of all users.",
                "Your access to and use of the Service depends on your acceptance and compliance with these Terms. These Terms apply to all visitors, users, and anyone accessing or utilizing the Service.",
                "We value your right to information and strive to offer transparent “Terms.” Please review this Policy to understand the “Terms” you are agreeing to.",
                "IF YOU DO NOT ACCEPT ANY OF THE TERMS AND CONDITIONS STATED HERE, PLEASE DO NOT GIVE YOUR CONSENT AND DO NOT PROCEED TO ACCESS THE Platform. By consenting to access the Platform, you agree to comply with the stated “Terms.”",
                "The policies or “Terms” set forth by the Company form a legally binding agreement between you and the Company. We advise you to read these Terms carefully before using the Platform.",
                "This document constitutes an electronic record in accordance with the Information Technology Act, 2000 (“IT Act”) and applicable rules. This electronic record is generated by a computer system and does not require physical or digital signatures.",
                "You confirm that you are over 18 years of age. The Company does not allow anyone under 18 to use the Service.",
                "Your access to and use of the Service is also contingent on your acceptance of the Company’s Privacy Policy. This policy details how we collect, use, and disclose your personal information when you use the Platform or Website and informs you of your privacy rights and protections under the law. Please read the Privacy Policy carefully before using our Service.",
                "You acknowledge that the issuance or denial of a visa is solely at the discretion of the Embassy. Visa Search is not involved in this process and bears no responsibility for any delays, approvals, or rejections related to your visa Platform, as the Embassy retains the right to request additional documentation and deny Platforms."
            ]
        },
        {
            title: "Placing Orders for Goods",
            content: [
                "By placing an Order for Goods through the Service, you confirm that you are legally capable of entering into binding contracts."
            ]
        },
        {
            title: "Your Information",
            list: [
                "To use the Services offered by the Company, you must register on the web portal.You may need to provide information relevant to your Order, including but not limited to: your Name, Email, Phone Number, Credit Card Number, Credit Card expiration date, Billing Address, Shipping Information, Passport Details, and PAN details.",
                <>
               <p>You represent and warrant that:</p>
                <ul>
                    <li> You have the legal right to use any credit or debit cards or other payment methods in relation to any Platform.</li>
                    <li> The information you provide is true, accurate, complete, and not misleading.</li>
                    <li> You will respond to communications from Visa Search within 24 hours of receipt. Failure to do so may result in the cancellation of your access to Platform, for which Visa Search will not be liable for any loss or damage due to delays or non-processing of your visa.</li>
                </ul>
               </>,
               "By providing this information, you authorize us to share it with third-party payment processors to facilitate your Platform. We reserve the right to request additional information for processing your visa. Failure or delay in providing this may result in your access to Platform being cancelled or delayed.",
                "Some Countries may require us to complete and submit the relevant Platform forms online or physically, as per their requirements. By submitting a request through our partners, you authorize us to complete these forms based on the information you provide and submit them to the relevant Visa offices."
            ]
        },
        {
            title: "Order Cancellation and Refunds",
            list : [
                <>
               <p>We reserve the right to refuse or cancel your access to Platform at any time for various reasons, including but not limited to:</p>
                <ul>
                    <li> Downtime or unavailability of Embassy/third-party systems</li>
                    <li> Unforeseen circumstances</li>
                    <li> Inaccurate or incomplete information provided by the User, such as incorrect travel dates or unclear passport copies, or failure to respond to communications from Visa Search within 24 hours.</li>
                </ul>
               </>,               
                "We may refuse or cancel your access to Platform if fraud or an unauthorized or illegal transaction is suspected.",
                "We have a strict NO REFUND policy. Visa Search will not refund Visa fees or Visa Search fees for any reason, including visa rejections by the embassy or authorities.",
                "If you overstay without legitimate approvals, you will be liable for any penalties, legal proceedings, or actions by the Government or relevant authorities. Visa Search will not be responsible for such situations."
            ]
        },
        {
            title: "Availability, Errors, and Inaccuracies",
            content: [
                "We continuously update our fee offerings.Fees displayed may be mispriced, inaccurately described, or unavailable, and delays may occur in updating this information on our Service and other advertising platforms."
            ]
        },
        {
            title: "Pricing Policy",
                list: [
                "The Company reserves the right to change prices or fees at any time.",
                "Prices may be revised after you join our platform due to factors beyond the Company's control, such as government actions, changes in customs duties, increased shipping costs, or higher foreign exchange rates. In such cases, you have the right to cancel membership."
            ]
        },
        {
            title: "Payments",
            list: [
                "All partners memberships require recurring monthly payments.Payment can be made through various methods available, such as Visa, MasterCard, Affinity Card, American Express, or online payment platforms like PayPal.",
                "Payment cards are subject to validation and authorization by your card issuer. If we do not receive the required authorization, we are not liable for any delay or canceling your membership."
            ]
        },
        {
            title: "Promotions",
            list: [
                "Any promotions offered through the Service may have separate rules from these Terms.",
                "If you participate in promotions, please review the applicable rules and our Privacy Policy. If there’s a conflict between the Promotion rules and these Terms, the Promotion rules will prevail."
            ]
        },
        {
            title: "User Accounts",
            list: [
                "When you create an account with us, you must provide accurate, complete, and current information.",
                "Failure to do so constitutes a breach of these Terms and may result in immediate account termination.",
                "You are responsible for maintaining the confidentiality of your Account Information and restricting access to your devices to prevent unauthorized access to your account.",
                "You are responsible for safeguarding your password and any actions taken under your password, whether it’s with our Service or a Third-Party Social Media Service.",
                "You agree not to share your password with any third party and must notify us immediately if you become aware of any security breach or unauthorized use of your account.",
                <>
               <p>The following additional terms apply to Vendors:</p>
                <ul>
                    <li>Each Vendor may set up only one user account and must provide accurate and complete information during registration. Login details must be kept confidential. The Vendor must inform Visa Search immediately upon suspecting any unauthorized use of their account.</li>
                    <li>Vendors are solely responsible for verifying the contents of their account on the Visa Search portal. Errors in the account submitted by the Vendor will result in liability for the Vendor, including any delays or non-approval of visas.</li>
                    <li>Vendors guarantee that all data submitted is complete, accurate, and truthful. Any changes must be promptly updated in their account.</li>
                </ul>
               </>,
            ]
        },
        {
            title: "Intellectual Property",
            list: [
                "The Service and its original content (excluding user-provided content) remain the exclusive property of the Company and its licensors.",
                "The Service is protected by copyright, trademark, and other applicable laws.",
                "Our trademarks and trade dress cannot be used in connection with any product or service without prior written consent from the Company."
            ]
        },
        {
            title: "Links to Other Websites",
            list: [
                "Our Service may contain links to third-party websites or services not owned or controlled by the Company.",
                "The Company is not responsible for the content, privacy policies, or practices of any third-party sites or services and will not be liable for any damages or losses resulting from such use.",
                "We strongly advise you to review the terms and privacy policies of any third-party sites you visit."
            ]
        },

        {
            title: "Termination",
            list: [
                "We may terminate or suspend your Account immediately, without notice or liability, for any reason, including breach of these Terms. You may terminate your Account simply by discontinuing use of the Service."
            ]
        },
        {
            title: "Limitation of Liability",
            list: [
                "To the fullest extent permitted by law, neither the Company nor its suppliers will be liable for any special, incidental, indirect, or consequential damages arising from the use or inability to use the Service, even if advised of the possibility of such damages",
                "The Company provides the Service as a convenience and does not guarantee any damages if a User's visa gets refused",
                "Some states prohibit the exclusion of implied warranties or limitations on liability for incidental or consequential damages, which means some of the limitations mentioned may not apply. In such states, each party's liability will be restricted to the maximum extent allowed by law"
            ]
        },
        {
            title: "'AS IS' and 'AS AVAILABLE' Disclaimer",
            content:[
                "The Service is provided to you 'AS IS' and 'AS AVAILABLE,' with all faults and defects, without any warranty. To the fullest extent permitted by applicable law, the Company, on its own behalf and on behalf of its Affiliates and their respective licensors and service providers, expressly disclaims all warranties—whether express, implied, statutory, or otherwise—concerning the Service. This includes all implied warranties of merchantability, fitness for a particular purpose, title, and non-infringement, as well as any warranties arising from the course of dealing, performance, usage, or trade practices. Specifically, the Company does not guarantee that the Service will meet your needs, achieve specific results, be compatible with other software or systems, operate without interruptions, meet performance or reliability standards, or be free from errors, nor that any errors will be corrected"
            ],
            list: [
                <>
               <p>Without limiting the above, neither the Company nor any of its providers makes any express or implied warranties regarding:</p>
                <ul>
                    <li>The operation or availability of the Service, or any information, content, materials, or products included therein;  </li>
                    <li>The absence of viruses, scripts, trojan horses, worms, malware, time bombs, or other harmful components in the Service, its servers, or emails sent by or on behalf of the Company.</li>
                    </ul>
               </>,
               "Some jurisdictions do not allow exclusions of certain warranties or limitations on statutory rights of consumers, so some or all of the above limitations may not apply to you. In such cases, the exclusions and limitations in this section will apply to the fullest extent enforceable under applicable law",
                "Visa Search takes reasonable precautions when handling applicants' documents and shall not be liable for any documents lost during transit due to accidents, theft, natural disasters, or any other reasons beyond Visa Search's control, nor for documents handed directly to the applicant. You agree that the courier service used to return documents is operated by a third-party vendor, which Visa Search does not control. Visa Search disclaims all liability for any loss or damage caused by the courier service, whether due to negligence, accidents, or other causes. Your only recourse will be against the courier company. Visa Search and its representatives will not be liable for any direct, indirect, punitive, incidental, special, or consequential damages resulting from delays, losses, or damages to documents, including passports.",

            ]
        },
        {
            title: "Restrictions",
            content:[
                "The Service is provided to you 'AS IS' and 'AS AVAILABLE,' with all faults and defects, without any warranty. To the fullest extent permitted by applicable law, the Company, on its own behalf and on behalf of its Affiliates and their respective licensors and service providers, expressly disclaims all warranties—whether express, implied, statutory, or otherwise—concerning the Service. This includes all implied warranties of merchantability, fitness for a particular purpose, title, and non-infringement, as well as any warranties arising from the course of dealing, performance, usage, or trade practices. Specifically, the Company does not guarantee that the Service will meet your needs, achieve specific results, be compatible with other software or systems, operate without interruptions, meet performance or reliability standards, or be free from errors, nor that any errors will be corrected"
            ],
            list: [
                "You may use the Platform only for lawful and reasonable purposes and must not engage in any activities that harm the Platform or its content. You are granted a limited license to access and use the Platform solely to avail yourself of the Services, subject to these Terms",

                <>
               <p>You will not:</p>
                <ul>
                    <li>Delete or modify any content on the Platform that you do not own or lack permission to change</li>
                    <li>Post or upload any content that is defamatory, abusive, threatening, harassing, hateful, or otherwise unlawful or infringing on third-party rights</li>
                    </ul>
               </>,
            ]
        },
        {
            title: "Indemnification and Limitation of Liability",
            list: [
                "You agree to indemnify and defend the Company against any losses, liabilities, damages, and costs (including reasonable attorney fees) arising from your use of the Platform or Services, violations of these Terms, or infringements of the Company's rights. The Company will inform you of such claims, and you agree to cooperate and assist in defending against them.",
                "The Company shall not be liable for any content posted, transmitted, exchanged, or received by any user or other person on or through the Platform"
            ]
        },
        {
            title: "Miscellaneous",
            list: [
                "The Company is only responsible for providing information about the visa's and completing required formalities as outlined in official rules and regulations. The Company is not accountable for the approval or rejection of any visa applications",
                "The Company is not liable for any consequences resulting from your decision to overstay at a destination without proper authorization. You will be responsible for any penalties, disciplinary actions, or legal proceedings by authorities in such cases"
            ]
        },
        {
            title: "Force Majeure",
            content: [
                "The Company will not be liable for delays or failures to perform obligations due to events beyond its reasonable control, such as natural disasters."
            ]
        },
        {
            title: "Grievance Redressal",
            content: [
                <>
        You may submit grievances related to the Platform or Services via email at:{" "}
        <a href="mailto:office@visasearch.info" style={{ color: '#1D4ED8', textDecoration: 'underline' }}>
          office@visasearch.info
        </a>.
      </>
            ]
        },
        {
            title: "Governing Law",
            content: [
                "These Terms and related policies are governed by the laws of Australia.",
                "Your use of the Platform may also be subject to other applicable local, state, national, or international laws."
            ]
        },
        {
            title: "Dispute Resolution",
            content: [
                "For any disputes regarding these Terms, you should first attempt to resolve the issue informally by contacting the Company.",
                "If the dispute is not resolved within 45 days, it will be addressed through arbitration, with a sole arbitrator appointed by the Company in accordance with the Arbitration and Conciliation Act, 1996.",
                "Arbitration proceedings will be conducted in English and must conclude within 180 days from the dispute referral date."
            ]
        },
        {
            title: "Severability and Waiver",
            list: [
                "If any provision of these Terms is found to be unenforceable or invalid, that provision will be interpreted to achieve its intended purpose as closely as possible, while the remaining provisions will continue to be effective.",
                "The failure to exercise any right under these Terms does not affect a party’s ability to exercise that right later, nor does a waiver of a breach constitute a waiver of any subsequent breaches."
            ]
        },
        {
            title: "Translation Interpretation",
            content: [
                "These Terms may have been translated if provided to you in another language. In case of any dispute, the original English text will prevail",
            ]
        },
        {
            title: "Changes to These Terms",
            content: [
                "We reserve the right to modify or replace these Terms at any time. If the changes are significant, we will provide at least 30 days' notice before the new terms take effect.",
                "By continuing to use our Service after changes take effect, you agree to the revised terms. If you do not agree, please stop using the website and the Service."
            ]
        }
    ];
    
    
      
      
      return (
        <>
        <NavbarNew tab={"visa"} aboutus={true}/>
            <section className="relative lg:pt-24 pt-[74px] overflow-hidden" style={{ backgroundColor: '#000000' }}>
                <div className="container text-center text-white py-10">
                    <p className="text-lg font-bold mb-5">Terms of Use for Visa Search</p>
                    <h1 className="font-bold text-4xl lg:text-6xl">Your Rights and Responsibilities</h1>
                </div>
            </section>

            <section className="relative">
                <div style={{paddingLeft:'45px',paddingRight:'25px'}}>
                            <div className="p-1">
                                {termsOfUseData.map((section, index) => (
                                    <div key={index}>
                                        {/* <h5 className="text-xlabc font-semibold mb-4 mt-4">{`${index+1}.${section.title}`}</h5> */}
                                        <h5 className="text-xlabc font-semibold mb-4 mt-4">{section.title}</h5>

                                        {section.content && section.content.map((text, i) => (
                                            <p key={i} className="text-slate-400">{text}</p>
                                        ))}

                                        {section.list && (
                                            <ul className="list-none text-slate-400 mb-4 mt-4">
                                                {section.list.map((item, i) => (
                                                    <li key={i} className="flex items-start mb-4 mt-4">
                                                        <span className="text-violet-600 text-base" style={{ marginRight: '8px' }}><span style={{display:'inline-flex'}}>●</span></span>
                                                        <span>{item}</span>
                                                    </li>
                                                ))}
                                            </ul>
                                        )}
                                    </div>
                                ))}
                            </div>
                </div>
            </section>
            <Footer />
        </>
    );
}