import React, { useEffect, useState, useRef } from "react";
import Footer from "../../components/footer";
import star from "../../assets/images/rating-star.png";
import next from "../../assets/images/next.png";
import prev from "../../assets/images/previous.png";
import check from "../../assets/images/tick.png";
import travel from "../../assets/images/travel-insurance.png";
import { Icon } from "@iconify/react";

import airplaneTakeoffDuotone from "@iconify/icons-ph/airplane-takeoff-duotone";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  BiPlusCircle,
  BiDoorOpen,
  BiWallet,
  BiMinusCircle,
  IoMdClose,
  RiExchangeDollarLine,
  MdWorkOff,
  GiEntryDoor,
} from "../../assets/icons/vander";
import {
  Users,
  Calendar,
  Clock,
} from "feather-icons-react/build/IconComponents";
import NavbarNew from "../../components/navbar-new";
import { dataArray } from "../../data/data";
import TinySlider from "tiny-slider-react";
import axios from "axios";
import { EXCHANGE_BASE_URL, REST_API_END_POINT } from "../../constants/DefaultValue";
import { empty } from "../../common/Helper";
import req from '../../images/how to/image.jpg'
import apply from '../../images/how to/image (1).jpg'
import globe from '../../images/how to/image (3).jpg'
import sts from '../../images/how to/image (2).jpg'
import moment from "moment";
import localforage from "localforage";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NotificationNav from "../../components/notificationNav";
import { Grid } from "@material-ui/core";
import { DNA } from "react-loader-spinner";

const Entry = [
  { id: 1, label: "Single Entry" },
  { id: 2, label: "Multiple Entry" },
  { id: 3, label: "Family Entry" },
  { id: 4, label: "Work Entry" },
];
const VISA_TYPE = [
  { name: 'Travel', id: 1 },
  { name: 'Work', id: 2 },
  { name: 'Student', id: 3 },
  { name: 'Permanent', id: 4 },

];

const newImg = [
  { image_url:globe },
  { image_url: req },
  { image_url: apply },
  { image_url: sts },
];
const settings = {
  items: 1,
  controls: false, 
  mouseDrag: true,
  loop: true,
  rewind: true,
  autoplay: false,
  autoplayButtonOutput: false,
  navPosition: "bottom",
  nav: false,
  gutter: 0,
};

const settings1 = {
  items: 1,
  controls: true,
  mouseDrag: true,
  loop: true,
  rewind: true,
  autoplay: true,
  autoplayButtonOutput: false,
  autoplayTimeout: 3000,
  navPosition: "bottom",
  nav: false,
  speed: 400,
  gutter: 0,
  controlsText: ['<i class="mdi mdi-chevron-left "></i>', '<i class="mdi mdi-chevron-right"></i>']
};
const parseDescription = (description) => {
  const sentences = description.split(/[.]/); 
  const trimmedSentences = sentences.filter(sentence => sentence.trim() !== '');

  const parsedSentences = trimmedSentences.map(sentence => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = sentence;
    
    if (tempDiv.querySelector('ul')) {
      const listItems = Array.from(tempDiv.querySelectorAll('li')).map(li => li.textContent);
      return [tempDiv.textContent || tempDiv.innerText || '', ...listItems];
    } else {
      return [tempDiv.textContent || tempDiv.innerText || ''];
    }
  });
  
  return parsedSentences.flat();
}
const getData = (source, ids) => {
  if (!ids) return [];
  return ids.split(',').map(id => source.find(item => item.id == id)).filter(item => item !== undefined);
};


const getCurrencyCodeAndSymbol = async (country) => {
  const url = `https://restcountries.com/v3.1/name/${country}`;
  try {
    const response = await fetch(url);
    const data = await response.json();
    const index = country.toLowerCase() === 'india' ? 1 : 0;
    if (data && data[index] && data[index].currencies) {
      const currencyCode = Object.keys(data[index].currencies)[0];
      const currencySymbol = data[index].currencies[currencyCode].symbol;
      return { currencyCode, currencySymbol };
    } else {
      console.error('Error fetching currency code for country:', country);
      return null;
    }
  } catch (error) {
    console.error('Error:', error);
    return null;
  }
};


const getExchangeRate = async (fromCountry, toCountry) => {
  const cacheKey = `${fromCountry}-${toCountry}`;
  const cachedData = await localforage.getItem(cacheKey);

  if (cachedData) {
    return cachedData;
  }

  try {
    const [fromCurrencyDetails, toCurrencyDetails] = await Promise.all([
      getCurrencyCodeAndSymbol(fromCountry),
      getCurrencyCodeAndSymbol(toCountry),
    ]);

    if (!fromCurrencyDetails || !toCurrencyDetails) {
      console.error('Invalid country name provided.');
      return null;
    }

    const { currencyCode: fromCurrency, currencySymbol: fromSymbol } = fromCurrencyDetails;
    const { currencyCode: toCurrency, currencySymbol: toSymbol } = toCurrencyDetails;

    const url = `${EXCHANGE_BASE_URL}/${fromCurrency}/${toCurrency}`;
    const response = await fetch(url);
    const data = await response.json();

    if (data && data.conversion_rate) {
      const exchangeRate = data.conversion_rate;
      const rateData = {
        exchangeRate,
        fromSymbol,
        toSymbol,
        fromCurrency,
        toCurrency
      };

      await localforage.setItem(cacheKey, rateData);
      return rateData;
    } else {
      console.error('Error fetching exchange rate:', data.error);
    }
  } catch (error) {
    console.error('Error:', error);
  }
  return null;
};


export default function VisaDetails() {
  const params = useParams();
  const navigate = useNavigate()
  const { tab } = useParams();
  const [placeBid, setPlaceBid] = useState(false);
  const country_id = params.country_id;
  const location = useLocation()
  const [sliderIndex,setSliderIndex] = useState(0)
  const [visaTypeId,setVisaTypeId] = useState(null)
  const [activeIndex, setActiveIndex] = useState(0);
  const [activeIndexx, setActiveIndexx] = useState(0);
  const [countryDetails, setCountryDetails] = useState({});
  const [activeDocument, setActiveDocument] = useState(null);
  const [currentFormData,setCurrentFormData] = useState({})
  const [userData,setUserData] = useState({})
  const [visaDetail, setVisaDetails] = useState([]);
  const visaId = visaDetail?.id
  const [documents,setDocuments] = useState([])
  const [travellers, setTravellers] = useState(1);
  const [currentDate, setCurrentDate] = useState("");
  const sliderRef = useRef(null);
  const [docReqData,setDocReqData] = useState([])
  const [docName,setDocName] = useState([])
  const [currencyExchange,setCurrencyExchange] = useState(null)
  const [countryPrefer,setCountryPrefer] = useState({})
  const [faqs, setFaq] = useState([])
  const [loading, setLoading] = useState(true);
  const [visaleng, setVisaleng] = useState('');
  useEffect(() => {
    const getCurrentDate = () => {
      const options = { day: "numeric", month: "long", };
      const today = new Date();
      return today.toLocaleDateString("en-US", options);
    };
    setCurrentDate(getCurrentDate());
    setVisaTypeId(JSON.parse(localStorage.getItem('visaTypeId')))
    setCountryPrefer(JSON.parse(localStorage.getItem('country_preference')))
    setUserData(JSON.parse(localStorage.getItem('userVisa')))
  }, []);


  const handleNext = () => {
    if (sliderIndex < visaDetail.length - 1) {
      const newIndex = sliderIndex + 1;
      setSliderIndex(newIndex);
      setVisaleng((visaDetail.length-1)-newIndex)
      setCurrentFormData(visaDetail[newIndex]);
      sliderRef.current.slider.goTo("next");
    }

  };

  const handlePrev = () => {
    if (sliderIndex > 0) {
      const newIndex = sliderIndex - 1;      
      setSliderIndex(newIndex);
      setVisaleng(visaleng+1)
      setCurrentFormData(visaDetail[newIndex]);
      sliderRef.current.slider.goTo("prev");
    }
  };

//below function are for the currency exchange rate
const getCurrencyCode = async (country) => {
  const REST_COUNTRIES_URL = `https://restcountries.com/v3.1/name/${country}`;
  try {
    const response = await fetch(REST_COUNTRIES_URL);
    const data = await response.json();

    if (data && data[0] && data[0].currencies) {
      const currencyCode = Object.keys(data[0].currencies)[0];
      return currencyCode;
    } else {
      console.error('Error fetching currency code for country:', country);
      return null;
    }
  } catch (error) {
    console.error('Error:', error);
    return null;
  }
};
  



  const fetchVisaDetails = () => {
    if(visaTypeId){
    axios
      .get(`${REST_API_END_POINT}details/${country_id}/${visaTypeId}`)
      .then((res) => {
        if (res.data.status === 1) {
          setCountryDetails(res.data.countryDetails);
          setDocuments(res.data.documents)
          setCurrentFormData(res.data.visaTypeDetails[0])
          setVisaDetails(res.data.visaTypeDetails);
          setVisaleng(res.data.visaTypeDetails.length-1)
          setFaq(res.data.faq);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    }
  };

  const initialFAQ = getData(faqs,currentFormData?.faq_ids)

  const toggleAccordion = (index) => {
    if (activeIndex === index) {
        setActiveIndex(0);
    } else {
        setActiveIndex(index);
    }
}
  const toggleAccordions = (index) => {
    if (activeIndexx === index) {
        setActiveIndexx(0);
    } else {
        setActiveIndexx(index);
    }
}

  const handleClick = (data) => {
    setActiveIndex(0);
    setDocReqData(getData(documents,data.document_ids));
      setDocName(data.document_type)
      setActiveDocument(data.document_type);
  };


  useEffect(() => {
    const fetchData = async () => {
      if (currentFormData?.documentsRequired?.length > 0) {
        handleClick(currentFormData?.documentsRequired[0]);
      }
      if (countryPrefer?.name && countryDetails?.name) {
        const rateData = await getExchangeRate(countryPrefer.name, countryDetails.name);
        if (rateData) {
          setCurrencyExchange(rateData);
        }
      }
    };
    fetchData();
  }, [currentFormData?.documentsRequired, countryDetails, countryPrefer?.name]);
  
  

  const visaType = VISA_TYPE.find((v) => v.id === currentFormData?.visa_type);
  const personEntry = Entry.find((v) => v.id === currentFormData?.choose_entry_id);
  useEffect(() => {
    // setLoading(true);
    fetchVisaDetails();
    // const timer = setTimeout(() => {
    //   setLoading(false);
    // }, 5000);
    // return () => clearTimeout(timer);
  }, [country_id,visaTypeId]);


  const [showSection, setShowSection] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 500) {
        // Change 500 to the scroll position where you want the section to appear
        setShowSection(true);
      } else {
        setShowSection(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);




  const handleDecrease = () => {
    if (travellers > 1) {
      setTravellers(travellers - 1);
    }
  };

  const handleIncrease = () => {
    setTravellers(travellers + 1);
  };

  useEffect(() => {
    document.documentElement.classList.add("dark");
  }, []);

  const handleDownload=(data)=>{
    try {
     
      const link = document.createElement('a');
      link.href = data.url;
      link.setAttribute('download', data.name);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

    } catch (error) {
      console.error('Error downloading file:', error);
    }
  }
//   const formatDescription = (description) => {
//     description = description?.replace(/<p><br><\/p>/g, '<br>');
//     description = description?.replace(/<p>/g, '<p>• ');
//     description = description?.replace(/<ul><li>/g, '');
//     description = description?.replace(/<li>/g, '<p>• ');
//     description = description?.replace(/<\/li>/g, '</p>');
//     description = description?.replace(/<\/ul>/g, '');

//     if (description && !description.startsWith('<p>• ')) {
//         description = '<p>• ' + description;
//     }

//     return description;
// };

const formatDescription = (description) => {
  if (!description) return '';

  // Handle ordered lists
  let listIndex = 1;
  if (description.includes('<ol>')) {
    description = description.replace(/<ol>/g, '');
    description = description.replace(/<\/ol>/g, '');
    description = description.replace(/<li>/g, () => {
      const item = `${listIndex++}. `;
      return `<div class="list-item">${item}`;
    });
  }

  // Handle unordered lists
  if (description.includes('<ul>')) {
    description = description.replace(/<ul>/g, '');
    description = description.replace(/<\/ul>/g, '');
    description = description.replace(/<li>/g, '<div class="list-item" style="margin-left:35px;">● ');
  }

  description = description.replace(/<\/li>/g, '</div>');

  // Clean up empty paragraphs if any
  description = description.replace(/<p><\/p>/g, '');

  return description;
};

  
  const expireDate = moment.unix(currentFormData?.notification_date);
  const formattedExpireDate = expireDate.format('MMMM D, YYYY');

  const handleClickApply=()=>{
    if(empty(userData)){
      navigate('/signup-visa/visa')
    }else{
      axios.post(`${REST_API_END_POINT}user-apply-for-visa`,{
        user_id:userData.user_id,visa_type:visaTypeId,formId:currentFormData.id
      })
      .then((res)=>{
        if(res.data.status==1){
          toast.success(res.data.message)
        }else{
          toast.error(res.data.message)
        }
      })
      .catch((err)=>{
        console.log(err)
      })
    }
  }

  return (
  <>
      {loading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
          }}
        >
        <DNA
        visible={true}
        height="140"
        width="140"     
        ariaLabel="dna-loading"
        wrapperStyle={{}}
         wrapperClass="dna-wrapper"
           />
        </div>
      ) : (<>
      
        {currentFormData?.addNotification === 1&&<NotificationNav notificationData={currentFormData?.notification} />}
        <NavbarNew noHomeTab={true} tab={tab} notification={currentFormData?.addNotification === 1?true:false}  />
      
        { !empty(visaDetail) ? (
           <section
           className="relative md:py-24 py-16"
           style={{ marginTop:currentFormData?.addNotification === 1?"70px": "20px" }}
         >
           <div className="container">
           {/* {(currentFormData?.addNotification === 1) && (
                          <div style={{ display: "flex", justifyContent: "center" }}>
                              <div className="flex items-center p-3 mt-3 rounded-lg" style={{ width: '100%', backgroundColor: 'grey', marginBottom: '10px', justifyContent: 'space-between', color: 'white' }}>
  
  
                                  <div className="ms-2 items-center" style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'row', textAlign: 'left' }}>
                                      <BsExclamationOctagon style={{ color: 'white', marginRight: '10px' }} className="text-3xl" />
                                      <div>
                                          <span className="block" >{currentFormData?.notification}</span>
                                      </div>
                                  </div>
                                  <div className="ms-2" style={{ display: 'flex', justifyContent: 'flex-end', flexDirection: 'column', textAlign: 'right' }}>
                                     
                                  </div>
  
                              </div>
  
                          </div>
                      )} */}
             <div className="md:flex anothermt1">
               <div
                 className="lg:w-3/5 md:w-full md:mb-8 item-ml md:order-2"
                
               >
                 <div
                   style={{
                     position: "relative",
                     width: "100%",
                     maxWidth: "100%",
                     overflow: "hidden",
                   }}
                 >
                   <img
                     style={{
                       width: "100%",
                       left: "0",
                       minWidth: "300px",
                       height: "365px",
                       borderRadius: "10px",
                       objectFit: "cover",
                     }}
                     src={countryDetails?.image}
                     alt="VIsa Search Image"
                   />
                   <div
                     className="visa-image"
                     style={{ display: "flex", justifyContent: "space-between" }}
                   > 
                     {countryDetails?.name} {currentFormData?.visa_name} 
  
                    <div style={{display:'flex',justifyContent:'space-between',width:'83px',paddingRight:'15px',paddingTop:'20px'}}>
                       {/* <div> */}
                         {/* {countryDetails?.is_visa_on_arrival===1?(
                          <span
                          className="visa-image1"
                          style={{ paddingRight: "15px" }}
                        >
                        Visa arrival on  <br />
                          <span
                            className="visa-image1"
                            style={{ display: "flex", justifyContent: "flex-end" }}
                          >
                            {" "}
                           
                            {countryDetails?.visa_arrival} {countryDetails?.visa_arrival_unit} 
                          </span>
                        </span>
                         ):( */}
                            {/* <span
                            className="visa-image1"
                            style={{ paddingRight: "5px" }}
                          >
                          Visa arrival on <br />
                            <span
                              className="visa-image1"
                              style={{ display: "flex", justifyContent: "flex-end" }}
                            >
                              {" "}
                             
                              {currentFormData?.visa_arrives_in} {currentFormData?.visa_arrives_in_unit} 
                            </span>
                          </span> */}
                         {/* ) } */}
                       {/* </div> */}
                       <div>
                        
                          <span
                          className="visa-image1"
                          style={{ paddingRight: "15px" }}
                        >
                          {currentFormData?.review} <img
                           src={star}
                           height={27}
                           width={27}
                           alt="Image Description"
                           className="badge-image"
                           style={{ marginLeft: "0px", }}
                         />
                         <br />
                          <span
                            className="visa-image1"
                            style={{ display: "flex", justifyContent: "flex-end" ,fontSize:'14px'}}
                          >
                            Rating({currentFormData?.reviewers_count})
                          </span>
                        </span>
                        
                       </div>
                    </div>
                   
                   { currentFormData?.is_govt_partner ===1 && <span
                       className="color"
                       style={{
                         top: "-280px",
                         right: "10px",
                         position: "absolute",
                       }}
                     >
                       <li className="inline-block m-2">
                         <Link
                           to="#"
                           className="text-slate-400 bg-gray-50 dark:bg-slate-801 official-partner font-semibold rounded-lg shadow items-center"
                          style={{display: 'flex',overflow: 'hidden', textOverflow: 'ellipsis', height:'fit-content'}}>
                           Official Partner With {countryDetails?.name} Government
                         </Link>
                       </li>
                     </span>}
                   </div>
                 </div>
   
                 {/* <div
                   className="lg:flex"
                   style={{ marginLeft: "0px", marginTop: "40px" }}
                 >
                   <div
                     className="p-6 w-full"
                     style={{
                       display: "flex",
                       flexDirection: "column",
                       alignItems: "center",
                       justifyContent: "center",
                     }}
                   >
                     <div
                       className="flex flex-wrap justify-between wrapp"
                       style={{ gap: "20px" }}
                     >
                       
                       <div style={{ marginBottom: "20px", marginRight: "30px" }}>
                         <div className="popover-container1">
                           <div className="flex items-center">
                             <div
                               className="buttonn"
                               style={{ textAlign: "center" }}
                             >
                               <div className="buttonn-layer1"></div>
                               <Link
                                 to="#"
                                 style={{
                                   color: "#000000",
                                   fontWeight: "bold",
                                   fontSize: "18px",
                                   cursor: "pointer",
                                   marginLeft: "15px",
                                   borderBottom: "2px solid #000",
                                   paddingBottom: "3px",
                                   whiteSpace: "nowrap",
                                 }}
                                 className="under"
                                 id="button"
                               >
                                 Track Your Application
                               </Link>
                             </div>
                           </div>
                         </div>
                       </div>
   
                       <div
                         className="flex items-center justify-center"
                         style={{ marginTop: "-15px", textAlign: "center" }}
                       >
                         <div className="popover-container1">
                           <Link
                             className="color"
                             style={{
                               marginLeft: "10px",
                               color: "#000000",
                               fontWeight: "bold",
                               fontSize: "20px",
                               cursor: "pointer",
                               textAlign: "center",
                               textDecoration: "underline",
                             }}
                           >
                            Rating : {currentFormData?.review}
                           </Link>
                           <div
                             className="popover-top-review"
                             style={{ textAlign: "left" }}
                           >
                             <div className="popover">
                               <h3 className="popover-heading1 color-price">
                                 
                               </h3>
                               <p className="popover-paragraph1 color-price">
                                 Rating From Our Recent Clients
                               </p>
                             </div>
                           </div>
                         </div>
                         <img
                           src={star}
                           height={32}
                           width={32}
                           alt="Image Description"
                           className="badge-image"
                           style={{ marginLeft: "0px", marginTop: "3px" }}
                         />
                       </div>
   
                       <div style={{ marginBottom: "10px" }}>
                         <Link
                           to="#"
                           className="color"
                             style={{
                               marginLeft: "10px",
                               color: "#000000",
                               fontWeight: "bold",
                               fontSize: "20px",
                               cursor: "pointer",
                               textAlign: "center",
                               textDecoration: "underline",
                             }}
                         >
                           Plan Your Journey
                         </Link>
                       </div>
                     </div>
                   </div>
                 </div> */}
               </div>
   
               <div
                 className="lg:w-2/5 md:order-1 md:mt-0 mt-6 md:mt-0 lg:mt-0 mb-8"
                 style={{
                   width: "100%",
                   "@media (max-width: 560px)": {
                     width: "100%",
                   },
                   cursor: "pointer",
                  
                 }}
               >
                 <div>
                   <div className="sticky top-20"></div>
                   <div
                     className="relative"
                     //  style={{
                     //   width: '417px',
                     //   '@media (max-width: 560px)': {
                     //     width: '317px',
                     //   }
                     // }}
                   >
                     <div
                       className="tiny-one-icon-item"
                       style={{ marginTop: "-8px" }}
                     > 
                       <TinySlider settings={settings} ref={sliderRef}>
                       {visaDetail?.map((ele, index) => {
  
                        let segments = ele?.card_notification?.split('.')?.filter(sentence => sentence.trim() !== '');
                        return(
                           <div className="tiny-slide"
                            key={index}
                            >
                             <div className="m-2 bg-white dark:bg-slate-900 rounded-lg dark:shadow-gray-800" >
                               <div
                                 className="offer-badge"
                                 style={{ marginTop: "-70px" }}
                               > 
                                 <img
                                   src={check}
                                   height={80}
                                   width={80}
                                   alt="Image Description"
                                   className="badge-image"
                                 />
                                 <div className="badge-text-container">
                                   <span className="badge-text1">
                                     Your visa outcome arrives in
                                   </span>
                                   <br />
                                   <span className="badge-text">
                                     {currentFormData?.visa_arrives_in} {' '} {currentFormData?.visa_arrives_in_unit}
                                   </span>
                                 </div>
                               </div>
   
                               <div className="product-card"
                               
                                style={{minHeight:'325px',maxHeight:'433px'}}>
                                  <div className="  scroll-container" 
  style={{ marginTop:'-23px',
   textAlign: "left",
    paddingLeft: '15px',
     paddingTop: '10px',
      zIndex: 999,
      //  border: '1px solid #dbdada',
        borderRadius: '10px',
        // backgroundColor:'#dbdada',
        cursor: "all-scroll",
        height:'268px',
        overflowY: "scroll", // Ensure scrollbar appears only on the y-axis
        msOverflowStyle: "none", // Internet Explorer 10+
        scrollbarWidth: "thin", // Firefox
         }}>
                                 <div
                                   className="color-price"
                                   style={{ marginTop: "70px" }}
                                 >
                                   <div className="color-option">
                                     <span style={{ marginRight: "10px" }}>
                                       <Users style={{ color: "black" }} />
                                     </span>
                                     <span className="color">Travellers</span>
                                   </div>
                                   <div className="price">
                                     <div className="color-option">
                                       
                                       <span
                                         onClick={handleDecrease}
                                         style={{
                                           marginRight: "10px",
                                           fontSize: "20px",
                                           cursor: "pointer",
                                         }}
                                       >
                                         <BiMinusCircle
                                           style={{ color: "black" }}
                                         />
                                       </span>
                                      
                                       <span className="color">{travellers}</span>
                                      
                                       <span
                                         onClick={handleIncrease}
                                         style={{
                                           marginRight: "10px",
                                           fontSize: "20px",
                                           cursor: "pointer",
                                         }}
                                       >
                                         <BiPlusCircle
                                           style={{ color: "black" }}
                                         />
                                       </span>
                                     
                                     </div>
                                   </div>
                                 </div>
                                 <div className="color-price mt-4">
                                   <div className="color-option">
                                     <div
                                       className="popover-container1"
                                       style={{
                                         display: "flex",
                                         alignItems: "center",
                                       }}
                                     >
                                       <span
                                         style={{
                                           marginRight: "10px",
                                           fontSize: "25px",
                                         }}
                                       >
                                         <GiEntryDoor style={{ color: "black" }} />
                                       </span>
                                       <span className="color">
                                       {ele.choose_entry_id === 1 ? 'Single' : ele.choose_entry_id === 2 ? 'Multiple' : ele.choose_entry_id === 3 ? 'Family' : 'Work'} Entry
                                       </span>
                                     </div>
                                   </div>
                                   <div className="price">
                                     <div className="color-option">
                                       <span className="color">
                                         <span
                                           style={{
                                             fontWeight: "bold",
                                             color: "#000",
                                           }}
                                         >
                                           {ele.day_of_stay} {" "} {ele.day_of_stay_unit}
                                         </span>
                                       </span>
                                     </div>
                                   </div>
                                 </div>
                                 
                                  <div className="color-price">
                                   <div className="color-option">
                                     <div
                                       className="popover-container1"
                                       style={{
                                         display: "flex",
                                         alignItems: "center",
                                       }}
                                     >
                                       <span
                                         style={{
                                           marginRight: "10px",
                                           fontSize: "25px",
                                         }}
                                       >
                                         <BiWallet style={{ color: "black" }} />
                                       </span>
                                       <span
                                         className="color"
                                         style={{ textDecoration: "underline" }}
                                       >
                                         Visa Fees
                                       </span>
                                       <div
                                         className="popover-content11"
                                         style={{ marginLeft: "60px",minHeight:'85px' }}
                                       >
                                         <div className="popover1">
                                           {/* <h3 className="popover-heading1 color-price">
                                             Visa Fees:
                                           </h3> */}
                                           <p className="popover-paragraph1">
                                             Fee charged by the embassy to process
                                             your visa. This includes all taxes and
                                             is directly paid to the government.
                                           </p>
                                         </div>
                                       </div>
                                     </div>
                                   </div>
                                   <div className="price">
                                     <div className="color-option">
                                       <span className="color">
                                         <span
                                           style={{
                                             fontWeight: "bold",
                                             color: "#000",
                                           }}
                                         >
                                           {ele.visa_fee_unit} {ele.visa_fee}
                                         </span>
                                         {/* <span>x 1</span> */}
                                       </span>
                                     </div>
                                   </div>
                                 </div>
                                   {ele.has_our_fee===1 ?(
                                  <div className="color-price">
                                   <div className="color-option">
                                     <div
                                       className="popover-container1"
                                       style={{
                                         display: "flex",
                                         alignItems: "center",
                                       }}
                                     >
                                       <span
                                         style={{
                                           marginRight: "10px",
                                           fontSize: "25px",
                                         }}
                                       >
                                         <BiWallet style={{ color: "black" }} />
                                       </span>
                                       <span
                                         className="color"
                                         style={{ textDecoration: "underline" }}
                                       >
                                         Our Fees
                                       </span>
                                       <div
                                         className="popover-content11"
                                         style={{ marginLeft: "60px" }}
                                       >
                                         <div className="popover1">
                                           {/* <h3 className="popover-heading1 color-price">
                                             Visa Fees:
                                           </h3> */}
                                           <p className="popover-paragraph1">
                                            Visa Search platform and service fee.
                                           </p>
                                         </div>
                                       </div>
                                     </div>
                                   </div>
                                   <div className="price">
                                     <div className="color-option">
                                       <span className="color">
                                         <span
                                           style={{
                                             fontWeight: "bold",
                                             color: "#000",
                                           }}
                                         >
                                           {ele.our_fee_unit} {ele.our_fee}
                                         </span>
                                         {/* <span>x 1</span> */}
                                       </span>
                                     </div>
                                   </div>
                                 </div>):''}
                                 <hr style={{marginTop:'15px',border:'0.5px solid black',}}/>
                                
                                 
                                 
                                 {segments?.map((item,index)=>{
                                  
                                  return(
                                    <div className="color-price" key={index}>
                                    <div className="color-option">
                                      <div
                                        className="popover-container1"
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <span
                                          style={{
                                            marginRight: "10px",
                                            fontSize: "25px",
                                          }}
                                        >
                                          <span
                                            class="mdi--tick"
                                            style={{
                                              color: "#000",
                                              fontSize: "25px",
                                            }}
                                          ></span>
                                        </span>
                                        <span
                                          className="color"
                                         //  style={{ textDecoration: "underline" }}
                                        >
                                         {item}
                                        </span>
                                      </div>
                                    </div>
                                   
                                  </div>
                                  )
                                 })}
   </div>
                                 <div
                                   className="color-price"
                                   style={{
                                     display: "flex",
                                     justifyContent: "center",
                                     alignContent: "center",
                                     alignItems: "center",
                                     flexDirection:'row',
                                     marginTop:'-5px'
                                     
                                   }}
                                 >
                                     <span className="color"  style={{
                                     display: "flex",
                                     justifyContent: "center",
                                     alignContent: "center",
                                     alignItems: "center",
                                     flexDirection:'row'
                                     
                                   }}>
                                    {visaDetail?.length>1&&(
                                     <button
                                       className="inline-block m-2"
                                       onClick={handlePrev}
                                     >
                                       <img
                                         className="px-3 py-2 text-slate-400 text-sm font-semibold rounded-lg shadow items-center"
                                         style={{ cursor: "pointer" }}
                                         width={50}
                                         height={50}
                                         src={prev}
                                       />
                                     </button>
                                     )}
                                       <li className="m-2">
                                         <Link
                                           to={'/insurance'} style={{marginTop:'-360px'}}
                                           className="px-3 py-2 text-slate-400 bg-gray-50 dark:bg-slate-801 text-sm font-semibold rounded-lg shadow items-center"
                                         >
                                           Find  insurance here
                                         </Link>
                                       </li>
                                       {visaDetail?.length>1&&(
                                       <button
                                       className="inline-block m-2"
                                       onClick={handleNext}
                                     >
                                       <span style={{display:'flex',}} >
                                      
                                         <img
                                           className="px-3 py-2 text-slate-400 text-sm font-semibold rounded-lg shadow items-center"
                                           style={{
                                             cursor: "pointer",
                                             marginRight: "15px",
                                           }}
                                           width={50}
                                           height={50}
                                           src={next}
                                         />
                                         
                                       </span>
                                     </button>
                                       )}
                                     </span>
                                     
                                 </div>
                                                             
                                 {/* {visaleng>=1&&
                                 <div style={{display:'flex',
                                  justifyContent:'center',
                                  alignContent:'center',
                                  alignItems:'center',padding:'5px',borderTopRightRadius:'10px',borderTopLeftRadius:'10px',border:'1px solid #e6e6e6',borderBottomLeftRadius:'22px',borderBottomRightRadius:'22px'}}>
                                     <span style={{width:'fit-content'}} className="color">
                                         <span
                                           style={{
                                             fontWeight: "bold",
                                             color: "#000",
                                             display:'flex',
                                            flexDirection:'row',
                                            alignContent:'center',
                                            justifyContent:'center',
                                            gap:'5px',
                                            alignItems:'center'
                                           }}
                                         >
                                           <span className="ri--visa-fill" style={{ color: "black",fontSize:'1.5rem' }} />{visaleng} more visa type
                                         </span>
                                       </span>
                                       </div>
                                       } */}
                              
                               </div>
                             </div>
                           </div>
                       )})}
                       </TinySlider>
                     </div>
                   </div>
                 </div>
               </div>
             </div>
           </div>
   
           <div
             className="group rounded-xl bg-white dark:bg-slate-900 w-full mx-auto lg:max-w-2xl "
             style={{
               zIndex: 1,
               marginTop: "-10px",
               display: "flex",
               justifyContent: "center",
               alignContent: "center",
               alignItems: "center",paddingLeft:'10px',paddingRight:'9px'
             }}
           >
             <Link
               className="font-semibold transition-all duration-500 ease-in-out text-white bg-violet-6001 "
               type="button"
               to={'/consultants'}
              //  onClick={handleClickApply}
               style={{
                 color: "black",
                 width: "550px",
                 height: "70px",
                 display: "flex",
                 justifyContent: "center",
                 textAlign: "center",
                 padding: "22px",
                 borderRadius: "14px",
               }}
             >
               Find migration consultant
             </Link>
           </div>
   
           <hr
             style={{
               marginTop: "-36px",
               borderColor: "black",
               borderWidth: "1px",
             }}
           />
  
           <Grid direction="row">
  
           </Grid>
           <div className="container"  style={{ marginTop: '50px', }}>
   <div className="lg:flex" >
   <div className="w-full" >
      <div
        className="grid-container paddingPr"
        style={{
          display: 'grid',
          width: '100%',
        }}
      >
                 <div className="color-price margnItem1" style={{ marginTop: "30px" }}>
                   <div className="flex items-center">
                     <div
                       style={{
                         fontSize: "26px",
                         color: "#000000",
                         marginRight: "18px",
                       }}
                     >
                       <Icon
                         style={{ color: "black" }}
                         icon={airplaneTakeoffDuotone}
                       />
                     </div>
   
                     <div
                       className="color-option"
                       style={{ display: "flex", flexDirection: "column" }}
                     >
                       <Link
                         className="color"
                         style={{
                           fontSize: "18px",
                           color: "#000",
                           marginBottom: "4px",
                         }}
                       >
                         Type Of Visa
                       </Link>
                       <div className="popover-container1">
                         <Link
                           className="color"
                           style={{
                             fontSize: "16px",
                             color: "#000000",
                             textDecoration: "underline",
                           }}
                         >
                            {visaType?.name}
                         </Link>
                         <div className="popover-content11">
                           <div className="popover1">
                             {/* <h3 className="popover-heading1 color-price">
                               Visa Type: {visaType?.name}
                             </h3> */}
                             <p className="popover-paragraph1">
                             {currentFormData?.visa_type_description}
                             </p>
                           </div>
                         </div>
                       </div>
                     </div>
                   </div>
                 </div>
   
                 <div className="color-price margnItem1" style={{ marginTop: "30px" }}>
                   <div className="flex items-center">
                     <div
                       style={{
                         fontSize: "26px",
                         color: "#000000",
                         marginRight: "18px",
                       }}
                     >
                       <Clock style={{ color: "black" }} />
                     </div>
                     <div
                       className="color-option"
                       style={{ display: "flex", flexDirection: "column" }}
                     >
                       <Link
                         className="color"
                         style={{
                           fontSize: "18px",
                           color: "#000000",
                           marginBottom: "4px",
                         }}
                       >
                         Validity Period
                       </Link>
                       <div className="popover-container1">
                         <Link
                           className="color"
                           style={{
                             fontSize: "16px",
                             color: "#000000",
                             textDecoration: "underline",
                           }}
                         >
                           {currentFormData?.validity} {currentFormData.validity_unit}
                         </Link>
                         <div className="popover-content11">
                           <div className="popover1">
                             {/* <h3 className="popover-heading1 color-price">
                               Validity Period: {currentFormData?.validity} {currentFormData.validity_unit}
                             </h3> */}
                             <p className="popover-paragraph1">
                              {currentFormData?.validity_description}
                             </p>
                           </div>
                         </div>
                       </div>
                     </div>
                   </div>
                 </div>
   
                 <div className="color-price margnItem1" style={{ marginTop: "30px" }}>
                   <div className="flex items-center">
                     <div
                       style={{
                         fontSize: "26px",
                         color: "#000000",
                         marginRight: "18px",
                       }}
                     >
                       <BiDoorOpen style={{ color: "black" }} />
                     </div>
                     <div
                       className="color-option"
                       style={{ display: "flex", flexDirection: "column" }}
                     >
                       <Link
                         className="color"
                         style={{
                           fontSize: "18px",
                           color: "#000000",
                           marginBottom: "4px",
                         }}
                       >
                         Person Entry
                       </Link>
                       <div className="popover-container1">
                         <Link
                           className="color"
                           style={{
                             fontSize: "16px",
                             color: "#000000",
                             textDecoration: "underline",
                           }}
                         >
                           {personEntry?.label}
                         </Link>
                         <div className="popover-content11">
                           <div className="popover1">
                             {/* <h3 className="popover-heading1 color-price">
                             {personEntry?.label}
                             </h3> */}
                             <p className="popover-paragraph1">
                               {currentFormData?.entry_description}
                             </p>
                           </div>
                         </div>
                       </div>
                     </div>
                   </div>
                 </div>
   
                 <div className="color-price margnItem1" style={{ marginTop: "30px" }}>
                   <div className="flex items-center">
                     <div
                       style={{
                         fontSize: "26px",
                         color: "#000000",
                         marginRight: "18px",
                       }}
                     >
                       <Calendar style={{ color: "black" }} />
                     </div>
    
                     <div
                       className="color-option"
                       style={{ display: "flex", flexDirection: "column" }}
                     >
                       <Link
                         className="color"
                         style={{
                           fontSize: "18px",
                           color: "#000000",
                           marginBottom: "4px",
                         }}
                       >
                         Stay up to
                       </Link>
                       <div className="popover-container1">
                         <Link
                           className="color"
                           style={{
                             fontSize: "16px",
                             color: "#000000",
                             textDecoration: "underline",
                           }}
                         >
                           {currentFormData?.lengthDays} {currentFormData?.lengthDay_unit}
                         </Link>
                         <div className="popover-content11">
                           <div className="popover">
                             {/* <h3 className="popover-heading1 color-price">
                               Stay up to: {currentFormData?.lengthDays} {currentFormData?.lengthDay_unit}
                             </h3> */}
                             <p className="popover-paragraph1">
                              {currentFormData?.lengthDaysDescription}
                             </p>
                           </div>
                         </div>
                       </div>
                     </div>
                   </div>
                 </div>
   
                 <div className="color-price margnItem1" style={{ marginTop: "30px" }}>
                   <div className="flex items-center">
                     <div
                       style={{
                         fontSize: "26px",
                         color: "#000000",
                         marginRight: "18px",
                       }}
                     >
                       <MdWorkOff style={{ color: "black" }} />
                     </div>
                     <div
                       className="color-option"
                       style={{ display: "flex", flexDirection: "column" }}
                     >
                       <Link
                         className="color"
                         style={{
                           fontSize: "18px",
                           color: "#000000",
                           marginBottom: "4px",
                         }}
                       >
                         On this Visa
                       </Link>
                       <div className="popover-container1">
                         <Link
                           className="color"
                           style={{
                             fontSize: "16px",
                             color: "#000000",
                             textDecoration: "underline",
                           }}
                         >
                           Work {currentFormData?.workStatus === 2 && "Not"} Allowed
                         </Link>
                         <div className="popover-content11">
                           <div className="popover">
                             {/* <h3 className="popover-heading1 color-price">
                               Work
                               {currentFormData?.workStatus === 2 && " Not"} Allowed
                             </h3> */}
                             <p className="popover-paragraph1">
                              {currentFormData?.workStatusDescription}
                             </p>
                           </div>
                         </div>
                       </div>
                     </div>
                   </div>
                 </div>
   
                 <div className="color-price margnItem1" style={{ marginTop: "30px" }}>
                   <div className="flex items-center">
                     <div
                       style={{
                         fontSize: "26px",
                         color: "#000000",
                         marginRight: "18px",
                       }}
                     >
                       <img
                         src={travel}
                         height={25}
                         width={25}
                         alt="Image Description"
                         className="badge-image"
                       />
                     </div>
                     <div
                       className="color-option"
                       style={{ display: "flex", flexDirection: "column" }}
                     >
                       <Link
                         className="color"
                         style={{
                           fontSize: "18px",
                           color: "#000000",
                           marginBottom: "4px",
                         }}
                       >
                         Travel Insurance
                       </Link>
                       <div className="popover-container1">
                         <Link
                           className="color"
                           style={{
                             fontSize: "16px",
                             color: "#000000",
                             textDecoration: "underline",
                           }}
                         >
                           {currentFormData?.insuranceStatus === 2 && "Not"}Recommended
                         </Link>
                         <div className="popover-content11">
                           <div className="popover">
                             {/* <h3 className="popover-heading1 color-price">
                               Travel Insurance:
                             </h3> */}
                             <p className="popover-paragraph1">
                              {currentFormData?.insuranceStatusDescription}
                             </p>
                           </div>
                         </div>
                       </div>
                     </div>
                   </div>
                 </div>
   
                 <div className="color-price margnItem1" style={{ marginTop: "30px" }}>
                   <div className="flex items-center">
                     <div
                       style={{
                         fontSize: "26px",
                         color: "#000000",
                         marginRight: "18px",
                       }}
                     >
                       <RiExchangeDollarLine style={{ color: "black" }} />
                     </div>
                     <div
                       className="color-option"
                       style={{ display: "flex", flexDirection: "column" }}
                     >
                       <Link
                         className="color"
                         style={{
                           fontSize: "18px",
                           color: "#000000",
                           marginBottom: "4px",
                           marginTop: "15px",
                         }}
                       >
                         {countryDetails?.name} Currency
                       </Link>
                       <Link
                         className="color"
                         style={{ fontSize: "16px", color: "#000000" }}
                       >
                         Exchange Rate
                       </Link>
                       <Link
                      className="color"
                      style={{ fontSize: "16px", color: "#000000" }}
                        >
                       {currencyExchange ? (
               <>
             {currencyExchange.fromSymbol} 1 {currencyExchange.fromCurrency} = 
             {currencyExchange.toSymbol} {currencyExchange.exchangeRate} {currencyExchange.toCurrency}
           </>
         ) : (
          "Loading..."
           )}
                        </Link>
                     </div>
                   </div>
                 </div>
               </div>
             </div>
           </div>
           </div>
   
           <div className="container" style={{ marginTop: "50px" }}>
             <li className="block items-center">
               <span
                 style={{
                   color: "#000000",
                   fontWeight: "bold",
                   fontSize: "22px",
                   cursor: "pointer",
                 }}
               >
                 Information sourced from 
               </span>
             </li>
             <div className="grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-3 xs:hidden gap-[30px]">
               {currentFormData?.partnerImages?.map((ele, index) => (
                 <div
                   key={index}
                   className="group relative rounded-xl bg-white dark:bg-slate-900 shadow1 p-6 text-center mt-10 "
                   style={{
                     display: "flex",
                     justifyContent: "center",
                     alignContent: "center",
                   }}
                 >
                   <div
                     className="relative -mt-16"
                     style={{
                       display: "flex",
                       justifyContent: "center",
                       alignContent: "center",
                     }}
                   >
                     <img
                       style={{
                         marginTop: "60px",
                         width: "7rem",
                         height: "auto",
                         maxWidth: "100%",
                         verticalAlign: "middle",
                         display: "flex",
                         justifyContent: "center",
                         objectFit: "contain",
                       }}
                       src={ele.image_url}
                     ></img>
                   </div>
                 </div>
               ))}
             </div>
             <div className="grid relative grid-cols-1 mt-10 sm:hidden">
                <div className="tiny-four-icon-item">
                    <TinySlider settings={settings1}>
                        {currentFormData?.partnerImages?.map((item, index) => (
                            <div className="tiny-slide" key={index}>
                                <div className="group relative overflow-hidden bg-white dark:bg-slate-900 rounded-lg p-4 shadow dark:shadow-gray-800 ease-in-out duration-500 m-2">
                                    <img src={item.image_url} style={{verticalAlign: "middle",objectFit:'contain',height:'150px',justifyContent:'center',width:'100%'}} className="rounded-lg" alt="" />
                                </div>
                            </div>
                        ))}
                    </TinySlider>
                </div>
            </div>
           </div>
           <div className="container mt-16">
             <div className="md:flex">
             <div className="lg:w-2/5 md:w-full md:pe-4 md:order-1 md:mt-0 lg:mt-0 ">
             <div className="sticky top-20 -mt-10">
      {newImg?.map((ele, index) => (
        <div
          key={index}
          className="group relative rounded-xl shadow1 p-5 newImgWidth text-center mt-4 xs:hidden"
          style={{
            display: "flex",
            justifyContent: "center",
            backgroundColor:'black',
            alignContent: "center",
          //   display:'flex',
          // alignItems:'end'
          }}
        >
          <div
            className="relative"
            style={{
              display: "flex",
              // backgroundColor:'black',
              justifyContent: "center",
              alignContent: "center",
            }}
          >
            <img
              style={{
                width: "7rem",
                
                height: "auto",
                maxWidth: "100%",
                verticalAlign: "middle",
                objectFit: "contain",
              }}
              src={ele.image_url}
              alt={`Partner ${index}`}
            />
          </div>
        </div>
      ))}
      <div className="grid relative grid-cols-1 sm:hidden">
                <div className="tiny-four-icon-item">
                    <TinySlider settings={settings1}>
                        {newImg.map((item, index) => (
                            <div className="tiny-slide" key={index}>
                                <div className="group relative overflow-hidden rounded-lg shadow p-4 dark:shadow-gray-800 ease-in-out duration-500 m-2" style={{backgroundColor:'#000'}}>
                                    <img src={item.image_url}  alt={`Partner ${index}`} style={{verticalAlign: "middle",objectFit:'contain',height:'150px',justifyContent:'center',width:'100%'}} className="rounded-lg" />
                                </div>
                            </div>
                        ))}
                    </TinySlider>
                </div>
            </div>
       </div>
             </div>
  
   
               <div className="lg:w-3/5 md:w-full  md:mb-8 md:order-2 "
                 style={{ paddingInline: "1rem" }}
               >
                 <ol class="c-stepper">
                   <li
                     className="block items-center mb-2 item-mt"
                   >
                     <span
                       style={{
                         color: "#000000",
                         fontWeight: "bold",
                         fontSize: "24px",
                         cursor: "pointer",
                         
                       }}
                     >
                       How to Apply
                     </span>
                   </li>
                   <li class="c-stepper__item" style={{paddingTop:'1rem'}}>
                     <div class="c-stepper__content">
                       <a
                         class="stepper-link c-stepper__title"
                         style={{ display: "flex", alignItems: "center" }}
                       >
                         <span
                           style={{
                             color: "black",
                             marginLeft: "-48px",
                             fontWeight: "bold",
                             marginTop: "-7px",
                           }}>
                            Step <br/> 01
                           </span>
                         <span
                           style={{
                             marginLeft: "10px",
                             marginTop: "-15px",
                             fontWeight: "bold",
                             fontSize:"20px",
                             flex: "1",
                           }}
                         >
                           Before you apply
                         </span>
                       </a>
                       <a class="stepper-link travel-advice" style={{ textAlign: "left",marginTop:'-16px',marginLeft:'14px' }}>
                          <span dangerouslySetInnerHTML={{__html:formatDescription(currentFormData?.beforeapply)}} />
                        </a>
                     </div>
                   </li>
   
                   <li class="c-stepper__item">
                     <div class="c-stepper__content">
                       <a
                         class="stepper-link c-stepper__title"
                         style={{ display: "flex", alignItems: "center" }}
                       >
                         <span
                           style={{
                             color: "black",
                             marginLeft: "-48px",
                             fontWeight: "bold",
                             marginTop: "-7px",
                           }}>
                            Step <br/> 02
                           </span>
                         <span
                           style={{
                             marginLeft: "10px",
                             marginTop: "-15px",
                             flex: "1",
                             fontWeight: "bold",
                             fontSize:"20px",
                           }}
                         >
                         Collect your required documents
                         </span>
                       </a>
                       <a class="stepper-link travel-advice" style={{ textAlign: "left",marginTop:'-16px',marginLeft:'14px' }}>
                          <span dangerouslySetInnerHTML={{__html:formatDescription(currentFormData?.collectrequireddoc)}} />
                        </a>
                     </div>
                   </li>
   
                   <li class="c-stepper__item">
                     <div class="c-stepper__content">
                       <a
                         class="stepper-link c-stepper__title"
                         style={{ display: "flex", alignItems: "center" }}
                       >
                         <span
                           style={{
                             color: "black",
                             marginLeft: "-48px",
                             fontWeight: "bold",
                             marginTop: "-7px",
                           }}>
                            Step <br/> 03
                           </span>
                         <span
                           style={{
                             marginLeft: "10px",
                             marginTop: "-15px",
                             flex: "1",
                             fontWeight: "bold",
                             fontSize:"20px",
                           }}
                         >
                           Apply for visa <span
                            style={{
                              color: "#000000",
                              fontWeight: "bold",
                              marginTop: "7px", 
                              cursor: "pointer",
                              marginLeft: "1px",
                            }}
                          >
                            {currentDate} (Today)
                          </span>
                         </span>
                       </a>
                       <a class="stepper-link travel-advice" style={{ textAlign: "left",marginTop:'-16px',marginLeft:'14px' }}>
                          <span dangerouslySetInnerHTML={{__html:formatDescription(currentFormData?.applyforvisa)}} />
                        </a>
                     </div> 
                   </li>
                   <li class="c-stepper__item">
                     <div class="c-stepper__content">
                       <a
                         class="stepper-link c-stepper__title"
                         style={{ display: "flex", alignItems: "center" }}
                       >
                        <span
                           style={{
                             color: "black",
                             marginLeft: "-48px",
                             fontWeight: "bold",
                             marginTop: "-7px",
                           }}>
                            Step <br/> 04
                           </span>
                         <span
                           style={{
                             marginLeft: "10px",
                             marginTop: "-15px",
                             flex: "1",
                             fontWeight: "bold",
                             fontSize:"20px",
                           }}
                         >
                           After you apply
                         </span>
                       </a>
                       <a class="stepper-link travel-advice" style={{ textAlign: "left",marginTop:'-16px',marginLeft:'14px' }}>
                          <span dangerouslySetInnerHTML={{__html:formatDescription(currentFormData?.afterapply)}} />
                        </a>
                       {/* <li class="stepper-item">
                         {/* <span class="stepper-counter"><span class="streamline--check-solid" style={{color:'#80bc04',fontSize:'30px'}}></span></span> */}
                         {/* <span class="stepper-counter"> */}
                           {/* <img src={check} style={{ marginLeft: '10px' ,fontSize:'30px'}} height={100} width={100} alt="Image Description" className="badge-image"  /> */}
   
                           {/* <span
                             class="mdi--tick"
                             style={{ color: "#80bc04", fontSize: "30px" }}
                           ></span>
                         </span>
                         <a class="stepper-link">
                           <span>
                             Application has been sent to the immigration
                             supervisor
                           </span>
                           <span style={{ paddingTop: 6 }} className="bagde-flag1">
                             ON TIME
                           </span>
                         </a>
                         <span class="stepper-line"></span>
                       </li> */}
   
                       {/* <li class="stepper-item">
                         <span class="stepper-counter">
                           <span
                             class="mdi--tick"
                             style={{ color: "#80bc04", fontSize: "30px" }}
                           ></span>
                         </span>
                         <a class="stepper-link">
                           <span>
                             Application has been sent to the internal intelligence
                           </span>
                           <span style={{ paddingTop: 6 }} className="bagde-flag1">
                             ON TIME
                           </span>
                         </a>
                         <span class="stepper-line"></span>
                       </li> */}
                       {/* <li class="stepper-item">
                         <span class="stepper-counter">
                           <span
                             class="mdi--tick"
                             style={{ color: "#80bc04", fontSize: "30px" }}
                           ></span>
                         </span>
                         <a class="stepper-link">
                           <span>Application sent to the clearance officer </span>
                         </a>
                         <span class="stepper-line"></span>
                       </li> */}
                     </div>
                   </li>
                   <li class="c-stepper__item">
                     <div class="c-stepper__content">
                       <a class="stepper-link c-stepper__title">
                       <span
                           style={{
                             color: "black",
                             marginLeft: "-48px",
                             fontWeight: "bold",
                             marginTop: "-7px",
                           }}>
                            Step <br/> 05
                           </span>
                         <span style={{ marginLeft: "10px", marginTop: "5px", fontWeight: "bold",fontSize:"20px", }}>
                           Visa outcome in <span style={{fontWeight: "bold",fontSize:'20px'}}> {currentFormData?.visa_arrives_in} {' '} {currentFormData?.visa_arrives_in_unit} as per standard visa processing time</span>
                         </span>
                       </a>
                       <a class="stepper-link travel-advice" style={{ textAlign: "left",marginTop:'-16px',marginLeft:'14px' }}>
                          <span dangerouslySetInnerHTML={{__html:formatDescription(currentFormData?.visaoutcome)}} />
                        </a>
                       {/* <li class="stepper-item">
                         <span class="stepper-counter"></span>
                         <a class="stepper-link">
                           <span
                             style={{
                               color: "#000000",
                               fontWeight: "bold",
                               color: "black",
                               fontSize: "19px",
                               marginLeft: "-50px",
                               marginTop: "5px",
                             }}
                           >
                             {countryDetails?.no_of_dates} Days
                           </span>
                           <span
                             style={{
                               marginLeft: "10px",
                               paddingTop: 7,
                               background: "#000000",
                             }}
                             className="bagde-flag1"
                           >
                             Guaranteed
                           </span>
                         </a>
                         <span class="stepper-line"></span>
                       </li> */}
                     </div>
                   </li>
                 </ol>
               </div>
             </div>
           </div>
           <div className="container anothermt">
             <div className="md:flex">
               <div className="lg:w-2/5 md:w-full md:order-1 md:mt-0 mt-10 md:mt-0 lg:mt-0">
                 <div className="sticky top-20">
                   <li className="block items-center OnlyDesktopSpan1">
                     <span
                       style={{
                         color: "#000000",
                         fontWeight: "bold",
                         fontSize: "22px",
                         cursor: "pointer",
                       }}
                      //  className=""
                     >
                       Documents Required
                     </span>
                   </li>
                      
                   <ol className="c-stepper mt-8 OnlyDesktop">
                     {currentFormData?.documentsRequired?.map((step, index) => (
                       <li className="c-stepper__item" key={index} style={{marginBottom:'-15px'}}>
                         <div className="c-stepper__content">
                           <a className="c-stepper__title">
                           <span
                           style={{
                             color: "black",
                             marginLeft: "-53px",
                             fontWeight: "bold",
                             marginTop: "7px",
                           }}>
                            Doc 
                            </span>
                            <br/>
                            <span style={{color: "black",
                             marginLeft: "-44px",
                             fontWeight: "bold",
                             marginTop: "7px",}}>
                              0{index + 1}</span>
                           
                             <span
                               style={{ marginLeft: "10px", marginTop: "5px" }}
                             >
                               <div
                                 className="color-option"
                                 style={{ marginTop: "-38px",marginLeft:"15px" }}
                               >
                                 <div className="popover-container1">
                                 <span
                                          className="color"
                                          style={{
                                            fontSize: "20px",
                                            color: "#000",
                                            fontWeight: "bold",
                                            cursor: "pointer",
                                          }}
                                          onClick={() => handleClick(step)}
                                        >
                                          <span
                                          className='underlineeee'
                                          >
                                         
                                            {step?.document_type}
                                        </span>
                                        </span>            
                                 </div>
                               </div>
                             </span>
                           </a>
                         </div>
                       </li>
                     ))}
                   </ol>           
                   {/* <div
                     className="color-price"
                     style={{
                       whiteSpace: "nowrap",
                       marginTop: "5px",
                      //  marginBottom: "26px",
                     }}
                   >
                     <div className="flex items-center">
                       <Link
                         className="px-6 py-2 font-semibold rounded-md w-full transition-all duration-500 ease-in-out shadow1"
                         type="button"
                         onClick={()=>handleDownload(currentFormData?.checkList)}
                         style={{
                           cursor: "pointer",
                           color: "black",
                           display: "flex",
                           alignItems: "center",
                         }}
                       >
                         <span
                           className="material-symbols--download"
                           
                           style={{
                             cursor: "pointer",
                             whiteSpace: "nowrap",
                            //  marginRight: "5px",
                             fontSize: "23px",
                           }}
                         ></span>
                         Download Checklist
                       </Link>
                     </div>           
                   </div> */}
                  <div className="OnlyDesktop">
                    { currentFormData?.visa_forms?.length>0 && (
                     <li style={{marginTop:'20px'}} className="block items-center">
                       <span
                         style={{
                          color: "#000000",
                          fontWeight: "bold",
                          fontSize: "22px",
                          cursor: "pointer",                  
                         }}
                       >
                         Visa Forms
                       </span>
                     </li>)}
                     <ol className="c-stepper mt-8">
                       {currentFormData?.visa_forms?.map((d, index) => (
                         <li className="c-stepper__item" key={index} style={{marginBottom:'-15px'}}>
                           <div className="c-stepper__content">
                             <a className="c-stepper__title">                     
                              <span
                              className="material-symbols--download"
                             style={{
                               color: "black",
                               marginLeft: "-50px",
                               fontSize: "23px",
                               marginTop: "7px",
                             }}>                     
                              </span>                        
                               <span
                                 style={{ marginLeft: "10px", marginTop: "5px" }}
                               >
                                 <div
                                   className="color-option"
                                   style={{ marginTop: "-38px",marginLeft:"15px" }}
                                 >
                                   <Link
                             className="px-6 py-2 font-semibold rounded-md w-full transition-all 
                             duration-500 ease-in-out shadow1  truncated-link"
                             type="button"
                            
                             onClick={() => {
                              // Open the file in a new tab
                              const newTab = window.open();
                              // handleDownload(d);
                              
                              // Set the URL of the new tab to the file URL (optional)
                              if (newTab) {
                                newTab.location.href = d.url;
                              }
                            }}
                             style={{
                              cursor: "pointer",
                              color: "black",
                              display: "flex",
                              alignItems: "center",
                              whiteSpace: "nowrap", 
                              overflow: "hidden", 
                              textOverflow: "ellipsis", 
                            }}
                           >                     
                             {d?.name.slice(0, 12)}
                           </Link>
                                 </div>
                               </span>
                             </a>
                           </div>
                         </li>
                       ))}
                        <li className="c-stepper__item" key={1} >
                        <div className="c-stepper__content">
                             <a className="c-stepper__title">
                             <span
                              className="material-symbols--download"
                             style={{
                               color: "black",
                               marginLeft: "-50px",
                               fontSize: "23px",
                               marginTop: "7px",
                             }}>                     
                              </span>
                              <br/>
                              <span style={{color: "black",
                               marginLeft: "-44px",
                               fontWeight: "bold",
                               marginTop: "7px",}}>
                                </span>
                                <span
                                 style={{ marginLeft: "10px", marginTop: "5px" }}
                               >
                                 <div
                                   className="color-option"
                                   style={{ marginTop: "-65px",marginLeft:"15px" }}
                                 >
                                   <Link
                             className="px-6 py-2 font-semibold rounded-md w-full transition-all 
                             duration-500 ease-in-out shadow1"
                             type="button"
                            // onClick={()=>handleDownload(currentFormData?.checkList)}
    
    
                            onClick={() => {
                              // Open the file in a new tab
                              let d= currentFormData?.checkList
                              const newTab = window.open();
                              // handleDownload(d)
                              
                              // Set the URL of the new tab to the file URL (optional)
                              if (newTab) {
                                newTab.location.href = d.url;
                              }
                            }}
                             style={{
                               cursor: "pointer",
                               color: "black",
                               display: "flex",
                               alignItems: "center",
                             }}
                           >
                             <span
                              //  className="material-symbols--download"
                               
                               style={{
                                 cursor: "pointer",
                                 whiteSpace: "nowrap",
                                //  marginRight: "5px",
                                 fontSize: "23px",
                               }}
                             ></span>
                             Download Checklist
                           </Link>
                                 </div>
                               </span>                  
                             </a>
                           </div>
                             </li>
                     </ol>
                  </div>
                   {/* {currentFormData?.visa_forms.map((d,index)=>(
                       <div key={index}
                       className="color-price"
                       style={{
                         whiteSpace: "nowrap",
                         marginTop: "8px",
                  
                       }}
                     >
                       <div className="flex items-center">
                         <Link
                           className="px-6 py-2 font-semibold rounded-md w-full transition-all 
                           duration-500 ease-in-out shadow1"
                           type="button"
                           onClick={()=>handleDownload(d)}
                           style={{
                             cursor: "pointer",
                             color: "black",
                             display: "flex",
                             alignItems: "center",
                           }}
                         >
                           <span
                             className="material-symbols--download"
                             
                             style={{
                               cursor: "pointer",
                               whiteSpace: "nowrap",
                              //  marginRight: "5px",
                               fontSize: "23px",
                             }}
                           ></span>
                           Download
                         </Link>
                       </div>           
                     </div>  
                   ))} */}
                             
                 </div>
               </div>
   
               <div className="lg:w-3/5 md:w-full md:mb-8 md:order-2 OnlyDesktop"
                 style={{ padding: "2rem",backgroundColor:'black' ,borderRadius:'20px',height:'fit-content'}}
               >
                <p
               style={{
                 color: "white",
                 fontWeight: "bold",
                 textAlign:'center',
                 marginTop: "7px",
               }}>
              {docName}  Documents
                </p>
                 <div id="accordion-collapseone" data-accordion="collapse" className="mt-12" >
                                      {/* {initialFAQ.map((item, index) => ( */}
                                      {/* {reqDocuments && reqDocuments.length > 0 && reqDocuments.map((step, index) => ( */}
    
                                        {docReqData?.map((item, index) => (
                                          <div key={index} className="relative shadow1 dark:shadow1 rounded-md overflow-hidden mt-4" style={{backgroundColor:'white'}}>
                                              <h2 className="text-base font-semibold" id="accordion-collapse-heading-1" >
                                                  <button onClick={() => toggleAccordion(index)} type="button" style={{color:'black'}}
                                                      className={`flex justify-between items-center p-5 w-full font-semibold text-start ${activeIndex === index ? 'bg-gray-50 dark:bg-slate-8002 text-violet-6001' : ''}`}
                                                      data-accordion-target="#accordion-collapse-body-1" aria-expanded="true" aria-controls="accordion-collapse-body-1">
                                                      <span>{item.title }</span>
                                                      <svg data-accordion-icon className={`w-4 h-4  shrink-0 ${activeIndex === index ? "rotate-180" : "rotate-360"}`} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                          <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path>
                                                      </svg>
                                                  </button>
                                              </h2>
                                              {activeIndex === index && (
                                                  <div id="accordion-collapse-body-1" aria-labelledby="accordion-collapse-heading-1">
                                                      <div className="p-5" style={{backgroundColor:'#6e6e6e'}}>
                                                          <p className="travel-advice" style={{color:'white'}} 
                                                          dangerouslySetInnerHTML={{__html:formatDescription(item?.description_html)}}></p>
                                                      </div>
                                                  </div>
                                              )}
                                          </div>
                                      ))}
                                      {/* ))} */}
                                  </div>
               </div>
               <div
    className="lg:w-3/5 md:w-full md:mb-8 md:order-2 OnlyPhone"
    style={{ padding: "2rem", backgroundColor: 'black', borderRadius: '20px', height: 'fit-content' }}
  >
    {currentFormData?.documentsRequired?.map((step, index) => (
      <div key={index}>
        <p
          style={{
            color: "white",
            fontWeight: "bold",
            // fontSize: "18px",
            textAlign: 'center',
            marginTop: "7px",
            textDecoration: activeDocument === step.document_type ? 'underline' : 'none',
          }}
          onClick={() => handleClick(step)}
          
        >
          {step?.document_type} Documents
        </p>
  
        {docName === step.document_type && (
          <div id="accordion-collapseone" data-accordion="collapse" className="mt-4">
            {docReqData?.map((item, index) => (
              <div
                key={index}
                className="relative shadow1 dark:shadow1 rounded-md overflow-hidden mt-4"
                style={{ backgroundColor: 'white' }}
              >
                <h2 className="text-base font-semibold" id={`accordion-collapse-heading-${index}`}>
                  <button
                    onClick={() => toggleAccordion(index)}
                    type="button"
                    style={{ color: 'black' }}
                    className={`flex justify-between items-center p-5 w-full font-semibold text-start ${activeIndex === index ? 'bg-gray-50 dark:bg-slate-8002 text-violet-6001' : ''}`}
                    data-accordion-target={`#accordion-collapse-body-${index}`}
                    aria-expanded={activeIndex === index}
                    aria-controls={`accordion-collapse-body-${index}`}
                  >
                    <span>{item.title}</span>
                    <svg
                      data-accordion-icon
                      className={`w-4 h-4 shrink-0 ${activeIndex === index ? "rotate-180" : "rotate-360"}`}
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </button>
                </h2>
                {activeIndex === index && (
                  <div id={`accordion-collapse-body-${index}`} aria-labelledby={`accordion-collapse-heading-${index}`}>
                    <div className="p-5" style={{ backgroundColor: '#6e6e6e' }}>
                      <p
                        className="travel-advice"
                        style={{ color: 'white' }}
                        dangerouslySetInnerHTML={{ __html: formatDescription(item?.description_html) }}
                      ></p>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    ))}
  </div>
               <div className="OnlyPhone" style={{marginBottom:'-50px'}}>
                    { currentFormData?.visa_forms?.length>0 && (
                     <li style={{marginTop:'45px'}} className="block items-center">
                       <span
                         style={{
                          color: "#000000",
                          fontWeight: "bold",
                          fontSize: "22px",
                          cursor: "pointer",                  
                         }}
                       >
                         Visa Forms
                       </span>
                     </li>)}
                     <ol className="c-stepper mt-8">
                       {currentFormData?.visa_forms?.map((d, index) => (
                         <li className="c-stepper__item" key={index} style={{marginBottom:'-15px'}}>
                           <div className="c-stepper__content">
                             <a className="c-stepper__title">                     
                              <span
                              className="material-symbols--download"
                             style={{
                               color: "black",
                               marginLeft: "-50px",
                               fontSize: "23px",
                               marginTop: "7px",
                             }}>                     
                              </span>                        
                               <span
                                 style={{ marginLeft: "10px", marginTop: "5px" }}
                               >
                                 <div
                                   className="color-option"
                                   style={{ marginTop: "-38px",marginLeft:"15px" }}
                                 >
                                   <Link
                             className="px-6 py-2 font-semibold rounded-md w-full transition-all 
                             duration-500 ease-in-out shadow1  truncated-link"
                             type="button"
                            
                             onClick={() => {
                              // Open the file in a new tab
                              const newTab = window.open();
                              // handleDownload(d);
                              
                              // Set the URL of the new tab to the file URL (optional)
                              if (newTab) {
                                newTab.location.href = d.url;
                              }
                            }}
                             style={{
                              cursor: "pointer",
                              color: "black",
                              display: "flex",
                              alignItems: "center",
                              whiteSpace: "nowrap", 
                              overflow: "hidden", 
                              textOverflow: "ellipsis", 
                            }}
                           >                     
                             {d?.name.slice(0, 12)}
                           </Link>
                                 </div>
                               </span>
                             </a>
                           </div>
                         </li>
                       ))}
                        <li className="c-stepper__item" key={1} >
                        <div className="c-stepper__content">
                             <a className="c-stepper__title">
                             <span
                              className="material-symbols--download"
                             style={{
                               color: "black",
                               marginLeft: "-50px",
                               fontSize: "23px",
                               marginTop: "7px",
                             }}>                     
                              </span>
                              <br/>
                              <span style={{color: "black",
                               marginLeft: "-44px",
                               fontWeight: "bold",
                               marginTop: "7px",}}>
                                </span>
                                <span
                                 style={{ marginLeft: "10px", marginTop: "5px" }}
                               >
                                 <div
                                   className="color-option"
                                   style={{ marginTop: "-65px",marginLeft:"15px" }}
                                 >
                                   <Link
                             className="px-6 py-2 font-semibold rounded-md w-full transition-all 
                             duration-500 ease-in-out shadow1"
                             type="button"
                            // onClick={()=>handleDownload(currentFormData?.checkList)}
    
    
                            onClick={() => {
                              // Open the file in a new tab
                              let d= currentFormData?.checkList
                              const newTab = window.open();
                              // handleDownload(d)
                              
                              // Set the URL of the new tab to the file URL (optional)
                              if (newTab) {
                                newTab.location.href = d.url;
                              }
                            }}
                             style={{
                               cursor: "pointer",
                               color: "black",
                               display: "flex",
                               alignItems: "center",
                             }}
                           >
                             <span
                              //  className="material-symbols--download"
                               
                               style={{
                                 cursor: "pointer",
                                 whiteSpace: "nowrap",
                                //  marginRight: "5px",
                                 fontSize: "23px",
                               }}
                             ></span>
                             Download Checklist
                           </Link>
                                 </div>
                               </span>                  
                             </a>
                           </div>
                             </li>
                     </ol>
                  </div>
             </div>
           </div>
           <div className="container " style={{ marginTop: "0px" }}>
             <div className="md:flex">
               <div className="lg:w--full md:w-full md:pe-4 flex flex-wrap justify-between px-6  py-2 font-semibold w-full transition-all duration-500 ease-in-out text-white bg-violet-6001" style={{marginTop:'40px',borderRadius:'20px'}}>
                   <div className="buttonVisaInner">
                     <div>
                       <div>
                         <Link
                          //  className="px-6 py-2 font-semibold rounded-md w-full transition-all duration-500 ease-in-out text-white bg-violet-6001"
                           type="button"
                           to={`/travel-advice/${currentFormData.id}`}
                           // onClick={() => setIndex(0)}
                           style={{ color: "white" , fontSize: "18px",}}
                           className="underlineeee buttonVisaInnerStyle"
                         >
                           Travel Advice For {countryDetails?.name}
                         </Link>
                       </div>
                     </div>
   
                     <div
                      
                      
                     >
                       <div>
                         <Link
                           to={`/visa-customs/${currentFormData.id}`}
                           style={{
                             color: "white",
                             fontWeight: "bold",
                             fontSize: "18px",
                             cursor: "pointer",
                             whiteSpace: "nowrap",
                           }}    
                           className="underlineeee"
                         >
                           Customs Info for {countryDetails?.name}
                         </Link>
                       </div>
                     </div>
                     
                   </div>
                 </div>
             </div>
             {!empty(initialFAQ)?(<div className="md:flex" style={{ marginTop: '70px' }}>
                          <div className="lg:w-5/5 md:w-full md:mb-8 md:order-2" >
                              <li className="block">
                                  <span style={{ color: '#000000', fontWeight: 'bold', fontSize: '30px', cursor: 'pointer', textAlign: 'left' }}>FAQs</span>
                              </li>
  
                              <div id="accordion-collapseone" data-accordion="collapse" className="mt-12">
                                  {initialFAQ.map((item, index) => (
                                    
                                      <div key={index} className="relative shadow1 dark:shadow1 rounded-md overflow-hidden mt-4">
                                          <h2 className="text-base font-semibold" id="accordion-collapse-heading-1">
                                              <button onClick={() => toggleAccordions(index)} type="button" style={{ color: 'black' }}
                                                  className={`flex justify-between items-center p-5 w-full font-semibold text-start ${activeIndexx === index ? 'bg-gray-50 dark:bg-slate-8002 text-violet-6001' : ''}`}
                                                  data-accordion-target="#accordion-collapse-body-1" aria-expanded="true" aria-controls="accordion-collapse-body-1">
                                                  <span>{item.title}</span>
                                                  <svg data-accordion-icon className={`w-4 h-4  shrink-0 ${activeIndexx === index ? "rotate-180" : "rotate-360"}`} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                      <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path>
                                                  </svg>
                                              </button>
                                          </h2>
                                          {activeIndexx === index && (
                                              <div id="accordion-collapse-body-1" aria-labelledby="accordion-collapse-heading-1">
                                                  <div className="p-5" style={{ backgroundColor: 'rgb(110 110 110 / 15%)' }}>
                                                      <p className="travel-advice" style={{ color: 'black' }}
                                                          dangerouslySetInnerHTML={{ __html: formatDescription(item?.description) }}></p>
                                                  </div>
                                              </div>
                                          )}
                                      </div>
                                  ))}
                              </div>
  
                          </div>
                      </div>):("")}
           </div>
          <div style={{alignItems:'center',justifyContent:'center'}}>
             <Link
                            //  to={`/visa-customs/${visaId}`}
                             style={{
                               color: "black",
                               fontWeight: "bold",
                               fontSize: "22px",
                               cursor: "pointer", 
                               paddingBottom: "3px",
                               whiteSpace: "nowrap",
                             }}    
                             className="underlineeee"
                           >
                             {/* FAQ for {countryDetails?.name} tourist visa */}
                           </Link>
          </div>
           <div
             className="container"
             style={{ marginLeft: "0px", marginBottom: "-60px",marginTop:'-60px' }}
           >
             <div className="flex justify-between">
               <div style={{ display: "flex", justifyContent: "center" }}>
                 {/* <div class="avatar-group">
                   <div class="avatar">
                     <img src="https://pixinvent.com/materialize-material-design-admin-template/app-assets/images/user/12.jpg" />
                   </div>
                   <div class="avatar">
                     <img src="https://htmlstream.com/preview/unify-v2.6.2/assets/img-temp/400x450/img5.jpg" />
                   </div>
                   <div class="avatar">
                     <img src="https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500" />
                   </div>
                   <div class="avatar">
                     <img src="https://rimage.gnst.jp/livejapan.com/public/article/detail/a/00/04/a0004213/img/basic/a0004213_main.jpg?20200710184501&q=80&rw=750&rh=536" />
                   </div>
                   <div class="avatar">
                     <img src="https://da4e1j5r7gw87.cloudfront.net/wp-content/uploads/sites/768/2018/08/glasses-american-man-20s-hipster.jpg" />
                   </div>
                 </div> */}
                 {/* <div className="flex items-center">
                   <Link
                     onClick={() => setPlaceBid(!placeBid)}
                     style={{
                       color: "#000000",
                       fontWeight: "bold",
                       fontSize: "20px",
                       cursor: "pointer",
                       marginTop: "15px",
                       marginLeft: "10px",
                     }}
                   >
                     Hear from our recent travellers
                   </Link>
                   <div
                     style={{
                       marginTop: "17px",
                       fontSize: "40px",
                       color: "#000000",
                       cursor: "pointer",
                     }}
                   >
                     <RiArrowDropDownLine
                       onClick={() => setPlaceBid(!placeBid)}
                       style={{ color: "#000000" }}
                     />
                   </div>
                 </div> */}
               </div>
             </div>
           </div>
           {/* {showSection && (
             <div
               className={`lg:flex scrolldown scrollbutton ${
                 showSection ? "show" : ""
               }`}
               style={{
                 marginLeft: "0px",
                 marginTop: "200px",
                 backgroundColor: "#000",
                 position: "sticky",
                 bottom: 0,
               }}
             >
               <div
                 className="p-4 w-full"
                 style={{
                   display: "flex",
                   flexDirection: "column",
                   marginTop: "-25px",
                 }}
               >
                 <div className="flex flex-wrap justify-between">
                   <div className="color-price" style={{ marginTop: "30px" }}>
                     <div className="flex items-center">
                       <div style={{ display: "flex", flexDirection: "column" }}>
                         <Link
                           className="color"
                           style={{
                             fontSize: "19px",
                             color: "#fff",
                             fontWeight: "bold",
                             marginRight: "70px",
                           }}
                         >                    
                         </Link>
                       </div>
                     </div>
                   </div>
   
                   <div
                     className="group rounded-xl bg-white dark:bg-slate-900 w-full mx-auto lg:max-w-2xl"
                     style={{
                       marginTop: "30px",
                       display: "flex",
                       justifyContent: "center",
                       alignContent: "center",
                       alignItems: "center",
                     }}
                   >
                     <Link
                       className="font-semibold transition-all duration-500 ease-in-out text-black"
                       type="button"
                       to="#"
                       // onClick={() => setIndex(0)}
                       style={{
                         color: "black",
                         width: "450px",
                         height: "70px",
                         display: "flex",
                         justifyContent: "center",
                         alignItems: "center",
                         textAlign: "center",
                         padding: "22px",
                         borderRadius: "14px",
                         backgroundColor: "#fff",
                       }}
                     >
                       Get Started Now
                     </Link>
                   </div>
   
                   <div className="color-price" style={{ marginTop: "30px" }}>
                     <div className="flex items-center">
                       <div style={{ display: "flex", flexDirection: "column" }}>
                         <Link
                           className="color"
                           style={{
                             fontSize: "16px",
                             textAlign: "center",
                             color: "#fff",
                             marginBottom: "4px",
                             fontWeight: "bold",
                           }}
                         >
                           Visa Guaranteed On
                         </Link>
                         <Link
                           className="color"
                           style={{
                             fontSize: "18px",
                             color: "#fff",
                             fontWeight: "bold",
                           }}
                         >
                           {countryDetails?.no_of_dates} Days
                         </Link>
                       </div>
                     </div>
                   </div>
                 </div>
               </div>
             </div>
           )} */}
  
  
  {/* <div
             className="group rounded-xl bg-white dark:bg-slate-900 w-full mx-auto lg:max-w-2xl "
             style={{
               zIndex: 1,
               marginTop: "150px",
               display: "flex",
               justifyContent: "center",
               alignContent: "center",
               alignItems: "center",
             }}
           >
             <Link
               className="font-semibold transition-all duration-500 ease-in-out text-white bg-violet-6001 "
               type="button"
               to={'/consultants'}
               style={{
                 color: "black",
                 width: "550px",
                 height: "70px",
                 display: "flex",
                 justifyContent: "center",
                 textAlign: "center",
                 padding: "22px",
                 borderRadius: "14px",
               }}
             >
               Find migration consultant
             </Link>
           </div>
   
           <hr
             style={{
               marginTop: "-36px",
               borderColor: "black",
               borderWidth: "1px",
             }}
           /> */}
  
           
           <div
             style={{
               position: "fixed",
               top: 0,
               left: 0,
               right: 0,
               bottom: 0,
               zIndex: 50,
               overflow: "hidden",
               display: "flex",
               justifyContent: "center",
               alignItems: "center",
               backgroundColor: "rgba(0, 0, 0, 0.5)",
             }}
             className={`m-auto justify-center items-center flex bg-gray-900 bg-opacity-50 dark:bg-opacity-80 ${
               placeBid ? "" : "hidden"
             }`}
           >
             <div
               style={{
                 position: "relative",
                 width: "100%",
                 height: "auto",
                 maxWidth: "600px",
                 padding: "4px",
               }}
               className="relative w-full h-auto max-w-2xl p-4"
             >
               <div
                 style={{
                   position: "relative",
                   backgroundColor: "white",
                   borderRadius: "8px",
                   boxShadow:
                     "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
                 }}
                 className="relative bg-white dark:bg-slate-900 rounded-lg shadow dark:shadow-gray-800"
               >
                 <div
                   style={{
                     display: "flex",
                     justifyContent: "space-between",
                     alignItems: "center",
                     padding: "6px 12px",
                     borderBottom: "1px solid #e6e6e6",
                   }}
                   className="flex justify-between items-center px-6 py-4 border-b border-gray-100 dark:border-t-gray-800"
                 >
                   <h5
                     style={{
                       fontSize: "1.5rem",
                       fontWeight: "800",
                       color: "black",
                     }}
                     className="text-xl font-bold"
                   >
                     ❤️ Hear from Visa Customers
                   </h5>
                   <button
                     style={{
                       color: "#718096",
                       backgroundColor: "transparent",
                       ":hover": { backgroundColor: "#edf2f7", color: "#2d3748" },
                       borderRadius: "8px",
                       fontSize: "1rem",
                       padding: "0.375rem 0.75rem",
                       marginLeft: "auto",
                     }}
                     type="button"
                     className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-lg p-1.5 ms-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
                   >
                     <IoMdClose
                       style={{ fontSize: "1.8rem", color: "black" }}
                       onClick={() => setPlaceBid(!placeBid)}
                     />
                   </button>
                 </div>
   
                 <div
                   id="center"
                   style={{
                     padding: "1.5rem",
                     overflowY: "auto",
                     maxHeight: "400px",
                     color: "black",
                   }}
                   className="p-6 overflow-y-auto max-h-96"
                 >
                   {dataArray.map((item, index) => (
                     <>
                       <div key={index} className="product-card1 mb-4">
                         <h5
                           style={{
                             fontSize: "1rem",
                             fontWeight: "600",
                             marginBottom: "20px",
                           }}
                           className="text-xl font-semibold"
                         >
                           {item.heading}
                         </h5>
                         <p
                           style={{
                             textAlign: "start",
                             color: "black",
                             fontWeight: "500",
                           }}
                         >
                           {item.content}
                         </p>
                         <img
                           src={item.image}
                           style={{
                             borderRadius: "0.75rem",
                             marginTop: "20px",
                             width: "10rem",
                             height: "10rem",
                             objectFit: "cover",
                           }}
                           alt="Your Image"
                         />
   
                         <div
                           style={{
                             paddingTop: "1rem",
                             borderTop: "1px solid #e6e6e6",
                             marginTop: "25px",
                           }}
                           className="pt-4 border-t dark:border-gray-700"
                         >
                           <div
                             style={{
                               display: "flex",
                               justifyContent: "space-between",
                               marginBottom: "1rem",
                             }}
                           >
                             <p
                               style={{
                                 textAlign: "start",
                                 color: "black",
                                 fontWeight: "500",
                                 display: "flex",
                                 alignItems: "center",
                               }}
                             >
                               {item.name}
                               <span
                                 style={{
                                   display: "flex",
                                   alignItems: "center",
                                   marginLeft: "10px",
                                   color:
                                     "rgb(111 118 133/var(--tw-text-opacity))",
                                 }}
                               >
                                 {" "}
                                 —{" "}
                                 <span
                                   class="mingcute--location-fill"
                                   style={{
                                     fontSize: "19px",
                                     marginRight: "5px",
                                     marginLeft: "7px",
                                   }}
                                 ></span>
                                 {item.from}
                               </span>
                             </p>
                           </div>
                           <div
                             style={{
                               display: "flex",
                               justifyContent: "space-between",
                               marginBottom: "1rem",
                             }}
                           >
                             <span
                               style={{
                                 background: "#000000",
                                 borderRadius: "15px",
                                 display: "flex",
                                 alignItems: "center",
                               }}
                               className="bagde-flag1"
                             >
                               <span
                                 class="mingcute--airplane-line"
                                 style={{ fontSize: "20px", marginRight: "5px" }}
                               ></span>
                               {item.location}
                             </span>
                           </div>
                         </div>
                       </div>
                     </>
                   ))}
                 </div>
               </div>
             </div>
           </div>
         </section>
        ):(
          <section
          className="relative md:py-24 py-16"
          style={{ marginTop: "20px" }}
        >
          <div className="container">
            <div className="md:flex">
              <div
                className="lg:w-5/5 md:w-full md:mb-8 md:order-2"
                style={{ paddingInline: "1rem" }}
              >
                <div
                  style={{
                    position: "relative",
                    width: "100%",
                    maxWidth: "100%",
                    overflow: "hidden",
                  }}
                >
                  <img
                    style={{
                      width: "100%",
                      left: "0",
                      minWidth: "300px",
                      height: "365px",
                      borderRadius: "10px",
                      objectFit: "cover",
                    }}
                    src={countryDetails?.image}
                    alt="VIsa Search Image"
                  />
           
                  <div
                    className="visa-image"
                    style={{ display: "flex", justifyContent: "space-between" }}
                  > 
                    {countryDetails?.name} 
                    <span
                      className="visa-image1"
                      style={{ paddingRight: "15px" }}
                    >
                   Coming Soon
                    </span>
                    { currentFormData?.is_govt_partner ===1 && <span
                       className="color"
                       style={{
                         top: "-280px",
                         right: "10px",
                         position: "absolute",
                       }}
                     >
                       <li className="inline-block m-2">
                         <Link
                           to="#"
                           className="text-slate-400 bg-gray-50 dark:bg-slate-801 official-partner font-semibold rounded-lg shadow items-center"
                          style={{ overflow: 'hidden', textOverflow: 'ellipsis', }}>
                           Official Partner With {countryDetails?.name} Government
                         </Link>
                       </li>
                     </span>}
                  </div>
                </div>
                </div>
                </div>
                </div>
                </section>
        )}
       
         <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          rtl={false}
           draggable
          theme="light"
           />
        <Footer />
      </>)}
 </>
  );
}
