import React, { useEffect, useState } from 'react';
import { Tooltip, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

export default function NotificationNav({ notificationData }) {
    const [isScrolled, setScrolled] = useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Detects mobile screens

    useEffect(() => {
        const handleScroll = () => {
            const scrolled = window.scrollY > 50;
            setScrolled(scrolled);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <>
            {!isScrolled && (
                <nav id="topnav1" className="defaultscroll1">
                    <div className="alert-banner1 container">
                        <Tooltip
                            title={notificationData}
                            arrow
                        >
                            <Alert
                                className='icon'
                                severity="info"
                                style={{
                                    backgroundColor: 'grey',
                                    color: 'white',
                                    padding:'0px',
                                    display:'flex',
                                    alignContent:'center',
                                    alignItems:'center',
                                }}
                            >
                                <Typography variant={isMobile ? 'caption' : 'body1'}>
                                    {notificationData}
                                </Typography>
                            </Alert>
                        </Tooltip>
                    </div>
                </nav>
            )}
        </>
    );
}